import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DeskCategoriesModalAdmin from "./DeskCategoriesModalAdmin";
import DeskCategoriesTableAdmin from "./DeskCategoriesTableAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";

class DeskCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
    };
  }

  openDeskCategoriesModal() {
    this.setState({
      modal: (
        <DeskCategoriesModalAdmin
          isOpen={true}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openEditModal(deskCategory, target) {
    this.setState({
      modal: (
        <DeskCategoriesModalAdmin
          isOpen={true}
          //openModal={(deskCategory, target) => this.openModal(deskCategory, target)}
          closeModal={() => this.closeModal()}
          deskCategoryId={deskCategory._id}
          target={target}
        />
      ),
    });
  }

  displaysAssociationModal(deskCategory, target) {
    console.log("Traget in editmodal", target);

    this.setState({
      modal: (
        <DeskCategoriesModalAdmin
          isOpen={true}
          openModal={(deskCategory, target) =>
            this.openModal(deskCategory, target)
          }
          closeModal={() => this.closeModal()}
          deskCategoryId={deskCategory._id}
          target={target}
        />
      ),
    });
  }

  displaysProductModal(deskCategory, target) {
    console.log("Traget in editmodal", target);

    this.setState({
      modal: (
        <DeskCategoriesModalAdmin
          isOpen={true}
          openModal={(deskCategory, target) =>
            this.openModal(deskCategory, target)
          }
          closeModal={() => this.closeModal()}
          deskCategoryId={deskCategory._id}
          target={target}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    //console.log('CLOSE MAIN');
    this.setState({ modal: null });
  }

  render() {
    const { deskCategories } = this.props;

    return (
      <React.Fragment>
        <button
          className="btn btn-info m-auto hoverable"
          onClick={(e) => this.openDeskCategoriesModal()}
        >
          <FormattedMessage id="Add.Categories" />
        </button>

        {(!deskCategories || deskCategories.length <= 0) && (
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Empty.Categories" />
          </div>
        )}

        {this.props.deskCategories &&
          this.props.deskCategories.length !== 0 && (
            <DeskCategoriesTableAdmin
              deskCategories={this.props.deskCategories}
              openEditModal={(data) => this.openEditModal(data)}
              displaysAssociationModal={(deskCategories) =>
                this.openEditModal(deskCategories, "association")
              }
              displaysProductModal={(deskCategories) =>
                this.openEditModal(deskCategories, "product")
              }
              openConfModal={(title, content, successCallback) =>
                this.openConfModal(title, content, successCallback)
              }
            />
          )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    deskCategories: state.deskCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskCategories);
