import React from "react";
import { connect } from "react-redux";
import StringUtil from "../../../../util/StringUtil";
import { FormattedMessage } from "react-intl";
import {
  updateAuditRoom,
  deleteAuditRoom,
} from "../../../../actions/settings/auditRooms/admin/auditRooms";
import Util from "../../../../util/Util";
import { UncontrolledTooltip } from "reactstrap";

class AuditRoomsRowAdmin extends React.Component {
  updateAuditRoom(auditRoomId, updatedField, updatedValue) {
    if (!auditRoomId) return;
    if (!this.props.auditRoom) return null;

    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      auditRoomId: auditRoomId,
      updatedField: updatedField,
      updatedValue: updatedValue + 1,
    };
    var successCallback = () => {
      var auditRooms = this.props.auditRooms;
      var auditRoomAscend = auditRooms.filter(
        (obj) => obj.sortIndex === updatedValue + 1
      );
      data = {
        auditRoomId: auditRoomAscend[0]._id,
        updatedField: updatedField,
        updatedValue: updatedValue,
      };
      this.props.onUpdateAuditRoom(data, () => {});
    };
    this.props.onUpdateAuditRoom(data, successCallback);
  }

  deleteAuditRoom(auditRoomId, updatedField, updatedValue) {
    if (!auditRoomId) return;
    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      auditRoomId: auditRoomId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };
    var successCallback = () => {
      var auditRooms = this.props.auditRooms;
      var auditRoomsAscend = auditRooms.filter(
        (obj) => obj.sortIndex > updatedValue
      );
      auditRoomsAscend.map((auditRoom) => {
        data = {
          auditRoomId: auditRoom._id,
          updatedField: updatedField,
          updatedValue: auditRoom.sortIndex - 1,
        };
        this.props.onUpdateAuditRoom(data, () => {
          return null;
        });
        return null;
      });
    };
    this.props.onDeleteAuditRoom(data, successCallback);
  }

  render() {
    // 'Delete audit room' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <React.Fragment>
        <FormattedMessage id="Room.Type.Remove.Confirmation" />
        <br />
        <br />
        <div className="card text-white bg-danger">
          <div className="card-header">
            <i className="fa fa-warning mr-2 text-white"></i>
            <FormattedMessage id="Warning" />
          </div>
          <div className="card-body">
            <p className="card-text">
              <FormattedMessage id="AuditRoom.Delete.Warning.Content" />
            </p>
          </div>
        </div>
      </React.Fragment>
    );
    var successCallback = () =>
      this.deleteAuditRoom(
        this.props.auditRoom._id,
        "sortIndex",
        this.props.auditRoom.sortIndex
      );

    var disabled =
      this.props.auditRooms.length <= this.props.auditRoom.sortIndex;

    return (
      <React.Fragment>
        <tr
          key={this.props.key}
          className="order-tr order-tr tablelist row-striped"
        >
          <td>{this.props.auditRoom.name}</td>
          <td>
            {this.props.auditRoom.default_text &&
              StringUtil.truncate(this.props.auditRoom.default_text, 30)}
          </td>
          <td>
            {this.props.auditRoom.specific_text &&
              StringUtil.truncate(this.props.auditRoom.specific_text, 30)}
          </td>

          <td className="d-none d-md-table-cell align-middle">
            <i
              id={"dmassociated" + this.props.auditRoom._id}
              className="fa fa-eye icon-big"
              onClick={(e) =>
                this.props.displaysDmAssociatedModal(this.props.auditRoom)
              }
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"dmassociated" + this.props.auditRoom._id}
            >
              <FormattedMessage id="Display.Associations" />
            </UncontrolledTooltip>
          </td>

          <td className="text-center tdaction align-top">
            <i
              id={"sort" + this.props.auditRoom._id}
              className="fa fa-arrow-down p-2"
              disabled={disabled}
              onClick={(e) => {
                if (!disabled) {
                  this.updateAuditRoom(
                    this.props.auditRoom._id,
                    "sortIndex",
                    this.props.auditRoom.sortIndex
                  );
                }
              }}
            ></i>
            {!disabled && (
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"sort" + this.props.auditRoom._id}
              >
                <FormattedMessage id="Retrograde" />
              </UncontrolledTooltip>
            )}
            <i
              id={"modify" + this.props.auditRoom._id}
              className="fa fa-edit icon-big"
              onClick={(e) => this.props.openEditModal(this.props.auditRoom)}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"modify" + this.props.auditRoom._id}
            >
              <FormattedMessage id="Modify" />
            </UncontrolledTooltip>
            <i
              id={"delete" + this.props.auditRoom._id}
              className="fa fa-trash icon-big"
              onClick={(e) =>
                this.props.openConfModal(
                  modalTitle,
                  modalContent,
                  successCallback
                )
              }
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"delete" + this.props.auditRoom._id}
            >
              <FormattedMessage id="Delete" />
            </UncontrolledTooltip>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAuditRoom: (data, successCallback) =>
      dispatch(deleteAuditRoom(data, successCallback)),
    onUpdateAuditRoom: (data, successCallback) =>
      dispatch(updateAuditRoom(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditRoomsRowAdmin);
