import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import AuditRoomsModalAdmin from "./AuditRoomsModalAdmin";
import AuditRoomsTableAdmin from "./AuditRoomsTableAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";

class AuditRooms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openAuditRoomsModal() {
    this.setState({
      modal: (
        <AuditRoomsModalAdmin
          auditRooms={this.props.auditRooms}
          isOpen={true}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  displaysDmAssociatedModal(auditRoom, target) {
    console.log("Traget in displaysDmAssociatedModal", target);

    this.setState({
      modal: (
        <AuditRoomsModalAdmin
          isOpen={true}
          openModal={(auditRoomId, target) =>
            this.openModal(auditRoomId, target)
          }
          closeModal={() => this.closeModal()}
          auditRoomId={auditRoom._id}
          target={target}
        />
      ),
    });
  }

  openEditModal(auditRoom, target) {
    console.log("Traget in openEditModal", target);
    this.setState({
      modal: (
        <AuditRoomsModalAdmin
          isOpen={true}
          closeModal={() => this.closeModal()}
          auditRoomId={auditRoom._id}
          target={target}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    const { auditRooms } = this.props;

    return (
      <React.Fragment>
        <button
          className="btn btn-info m-auto hoverable"
          onClick={(e) => this.openAuditRoomsModal()}
        >
          <FormattedMessage id="Add.Room" />
        </button>

        {(!auditRooms || auditRooms.length <= 0) && (
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Empty.Audit.Room" />
          </div>
        )}

        {this.props.auditRooms && this.props.auditRooms.length !== 0 && (
          <AuditRoomsTableAdmin
            auditRooms={this.props.auditRooms}
            displaysDmAssociatedModal={(auditRoom) =>
              this.openEditModal(auditRoom, "dmassociated")
            }
            openEditModal={(data) => this.openEditModal(data)}
            openConfModal={(title, content, successCallback) =>
              this.openConfModal(title, content, successCallback)
            }
          />
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditRooms);
