import React from "react";
import DateUtil from "../../../../util/DateUtil";
import MercurialStatus from "../../../../enums/MercurialStatus";
import APIUrl from "../../../../APIUrl";

class PromotionalOperationsRow extends React.Component {
  render() {
    const { promoOperation, mercurial } = this.props;

    var isOutDated = true;
    var isInactive = true;
    if (mercurial) {
      isOutDated =
        new Date(mercurial.end_date).setHours(0, 0, 0, 0) <
        new Date(new Date().setHours(0, 0, 0, 0));
      isInactive = mercurial.status === MercurialStatus.INACTIVE;
    }

    return (
      <React.Fragment>
        <tr
          key={this.props.keyId}
          className={
            isOutDated || isInactive
              ? "font-italic text-muted tre4mad"
              : "order-tr order-tr tablelist row-striped"
          }
        >
          <td>{promoOperation.operationName}</td>
          <td>{DateUtil.toDate(promoOperation.startDate)}</td>
          <td>{DateUtil.toDate(promoOperation.endDate)}</td>
          <td>
            <a
              role="button"
              href={
                APIUrl.getPromoOperationFile +
                promoOperation._id +
                "/" +
                promoOperation.fileName +
                "?token=" +
                APIUrl.jwtToken
              }
              rel="noopener noreferrer"
              download={promoOperation.fileName}
              target="_blank"
            >
              {promoOperation.fileName}
            </a>
          </td>
          <td className="text-center tdaction align-top">
            <i
              className="fa fa-edit icon-big"
              disabled={isInactive || isOutDated}
              onClick={(e) => {
                if (!isInactive && !isOutDated)
                  this.props.editPromotionalOperationModal(promoOperation);
              }}
            ></i>
            <i
              className="fa fa-trash icon-big"
              onClick={(e) =>
                this.props.deletePromotionalOperationModal(promoOperation._id)
              }
            ></i>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default PromotionalOperationsRow;
