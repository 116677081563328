import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import FileDropZone from "../../../sub/FileDropZone";
import {
  addAuditDM,
  updateAuditDM,
  getAuditDMByRoomIdAndName,
  addAuditDMFile,
} from "../../../../actions/settings/auditDM/admin/auditDM";
import Util from "../../../../util/Util";
import EqSellModes from "../../../../enums/EqSellModes";
import APIUrl from "../../../../APIUrl";
import { Button, Modal } from "react-bootstrap";

class RoomsAuditModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      room_id: "",
      text: "",
      type: "",
      refundable: false,
      file: null,
      renouv_vte: "",
      prescription_type_vte_text: "",
      prescription_type_loc_text: "",

      nameError: null,
      disabled: false,
      disabledFileUpload: false,
      modal: null,
    };

    if (this.props.auditDMId) {
      var auditDM = this.getAuditDM(this.props.auditDMId);

      this.state = {
        name: auditDM.name,
        room_id: auditDM.room_id,
        text: auditDM.text,
        type: auditDM.type.toString(),
        refundable: auditDM.refundable,
        renouv_vte: auditDM.renouv_vte,
        prescription_type_vte_text: auditDM.prescription_type_vte_text,
        prescription_type_loc_text: auditDM.prescription_type_loc_text,
        nameError: null,
        disabled: false,
        modal: null,
      };
    }
  }

  getAuditDM(auditDMId) {
    for (let auditDM of this.props.auditDMs) {
      if (auditDM._id === auditDMId) return auditDM;
    }
  }

  close() {
    this.props.closeModal();
  }

  onChange(key, value) {
    if (key === "refundable")
      this.setState({ refundable: !this.state.refundable });
    else this.setState({ [key]: value });

    var nameValue = document.getElementById("name-material-dm").value;
    var roomIdValue = document.getElementById("room-id-material-dm").value;

    if (key !== "name" && key !== "room_id") return;

    if (Util.emptyString(nameValue) || Util.emptyString(roomIdValue)) return;

    if (this.props.auditDMId) {
      var auditDM = this.getAuditDM(this.props.auditDMId);

      if (nameValue === auditDM.name && roomIdValue === auditDM.room_id) return;

      this.isExist(nameValue, roomIdValue);
    } else {
      this.isExist(nameValue, roomIdValue);
    }
  }

  isExist(name, room_id) {
    var successCallback = (auditRoom) => {
      if (auditRoom)
        this.setState({ nameError: <FormattedMessage id="Material.Exist" /> });
      else this.setState({ nameError: null });
    };

    this.props.onGetAuditDMByRoomIdAndName(name, room_id, successCallback);
  }

  onDropFile(file, clearCallback) {
    if (!file || file.length === 0) return;

    // Update mode. Immediately send file to BE
    if (this.props.auditDMId) {
      this.setState({ disabledFileUpload: true });

      let formData = new FormData();
      formData.append(file.name, file);

      var successCallback = () => {
        this.setState({ disabledFileUpload: false });
        clearCallback();
      };

      // Send to BE
      this.props.onAddAuditDMFile(
        this.props.auditDMId,
        this.props.roomId,
        formData,
        successCallback
      );
    }
    // Create mode. Store the file and wait for the onComplete event
    else {
      this.setState({ file: file });
    }
  }

  onSubmit() {
    var sendfile = (eq) => {
      if (!this.state.file || this.state.file.length === 0)
        return this.props.closeModal();

      let formData = new FormData();
      formData.append(this.state.file.name, this.state.file);

      this.props.onAddAuditDMFile(eq._id, eq.room_id, formData, () =>
        this.props.closeModal()
      );
    };

    var addAuditDM = (auditDM) => {
      this.setState({ disabled: true, disabledFileUpload: true });

      this.props.onAddAuditDM(auditDM, sendfile);
    };

    var imageExist = false;

    if (this.state.file) imageExist = true;

    var data = {
      name: this.state.name,
      room_id: this.state.room_id,
      text: this.state.text,
      type: this.state.type,
      refundable: this.state.refundable,
      renouv_vte: this.state.renouv_vte,
      prescription_type_vte_text: this.state.prescription_type_vte_text,
      prescription_type_loc_text: this.state.prescription_type_loc_text,
      image: imageExist,
    };

    console.log(data);

    addAuditDM(data);
  }

  onUpdate(updatedField, updatedValue) {
    if (!this.props.auditDMId || this.checkErrors()) return;

    var data = {
      auditDMId: this.props.auditDMId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    this.props.onUpdateAuditDM(data);
  }

  checkErrors() {
    return (
      this.state.nameError ||
      this.state.disabled ||
      this.state.disabledFileUpload ||
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.type) ||
      Util.emptyString(this.state.room_id)
    );
  }

  render() {
    let roomSelectOptions = this.props.auditRooms.map((auditRoom) => {
      return (
        <option key={auditRoom._id} value={auditRoom._id}>
          {auditRoom.name}{" "}
        </option>
      );
    });

    let typeDMSelectOptions = Object.values(EqSellModes).map((EqSellMode) => {
      return (
        <option key={EqSellMode} value={EqSellMode}>
          {this.props.intl.formatMessage({ id: "EqSellMode." + EqSellMode })}
        </option>
      );
    });

    var img;
    if (this.props.auditDMId) {
      var auditDM = this.getAuditDM(this.props.auditDMId);
      if (!auditDM) return null;

      var imgSrc =
        APIUrl.getAuditDMImg +
        auditDM._id +
        "/" +
        Math.random() +
        "?token=" +
        APIUrl.jwtToken;
      img = (
        <img
          src={imgSrc}
          className="col-form-label m-3"
          alt={"audit_DM_img" + Math.random()}
          height="150"
          width="150"
        />
      );
    }

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Add.DM" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-8">
                {auditDM && auditDM.image && (
                  <div className="d-none d-lg-block w-150 rounded light-card text-center">
                    <div className="form-group row m-0 p-0"></div>
                    <div className="w-150 m-0 p-0">{img}</div>
                  </div>
                )}

                <div className="form-group row mt-3">
                  <label
                    htmlFor="name"
                    className="col-12 col-md-4 col-form-label"
                  >
                    <FormattedMessage id="Name" />
                  </label>
                  <div id="name" className="col-12 col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      id="name-material-dm"
                      autoComplete="off"
                      value={this.state.name}
                      onChange={(e) => this.onChange("name", e.target.value)}
                      onBlur={(e) => this.onUpdate("name", this.state.name)}
                    />
                    <small className="text-danger">
                      {this.state.nameError}
                    </small>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="room"
                    className="col-12 col-md-4 col-form-label"
                  >
                    <FormattedMessage id="Audit.Room" />
                  </label>
                  <div className="col-12 col-md-8">
                    <select
                      id="room-id-material-dm"
                      className="form-control w-100"
                      value={this.state.room_id}
                      onChange={(e) => this.onChange("room_id", e.target.value)}
                      onBlur={(e) =>
                        this.onUpdate("room_id", this.state.room_id)
                      }
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "Select" })}
                      </option>
                      {roomSelectOptions}
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="dm"
                    className="col-12 col-md-4 col-form-label pt-0"
                  >
                    <FormattedMessage id="Image" />{" "}
                  </label>
                  <div className="col-12 col-md-8">
                    <FileDropZone
                      disabled={this.state.disabledFileUpload}
                      onDropFile={(file, clearCallback) =>
                        this.onDropFile(file, clearCallback)
                      }
                      acceptedExtensions={["jpg", "png"]}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="text"
                    className="col-12 col-md-4 col-form-label pt-0"
                  >
                    <FormattedMessage id="Text" />
                  </label>
                  <div id="text" className="col-12 col-md-8">
                    <textarea
                      className="form-control"
                      type="text"
                      id="text"
                      rows="7"
                      value={this.state.text}
                      onChange={(e) => this.onChange("text", e.target.value)}
                      onBlur={(e) => this.onUpdate("text", this.state.text)}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="refundable"
                    className="col-12 col-md-4 col-form-label"
                  >
                    <FormattedMessage id="Refundable" />
                  </label>
                  <div className="col-12 col-md-8">
                    <div className="custom-control custom-switch mx-auto switch-success text-left">
                      <input
                        type="checkbox"
                        id="refundable-switch"
                        className="custom-control-input switch-bg-blue"
                        checked={this.state.refundable}
                        value={this.state.refundable}
                        onChange={(e) => this.onChange("refundable")}
                        onBlur={(e) =>
                          this.onUpdate("refundable", this.state.refundable)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="refundable-switch"
                      ></label>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="type"
                    className="col-12 col-md-4 col-form-label"
                  >
                    <FormattedMessage id="Type" />
                  </label>
                  <div className="col-12 col-md-8">
                    <select
                      id="type"
                      className="form-control w-100"
                      value={this.state.type}
                      onChange={(e) => this.onChange("type", e.target.value)}
                      onBlur={(e) => this.onUpdate("type", this.state.type)}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "Select" })}
                      </option>
                      {typeDMSelectOptions}
                    </select>
                  </div>
                </div>

                {(this.state.type === "1" || this.state.type === "2") && (
                  <div className="form-group row">
                    <label
                      htmlFor="prescription_type_loc_text"
                      className="col-12 col-md-4 col-form-label"
                    >
                      <FormattedMessage id="Prescription.Loc" />
                    </label>
                    <div
                      id="prescription_type_loc_text"
                      className="col-12 col-md-8"
                    >
                      <textarea
                        className="form-control"
                        type="text"
                        value={this.state.prescription_type_loc_text}
                        id="prescription_type_loc_text"
                        rows="4"
                        placeholder={this.defaultPrescriptionText}
                        onChange={(e) =>
                          this.onChange(
                            "prescription_type_loc_text",
                            e.target.value
                          )
                        }
                        onBlur={(e) =>
                          this.onUpdate(
                            "prescription_type_loc_text",
                            this.state.prescription_type_loc_text
                          )
                        }
                      />
                    </div>
                  </div>
                )}

                {(this.state.type === "0" || this.state.type === "2") && (
                  <div className="form-group row">
                    <label
                      htmlFor="prescription_type_vte_text"
                      className="col-12 col-md-4 col-form-label"
                    >
                      <FormattedMessage id="Prescription.Sail" />
                    </label>
                    <div
                      id="prescription_type_vte_text"
                      className="col-12 col-md-8"
                    >
                      <textarea
                        className="form-control"
                        type="text"
                        value={this.state.prescription_type_vte_text}
                        id="prescription_type_vte_text"
                        rows="4"
                        placeholder={this.defaultPrescriptionText}
                        onChange={(e) =>
                          this.onChange(
                            "prescription_type_vte_text",
                            e.target.value
                          )
                        }
                        onBlur={(e) =>
                          this.onUpdate(
                            "prescription_type_vte_text",
                            this.state.prescription_type_vte_text
                          )
                        }
                      />
                    </div>
                  </div>
                )}

                {(this.state.type === "0" || this.state.type === "2") && (
                  <div className="form-group row">
                    <label
                      htmlFor="purchase_sail"
                      className="col-12 col-md-4 col-form-label"
                    >
                      <FormattedMessage id="Purchase.Sails" />
                    </label>
                    <div className="col-12 col-md-8">
                      <select
                        className="form-control d-inline"
                        id="renouv_vte"
                        value={this.state.renouv_vte}
                        onChange={(e) =>
                          this.onChange("renouv_vte", e.target.value)
                        }
                        onBlur={(e) =>
                          this.onUpdate("renouv_vte", this.state.renouv_vte)
                        }
                      >
                        <option
                          value="0"
                          defaultValue={
                            this.props.renouv_vte === "0" ? true : false
                          }
                        >
                          0 {this.props.intl.formatMessage({ id: "year" })}
                        </option>
                        <option
                          value="1"
                          defaultValue={
                            this.props.renouv_vte === "1" ? true : false
                          }
                        >
                          1 {this.props.intl.formatMessage({ id: "year" })}
                        </option>
                        <option
                          value="2"
                          defaultValue={
                            this.props.renouv_vte === "2" ? true : false
                          }
                        >
                          2 {this.props.intl.formatMessage({ id: "Years" })}
                        </option>
                        <option
                          value="3"
                          defaultValue={
                            this.props.renouv_vte === "3" ? true : false
                          }
                        >
                          3 {this.props.intl.formatMessage({ id: "Years" })}
                        </option>
                        <option
                          value="5"
                          defaultValue={
                            this.props.renouv_vte === "5" ? true : false
                          }
                        >
                          5 {this.props.intl.formatMessage({ id: "Years" })}
                        </option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>

          {!this.props.auditDMId && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.close()}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="info"
                onClick={() => this.onSubmit()}
                disabled={this.checkErrors()}
              >
                <FormattedMessage id="Add" />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
    auditDMs: state.auditDMs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAuditDM: (data, successCallback) =>
      dispatch(addAuditDM(data, successCallback)),
    onGetAuditDMByRoomIdAndName: (name, room_id, successCallback) =>
      dispatch(getAuditDMByRoomIdAndName(name, room_id, successCallback)),
    onUpdateAuditDM: (data) => dispatch(updateAuditDM(data)),
    onAddAuditDMFile: (auditDMId, roomId, data, successCallback) =>
      dispatch(addAuditDMFile(auditDMId, roomId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RoomsAuditModalAdmin));
