import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_PRODUCTS = "GET_PRODUCTS";

function getProductsAction(products) {
  return { type: GET_PRODUCTS, products: products };
}

export const getProducts = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getProducts)
      .then(function (response) {
        dispatch(getProductsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ==================================================================================
// ================================== ADMIN =========================================
// ==================================================================================

export const getProductsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getProductsAdmin)
      .then(function (response) {
        dispatch(getProductsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

/**
 *
 * @param {*} mercurialId
 * @param {*} getAllProducts Boolean : If set to true, all products are downloaded. ilf false (default) only a subset.
 * @returns
 */
export const getProductsByMercurialIdAdmin = function (
  mercurialId,
  getAllProducts
) {
  // Define if all products must be loaded (true) or not (false)
  // const loadAllProducts = (getAllProducts === undefined) ? false : typeof (getAllProducts === "boolean") ? getAllProducts : false;

  return function (dispatch) {
    return axios
      .get(APIUrl.getProductsByMercurialIdAdmin + mercurialId)
      .then(function (response) {
        dispatch(getProductsAction(response.data));
        return response.data;
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateProductAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateProductAdmin, data)
      .then(function (response) {
        dispatch(getProductsAdmin());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
