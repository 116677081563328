import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderRowAdmin from "./OrderRowAdmin";
import ConfirmationModal from "../../sub/modals/ConfirmationModal";
import SplitModal from "./SplitOrderModalAdmin";
import OrderStatus from "../../../enums/OrderStatus";
import Paginator from "../../sub/Paginator";
import DateUtil from "../../../util/DateUtil";
import StringUtil from "../../../util/StringUtil";
import Util from "../../../util/Util";
import { UncontrolledTooltip } from "reactstrap";

class OrdersAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      clientFilter: "",
      referenceFilter: "",
      dateStartFilter: "",
      dateEndFilter: "",
      statusFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  openSplitModal(order) {
    this.setState({
      modal: (
        <SplitModal
          isOpen={true}
          order={order}
          closeConfModal={() => this.closeModal()}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  sortByDateDesc(order1, order2) {
    if (order1.creation_date > order2.creation_date) return -1;
    if (order1.creation_date < order2.creation_date) return 1;
    return 0;
  }

  getAdmin(establishmentId) {
    for (var collaborator of this.props.collaborators) {
      if (collaborator.establishments) {
        for (var establishment of collaborator.establishments) {
          if (establishment.establishment_id === establishmentId)
            return collaborator;
        }
      }
    }
  }

  getEstablisment(establishmentId) {
    for (var establishment of this.props.establishments) {
      if (establishment._id === establishmentId) return establishment;
    }
  }

  setStartDateFilter = (date) => {
    this.setState({
      dateStartFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0
      ),
    });
  };

  setEndDateFilter = (date) => {
    this.setState({
      dateEndFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        23,
        59,
        59,
        999
      ),
    });
  };

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.clientFilter) ||
      !Util.emptyString(this.state.referenceFilter) ||
      !Util.emptyString(this.state.dateStartFilter) ||
      !Util.emptyString(this.state.dateEndFilter) ||
      !Util.emptyString(this.state.statusFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      clientFilter: "",
      referenceFilter: "",
      dateStartFilter: "",
      dateEndFilter: "",
      statusFilter: "",
    });
  }

  render() {
    if (!this.props.orders || this.props.orders.length === 0) {
      return (
        <React.Fragment>
          <div className="alert alert-secondary" role="alert">
            <FormattedMessage id="Admin.Empty.Orders" />
          </div>
        </React.Fragment>
      );
    }

    let orders = this.props.orders.sort(this.sortByDateDesc);

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;
    let ordersNode = orders.map((order) => {
      var client;
      for (var c of this.props.clients) {
        if (c._id === order.id_client) {
          client = c;
          break;
        }
      }

      if (!client) return null;

      if (this.state.clientFilter && this.state.clientFilter !== "") {
        if (client._id !== this.state.clientFilter) return null;
      }

      if (this.state.referenceFilter && this.state.referenceFilter !== "") {
        if (order.ref.indexOf(this.state.referenceFilter) === -1) return null;
      }

      if (this.state.dateStartFilter && this.state.dateStartFilter !== "") {
        if (
          new Date(order.creation_date) < new Date(this.state.dateStartFilter)
        )
          return null;
      }

      /*
            if (this.state.dateEndFilter && this.state.dateEndFilter !== "") {
                if (new Date(order.creation_date) >= new Date(this.state.dateEndFilter)) return null;
            }
            */

      if (this.state.statusFilter && this.state.statusFilter !== "") {
        if (order.status !== Number(this.state.statusFilter)) return null;
      }

      if (order.status === OrderStatus.TBV) return null;

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <OrderRowAdmin
          key={order._id}
          order={order}
          products={order.products}
          admin={this.getAdmin(client.establishment_id)}
          establishment={this.getEstablisment(client.establishment_id)}
          client={client}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
          openSplitModal={(order) => this.openSplitModal(order)}
          limit={this.props.limit && true}
        />
      );
    });

    var clientsNode = this.props.clients.map((client) => {
      return (
        <option key={client._id} value={client._id}>
          {client.name.toUpperCase()} {StringUtil.ucFirst(client.first_name)}
        </option>
      );
    });

    var statusNode = Object.values(OrderStatus).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "Order.Status." + option })}
        </option>
      );
    });

    // Define a button to reset search filters
    let resetSearchButton = () => {
      let button = (
        <button className="btn btn-outline-secondary" disabled>
          <i className="fa fa-filter"></i>
        </button>
      );

      if (this.areResultsFiltered()) {
        return (
          <>
            <button
              id="searchFilterBtn"
              className="btn btn-warning"
              onClick={() => this.resetSearchFields()}
            >
              <i className="fa fa-filter"></i>
            </button>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="bottom"
              target="searchFilterBtn"
            >
              <FormattedMessage id="Remove.Filter" />
            </UncontrolledTooltip>
          </>
        );
      } else {
        return button;
      }
    };

    return (
      <React.Fragment>
        {!this.props.limit && (
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-inline">
                    <label className="my-1 mr-2" htmlFor="search_client">
                      {this.props.intl.formatMessage({ id: "Client" })}
                    </label>
                    <select
                      id="search_client"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ clientFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.clientFilter}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      {clientsNode}
                    </select>

                    <label className="my-1 mr-2" htmlFor="search_reference">
                      {this.props.intl.formatMessage({ id: "Reference" })}
                    </label>
                    <input
                      id="search_reference"
                      className="form-control col-1 mr-sm-3"
                      onChange={(e) => {
                        this.setState({ referenceFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.referenceFilter}
                    />

                    <label className="my-1 mr-2" htmlFor="search_date">
                      {this.props.intl.formatMessage({ id: "Date" })}
                    </label>
                    <input
                      id="search_date"
                      className="form-control mr-sm-3"
                      type="date"
                      onChange={(e) => {
                        this.setStartDateFilter(e.target.value);
                      }}
                      disabled={disableFormInput}
                      value={DateUtil.toyyyyMMdd(this.state.dateStartFilter)}
                    />

                    {/* <label className="my-1 mr-2" htmlFor="search_delivery_date">{this.props.intl.formatMessage({ id: "Delivery.Date" })}</label>
                                    <input id="search_delivery_date" className="form-control mr-sm-3" type="date" onChange={(e) => { this.setEndDateFilter(e.target.value) }} value={DateUtil.toyyyyMMdd(this.state.dateEndFilter)} disabled={disableFormInput} /> */}

                    <label className="my-1 mr-2" htmlFor="search_status">
                      {this.props.intl.formatMessage({ id: "Status" })}
                    </label>
                    <select
                      id="search_status"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ statusFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.statusFilter}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      {statusNode}
                    </select>
                    {resetSearchButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <table className="table tablee4mad">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="Client" />
              </th>
              <th scope="col">
                <FormattedMessage id="Reference" />
              </th>
              <th scope="col">
                <FormattedMessage id="Date" />
              </th>
              <th scope="col">
                <FormattedMessage id="Expected.Delivery.Date" />
              </th>
              <th scope="col">
                <FormattedMessage id="Total.Excl.Tax" />
              </th>
              <th scope="col">
                <FormattedMessage id="Order.Status" />
              </th>
              {!this.props.limit && (
                <th scope="col" className="text-center">
                  <FormattedMessage id="Actions" />
                </th>
              )}
              <th scope="col" className="text-center">
                <FormattedMessage id="Urgent" />
              </th>
            </tr>
          </thead>
          <tbody>{ordersNode}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    clients: state.clients,
    collaborators: state.collaborators,
    establishments: state.establishments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OrdersAdmin));
