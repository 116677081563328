import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_REQUESTS = "GET_REQUESTS";

function getRequestsAction(requests) {
  return { type: GET_REQUESTS, requests: requests };
}

export const getRequests = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getRequests)
      .then(function (response) {
        dispatch(getRequestsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const createRequest = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addNewRequest, data)
      .then(function (response) {
        dispatch(getRequests());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ======================================================================
// ============================= ADMIN ==================================
// ======================================================================

export const getAllRequests = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAllRequests)
      .then(function (response) {
        dispatch(getRequestsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteRequest = function (requestId) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteRequest, requestId)
      .then(function (response) {
        dispatch(getAllRequests());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateRequest = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateRequest, data)
      .then(function (response) {
        dispatch(getAllRequests());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addRequestFiles = function (requestId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addRequestFiles + requestId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getAllRequests());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteRequestFile = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteRequestFile, data)
      .then(function (response) {
        dispatch(getAllRequests());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const createRequestAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addNewRequestAdmin, data)
      .then(function (response) {
        dispatch(getAllRequests());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
