import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import Util from "../../../util/Util";
import FileDropZone from "../FileDropZone";
import CustomLabel from "../CustomLabel";
import PropTypes from "prop-types";

const propTypes = {
  data: PropTypes.object.isRequired,
  documentType: PropTypes.string.isRequired,
  addDataDocument: PropTypes.any.isRequired,
  updateData: PropTypes.any.isRequired,
  close: PropTypes.any.isRequired,
  messageId: PropTypes.string.isRequired,
};

/**
 * Modal to add a document with title
 * @param {Array} data Object where document will be store
 * @param {String} documentType string to define what type of document it will be
 * @param {any} addDataDocument fonction to store file
 * @param {any} updateData fonction to update data
 * @param {any} close fonction to close modal
 * @param {string} messageId id for formatted message to display modal title
 */
class AddDocumentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: "",
      file: null,
      disabled: false,

      fileError: false,
    };
  }

  disabled() {
    return (
      Util.emptyString(this.state.label) ||
      !this.state.file ||
      this.state.disabled ||
      this.state.fileError
    );
  }

  onDropFile(file) {
    this.setState({ file: file });

    if (
      this.props.data[this.props.documentType].find(
        (p) => p.file_name === file.name
      )
    ) {
      this.setState({
        fileError: (
          <FormattedMessage
            id="File.Already.Exists"
            values={{ fileName: file.name }}
          />
        ),
      });
    } else {
      this.setState({ fileError: false });
    }
  }

  onSubmit() {
    if (this.disabled()) return;

    const { data, documentType, updateData, addDataDocument, close } =
      this.props;

    if (!data) return;

    this.setState({ disabled: true });

    const successCallback = () => {
      let clonedData = Util.shallowClone(data);
      if (!clonedData[documentType]) clonedData[documentType] = [];

      clonedData[documentType].push({
        title: this.state.label,
        date: Date.now(),
        file_name: this.state.file.name,
      });

      updateData(clonedData, () => close());
    };

    let formData = new FormData();
    formData.append(this.state.file.name, this.state.file);
    addDataDocument(data._id, formData, successCallback);
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal show={true} onHide={() => this.props.close()}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.intl.formatMessage({ id: this.props.messageId })}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Title" })}
                htmlFor="label"
                labelClassName="col-12 col-sm-5 col-form-label"
                required
              />
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="label"
                  autoComplete="off"
                  value={this.state.label}
                  onChange={(e) => this.setState({ label: e.target.value })}
                />
              </div>
            </div>

            <FileDropZone
              onDropFile={(file) => this.onDropFile(file)}
              acceptedExtensions={["pdf", "doc", "docx", "txt", "opt"]}
              multiple={false}
            />

            <div className="w-100 text-center">
              <small className="text-danger">{this.state.fileError}</small>
            </div>

            <div className="row mt-5">
              <div className="col-12 col-lg-6 offset-lg-3">
                <button
                  className="btn btn-info btn-block"
                  disabled={this.disabled()}
                  onClick={() => this.onSubmit()}
                >
                  <FormattedMessage id="Add" />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

AddDocumentModal.propTypes = propTypes;

export default injectIntl(AddDocumentModal);
