import React from "react";
import { connect } from "react-redux";
// import StringUtil from '../../../../util/StringUtil';
import { FormattedMessage, injectIntl } from "react-intl";
import {
  updateDeskCategory,
  deleteDeskCategory,
} from "../../../../actions/settings/deskCategories/admin/deskCategories";
import Util from "../../../../util/Util";
import { UncontrolledTooltip } from "reactstrap";

class DeskCategoriesRowAdmin extends React.Component {
  updateDeskCategory(deskCategoryId, updatedField, updatedValue) {
    //	console.log('updateDeskCategory',deskCategoryId, updatedField, updatedValue);

    if (!deskCategoryId) return;
    if (!this.props.deskCategory) return null;

    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      deskCategoryId: deskCategoryId,
      updatedField: updatedField,
      updatedValue: updatedValue + 1,
    };
    var successCallback = () => {
      var deskCategories = this.props.deskCategories;
      var deskCategoriesAscend = deskCategories.filter(
        (obj) => obj.sortIndex === updatedValue + 1
      );
      data = {
        deskCategoryId: deskCategoriesAscend[0]._id,
        updatedField: updatedField,
        updatedValue: updatedValue,
      };
      this.props.onUpdateDeskCategory(data, () => {});
    };

    this.props.onUpdateDeskCategory(data, successCallback);
  }

  deleteDeskCategory(deskCategoryId, updatedField, updatedValue) {
    if (!deskCategoryId) return;
    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      deskCategoryId: deskCategoryId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    var successCallback = () => {
      var deskCategories = this.props.deskCategories;
      var deskCategoriesAscend = deskCategories.filter(
        (obj) => obj.sortIndex > updatedValue
      );
      deskCategoriesAscend.map((deskCategory) => {
        data = {
          deskCategoryId: deskCategory._id,
          updatedField: updatedField,
          updatedValue: deskCategory.sortIndex - 1,
        };
        this.props.onUpdateDeskCategory(data, () => {
          return null;
        });
        return null;
      });
    };

    //console.log('deleteDeskCategory data',data);

    this.props.onDeleteDeskCategory(data, successCallback);
  }

  render() {
    //var refundable = <FormattedMessage id="No" />;

    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <React.Fragment>
        <div>
          <FormattedMessage id="Type.Category.Remove.Confirmation" />
          <br />
          <br />
          <div className="card text-white bg-danger">
            <div className="card-header">
              <i className="fa fa-warning mr-2 text-white"></i>
              <FormattedMessage id="Warning" />
            </div>
            <div className="card-body">
              <p className="card-text">
                <FormattedMessage id="Category.Delete.Warning.Content" />
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    var successCallback = () =>
      this.deleteDeskCategory(
        this.props.deskCategory._id,
        "sortIndex",
        this.props.deskCategory.sortIndex
      );

    var disabled =
      this.props.deskCategory._id.length <= this.props.deskCategory.sortIndex;

    return (
      <React.Fragment>
        <tr
          key={this.props.key}
          className="order-tr order-tr tablelist row-striped"
        >
          <td className="align-middle">{this.props.deskCategory.name}</td>
          <td className="d-none d-md-table-cell align-middle">
            <i
              id={"association" + this.props.deskCategory._id}
              className="fa fa-eye icon-big"
              onClick={(e) =>
                this.props.displaysAssociationModal(this.props.deskCategory)
              }
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"association" + this.props.deskCategory._id}
            >
              <FormattedMessage id="Display.Associations" />
            </UncontrolledTooltip>
          </td>
          <td className="d-none d-md-table-cell align-middle">
            <i
              id={"product" + this.props.deskCategory._id}
              className="fa fa-eye icon-big"
              onClick={(e) =>
                this.props.displaysProductModal(this.props.deskCategory)
              }
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"product" + this.props.deskCategory._id}
            >
              <FormattedMessage id="Display.Typeproducts" />
            </UncontrolledTooltip>
          </td>

          <td className="text-center tdaction align-middle">
            <i
              id={"sort" + this.props.deskCategory._id}
              className="fa fa-arrow-down p-2"
              disabled={disabled}
              onClick={(e) => {
                if (!disabled) {
                  this.updateDeskCategory(
                    this.props.deskCategory._id,
                    "sortIndex",
                    this.props.deskCategory.sortIndex
                  );
                }
              }}
            ></i>

            {!disabled && (
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"sort" + this.props.deskCategory._id}
              >
                <FormattedMessage id="Retrograde" />
              </UncontrolledTooltip>
            )}

            <i
              id={"modify" + this.props.deskCategory._id}
              className="fa fa-edit icon-big"
              onClick={(e) => this.props.openEditModal(this.props.deskCategory)}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"modify" + this.props.deskCategory._id}
            >
              <FormattedMessage id="Modify" />
            </UncontrolledTooltip>

            <i
              id={"delete" + this.props.deskCategory._id}
              className="fa fa-trash icon-big"
              onClick={(e) =>
                this.props.openConfModal(
                  modalTitle,
                  modalContent,
                  successCallback
                )
              }
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"delete" + this.props.deskCategory._id}
            >
              <FormattedMessage id="Delete" />
            </UncontrolledTooltip>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateDeskCategory: (data) => dispatch(updateDeskCategory(data)),
    onDeleteDeskCategory: (data) => dispatch(deleteDeskCategory(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeskCategoriesRowAdmin));
