import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../../util/Util";
import {
  addDeskProduct,
  updateDeskProduct,
  addDeskProductFile,
  deleteTypeofControl,
  addDeskCategoryRelated,
  addDeskProductRelated,
  deleteDeskProductRelated,
  deleteDeskCategoryRelated,
} from "../../../../actions/settings/deskCategories/admin/deskProducts";
import FileDropZone from "../../../sub/FileDropZone";
//import DeskCategoriesModalAdmin from './DeskCategoriesModalAdmin';
import ControlPointModal from "../../../sub/modals/ControlPointModal";
import { UncontrolledTooltip } from "reactstrap";
//import Select from 'react-select'
//import AsyncSelect from 'react-select/lib/AsyncSelect';
import ComboBox from "../../../sub/ComboBox";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import APIUrl from "../../../../APIUrl";
import { Button, Modal } from "react-bootstrap";

class DeskProductsModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.defaultPrescriptionText =
      "L'état de santé de M/Mme .......................... nécessite :";

    this.state = {
      image: "",
      modal: null,
      name: "",
      ref: "",
      category_id: this.props.deskCategoryId,
      description_text: "",
      refundable: false,
      vte_loc: "Location",
      renouv_vte: "",
      prescription_type_vte_text: "",
      prescription_type_loc_text: "",
      relatedProducts: [],
      relatedCategories: [],
      relatedQuestions: [],
      relatedRecommendations: [],
      type: "",
      disabled: false,
      typeofcontrol: "Question",
      questionnary: "Question",
      productRelatedId: "",
      categoryRelatedId: "",
      productRelatedValue: {},
      categoryRelatedValue: {},
    };

    if (this.props.deskProductId) {
      const product = this.props.deskProducts.find(
        (r) => r._id === this.props.deskProductId
      );
      if (product) {
        this.state = {
          name: product.name,
          category_id: product.category_id,
          description_text: product.description_text,
          ref: product.ref,
          refundable: product.refundable,
          vte_loc: product.vte_loc,
          renouv_vte: product.renouv_vte,
          prescription_type_vte_text: product.prescription_type_vte_text,
          prescription_type_loc_text: product.prescription_type_loc_text,
          relatedProducts: product.relatedProducts,
          relatedCategories: product.relatedCategories,
          relatedQuestions: product.relatedQuestions,
          relatedRecommendations: product.relatedRecommendations,
          nameError: null,
          disabled: false,
          modal: null,
        };
      }
    }
  }

  // Modal

  // Modal Questionnary
  openControlPointModal(typeofcontrol, deskProduct, index) {
    this.setState({
      modal: (
        <ControlPointModal
          typeofcontrol={typeofcontrol}
          index={index}
          deskProduct={deskProduct}
          deskProductId={this.props.deskProductId}
          //successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    //console.log('CLOSE MAIN');
    this.setState({ modal: null });
  }

  close() {
    this.props.closeModal();
  }

  onDropFile(file, clearCallback) {
    if (!file || file.length === 0) return;

    // Update mode. Immediately send file to BE
    if (this.props.deskProductId) {
      this.setState({ disabledFileUpload: true });

      let formData = new FormData();
      formData.append(file.name, file);

      var successCallback = () => {
        this.setState({ disabledFileUpload: false });
        clearCallback();
      };

      // Send to BE
      this.props.onAddDeskProductFile(
        this.props.deskProductId,
        formData,
        successCallback
      );
    }
    // Create mode. Store the file and wait for the onComplete event
    else {
      this.setState({ file: file });
    }
  }

  onSubmit() {
    var sendfile = (product) => {
      //console.log('this.state.file',this.state.file);

      if (!this.state.file || this.state.file.length === 0)
        return this.props.closeModal();

      let formData = new FormData();
      formData.append(this.state.file.name, this.state.file);

      this.props.onAddDeskProductFile(product._id, formData, () =>
        this.props.closeModal()
      );
    };

    var imageExist = !!this.state.file;

    var data = {
      name: this.state.name,
      ref: this.state.ref,
      description_text: this.state.description_text,
      image: imageExist,
      category_id: this.state.category_id,
      refundable: this.state.refundable,
      vte_loc: this.state.vte_loc,
      renouv_vte: this.state.renouv_vte,
      prescription_type_vte_text: this.state.prescription_type_vte_text,
      prescription_type_loc_text: this.state.prescription_type_loc_text,
      sortIndex: this.props.deskProducts
        ? this.props.deskProducts.length + 1
        : 1,
    };

    //	console.log(data);
    this.props.onAddDeskProduct(data, sendfile);
  }

  disabled() {
    return (
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.categorie_type) ||
      Util.emptyString(this.state.type) ||
      Util.emptyString(this.state.description_text) ||
      this.state.name ||
      this.state.categorie_type ||
      this.state.type ||
      this.state.description_text ||
      this.state.loading
    );
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  checkErrors() {
    return (
      this.state.nameError ||
      this.state.disabled ||
      Util.emptyString(this.state.name)
    );
  }

  onUpdate(updatedField, updatedValue) {
    if (!this.props.deskProductId || this.checkErrors()) return;

    if (updatedField === "name" && Util.emptyString(updatedValue)) return;

    var data = {
      deskProductId: this.props.deskProductId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    this.props.onUpdateDeskProduct(data);
  }

  deleteDeskControl(deskProductId, TypeOfControl, index) {
    if (!deskProductId) return;

    var data = {
      deskProductId: deskProductId,
      type: TypeOfControl,
      index: index,
    };

    this.props.onDeleteDeskControl(data);
  }

  handleProductRelatedChange = (newValue, actionMeta) => {
    if (newValue) {
      this.setState({
        productRelatedValue: this.getProductById(newValue).name,
        productRelatedId: newValue,
      });
    }
  };

  AddProductRelated() {
    if (!this.props.deskProductId || !this.state.productRelatedId) return;

    const product = this.props.deskProducts.find(
      (r) => r._id === this.props.deskProductId
    );

    if (product.relatedProducts.indexOf(this.state.productRelatedId) === -1) {
      var data = {
        deskProductId: this.props.deskProductId,
        productRelatedId: this.state.productRelatedId,
      };

      this.props.onAddProductRelated(data);

      this.setState({ productRelatedValue: null });
    } else {
      alert("Le produit est deja dans la liste");
    }
  }

  deleteProductRelated(productRelatedId, index) {
    if (!this.props.deskProductId) return;

    var data = {
      deskProductId: this.props.deskProductId,
      productRelatedId: productRelatedId,
      index: index,
    };
    this.props.onDeleteDeskProductRelated(data);
  }

  handleCategoryRelatedChange = (newValue, actionMeta) => {
    if (newValue) {
      this.setState({
        categoryRelatedValue: this.getCategoryById(newValue).name,
        categoryRelatedId: newValue,
      });
    }
  };
  getProductById(productId) {
    var productsRelatedFilter = [];
    const product = this.props.deskProducts.find(
      (r) => r._id === this.props.deskProductId
    );
    const { deskProducts } = this.props;
    if (product && deskProducts && deskProducts.length) {
      productsRelatedFilter = deskProducts.filter((ele) => {
        let result = false;
        if (
          ele._id !== product._id &&
          product.relatedProducts.indexOf(ele._id) === -1
        )
          result = true;
        return result;
      });
    }
    for (let cat of productsRelatedFilter) {
      if (cat._id === productId) {
        return cat;
      }
    }
  }
  getCategoryById(categoryId) {
    var categoriesRelatedFilter = [];
    const product = this.props.deskProducts.find(
      (r) => r._id === this.props.deskProductId
    );
    const { deskCategories } = this.props;
    if (product && deskCategories && deskCategories.length) {
      categoriesRelatedFilter = deskCategories.filter((ele) => {
        let result = false;
        if (
          ele._id !== product.category_id &&
          product.relatedCategories.indexOf(ele._id) === -1
        )
          result = true;
        return result;
      });
    }
    for (let cat of categoriesRelatedFilter) {
      if (cat._id === categoryId) {
        return cat;
      }
    }
  }
  AddCategoryRelated() {
    if (!this.props.deskProductId || !this.state.categoryRelatedId) return;

    const product = this.props.deskProducts.find(
      (r) => r._id === this.props.deskProductId
    );

    if (
      product.relatedCategories.indexOf(this.state.categoryRelatedId) === -1
    ) {
      var data = {
        deskProductId: this.props.deskProductId,
        categoryRelatedId: this.state.categoryRelatedId,
      };

      this.props.onAddCategoryRelated(data);

      this.setState({ categoryRelatedValue: null });
    } else {
      alert("La categorie est deja dans la liste des categories associes");
    }
  }

  deleteCategoryRelated(categoryRelatedId, index) {
    if (!this.props.deskProductId) return;

    var data = {
      deskProductId: this.props.deskProductId,
      categoryRelatedId: categoryRelatedId,
      index: index,
    };

    this.props.onDeleteDeskCategoryRelated(data);
  }

  render() {
    const { deskProducts } = this.props;

    const { deskCategories } = this.props;
    if (!deskCategories) return null;

    var deskCategoriesNode = deskCategories.map((p) => {
      // var disabled = false;
      return (
        <option value={p._id} key={p._id}>
          {p.name}
        </option>
      );
    });

    const product = this.props.deskProducts.find(
      (r) => r._id === this.props.deskProductId
    );

    var modalTitle = <FormattedMessage id="Confirm" />;
    var txtconfirm = "Type.Questionnary.Remove.Confirmation";

    var modalContentQuestion = (
      <React.Fragment>
        <div>
          <FormattedMessage id={txtconfirm} />
          <br />
          <br />
          <div className="card text-white bg-danger">
            <div className="card-header">
              <i className="fa fa-warning mr-2 text-white"></i>
              <FormattedMessage id="Warning" />
            </div>
            <div className="card-body">
              <p className="card-text">
                <FormattedMessage id="Delete.Warning.Content" />
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    var modalContentReco = (
      <React.Fragment>
        <div>
          <FormattedMessage id="Type.Recommandation.Remove.Confirmation" />
          <br />
          <br />
          <div className="card text-white bg-danger">
            <div className="card-header">
              <i className="fa fa-warning mr-2 text-white"></i>
              <FormattedMessage id="Warning" />
            </div>
            <div className="card-body">
              <p className="card-text">
                <FormattedMessage id="Delete.Warning.Content" />
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    var modalContentAssocProduct = (
      <React.Fragment>
        <div>
          <FormattedMessage id="Type.Associated.Product.Remove.Confirmation" />
          <br />
          <br />
          <div className="card text-white bg-danger">
            <div className="card-header">
              <i className="fa fa-warning mr-2 text-white"></i>
              <FormattedMessage id="Warning" />
            </div>
            <div className="card-body">
              <p className="card-text">
                <FormattedMessage id="Delete.Warning.Content" />
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    var modalContentAssocCateg = (
      <React.Fragment>
        <div>
          <FormattedMessage id="Type.Associated.Category.Remove.Confirmation" />
          <br />
          <br />
          <div className="card text-white bg-danger">
            <div className="card-header">
              <i className="fa fa-warning mr-2 text-white"></i>
              <FormattedMessage id="Warning" />
            </div>
            <div className="card-body">
              <p className="card-text">
                <FormattedMessage id="Delete.Warning.Content" />
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    var deskQuestionsNodes = "";
    if (
      product &&
      product.relatedQuestions &&
      product.relatedQuestions.length
    ) {
      deskQuestionsNodes = product.relatedQuestions.map((txt, idx) => {
        var successCallback = () =>
          this.deleteDeskControl(this.props.deskProductId, "question", idx);
        return (
          <tr key={"question-" + this.props.deskProductId + idx}>
            <td>{txt}</td>
            <td className="row float-right mr-2">
              <i
                id={"modifyquest" + this.props.deskProductId + idx}
                className="fa fa-edit icon-big"
                onClick={(e) =>
                  this.openControlPointModal("question", product, idx)
                }
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"modifyquest" + this.props.deskProductId + idx}
              >
                <FormattedMessage id="Modify" />
              </UncontrolledTooltip>

              <i
                id={"deletequest" + this.props.deskProductId + idx}
                className="fa fa-trash icon-big"
                //onClick={(e) => this.deleteDeskControl(this.props.deskProductId, 'question', idx) }
                onClick={(e) =>
                  this.openConfModal(
                    modalTitle,
                    modalContentQuestion,
                    successCallback
                  )
                }
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"deletequest" + this.props.deskProductId + idx}
              >
                <FormattedMessage id="Delete" />
              </UncontrolledTooltip>
            </td>
          </tr>
        );
      });
    }

    var deskRecommendationsNodes = "";
    if (
      product &&
      product.relatedRecommendations &&
      product.relatedRecommendations.length
    ) {
      deskRecommendationsNodes = product.relatedRecommendations.map(
        (txt, idx) => {
          var successCallback = () =>
            this.deleteDeskControl(
              this.props.deskProductId,
              "recommandation",
              idx
            );
          return (
            <tr key={"recommendation-" + this.props.deskProductId + idx}>
              <td>{txt}</td>
              <td className="row float-right mr-2">
                <i
                  id={"modifyreco" + this.props.deskProductId + idx}
                  className="fa fa-edit icon-big"
                  onClick={(e) =>
                    this.openControlPointModal("recommandation", product, idx)
                  }
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"modifyreco" + this.props.deskProductId + idx}
                >
                  <FormattedMessage id="Modify" />
                </UncontrolledTooltip>

                <i
                  id={"deletereco" + this.props.deskProductId + idx}
                  className="fa fa-trash icon-big"
                  onClick={(e) =>
                    this.openConfModal(
                      modalTitle,
                      modalContentReco,
                      successCallback
                    )
                  }
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"deletereco" + this.props.deskProductId + idx}
                >
                  <FormattedMessage id="Delete" />
                </UncontrolledTooltip>
              </td>
            </tr>
          );
        }
      );
    }

    var productsRelatedFilter = [];
    if (product && deskProducts && deskProducts.length) {
      productsRelatedFilter = deskProducts.filter((ele) => {
        let result = false;
        if (
          ele._id !== product._id &&
          product.relatedProducts.indexOf(ele._id) === -1
        )
          result = true;
        return result;
      });
    }

    var productsRelatedNodes = "";
    if (product && product.relatedProducts && product.relatedProducts.length) {
      productsRelatedNodes = product.relatedProducts.map(
        (_productRelatedId, idx) => {
          var successCallback = () =>
            this.deleteProductRelated(productRelated._id, idx);
          var productRelated = deskProducts.filter((ele) => {
            return ele._id === _productRelatedId;
          })[0];
          return (
            <tr key={"productrelated-" + productRelated._id}>
              <td>{productRelated.name}</td>
              <td className="float-right">
                <i
                  id={"deleteproductrelated" + productRelated._id}
                  className="fa fa-trash icon-big"
                  onClick={(e) =>
                    this.openConfModal(
                      modalTitle,
                      modalContentAssocProduct,
                      successCallback
                    )
                  }
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"deleteproductrelated" + productRelated._id}
                >
                  <FormattedMessage id="Delete" />
                </UncontrolledTooltip>
              </td>
            </tr>
          );
        }
      );
    }

    var categoriesRelatedFilter = [];
    if (product && deskCategories && deskCategories.length) {
      categoriesRelatedFilter = deskCategories.filter((ele) => {
        let result = false;
        if (
          ele._id !== product.category_id &&
          product.relatedCategories.indexOf(ele._id) === -1
        )
          result = true;
        return result;
      });
    }

    var categoriesRelatedNodes = "";
    if (
      product &&
      product.relatedCategories &&
      product.relatedCategories.length
    ) {
      categoriesRelatedNodes = product.relatedCategories.map(
        (_categoryRelatedId, idx) => {
          var successCallback = () =>
            this.deleteCategoryRelated(categoryRelated._id, idx);

          var categoryRelated = deskCategories.filter((ele) => {
            return ele._id === _categoryRelatedId;
          })[0];
          //console.log('categoryRelated',categoryRelated);
          return (
            <tr key={"categoryRelated-" + categoryRelated._id}>
              <td>{categoryRelated.name}</td>
              <td className="float-right">
                <i
                  id={"deletecategoryRelated" + categoryRelated._id}
                  className="fa fa-trash icon-big"
                  onClick={(e) =>
                    this.openConfModal(
                      modalTitle,
                      modalContentAssocCateg,
                      successCallback
                    )
                  }
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"deletecategoryRelated" + categoryRelated._id}
                >
                  <FormattedMessage id="Delete" />
                </UncontrolledTooltip>
              </td>
            </tr>
          );
        }
      );
    }

    // Image
    //var imgProduct;

    var imgProduct;

    if (product && product.image) {
      var imgSrc =
        APIUrl.getDeskProductImg +
        product._id +
        "/" +
        Math.random() +
        "?token=" +
        APIUrl.jwtToken;
      imgProduct = (
        <img
          src={imgSrc}
          className="col-form-label m-3"
          alt={"desk_category_img" + Math.random()}
          height="150"
          width="150"
        />
      );
    }

    return (
      <React.Fragment>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.props.closeModal()}
            backdrop={"static"}
            size={"xl"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.isUpdate ? (
                  <FormattedMessage id="Update.Type.Product" />
                ) : (
                  <FormattedMessage id="Add.Type.Product" />
                )}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {product && product.image && (
                <div className="d-none d-lg-block w-150 rounded light-card text-center">
                  <div className="form-group row m-0 p-0"></div>
                  <div className="w-150 m-0 p-0">{imgProduct}</div>
                </div>
              )}

              {/* Name */}
              <div className="form-group row">
                <label
                  htmlFor="name"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Name" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={this.state.name}
                    onChange={(e) => this.onChange("name", e.target.value)}
                    onBlur={(e) => this.onUpdate("name", e.target.value)}
                  />
                </div>
              </div>

              {/* Ref */}
              <div className="form-group row">
                <label htmlFor="ref" className="col-12 col-sm-5 col-form-label">
                  <FormattedMessage id="Ref" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="ref"
                    autoComplete="off"
                    value={this.state.ref}
                    onChange={(e) => this.onChange("ref", e.target.value)}
                    onBlur={(e) => this.onUpdate("ref", e.target.value)}
                  />
                </div>
              </div>

              {/* Category  */}
              <div className="form-group row">
                <label
                  htmlFor="categorie_type"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Categorie" />
                </label>
                <div className="col-12 col-sm-7">
                  <select
                    className="form-control w-100 selectlist"
                    value={this.state.category_id}
                    onChange={(e) =>
                      this.onChange("category_id", e.target.value)
                    }
                    onBlur={(e) => this.onUpdate("category_id", e.target.value)}
                  >
                    <option value="" disabled={true}></option>
                    {deskCategoriesNode}
                  </select>
                </div>
              </div>

              {/* Visuel */}
              <div className="form-group row">
                <label
                  htmlFor="logo"
                  className="col-12 col-md-5 col-form-label"
                >
                  <FormattedMessage id="Visuel" />
                </label>
                <div className="col-12 col-md-7">
                  <FileDropZone
                    disabled={this.state.disabledDropZone}
                    onDropFile={(file, clearCallback) =>
                      this.onDropFile(file, clearCallback)
                    }
                    acceptedExtensions={["jpg", "png"]}
                    multiple={false}
                  />
                </div>
              </div>

              {/* Txt Descriptif */}
              <div className="form-group row">
                <label
                  htmlFor="defaultText"
                  className="col-12 col-md-5 col-form-label"
                >
                  <FormattedMessage id="Description" />
                </label>
                <div id="defaultText" className="col-12 col-sm-7">
                  <textarea
                    className="form-control"
                    type="text"
                    value={this.state.description_text}
                    id="description"
                    rows="7"
                    onChange={(e) =>
                      this.onChange("description_text", e.target.value)
                    }
                    onBlur={(e) =>
                      this.onUpdate(
                        "description_text",
                        this.state.description_text
                      )
                    }
                  />
                </div>
              </div>

              {/* Refundable */}
              <div className="form-group row">
                <label
                  htmlFor="on_leave-switch"
                  className="col-12 col-md-5 col-form-label"
                >
                  <FormattedMessage id="Refundable" />
                </label>
                <div className="col-12 col-md-7">
                  <div className="custom-control custom-switch mx-auto switch-success text-left">
                    <input
                      onChange={(e) =>
                        this.onChange("refundable", !this.state.refundable)
                      }
                      type="checkbox"
                      id="on_leave-switch"
                      className="custom-control-input switch-bg-blue"
                      checked={this.state.refundable}
                      onBlur={(e) =>
                        this.onUpdate("refundable", this.state.refundable)
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="on_leave-switch"
                    ></label>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="purchase_sail"
                  className="col-12 col-md-5 col-form-label"
                >
                  <FormattedMessage id="Purchase.Sails" />
                </label>
                <div className="col-12 col-md-7">
                  <select
                    className="form-control d-inline"
                    id="renouv_vte"
                    value={this.state.renouv_vte}
                    onChange={(e) =>
                      this.onChange("renouv_vte", e.target.value)
                    }
                    onBlur={(e) =>
                      this.onUpdate("renouv_vte", this.state.renouv_vte)
                    }
                  >
                    <option
                      value="0"
                      defaultValue={
                        this.props.renouv_vte === "0" ? true : false
                      }
                    >
                      0 {this.props.intl.formatMessage({ id: "year" })}
                    </option>
                    <option
                      value="1"
                      defaultValue={
                        this.props.renouv_vte === "1" ? true : false
                      }
                    >
                      1 {this.props.intl.formatMessage({ id: "year" })}
                    </option>
                    <option
                      value="2"
                      defaultValue={
                        this.props.renouv_vte === "2" ? true : false
                      }
                    >
                      2 {this.props.intl.formatMessage({ id: "Years" })}
                    </option>
                    <option
                      value="3"
                      defaultValue={
                        this.props.renouv_vte === "3" ? true : false
                      }
                    >
                      3 {this.props.intl.formatMessage({ id: "Years" })}
                    </option>
                    <option
                      value="5"
                      defaultValue={
                        this.props.renouv_vte === "5" ? true : false
                      }
                    >
                      5 {this.props.intl.formatMessage({ id: "Years" })}
                    </option>
                  </select>
                </div>
              </div>

              {/* Select Type Locations / Ventes vte_loc */}

              <div className="form-group row">
                <label
                  htmlFor="type"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Type" />
                </label>
                <div className="col-12 col-sm-7">
                  <select
                    className="form-control w-100 selectlist"
                    value={this.state.vte_loc}
                    onChange={(e) => this.onChange("vte_loc", e.target.value)}
                    onBlur={(e) => this.onUpdate("vte_loc", e.target.value)}
                  >
                    <option value="Location">
                      {this.props.intl.formatMessage({ id: "EqSellMode.1" })}
                    </option>
                    <option value="Vente">
                      {this.props.intl.formatMessage({ id: "EqSellMode.0" })}
                    </option>
                    <option value="LocationVente">
                      {this.props.intl.formatMessage({ id: "EqSellMode.2" })}
                    </option>
                  </select>
                </div>
              </div>

              {/* Prescription Type Location */}
              {(this.state.vte_loc === "Location" ||
                this.state.vte_loc === "LocationVente") && (
                <div className="form-group row">
                  <label
                    htmlFor="prescription_type_loc_text"
                    className="col-12 col-md-5 col-form-label"
                  >
                    <FormattedMessage id="Prescription.Loc" />
                  </label>
                  <div
                    id="prescription_type_loc_text"
                    className="col-12 col-sm-7"
                  >
                    <textarea
                      className="form-control"
                      type="text"
                      value={this.state.prescription_type_loc_text}
                      id="prescription_type_loc_text"
                      rows="4"
                      placeholder={this.defaultPrescriptionText}
                      onChange={(e) =>
                        this.onChange(
                          "prescription_type_loc_text",
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        this.onUpdate(
                          "prescription_type_loc_text",
                          this.state.prescription_type_loc_text
                        )
                      }
                    />
                  </div>
                </div>
              )}

              {/* Prescription Type Vente */}
              {(this.state.vte_loc === "Vente" ||
                this.state.vte_loc === "LocationVente") && (
                <div className="form-group row">
                  <label
                    htmlFor="prescription_type_vte_text"
                    className="col-12 col-md-5 col-form-label"
                  >
                    <FormattedMessage id="Prescription.Sail" />
                  </label>
                  <div
                    id="prescription_type_vte_text"
                    className="col-12 col-sm-7"
                  >
                    <textarea
                      className="form-control"
                      type="text"
                      value={this.state.prescription_type_vte_text}
                      id="prescription_type_vte_text"
                      rows="4"
                      placeholder={this.defaultPrescriptionText}
                      onChange={(e) =>
                        this.onChange(
                          "prescription_type_vte_text",
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        this.onUpdate(
                          "prescription_type_vte_text",
                          this.state.prescription_type_vte_text
                        )
                      }
                    />
                  </div>
                </div>
              )}

              {this.props.deskProductId && (
                <div>
                  {/* Br */}
                  <br className="hidden-xs" />
                  {/* Line Spacer */}
                  <hr />
                  {/* Br */}
                  <br className="hidden-xs" />
                </div>
              )}

              {/* Questionnary */}
              {this.props.deskProductId && (
                <div>
                  <table className="table table-striped col-12 tablee4mad mt-3">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="Questionnary" />
                          <button
                            type="button"
                            className="btn btn-cyan align-middle"
                          >
                            <i
                              className="fa fa-plus-square icon-small"
                              aria-hidden="true"
                              onClick={(e) =>
                                this.openControlPointModal("question")
                              }
                            ></i>
                          </button>
                        </th>
                        <th className="align-middle float-right">
                          <FormattedMessage id="Actions" />
                        </th>
                      </tr>
                    </thead>
                    {deskQuestionsNodes !== "" && (
                      <tbody>{deskQuestionsNodes}</tbody>
                    )}
                  </table>

                  {/* {If no datas, display message alert} */}
                  {deskQuestionsNodes === "" && (
                    <div className="alert alert-secondary mt-3" role="alert">
                      <FormattedMessage id="Empty.Questionnary" />
                    </div>
                  )}
                </div>
              )}

              {/* Recommandations */}
              {this.props.deskProductId && (
                <div>
                  <table className="table table-striped col-12 tablee4mad mt-3">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="Recommandation" />
                          <button
                            type="button"
                            className="btn btn-cyan align-middle"
                          >
                            <i
                              className="fa fa-plus-square icon-small"
                              aria-hidden="true"
                              onClick={(e) =>
                                this.openControlPointModal("recommandation")
                              }
                            ></i>
                          </button>
                        </th>
                        <th className="align-middle float-right">
                          <FormattedMessage id="Actions" />
                        </th>
                      </tr>
                    </thead>
                    {deskRecommendationsNodes !== "" && (
                      <tbody>{deskRecommendationsNodes}</tbody>
                    )}
                  </table>
                  {/* {If no datas, display message alert} */}
                  {deskRecommendationsNodes === "" && (
                    <div className="alert alert-secondary mt-3" role="alert">
                      <FormattedMessage id="Empty.Recommandations" />
                    </div>
                  )}
                </div>
              )}

              {this.props.deskProductId && (
                <div>
                  {/* Br */}
                  <br className="hidden-xs" />
                  {/* Line Spacer */}
                  <hr />
                </div>
              )}

              {/* Associated Products */}
              {this.props.deskProductId && (
                <div>
                  <table className="table table-striped tablee4mad mt-5">
                    <thead>
                      <tr>
                        <th className="align-middle">
                          <FormattedMessage id="Types.Products.Associated" />
                        </th>
                        <th className="row w-100 float-right">
                          <div className={"w-75"}>
                            <ComboBox
                              onChange={(value) =>
                                this.handleProductRelatedChange(value)
                              }
                              defaultOption={this.state.productRelatedId}
                              options={productsRelatedFilter}
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-cyan align-middle"
                          >
                            <i
                              className="fa fa-plus-square icon-big"
                              aria-hidden="true"
                              onClick={() => this.AddProductRelated()}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    {/* Types af associated products */}
                    {productsRelatedNodes !== "" && (
                      <tbody>{productsRelatedNodes}</tbody>
                    )}
                  </table>
                  {/* {If no datas, display message alert} */}
                  {productsRelatedNodes === "" && (
                    <div className="alert alert-secondary mt-3" role="alert">
                      <FormattedMessage id="Empty.Types.Products.Associated" />
                    </div>
                  )}
                </div>
              )}

              {this.props.deskProductId && (
                <div>
                  {/* Br */}
                  <br className="hidden-xs" />
                  {/* Line Spacer */}
                  <hr />
                </div>
              )}

              {/* Types af associated categories */}
              {this.props.deskProductId && (
                <div>
                  <table className="table table-striped tablee4mad mt-5">
                    <thead>
                      <tr>
                        <th className="align-middle">
                          <FormattedMessage id="Types.Categories.Associated" />
                        </th>
                        <th className="row w-100 float-right">
                          <div className={"w-75"}>
                            <ComboBox
                              onChange={(value) =>
                                this.handleCategoryRelatedChange(value)
                              }
                              defaultOption={this.state.categoryRelatedId}
                              options={categoriesRelatedFilter}
                            />
                          </div>

                          <button
                            type="button"
                            className="btn btn-cyan align-middle"
                          >
                            <i
                              className="fa fa-plus-square icon-big"
                              aria-hidden="true"
                              onClick={(e) => this.AddCategoryRelated()}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    {categoriesRelatedNodes !== "" && (
                      <tbody>{categoriesRelatedNodes}</tbody>
                    )}
                  </table>
                  {/* {If no datas, display message alert} */}
                  {categoriesRelatedNodes === "" && (
                    <div className="alert alert-secondary mt-3" role="alert">
                      <FormattedMessage id="Empty.Types.Categories.Associated" />
                    </div>
                  )}
                </div>
              )}

              {/* Br */}
              <br className="hidden-xs" />
            </Modal.Body>

            {!this.props.deskProductId && (
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.props.closeModal()}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
                <Button variant="info" onClick={() => this.onSubmit()}>
                  <FormattedMessage id="Add" />
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskProducts: state.deskProducts,
    deskCategories: state.deskCategories,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDeskProduct: (deskProduct, successCallback) =>
      dispatch(addDeskProduct(deskProduct, successCallback)),
    onUpdateDeskProduct: (data) => dispatch(updateDeskProduct(data)),
    onAddDeskProductFile: (deskProductId, data, successCallback) =>
      dispatch(addDeskProductFile(deskProductId, data, successCallback)),
    onDeleteDeskControl: (data, successCallback) =>
      dispatch(deleteTypeofControl(data)),
    onAddProductRelated: (data, successCallback) =>
      dispatch(addDeskProductRelated(data)),
    onAddCategoryRelated: (data, successCallback) =>
      dispatch(addDeskCategoryRelated(data)),
    onDeleteDeskCategoryRelated: (data, successCallback) =>
      dispatch(deleteDeskCategoryRelated(data)),
    onDeleteDeskProductRelated: (data, successCallback) =>
      dispatch(deleteDeskProductRelated(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeskProductsModalAdmin));
