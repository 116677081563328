import React from "react";
import { connect } from "react-redux";
import PriceUtil from "../../util/PriceUtil";
import {
  updateUrgent,
  increaseQuantity,
  decreaseQuantity,
  deleteCartById,
} from "../../actions/cart/cart";
import { FormattedMessage } from "react-intl";
import Roles from "../../enums/Roles";
import { UncontrolledTooltip } from "reactstrap";

class CartRow extends React.Component {
  updateUrgent(e, cartId, urgent) {
    var data = { cartId: cartId, urgent: !urgent };
    this.props.onUpdateUrgent(data);
  }

  increaseQuantity(e, cartId) {
    var data = { cartId: cartId };
    this.props.onIncreaseQuantity(data);
  }

  decreaseQuantity(e, cartId) {
    var data = { cartId: cartId };
    this.props.onDecreaseQuantity(data);
  }

  deleteCartById(e, cartId) {
    var data = { cartId: cartId };
    this.props.onDeleteCartById(data);
  }

  render() {
    // 'Delete article' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <FormattedMessage
        id="Product.Remove.Confirmation"
        values={{ designation: this.props.product.designation }}
      />
    );
    var successCallback = (e) => this.deleteCartById(e, this.props.cart._id);

    var switchClassName = "switch-bg-blue";
    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    )
      switchClassName = "switch-bg-blue";

    return (
      <tr key={this.props.cart._id} className="tre4mad">
        <td className="d-none d-lg-table-cell">{this.props.product.famille}</td>
        <td>{this.props.product.designation}</td>
        <td className="d-none d-md-table-cell">
          {this.props.product.unite_de_vente}
        </td>
        <td className="d-none d-lg-table-cell">{this.props.product.ref}</td>
        <td>{this.props.cart.quantity}</td>
        <td className="d-none d-lg-table-cell">
          {PriceUtil.priceHt(this.props.product, 1, 2)}€
        </td>
        <td className="d-none d-md-table-cell">
          {this.props.product.tva * 100}%
        </td>
        <td className="d-none d-md-table-cell">
          {PriceUtil.priceTtc(this.props.product, 1, 2)}€
        </td>
        <td className="d-none d-lg-table-cell">
          {PriceUtil.priceHt(this.props.product, this.props.cart.quantity, 2)}€
        </td>
        <td>
          {PriceUtil.priceTtc(this.props.product, this.props.cart.quantity, 2)}€
        </td>
        <td className="text-center card-body d-none d-md-table-cell">
          <div className="custom-control custom-switch mx-auto switch-primary">
            <input
              onChange={(e) =>
                this.updateUrgent(
                  e,
                  this.props.cart._id,
                  this.props.cart.urgent
                )
              }
              type="checkbox"
              className={"custom-control-input " + switchClassName}
              id={"switch" + this.props.product._id}
              checked={this.props.cart.urgent}
            />
            <label
              className="custom-control-label"
              htmlFor={"switch" + this.props.product._id}
            ></label>
          </div>
        </td>
        <td className="text-center tdaction">
          <i
            id={"decrement" + this.props.cart._id}
            onClick={(e) => this.decreaseQuantity(e, this.props.cart._id)}
            className="fa fa-minus-circle icon-big"
          ></i>
          <UncontrolledTooltip
            delay={{ show: 0, hide: 0 }}
            placement="top"
            target={"decrement" + this.props.cart._id}
          >
            <FormattedMessage id="Decrement.Product" />
          </UncontrolledTooltip>
          <i
            id={"increment" + this.props.cart._id}
            onClick={
              this.props.cart.quantity >= 1000
                ? ""
                : (e) => this.increaseQuantity(e, this.props.cart._id)
            }
            className="fa fa-plus-circle icon-big"
          ></i>
          <UncontrolledTooltip
            delay={{ show: 0, hide: 0 }}
            placement="top"
            target={"increment" + this.props.cart._id}
          >
            <FormattedMessage id="Increment.Product" />
          </UncontrolledTooltip>
          <i
            id={"delete" + this.props.cart._id}
            className="fa fa-trash icon-big"
            onClick={(e) =>
              this.props.openConfModal(
                modalTitle,
                modalContent,
                successCallback
              )
            }
          ></i>
          <UncontrolledTooltip
            delay={{ show: 0, hide: 0 }}
            placement="top"
            target={"delete" + this.props.cart._id}
          >
            <FormattedMessage id="Delete" />
          </UncontrolledTooltip>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUrgent: (data) => dispatch(updateUrgent(data)),
    onIncreaseQuantity: (data) => dispatch(increaseQuantity(data)),
    onDecreaseQuantity: (data) => dispatch(decreaseQuantity(data)),
    onDeleteCartById: (data) => dispatch(deleteCartById(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartRow);
