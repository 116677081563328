import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import AutoSuggestProducts from "../sub/AutoSuggestProducts";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import Util from "../../util/Util";
import StringUtil from "../../util/StringUtil";
import { Link } from "react-router-dom";
import MercurialStatus from "../../enums/MercurialStatus";
import ReactImageFallback from "react-image-fallback";
import "../../css/families/families.css";

class FamiliesAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popSugg: false,

      clientId: "",
      mercurialId: "",
    };
  }

  selectClient(clientId) {
    let client = this.props.clients.find((client) => client._id === clientId);
    if (!client) {
      this.setState({ clientId: "", mercurialId: "" });
      return null;
    }
    let mercurialId = client.mercurial_Id ? client.mercurial_Id : "";
    this.setState({ clientId: clientId, mercurialId: mercurialId });
  }

  selectMercurial(mercurialId) {
    this.setState({ mercurialId: mercurialId, clientId: "" });
  }

  onSearch(value, products, mercurialId) {
    // If it's a product
    for (let product of products) {
      if (product.ref === value.ref && product.mercurial_id === mercurialId) {
        window.location.href =
          "#/home/products/mercurial/" +
          product.mercurial_id +
          "/" +
          encodeURI(product.famille) +
          "/" +
          product._id;
      }
    }
  }

  productsToSuggestions(products) {
    var suggestions = [];
    var alreadyUsedFamilies = {};

    for (let product of products) {
      var sugg = ProductsParserUtil.trim(product);

      if (!sugg && !alreadyUsedFamilies[product.sous_famille]) {
        sugg = product.sous_famille;
        alreadyUsedFamilies[product.sous_famille] = true;
      }

      if (sugg) suggestions.push(sugg);
    }

    return suggestions;
  }

  render() {
    // No 'families'? No render
    if (this.props.products.length === 0) {
      return (
        <div className="alert alert-secondary" role="alert">
          <FormattedMessage id="Empty.Families" />
        </div>
      );
    }

    let filteredProducts = [];
    filteredProducts = this.props.products.filter(
      (p) => p.mercurial_id === this.state.mercurialId
    );

    var clientMercurialId = null;
    if (this.state.clientId && !Util.emptyString(this.state.clientId)) {
      for (let clientsSetting of this.props.mercurials) {
        if (clientsSetting._id === this.state.clientId) {
          clientMercurialId = clientsSetting._id;
          break;
        }
      }
    }

    let suggestions2 = [];
    filteredProducts.forEach((element) => {
      let obj = {};
      obj.designation = element.designation;
      obj.caracteristiques = element.caracteristiques;
      obj.ref = element.ref;
      obj.mercurial_id = element.mercurial_id;
      obj.ref_frn = element.ref_frn;
      obj.famille = element.famille;
      obj.sous_famille = element.sous_famille;
      suggestions2.push(obj);
    });

    var familiesUniques = {};
    for (let product of filteredProducts) {
      // Mercurials filter
      if (
        this.state.mercurialId &&
        !Util.emptyString(this.state.mercurialId) &&
        this.state.mercurialId !== product.mercurial_id
      )
        continue;

      // Clients filter
      if (clientMercurialId && clientMercurialId !== product.mercurial_id)
        continue;

      familiesUniques[product.famille] = {
        mercurialId: product.mercurial_id,
        fam: product.fam,
      };
    }

    let familiesNode = null;
    if (
      !Util.emptyString(this.state.clientId) ||
      !Util.emptyString(this.state.mercurialId)
    ) {
      familiesNode = Object.keys(familiesUniques).map((famille) => {
        // Href link
        var imgSrc =
          APIUrl.getFamilyImg +
          familiesUniques[famille].mercurialId +
          "/FAM_" +
          familiesUniques[famille].fam.split(".")[0] +
          "?token=" +
          APIUrl.jwtToken;

        return (
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4 mx-auto mw-400"
            key={famille}
          >
            <Link
              to={
                "/home/products/mercurial/" +
                familiesUniques[famille].mercurialId +
                "/" +
                encodeURI(famille)
              }
              className="nostyle-a"
            >
              <div className="card hoverable h-300">
                <div className="card-header text-center">
                  <h6 className="text-info mb-0">
                    <strong>{famille}</strong>
                  </h6>
                </div>
                <div className="h-350">
                  <ReactImageFallback
                    src={imgSrc}
                    fallbackImage="/images/no_image_512.png"
                    initialImage="/images/loader.gif"
                    alt=""
                    className="d-block align-middle mx-auto blocFamilies"
                  />
                </div>
              </div>
            </Link>
          </div>
        );
      });
    }

    var familles = [];
    var suggestions = [];
    var products = [];

    if (
      !Util.emptyString(this.state.clientId) ||
      !Util.emptyString(this.state.mercurialId)
    ) {
      if (this.state.popSugg) {
        familles = Object.keys(familiesUniques);

        suggestions = suggestions.concat(familles);

        for (let famille of familles) {
          let ps = ProductsParserUtil.parseProducts(filteredProducts, famille);
          suggestions = suggestions.concat(this.productsToSuggestions(ps));
          products = products.concat(ps);
        }
      }
    }

    // Filters
    var clientsNode = this.props.clients.map((client) => {
      return (
        <option key={client._id} value={client._id}>
          {client.name + " " + StringUtil.ucFirst(client.first_name)}
        </option>
      );
    });

    var clientSelectNode = (
      <div className="text-center w-100" role="alert">
        <select
          id="mercurials-filter-clientId"
          className="form-control w-100"
          value={this.state.clientId}
          onChange={(e) => this.selectClient(e.target.value)}
        >
          <option value="">
            {this.props.intl.formatMessage({ id: "Select.Client" })}...
          </option>
          {clientsNode}
        </select>
      </div>
    );

    var mercurialsNode = this.props.mercurials.map((mercurial) => {
      var now = new Date();
      var hasExpired =
        new Date(mercurial.start_date) > now ||
        new Date(mercurial.end_date) < now;
      if (hasExpired) return null;
      if (mercurial.status === MercurialStatus.INACTIVE) return null;

      return (
        <option key={mercurial._id} value={mercurial._id}>
          {mercurial.name}
        </option>
      );
    });

    var mercurialsSelectNode = (
      <div className="text-center w-100" role="alert">
        <select
          id="mercurials-filter-clientId"
          className="form-control w-100"
          value={this.state.mercurialId}
          onChange={(e) => this.selectMercurial(e.target.value)}
        >
          <option value="">
            {this.props.intl.formatMessage({ id: "Select.Mercurial" })}...
          </option>
          {mercurialsNode}
        </select>
      </div>
    );

    return (
      <React.Fragment>
        <div className="row search-filters">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="form-inline row">
                  <div className="col-md-3">{clientSelectNode}</div>
                  <div className="col-md-3">{mercurialsSelectNode}</div>
                  <div className="col-md-6">
                    {(!Util.emptyString(this.state.clientId) ||
                      !Util.emptyString(this.state.mercurialId)) && (
                      <AutoSuggestProducts
                        id="search"
                        name="search"
                        onBlur={(e) => this.update("name", e.target.value)}
                        onSuggestionSelected={(value) =>
                          this.onSearch(
                            value,
                            filteredProducts,
                            this.state.mercurialId
                          )
                        }
                        suggestions={suggestions2}
                        placeholder={"Search.Product.Placeholder"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">{familiesNode}</div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    products: state.products,
    mercurials: state.mercurials,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FamiliesAdmin));
