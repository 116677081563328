import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_PATIENTS = "GET_PATIENTS";

function getPatientsAction(patients) {
  return { type: GET_PATIENTS, patients: patients };
}

//********************************** PATIENT(ADMIN & SALEREP) ************************************/

export const getPatients = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPatients)
      .then(function (response) {
        dispatch(getPatientsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getPatientsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPatientsAdmin)
      .then(function (response) {
        dispatch(getPatientsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

//********************************** PATIENT(USER) ************************************/

export const addPatient = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatient, patient)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updPatientAdmin = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updPatient, patient)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updPatient = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updPatient, patient)
      .then(function (response) {
        dispatch(getPatients());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatient = function (patientId, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deletePatient, { patientId })
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

//********************************** TECHNICHAL-SHEET ************************************/

export const addPatientTechnicalFile = function (
  patientId,
  file,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientTechnicalSheet + patientId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatientTechnicalSheet = function (
  patientId,
  sheetId,
  successCallback
) {
  return function (dispatch) {
    return axios
      .get(APIUrl.deletePatientTechnicalSheet + patientId + "/" + sheetId)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

//********************************** ESTIMATES ************************************/

export const addPatientEstimateFile = function (
  patientId,
  file,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientEstimateFile + patientId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatientEstimateFile = function (
  patientId,
  fileId,
  successCallback
) {
  return function (dispatch) {
    return axios
      .get(APIUrl.deletePatientEstimateFile + patientId + "/" + fileId)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

//********************************** DOCUMENTS ************************************/

export const addPatientDocumentFile = function (
  patientId,
  file,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientDocument + patientId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatientDocument = function (
  patientId,
  documentId,
  successCallback
) {
  return function (dispatch) {
    return axios
      .get(APIUrl.deletePatientDocument + patientId + "/" + documentId)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

//********************************** BILLS ************************************/

export const addPatientBillFile = (patientId, billFile, successCallback) => {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientBill + patientId, billFile, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatientBillFile = function (
  patientId,
  billId,
  successCallback
) {
  return function (dispatch) {
    return axios
      .delete(APIUrl.deletePatientBill + patientId + "/" + billId)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) {
          return successCallback();
        }
      })
      .catch(function (err) {
        throw err;
      });
  };
};
