import React from "react";
import { connect } from "react-redux";
import FileDropZone from "../sub/FileDropZone";

import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class MercurialFileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nowDate:
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2),
      disabled: false,
      file: null,
      name: null,
      startDate: null,
      endDate: null,
      nameError: null,
      dateError: null,
      endDateError: null,
      errorYear: null,
      formVisibility: "",
      progressBarVisibility: "hide",
    };
  }

  close() {
    this.props.closeModal();
  }

  onDropFile(file) {
    this.setState({ file: file });
  }

  onNameChange(e) {
    var name = e.target.value;

    for (let mercurial of this.props.mercurials) {
      if (mercurial.name === name) {
        this.setState({
          name: name,
          nameError: <FormattedMessage id="Mercurials.Already.Imported" />,
        });
        return;
      }
    }

    this.setState({ name: name, nameError: null });
  }

  onStartDateChange(e) {
    let startDateValue = e.target.value;

    const startDate = new Date(startDateValue);
    const endDate = new Date(this.state.endDate);

    const diffDays = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24));

    var yearsError = null;
    var error = null;
    var endError = null;
    if (
      this.state.endDate &&
      startDateValue &&
      startDateValue > this.state.endDate
    ) {
      error = <FormattedMessage id="StartDate.Superior.EndDate" />;
    } else if (this.state.endDate && this.state.endDate < this.state.nowDate) {
      endError = <FormattedMessage id="EndDate.Inferior.NowDate" />;
    } else if (diffDays >= 730) {
      yearsError = <FormattedMessage id="Two.Years.Error" />;
    }

    this.setState({
      startDate: startDateValue,
      dateError: error,
      endDateError: endError,
      errorYear: yearsError,
    });
  }

  onEndDateChange(e) {
    let endDateValue = e.target.value;

    const endDate = new Date(endDateValue);
    const startDate = new Date(this.state.startDate);

    const diffDays = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24));

    var yearsError = null;
    var error = null;
    var endError = null;
    if (
      this.state.startDate &&
      endDateValue &&
      this.state.startDate > endDateValue
    ) {
      error = <FormattedMessage id="StartDate.Superior.EndDate" />;
    } else if (endDateValue < this.state.nowDate) {
      endError = <FormattedMessage id="EndDate.Inferior.NowDate" />;
    } else if (diffDays >= 730) {
      yearsError = <FormattedMessage id="Two.Years.Error" />;
    }

    this.setState({
      endDate: endDateValue,
      dateError: error,
      endDateError: endError,
      errorYear: yearsError,
    });
  }

  onComplete() {
    this.setState({
      disabled: true,
      formVisibility: "hide",
      progressBarVisibility: "",
    });

    this.props.onComplete(
      this.state.name,
      this.state.file,
      this.state.startDate,
      this.state.endDate
    );
  }

  disableButton() {
    return (
      !this.state.file ||
      !this.state.name ||
      !this.state.startDate ||
      !this.state.endDate ||
      this.state.startDate > this.state.endDate ||
      this.state.endDate < this.state.nowDate ||
      this.state.disabled ||
      this.state.nameError ||
      this.state.errorYear
    );
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Mercurials.Add.Some" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h4 className="mb-5 w-100 text-center ">
              <FormattedMessage id="Mercurials.Step.1" />
            </h4>

            <div
              className={"text-center mb-5 " + this.state.progressBarVisibility}
            >
              <i className="fa fa-cog fa-spin fa-3x fa-fw text-success mb-3"></i>
              <div className="progress" style={{ height: "30px" }}>
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "100%" }}
                >
                  <strong>
                    <FormattedMessage id="Import.Mercurial.In.Progress" />
                  </strong>
                </div>
              </div>
            </div>

            <div className={this.state.formVisibility}>
              <div className="form-group row">
                <label htmlFor="fam" className="col-sm-4 col-form-label">
                  <FormattedMessage id="Mercurials.Name.Of" />{" "}
                </label>
                <div id="fam" className="col-sm-8 pb-3">
                  <input
                    type="text"
                    className="form-control"
                    maxLength="50"
                    id="file-name"
                    onChange={(e) => this.onNameChange(e)}
                  />
                  <div className="text-danger">
                    <small>{this.state.nameError}</small>
                  </div>
                </div>

                <label
                  htmlFor="file-start-date"
                  className="col-sm-4 col-form-label"
                >
                  <FormattedMessage id="Start.Date" />{" "}
                </label>
                <div className="col-sm-8 pb-3">
                  <input
                    type="date"
                    className="form-control"
                    id="file-start-date"
                    onChange={(e) => this.onStartDateChange(e)}
                  />
                  <div className="text-danger">
                    <small>{this.state.dateError}</small>
                  </div>
                </div>

                <label
                  htmlFor="file-end-date"
                  className="col-sm-4 col-form-label"
                >
                  <FormattedMessage id="End.Date" />{" "}
                </label>
                <div className="col-sm-8 pb-3">
                  <input
                    type="date"
                    className="form-control"
                    id="file-end-date"
                    onChange={(e) => this.onEndDateChange(e)}
                  />
                  <div className="text-danger">
                    <small>{this.state.endDateError}</small>
                  </div>
                  <div className="text-danger">
                    <small>{this.state.errorYear}</small>
                  </div>
                </div>
              </div>

              <FileDropZone
                onDropFile={(file) => this.onDropFile(file)}
                acceptedExtensions={["xls", "xlsx"]}
                multiple={false}
              />

              <div className="w-100 text-center mt-3">
                <a
                  role="button"
                  href="templates/template_mercurial.xlsx"
                  download="template_mercurial.xlsx"
                >
                  <i className="fa fa-file-excel-o text-blue mr-2"></i>

                  <FormattedMessage id="Mercurials.Download.Template" />
                </a>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.onComplete()}
              disabled={this.disableButton()}
            >
              <FormattedMessage
                id={
                  this.state.progressBarVisibility === "hide"
                    ? "Mercurials.Step.2"
                    : "Plz.Wait"
                }
              />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurialFileModal);
