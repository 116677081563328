import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import Families from "../families/Families";
import FamiliesAdmin from "../families/FamiliesAdmin";
import Products from "../products/Products";
import Cart from "../cart/Cart";
import Orders from "../orders/Orders";
import OrdersAdmin from "../orders/admin/OrdersAdmin";
import MenuSettingsMad from "../settings/admin/MenuSettingsMad";
import { connect } from "react-redux";
import { getUser } from "../../actions/user/user";
import { getRgpd } from "../../actions/rgpd/rgpd";
import { getProducts, getProductsAdmin } from "../../actions/products/products";
import { getCart } from "../../actions/cart/cart";
import { getOrders, getOrdersAdmin } from "../../actions/orders/orders";
import { getClientsforClient, getClients } from "../../actions/clients/clients";
import { getEstablishments } from "../../actions/establishments/establishments";
import { getEstablishment } from "../../actions/establishment/establishment";
import { getOrdersSettings } from "../../actions/settings/ordersSettings/ordersSettings";
import { getOrdersSettingsAdmin } from "../../actions/settings/ordersSettings/admin/ordersSettings";

import { getAuditRooms } from "../../actions/settings/auditRooms/admin/auditRooms";
import { getDeskCategories } from "../../actions/settings/deskCategories/admin/deskCategories";
import { getDeskProducts } from "../../actions/settings/deskCategories/admin/deskProducts";

import { getDocuments } from "../../actions/settings/documentSettings/document";

import {
  getPromoOperations,
  getPromoOperationsAdmin,
} from "../../actions/promoOperations/promoOperations";
import { getAuditDMs } from "../../actions/settings/auditDM/admin/auditDM";
import { getRequests, getAllRequests } from "../../actions/requests/requests";
// Menus Pharmas
import MenuAdminGen from "./Menus/MenuAdminGen";
import MenuClientsGen from "./Menus/MenuClientsGen";
import MenuSalesRepGen from "./Menus/MenuSalesRepGen";
// Roles
import Roles from "../../enums/Roles";
import NotificationSubject from "../../enums/NotificationSubject";
import { FormattedMessage } from "react-intl";
import NotificationModal from "../../components/sub/modals/NotificationModal.js";
import {
  getNotifs,
  deleteNotif,
} from "../../actions/notifications/notifications";
import SplitHomeAdmin from "./SplitHomeAdmin";
import SplitHome from "./SplitHome";
import SplitHomeHome from "./SplitHomeHome";
import SplitHomeDesk from "./SplitHomeDesk";
import SplitHomeDeskAdmin from "./SplitHomeDeskAdmin";
import SplitHomeHomeAdmin from "./SplitHomeHomeAdmin";
import SplitHomeStore from "./SplitHomeStore";
import SplitHomeStoreAdmin from "./SplitHomeStoreAdmin";
import Estimates from "../estimates/Estimates";
import { getMercurials } from "../../actions/mercurials/mercurials";
import {
  getEstablishmentsSettings,
  getEstablishmentSettings,
} from "../../actions/establishments/establishmentSettings";
import { getCollaboratorTypes } from "../../actions/configurables/collaboratorTypes";
import { getGeneralSettings } from "../../actions/settings/generalSettings/generalSettings";
import { getCollaborators } from "../../actions/collaborators/collaborators";
import { getAdmin } from "../../actions/admin/admin";
import { getPatientsAdmin } from "../../actions/patients/patients";
import { getPatients } from "../../actions/patients/patients";
import RgpdModal from "../rgpd/RgpdModal";
import Budget from "../budget/Budget";
import BudgetAdmin from "../budget/admin/BudgetAdmin";
import Account from "../account/Account";
import Patients from "../patients/Patients";
import Clients from "../clients/Clients";
import Mercurials from "../../components/mercurials/Mercurials";
import { getClientTypes } from "../../actions/settings/clientType/admin/clientType";
import { getAuditSettings } from "../../actions/settings/auditSettings/auditSettings";
import { getAuditDeskSettings } from "../../actions/settings/auditSettings/auditDeskSettings";
import { getAudits, getAuditsAdmin } from "../../actions/audits/audits";
import { getCompany } from "../../actions/company/company";

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rgpdModal: null,
    };
  }

  closeRgpdModal() {
    this.setState({ rgpdModal: null });
  }

  componentDidMount() {
    Promise.all([
      // First, fetch the user
      this.props.onGetRgpd(),
      this.props.onGetUser(),
    ]).then(() => {
      // Next things to fetch
      if (!this.props.rgpd)
        this.setState({
          rgpdModal: <RgpdModal closeRgpdModal={() => this.closeRgpdModal()} />,
        });

      this.props.onGetCart();
      this.props.onGetEstablishment();
      this.props.onGetNotifs();
      this.props.onGetCollaboratorTypes();
      this.props.onGetGeneralSettings();
      this.props.onGetCompany();

      // Client specific
      if (this.props.user.role === Roles.CLIENT) {
        this.props.onGetProducts();
        this.props.onGetOrders();
        this.props.onGetOrdersSettings();
        this.props.onGetRequests();
        this.props.onGetEstablishmentSettings();
        this.props.onGetAdmin();
        this.props.onGetClientsforClient();
        this.props.onGetPatients();
        this.props.onGetAudits();
        this.props.onGetPromoOperations();
      }

      // Admin & Sales rep
      if (
        this.props.user.role === Roles.SALES_REP ||
        this.props.user.role === Roles.ADMIN
      ) {
        this.props.onGetProductsAdmin();
        this.props.onGetOrdersAdmin();
        this.props.user.role === Roles.SALES_REP
          ? this.props.onGetClientsforClient()
          : this.props.onGetClients();
        this.props.onGetEstablishments();
        this.props.onGetMercurials();
        this.props.onGetAllRequests();
        this.props.onGetOrdersSettingsAdmin();
        this.props.onGetPatientsAdmin();
        this.props.onGetAuditRooms();
        this.props.onGetDeskCategories();
        this.props.onGetDocuments();
        this.props.onGetDeskProducts();
        this.props.onGetAuditDMs();
        this.props.onGetClientTypes();
        this.props.onGetAuditSettings();
        this.props.onGetAuditDeskSettings();
        this.props.onGetAuditsAdmin();
      }

      // Admin specific
      if (this.props.user.role === Roles.ADMIN) {
        this.props.onGetCollaborators();
        this.props.onGetPromoOperationsAdmin();
      }
    });
  }

  closeNotifModal(notif) {
    this.props.onDeleteNotif({ notifId: notif._id });
  }

  render() {
    var hrefTerms = "tou-" + this.props.user.lang + ".html";

    // Display a link to release notes ONLY if user is an admin or a sales_rep. If user is a client, we only display version number
    var releaseNotesLink =
      this.props.user.role !== Roles.CLIENT ? (
        <a
          href={"release-notes-" + this.props.user.lang + ".html"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="Version" /> {process.env.REACT_APP_VERSION}
        </a>
      ) : (
        <span>
          <FormattedMessage id="Version" /> {process.env.REACT_APP_VERSION}
        </span>
      );

    var ordersSettings;
    if (
      this.props.ordersSettings.length === 0 &&
      this.props.user.role === Roles.CLIENT
    )
      return null;
    if (
      this.props.ordersSettings.length !== 0 &&
      this.props.user.role === Roles.CLIENT
    )
      ordersSettings = this.props.ordersSettings;

    var totalProduct = 0;
    for (var p of this.props.cart) totalProduct += p.quantity;

    ////////////////////////////////////////
    // Check Role : Admin, Clients, Collaborators
    // && Access : Mad,Home,Store,Desk
    ////////////////////////////////////////

    var menu = (
      <MenuClientsGen
        ordersSettings={ordersSettings}
        totalProduct={totalProduct}
      />
    );
    var switchComponent = <SwitchComponent />;

    // Mad + Admin
    if (this.props.user.role === Roles.ADMIN) {
      menu = <MenuAdminGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentAdmin />;
    }

    // Mad + Salesrep
    if (this.props.user.role === Roles.SALES_REP) {
      menu = <MenuSalesRepGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentSalesRep />;
    }

    ////////////////////////////////////////

    // Home + Admin
    if (
      this.props.user.access_e4pharmahome === true &&
      this.props.user.role === Roles.ADMIN
    ) {
      menu = <MenuAdminGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentHome />;
    }

    // Home + Client
    if (
      this.props.user.access_e4pharmahome === true &&
      this.props.user.role === Roles.CLIENT
    ) {
      menu = <MenuClientsGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentHomeClients />;
    }

    // Home + Salesrape
    if (
      this.props.user.access_e4pharmahome === true &&
      this.props.user.role === Roles.SALES_REP
    ) {
      menu = <MenuSalesRepGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentHomeSalesrep />;
    }
    ////////////////////////////////////////

    // Store + Admin
    if (
      this.props.user.access_e4pharmastore === true &&
      this.props.user.role === Roles.ADMIN
    ) {
      menu = <MenuAdminGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentStore />;
    }
    // Store + Clients (Clients)
    if (
      this.props.user.access_e4pharmastore === true &&
      this.props.user.role === Roles.CLIENT
    ) {
      menu = <MenuClientsGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentStoreClients />;
    }
    // Store + Salesrep
    if (
      this.props.user.access_e4pharmastore === true &&
      this.props.user.role === Roles.SALES_REP
    ) {
      menu = <MenuSalesRepGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentStoreSalesrep />;
    }

    // Desk + Admin
    if (
      this.props.user.access_e4pharmadesk === true &&
      this.props.user.access_e4pharmamad === true &&
      this.props.user.access_e4pharmastore === true
    ) {
      menu = <MenuAdminGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentAdmin />;
    }

    if (
      this.props.user.access_e4pharmadesk === true &&
      this.props.user.access_e4pharmamad === false &&
      this.props.user.access_e4pharmahome === false &&
      this.props.user.access_e4pharmastore === false
    ) {
      menu = <MenuAdminGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentDesk />;
    }

    // Desk + other Client
    if (
      this.props.user.access_e4pharmadesk === true &&
      this.props.user.role === Roles.CLIENT
    ) {
      menu = <MenuClientsGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentDeskClients />;
    }

    // Desk Client
    if (
      this.props.user.access_e4pharmadesk === true &&
      this.props.user.role === Roles.CLIENT &&
      this.props.user.access_e4pharmamad === false &&
      this.props.user.access_e4pharmahome === false &&
      this.props.user.access_e4pharmastore === false
    ) {
      menu = <MenuClientsGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentDeskClientsOnly />;
    }

    // Desk + Other Salesrep
    if (
      this.props.user.access_e4pharmadesk === true &&
      this.props.user.role === Roles.SALES_REP
    ) {
      menu = <MenuSalesRepGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentDeskSalesrep />;
    }
    // Desk Salesrep
    if (
      this.props.user.access_e4pharmadesk === true &&
      this.props.user.role === Roles.SALES_REP &&
      this.props.user.access_e4pharmamad === false &&
      this.props.user.access_e4pharmahome === false &&
      this.props.user.access_e4pharmastore === false
    ) {
      menu = <MenuSalesRepGen totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentDeskSalesrepOnly />;
    }

    let notifModals = [];
    if (this.props.notifications.length !== 0) {
      for (let notif of this.props.notifications) {
        if (notif.subject === NotificationSubject.MERCURIAL_DELETE_OR_UPDATE) {
          let title = (
            <FormattedMessage id="Notification.Mercurial.Upd.Title" />
          );
          let content = (
            <FormattedMessage id="Notification.Mercurial.Upd.Content" />
          );
          notifModals.push(
            <NotificationModal
              key={notif._id}
              title={title}
              content={content}
              successCallback={() => this.closeNotifModal(notif)}
              closeModal={() => this.closeNotifModal(notif)}
            />
          );
        }
      }
    }

    return (
      <React.Fragment>
        {menu}
        {/* {console.log("User Role :",this.props.user.access_e4pharmahome,this.props.user.role)} */}
        <div className="maindiv">
          <div className="container-fluid p-3 animated fadeIn">
            {switchComponent}
          </div>

          {notifModals}

          {this.state.rgpdModal}
          <div className="footerLinks p-2 text-center">
            <a href={hrefTerms} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="General.Conditions" />
            </a>{" "}
            | {releaseNotesLink}
          </div>
          <NotificationContainer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('HOEM clientTypes',state.clientTypes);

  return {
    rgpd: state.rgpd,
    user: state.user,
    cart: state.cart,
    notifications: state.notifications,
    ordersSettings: state.ordersSettings,
    test: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => dispatch(getUser()),
    onGetRgpd: () => dispatch(getRgpd()),
    onGetCart: () => dispatch(getCart()),
    onGetProducts: () => dispatch(getProducts()),
    onGetProductsAdmin: () => dispatch(getProductsAdmin()),
    onGetOrders: () => dispatch(getOrders()),
    onGetNotifs: () => dispatch(getNotifs()),
    onDeleteNotif: (data) => dispatch(deleteNotif(data)),
    onGetOrdersSettings: () => dispatch(getOrdersSettings()),
    onGetRequests: () => dispatch(getRequests()),
    onGetCollaboratorTypes: () => dispatch(getCollaboratorTypes()),
    onGetEstablishmentSettings: () => dispatch(getEstablishmentSettings()),
    onGetEstablishment: () => dispatch(getEstablishment()),
    onGetAdmin: () => dispatch(getAdmin()),
    onGetGeneralSettings: () => dispatch(getGeneralSettings()),
    onGetPatients: () => dispatch(getPatients()),
    onGetAudits: () => dispatch(getAudits()),
    onGetCompany: () => dispatch(getCompany()),
    onGetPromoOperations: () => dispatch(getPromoOperations()),
    onGetClientsforClient: () => dispatch(getClientsforClient()),

    // ADMIN & SALES_REP
    onGetOrdersAdmin: () => dispatch(getOrdersAdmin()),

    onGetClients: () => dispatch(getClients()),

    onGetEstablishments: () => dispatch(getEstablishments()),
    onGetMercurials: () => dispatch(getMercurials()),
    onGetAllRequests: () => dispatch(getAllRequests()),
    onGetEstablishmentsSettings: () => dispatch(getEstablishmentsSettings()),
    onGetPatientsAdmin: () => dispatch(getPatientsAdmin()),
    onGetAuditRooms: () => dispatch(getAuditRooms()),
    onGetDeskCategories: () => dispatch(getDeskCategories()),
    onGetDeskProducts: () => dispatch(getDeskProducts()),
    onGetDocuments: () => dispatch(getDocuments()),

    onGetAuditDMs: () => dispatch(getAuditDMs()),
    onGetClientTypes: () => dispatch(getClientTypes()),
    onGetAuditSettings: () => dispatch(getAuditSettings()),
    onGetAuditDeskSettings: () => dispatch(getAuditDeskSettings()),
    onGetAuditsAdmin: () => dispatch(getAuditsAdmin()),

    // ADMIN only
    onGetOrdersSettingsAdmin: () => dispatch(getOrdersSettingsAdmin()),
    onGetCollaborators: () => dispatch(getCollaborators()),
    onGetPromoOperationsAdmin: () => dispatch(getPromoOperationsAdmin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

///////////////////////////////
// Routes
///////////////////////////////

// PHAMA-MAD
// Admin

class SwitchComponentAdmin extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/clients" component={Clients} />
        <Route path="/home/mercurials" component={Mercurials} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/budget" component={BudgetAdmin} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/families" component={FamiliesAdmin} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/orders" component={OrdersAdmin} />
        <Route path="/home/settings" component={MenuSettingsMad} />
        <Route path="/home" component={SplitHomeAdmin} />
      </Switch>
    );
  }
}
// Clients / Client
class SwitchComponent extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/budget" component={Budget} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/families" component={Families} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/cart" component={Cart} />
        <Route path="/home/orders" component={Orders} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home" component={SplitHome} />
      </Switch>
    );
  }
}
// SalesRep
class SwitchComponentSalesRep extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/clients" component={Clients} />
        <Route path="/home/budget" component={BudgetAdmin} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/families" component={FamiliesAdmin} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/orders" component={OrdersAdmin} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home" component={SplitHomeAdmin} />
      </Switch>
    );
  }
}

//////////////////////////////

// PHAMA-STORE
// Admin
class SwitchComponentStore extends React.Component {
  render() {
    return (
      <Switch>
        {/* <Route path="/home/collaborators" component={Collaborators} /> */}
        <Route path="/home/clients" component={Clients} />
        <Route path="/home/mercurials" component={Mercurials} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/budget" component={BudgetAdmin} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/families" component={FamiliesAdmin} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/orders" component={OrdersAdmin} />
        <Route path="/home/settings" component={MenuSettingsMad} />
        <Route path="/home" component={SplitHomeStoreAdmin} />
      </Switch>
    );
  }
}
// Clients
class SwitchComponentStoreClients extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/mercurials" component={Mercurials} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/budget" component={Budget} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/families" component={Families} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/cart" component={Cart} />
        <Route path="/home/orders" component={Orders} />
        <Route path="/home" component={SplitHomeStore} />
      </Switch>
    );
  }
}
// Salesrep
class SwitchComponentStoreSalesrep extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/mercurials" component={Mercurials} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/budget" component={BudgetAdmin} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/families" component={FamiliesAdmin} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/orders" component={OrdersAdmin} />
        <Route path="/home" component={SplitHomeStore} />
      </Switch>
    );
  }
}
//////////////////////////////

// PHAMA-HOME
// Admin
class SwitchComponentHome extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/clients" component={Clients} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/settings" component={MenuSettingsMad} />
        {/* <Route path="/home/collaborators" component={Collaborators} /> */}
        <Route path="/home" component={SplitHomeHomeAdmin} />
      </Switch>
    );
  }
}
// Clients
class SwitchComponentHomeClients extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/account" component={Account} />
        <Route path="/home" component={SplitHomeHome} />
      </Switch>
    );
  }
}
// Salesrep
class SwitchComponentHomeSalesrep extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/budget" component={BudgetAdmin} />
        <Route path="/home" component={SplitHomeHomeAdmin} />
      </Switch>
    );
  }
}
//////////////////////////////

// PHAMA-DESK
// Admin
class SwitchComponentDesk extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/clients" component={Clients} />
        <Route path="/home/families" component={FamiliesAdmin} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/settings" component={MenuSettingsMad} />
        {/* <Route path="/home/collaborators" component={Collaborators} /> */}
        <Route path="/home/" component={SplitHomeDeskAdmin} />
      </Switch>
    );
  }
}

// Clients
class SwitchComponentDeskClientsOnly extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/account" component={Account} />
        <Route path="/home" component={SplitHome} />
      </Switch>
    );
  }
}

class SwitchComponentDeskClients extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/families" component={Families} />
        <Route path="/home/orders" component={Orders} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/cart" component={Cart} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/account" component={Account} />
        <Route path="/home" component={SplitHome} />
      </Switch>
    );
  }
}

// Salesrep ++
class SwitchComponentDeskSalesrep extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/orders" component={OrdersAdmin} />
        <Route path="/home/mercurials" component={Mercurials} />
        <Route path="/home/families" component={FamiliesAdmin} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/budget" component={BudgetAdmin} />
        <Route path="/home" component={SplitHomeDesk} />
      </Switch>
    );
  }
}
class SwitchComponentDeskSalesrepOnly extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/patients" component={Patients} />
        <Route path="/home/budget" component={BudgetAdmin} />
        <Route path="/home" component={SplitHomeDesk} />
      </Switch>
    );
  }
}
