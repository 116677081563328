import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import AuditRoomsAddModalAdmin from "./AuditRoomsAddModalAdmin";
import AuditDM from "../auditDM/AuditDM";
import { Modal, Tab, Tabs } from "react-bootstrap";

class AuditRoomsModalAdmin extends React.Component {
  close() {
    this.props.closeModal();
  }

  closeModal() {
    this.setState({ modal: null });
  }

  getAuditRoom(auditRoomId) {
    for (let auditRoom of this.props.auditRooms) {
      if (auditRoom._id === auditRoomId) return auditRoom;
    }
  }

  render() {
    const { auditRoomId } = this.props;
    const RoomCategory = this.getAuditRoom(auditRoomId);

    var target = this.props.target ? this.props.target : "general";

    var aClassName = RoomCategory ? "" : "disabled";

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.closeModal()}
          backdrop={"static"}
          size={"xl"}
        >
          <Modal.Body>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.closeModal()}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <Tabs
              defaultActiveKey={target}
              id="tab-collaborators"
              className="mb-3"
            >
              <Tab
                eventKey="general"
                title={<FormattedMessage id="General.Informations" />}
              >
                <AuditRoomsAddModalAdmin
                  closeModal={() => this.close()}
                  auditRoomId={this.props.auditRoomId}
                  deskCategoryId={
                    this.props.deskCategoryId ? this.props.deskCategoryId : null
                  }
                />
              </Tab>
              <Tab
                eventKey="dmassociated"
                title={<FormattedMessage id="Audit.Dm" />}
                disabled={aClassName}
              >
                <AuditDM auditRoomId={this.props.auditRoomId} />
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AuditRoomsModalAdmin));
