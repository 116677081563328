import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class ErrorModal extends React.Component {
  close() {
    this.props.closeModal();
  }

  confirm() {
    this.props.successCallback();
    this.close();
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          dialogClassName={"modal-dialog " + this.props.size}
          size={this.props.size}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa fa-warning fa-big mr-3"></i>
              {this.props.title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>{this.props.content}</Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={() => this.close()}>
              {this.props.buttonLabel ? (
                this.props.buttonLabel
              ) : (
                <FormattedMessage id="Ok" />
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ErrorModal;
