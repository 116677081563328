import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ShippingModalAdmin from "./ShippingModalAdmin";
import ShippingCostSettingAdmin from "./ShippingCostSettingAdmin";
import {
  updateOrderSettingAdmin,
  updateShippingCostsSetting,
} from "../../../../actions/settings/ordersSettings/admin/ordersSettings";
import { UncontrolledTooltip } from "reactstrap";

class OrdersSettingsAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      shipping_delay: this.props.ordersSettingsAdmin.shipping_delay,
      min_order_amount: this.props.ordersSettingsAdmin.min_order_amount,
      annual_budget: this.props.ordersSettingsAdmin.annual_budget,
      urgent: this.props.ordersSettingsAdmin.urgent,
    };
  }

  openShippingModal(successCallback) {
    if (this.hasInfinity()) return;

    this.setState({
      modal: (
        <ShippingModalAdmin
          isOpen={true}
          index={-1}
          shippingCosts={this.props.ordersSettingsAdmin.shipping_costs}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  createShippingCostSetting(shippingCosts) {
    // Send to BE
    this.props.onUpdateShippingCostsSetting({ shippingCosts: shippingCosts });
  }

  onFieldChange(field, value = 0) {
    this.setState({ [field]: value });
  }

  updateOrderSettingAdmin(field, value = 0) {
    var data = {
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateOrderSettingAdmin(data);
  }

  hasInfinity() {
    return (
      this.props.ordersSettingsAdmin.shipping_costs.length !== 0 &&
      !this.props.ordersSettingsAdmin.shipping_costs[
        this.props.ordersSettingsAdmin.shipping_costs.length - 1
      ].max
    );
  }

  render() {
    if (this.props.ordersSettingsAdmin.length === 0) return null;
    // Shipping modal set up
    var successCallback = (e) =>
      this.createShippingCostSetting(
        this.props.ordersSettingsAdmin.shipping_costs
      );

    let shippingCostNode = null;
    if (this.props.ordersSettingsAdmin.shipping_costs.length !== 0) {
      shippingCostNode = (
        <ShippingCostSettingAdmin
          ordersSettingsAdmin={this.props.ordersSettingsAdmin}
        />
      );
    }

    return (
      <React.Fragment>
        {this.state.modal}

        <div className="col-12 col-lg-7 col-xl-6">
          <div className="form-group row">
            <label
              htmlFor="shippingDelay"
              className="col-12 col-md-7 col-form-label"
            >
              <FormattedMessage id="Shipping.Delay" />
            </label>
            <div className="col-12 col-md-5">
              <input
                type="number"
                className="form-control"
                id="shippingDelay"
                min="0"
                value={this.state.shipping_delay}
                onChange={(e) =>
                  this.onFieldChange("shipping_delay", e.target.value)
                }
                onBlur={(e) =>
                  this.updateOrderSettingAdmin("shipping_delay", e.target.value)
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="minPrice"
              className="col-12 col-md-7 col-form-label"
            >
              <FormattedMessage id="Min.Price" />
            </label>
            <div className="col-12 col-md-5">
              <input
                type="number"
                className="form-control"
                id="minPrice"
                min="0"
                value={this.state.min_order_amount}
                onChange={(e) =>
                  this.onFieldChange("min_order_amount", e.target.value)
                }
                onBlur={(e) =>
                  this.updateOrderSettingAdmin(
                    "min_order_amount",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="annualBudget"
              className="col-12 col-md-7 col-form-label"
            >
              <FormattedMessage id="Budget.Annual" />
            </label>
            <div className="col-12 col-md-5">
              <input
                type="number"
                className="form-control"
                id="annualBudget"
                min="0"
                value={this.state.annual_budget}
                onChange={(e) =>
                  this.onFieldChange("annual_budget", e.target.value)
                }
                onBlur={(e) =>
                  this.updateOrderSettingAdmin("annual_budget", e.target.value)
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="annualBudget"
              className="col-12 col-md-7 col-form-label"
            >
              <FormattedMessage id="Settings.Urgent.Cost" />{" "}
              <FormattedMessage id="Excl.Tax" />
            </label>
            <div className="col-12 col-md-5">
              <input
                type="number"
                className="form-control"
                id="urgent"
                min="0"
                value={this.state.urgent || 0}
                onChange={(e) => this.onFieldChange("urgent", e.target.value)}
                onBlur={(e) =>
                  this.updateOrderSettingAdmin("urgent", e.target.value)
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="shippingCost"
              className="col-10 col-sm-11 col-md-7 col-form-label"
            >
              <FormattedMessage id="Shipping.Cost" />
            </label>
            <div className="col-2 col-sm-1 col-md-5">
              <i
                className="fa fa-plus-square icon-big hoverable clickable p-0 pt-2"
                id="shippingCost"
                onClick={(e) => this.openShippingModal(successCallback)}
                disabled={this.hasInfinity()}
              ></i>
              {!this.hasInfinity() && (
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"shippingCost"}
                >
                  <FormattedMessage id="Delete" />
                </UncontrolledTooltip>
              )}
            </div>
          </div>
          {shippingCostNode}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
    user: state.user,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateOrderSettingAdmin: (data) =>
      dispatch(updateOrderSettingAdmin(data)),
    onUpdateShippingCostsSetting: (data) =>
      dispatch(updateShippingCostsSetting(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersSettingsAdmin);
