import React from "react";
import { connect } from "react-redux";
// import StringUtil from '../../../../util/StringUtil';
import { FormattedMessage, injectIntl } from "react-intl";
import { deleteAuditDM } from "../../../../actions/settings/auditDM/admin/auditDM";
import { UncontrolledTooltip } from "reactstrap";

class AuditRoomsRowAdmin extends React.Component {
  deleteAuditDM(auditDM) {
    var data = {
      auditDMId: auditDM._id,
      roomId: auditDM.room_id,
    };

    this.props.onDeleteAuditDM(data);
  }

  render() {
    var refundable = <FormattedMessage id="No" />;
    if (this.props.auditDM.refundable)
      refundable = <FormattedMessage id="Yes" />;

    // 'Delete audit dm' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <React.Fragment>
        <div>
          <FormattedMessage id="Type.DM.Remove.Confirmation" />
        </div>
      </React.Fragment>
    );
    var successCallback = () => this.deleteAuditDM(this.props.auditDM);

    return (
      <React.Fragment>
        <tr
          key={this.props.key}
          className="order-tr order-tr tablelist row-striped"
        >
          <td>{this.props.auditDM.room.name}</td>
          <td>{this.props.auditDM.name}</td>
          <td>{refundable}</td>
          <td>
            {this.props.intl.formatMessage({
              id: "EqSellMode." + this.props.auditDM.type,
            })}
          </td>
          <td className="text-center tdaction">
            <i
              id={"modify" + this.props.auditDM._id}
              className="fa fa-edit icon-big"
              onClick={(e) => this.props.openEditModal(this.props.auditDM)}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"modify" + this.props.auditDM._id}
            >
              <FormattedMessage id="Modify" />
            </UncontrolledTooltip>
            <i
              id={"delete" + this.props.auditDM._id}
              className="fa fa-trash icon-big"
              onClick={(e) =>
                this.props.openConfModal(
                  modalTitle,
                  modalContent,
                  successCallback
                )
              }
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"delete" + this.props.auditDM._id}
            >
              <FormattedMessage id="Delete" />
            </UncontrolledTooltip>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAuditDM: (data) => dispatch(deleteAuditDM(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AuditRoomsRowAdmin));
