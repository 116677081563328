import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { updPatientAdmin } from "../../actions/patients/patients";
import { nanoid } from "nanoid";
import { updClient } from "../../actions/clients/clients";
import { UncontrolledTooltip } from "reactstrap";

class PatientPrescribers extends React.Component {
  constructor(props) {
    super(props);

    this.prescriberLeadExist = false;

    this.state = {
      prescriber_id: "",
      prescriber_lead: this.props.patient
        ? this.props.patient.prescriber_lead
        : null,
      disabled: false,
    };
  }

  onChange(field, value, prescriberId) {
    if (field === "lead") {
      var clonedPatient = Util.shallowClone(this.props.patient);
      clonedPatient.prescriber_lead = prescriberId;

      if (!value) clonedPatient.prescriber_lead = null;

      const successCallback = (patient) => {
        this.setState({ prescriber_lead: patient.prescriber_lead });
      };

      this.props.onupdPatientAdmin(clonedPatient, successCallback);
    } else {
      this.setState({ [field]: value });
      /*
						if (field === 'prescriber_id' && !this.props.patient.prescribers_ids.length) {
					      var clonedPatient = Util.shallowClone(this.props.patient);
								clonedPatient.prescriber_lead = value;
								const successCallback = (patient) => {
										this.setState({ prescriber_lead: patient.prescriber_lead });
								}								
								this.props.onupdPatientAdmin(clonedPatient, successCallback);
						}						
						*/

      if (
        field === "prescriber_id" &&
        !this.props.patient.prescribers_ids.length
      ) {
        this.setState({ prescriber_lead: value });
      }
    }
  }

  addPrescriber() {
    if (Util.emptyString(this.state.prescriber_id) || this.state.disabled)
      return;

    this.setState({ disabled: true, prescriber_id: "" });

    var successCallback = () => this.setState({ disabled: false });

    var clonedPatient = Util.shallowClone(this.props.patient);
    if (!clonedPatient.prescribers_ids) clonedPatient.prescribers_ids = [];

    clonedPatient.prescribers_ids.push(this.state.prescriber_id);

    if (clonedPatient.prescribers_ids.length === 1) {
      clonedPatient.prescriber_lead = this.state.prescriber_lead;
    }

    this.props.onupdPatientAdmin(clonedPatient, successCallback);
  }

  removePrescriber(patient, prescriberId) {
    if (this.state.disabled) return;

    var clonedPatient = Util.shallowClone(patient);
    clonedPatient.prescribers_ids = clonedPatient.prescribers_ids.filter(
      (id) => id !== prescriberId
    );
    if (clonedPatient.prescriber_lead === prescriberId)
      clonedPatient.prescriber_lead = null;

    var successCallback = (patient) => {
      this.setState({
        disabled: false,
        prescriber_lead: patient.prescriber_lead,
      });
    };

    this.props.onupdPatientAdmin(clonedPatient, successCallback);
  }

  render() {
    const { patient, prescribers } = this.props;

    if (!patient) return null;

    var prescribersNode = prescribers.map((p) => {
      var disabled = false;
      if (patient.prescribers_ids)
        disabled = patient.prescribers_ids.find((pId) => p._id === pId);
      return (
        <option value={p._id} key={p._id} disabled={disabled}>
          {p.first_name} {p.name}
        </option>
      );
    });

    var prescribersAssignmentsNodes = patient.prescribers_ids.map((id) => {
      var prescriber = prescribers.find((p) => p._id === id);
      if (!prescriber) return null;
      let leadId = nanoid();
      return (
        <tr key={"prescriber-" + prescriber._id}>
          <td>{prescriber.first_name}</td>
          <td>{prescriber.name}</td>
          <td>
            <div className="custom-control custom-switch mx-auto switch-success text-left">
              <input
                type="checkbox"
                id={"lead-switch" + leadId}
                className="custom-control-input switch-bg-blue"
                onChange={(e) => {
                  this.onChange("lead", e.target.checked, prescriber._id);
                }}
                checked={this.state.prescriber_lead === prescriber._id}
              />
              <label
                className="custom-control-label"
                htmlFor={"lead-switch" + leadId}
              ></label>
            </div>
          </td>
          <td>
            <i
              id={"delete" + prescriber._id}
              className="fa fa-trash icon-big"
              onClick={(e) => this.removePrescriber(patient, prescriber._id)}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"delete" + prescriber._id}
            >
              <FormattedMessage id="Delete" />
            </UncontrolledTooltip>
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <div className="col-12 col-lg-9 col-xl-8">
          <div className="form-group row">
            <label
              htmlFor="prescriber_id"
              className="col-12 col-md-5 col-form-label"
            >
              <FormattedMessage id="Assign.Prescriber" />
            </label>
            <div className="col-11 col-md-6">
              <select
                className="form-control d-inline"
                id="prescriber_id"
                value={this.state.prescriber_id}
                onChange={(e) => this.onChange("prescriber_id", e.target.value)}
              >
                <option value=""></option>
                {prescribersNode}
              </select>
            </div>

            <div className="col-1 text-left align-left float-left p-0">
              <i
                className="fa fa-plus-square icon-huge hoverable clickable d-inline"
                onClick={() => this.addPrescriber()}
                disabled={
                  Util.emptyString(this.state.prescriber_id) ||
                  this.state.disabled
                }
              />
            </div>
          </div>
        </div>

        {patient &&
          patient.prescribers_ids &&
          patient.prescribers_ids.length > 0 && (
            <table className="table col-12 tablee4mad mt-5">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="First.Name" />
                  </th>
                  <th>
                    <FormattedMessage id="Name" />
                  </th>
                  <th>
                    <FormattedMessage id="Lead" />
                  </th>
                  <th>
                    <FormattedMessage id="Actions" />
                  </th>
                </tr>
              </thead>
              <tbody>{prescribersAssignmentsNodes}</tbody>
            </table>
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
    prescribers: Util.filterClients(state.clients, "prescriber"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onupdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onupdClient: (prescriber, successCallback) =>
      dispatch(updClient(prescriber, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientPrescribers));
