import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import { UncontrolledTooltip } from "reactstrap";
import Roles from "../../enums/Roles";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import EstimateStatus from "../../enums/EstimateStatus";
import { deleteRequest, updateRequest } from "../../actions/requests/requests";

class RequestRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  updateRequest(target, request) {
    let data = {
      updatedValue: target.value,
      updatedField: "status",
      requestId: request._id,
    };
    this.props.onUpdateRequest(data);
  }

  updateParentState(data) {
    this.props.updateParentState(data);
  }

  deleteRequestAdmin(request) {
    const deleteCbk = () =>
      this.props.onDeleteRequest({ requestId: request._id });

    this.updateParentState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id={"Confirm"} />}
          content={<FormattedMessage id={"Request.Remove.Confirmation"} />}
          successCallback={deleteCbk}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.updateParentState({ modal: null });
  }

  render() {
    let statusNode = Object.values(EstimateStatus).map((status) => {
      return (
        <option key={status} value={status}>
          {this.props.intl.formatMessage({ id: "Estimate.Status." + status })}
        </option>
      );
    });

    let statusClassName = "light";

    switch (true) {
      case parseInt(this.props.request.status) === EstimateStatus.DECLINED:
        statusClassName = "danger";
        break;
      case parseInt(this.props.request.status) ===
        EstimateStatus.PARTIALLY_ACCEPTED:
        statusClassName = "warning";
        break;
      case parseInt(this.props.request.status) === EstimateStatus.ACCEPTED:
        statusClassName = "success";
        break;
      case parseInt(this.props.request.status) === EstimateStatus.PENDING:
        statusClassName = "info";
        break;
      default:
        break;
    }

    let status = (
      <div className={"alert alert-" + statusClassName}>
        &nbsp;
        {this.props.intl.formatMessage({
          id: "Estimate.Status." + this.props.request.status,
        })}
        &nbsp;
      </div>
    );

    return (
      <tr key={this.props.key} className="d-flex">
        {this.props.user.role !== Roles.CLIENT && (
          <td className="col col-2 align-middle">
            {this.props.clientName
              ? this.props.clientName
              : this.props.establishment}
          </td>
        )}

        <td className="col align-middle">
          <div className="truncate">{this.props.request.precisions}</div>
        </td>

        <td className={!this.props.limit ? "col col-2" : "col col-3"}>
          {DateUtil.toDate(this.props.request.creation_date)}
        </td>

        {this.props.user.role === Roles.CLIENT ? (
          <td className={!this.props.limit ? "col col-2" : "col col-2"}>
            {status}
          </td>
        ) : (
          <td className="col col-2">
            <select
              className={"form-control alert-" + statusClassName}
              onChange={(e) => this.updateRequest(e.target, this.props.request)}
              defaultValue={this.props.request.status}
            >
              {statusNode}
            </select>
          </td>
        )}
        {!this.props.limit && (
          <td className="col col-1 align-middle text-center tdaction">
            {this.props.user.role === Roles.CLIENT ? (
              <>
                <i
                  id={"consult" + this.props.request._id}
                  className="fa fa-eye icon-big hoverable clickable"
                  onClick={(e) =>
                    this.props.openEstimateModal(this.props.request)
                  }
                />
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"consult" + this.props.request._id}
                >
                  <FormattedMessage id="Consult" />
                </UncontrolledTooltip>
              </>
            ) : (
              <>
                <i
                  id={"modify" + this.props.request._id}
                  className="fa fa-edit icon-big hoverable clickable"
                  onClick={(e) =>
                    this.props.openEstimateModal(this.props.request)
                  }
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"modify" + this.props.request._id}
                >
                  <FormattedMessage id="Modify" />
                </UncontrolledTooltip>
                <i
                  id={"delete" + this.props.request._id}
                  className="fa fa-trash icon-big"
                  onClick={(e) => this.deleteRequestAdmin(this.props.request)}
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"delete" + this.props.request._id}
                >
                  <FormattedMessage id="Delete" />
                </UncontrolledTooltip>
              </>
            )}
          </td>
        )}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateRequest: (data) => dispatch(updateRequest(data)),
    onDeleteRequest: (requestId) => dispatch(deleteRequest(requestId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RequestRow));
