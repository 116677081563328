import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_COLLABORATOR_TYPES = "GET_COLLABORATOR_TYPES";

function getCollaboratorTypesAction(collaboratorTypes) {
  return { type: GET_COLLABORATOR_TYPES, collaboratorTypes: collaboratorTypes };
}

export const getCollaboratorTypes = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getCollaboratorTypes)
      .then(function (response) {
        if (response) dispatch(getCollaboratorTypesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addCollaboratorType = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addCollaboratorType, data)
      .then(function (response) {
        dispatch(getCollaboratorTypes());
        if (response) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeCollaboratorType = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.removeCollaboratorType, data)
      .then(function (response) {
        dispatch(getCollaboratorTypes());
        // if(response) console.log(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
