import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import ProductsBudget from "./ProductsBudget";
import OverTimeBudget from "./OverTimeBudget";
import TopProductsBudget from "./TopProductsBudget";
import { Tab, Tabs } from "react-bootstrap";

class Budget extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Tabs
          defaultActiveKey="budget-purchases"
          id="tab-budget"
          className="mb-3"
        >
          <Tab
            eventKey="budget-purchases"
            title={<FormattedMessage id="Purchases" />}
          >
            <ProductsBudget orders={this.props.orders} />
          </Tab>
          <Tab
            eventKey="budget-overview"
            title={<FormattedMessage id="Overview" />}
          >
            <OverTimeBudget
              orders={this.props.orders}
              establishmentSettings={this.props.establishmentSettings}
            />
          </Tab>
          <Tab
            eventKey="budget-topproducts"
            title={<FormattedMessage id="Top.Products" />}
          >
            <TopProductsBudget orders={this.props.orders} />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    establishmentSettings: state.establishmentSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
