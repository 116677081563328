import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import MenuProfile from "./MenuProfile";
import RequestStatus from "../../../enums/RequestStatus";
import OrderStatus from "../../../enums/OrderStatus";
import RequestTypes from "../../../enums/RequestTypes";
import APIUrl from "../../../APIUrl";
import { NavLink } from "react-router-dom";
import MenuLogo from "./MenuLogo";
import Roles from "../../../enums/Roles";
import Util from "../../../util/Util";

// Pharma
// Get User informtions
import { getUser } from "../../../actions/user/user";

class MenuSalesRepGen extends React.Component {
  componentDidMount() {
    // First, fetch the user

    this.props.onGetUser();
  }

  countNewRequests(requests) {
    var nbr = 0;
    for (var request of requests) {
      if (request.request_type === RequestTypes.NEW_MATERIAL) continue;

      if (request.status === RequestStatus.NEW) nbr++;
    }

    return nbr;
  }

  countNewOrders() {
    var nbr = 0;

    for (let order of this.props.orders) {
      if (order.status === OrderStatus.NEW) nbr++;
    }

    return nbr;
  }

  LogoTitleType() {
    // Whats user is (Mad, Home, Store, Desk)
    // Get datas
    var LogoTitleAccesType = "";
    if (
      this.props.user.access_e4pharmamad === true &&
      this.props.user.access_e4pharmadesk !== false
    ) {
      LogoTitleAccesType = "PharmaMad";
    } else {
      if (this.props.user.access_e4pharmadesk === true) {
        LogoTitleAccesType = "PharmaDesk";
      }
    }
    if (this.props.user.access_e4pharmamad === true) {
      LogoTitleAccesType = "PharmaMad";
    }

    if (this.props.user.access_e4pharmahome === true) {
      LogoTitleAccesType = "PharmaHome";
    }

    if (this.props.user.access_e4pharmastore === true) {
      LogoTitleAccesType = "PharmaStore";
    }

    if (
      this.props.user.access_e4pharmamad !== true &&
      this.props.user.access_e4pharmahome === true &&
      this.props.user.access_e4pharmadesk === true
    ) {
      LogoTitleAccesType = "PharmaHome & Desk";
    }

    if (
      this.props.user.access_e4pharmamad !== true &&
      this.props.user.access_e4pharmastore === true &&
      this.props.user.access_e4pharmadesk === true
    ) {
      LogoTitleAccesType = "PharmaStore & Desk";
    }

    return LogoTitleAccesType;
  }

  render() {
    var LogoTitle = this.LogoTitleType();
    var newOrdersNbr = this.countNewOrders();

    // First Check Role
    if (this.props.user.role === Roles.SALES_REP) {
      // Check User Access
      if (this.props.user.access_e4pharmamad !== false) {
        // PharmaMad is true
        return (
          <nav className="navbar navbar-expand-lg menu">
            <div
              id="menu"
              className="collapse navbar-collapse mb-3 mb-md-0 mt-2 pb-2"
            >
              <ul className="navbar-nav list-inline text-center align-items-center">
                <li className="nav-item list-inline-item align-middle">
                  <a
                    href={process.env.REACT_APP_LOGIN_URL + APIUrl.jwtToken}
                    className="logo-item"
                  >
                    <img
                      className="logo-header"
                      alt="logo e4Pharma"
                      title={LogoTitle}
                      src=" ./images/svg/Logo_PHARMA_MAD.svg"
                    />
                  </a>
                </li>

                {/* Displaying Menu with role SALESREP for Mad / Home / Store  */}
                {/* Accueil */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Home" />
                  </NavLink>
                </li>

                {/* Mercurial */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home/families"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Mercurials" />
                  </NavLink>
                </li>

                {/* Commandes */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home/orders"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Orders" />
                    {newOrdersNbr > 0 && (
                      <span className="badge badge-danger">{newOrdersNbr}</span>
                    )}
                  </NavLink>
                </li>

                {/* Suivi Patients */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home/patients"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Patients.Monitoring" />
                  </NavLink>
                </li>

                {/* Devis */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home/estimates"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Estimates" />
                  </NavLink>
                </li>

                {/* Statistiques */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home/budget"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Budget" />
                  </NavLink>
                </li>

                <MenuLogo generalSettings={this.props.generalSettings} />
                <li className="menuProfile nav-item list-inline-item align-middle">
                  <MenuProfile />
                </li>
              </ul>
            </div>
            <button
              className="navbar-toggler m-auto"
              type="button"
              data-toggle="collapse"
              data-target="#menu"
              aria-controls="menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </nav>
        );
      }
      if (this.props.user.access_e4pharmamad !== true) {
        // MAD is false
        return (
          <nav className="navbar navbar-expand-lg menu">
            <div
              id="menu"
              className="collapse navbar-collapse mb-3 mb-md-0 mt-2 pb-2"
            >
              <ul className="navbar-nav list-inline text-center align-items-center">
                <li className="nav-item list-inline-item align-middle">
                  <a
                    href={process.env.REACT_APP_LOGIN_URL + APIUrl.jwtToken}
                    className="logo-item"
                  >
                    <img
                      className="logo-header"
                      alt="logo e4Pharma"
                      title={LogoTitle}
                      src=" ./images/svg/Logo_PHARMA_MAD.svg"
                    />
                  </a>
                </li>

                {/* Displaying Menu with role SALESREP for Mad / Home / Store  */}
                {/* Accueil */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Home" />
                  </NavLink>
                </li>

                {/* Mercurials displaying */}
                {/* Mercurial STORE=TRUE / DESK=FALSE */}
                {this.props.user.access_e4pharmastore !== false &&
                  this.props.user.access_e4pharmahome !== true &&
                  this.props.user.access_e4pharmadesk !== true && (
                    <li className="nav-item list-inline-item align-middle text-nowrap">
                      <NavLink
                        className="w-100 p-3"
                        to="/home/families"
                        exact
                        activeClassName="selected"
                      >
                        <FormattedMessage id="Mercurials" />
                      </NavLink>
                    </li>
                  )}

                {/* Mercurial STORE=TRUE / DESK=TRUE */}
                {this.props.user.access_e4pharmastore !== false &&
                  this.props.user.access_e4pharmahome !== true &&
                  this.props.user.access_e4pharmadesk !== false && (
                    <li className="nav-item list-inline-item align-middle text-nowrap">
                      <NavLink
                        className="w-100 p-3"
                        to="/home/families"
                        exact
                        activeClassName="selected"
                      >
                        <FormattedMessage id="Mercurials" />
                      </NavLink>
                    </li>
                  )}

                {/* Commandes displaying */}
                {/* Commandes STORE=TRUE / DESK=FALSE */}
                {this.props.user.access_e4pharmastore !== false &&
                  this.props.user.access_e4pharmahome !== true &&
                  this.props.user.access_e4pharmadesk !== true && (
                    <li className="nav-item list-inline-item align-middle text-nowrap">
                      <NavLink
                        className="w-100 p-3"
                        to="/home/orders"
                        exact
                        activeClassName="selected"
                      >
                        <FormattedMessage id="Orders" />
                        {newOrdersNbr > 0 && (
                          <span className="badge badge-danger">
                            {newOrdersNbr}
                          </span>
                        )}
                      </NavLink>
                    </li>
                  )}
                {/* Commandes STORE=TRUE / DESK=TRUE */}
                {this.props.user.access_e4pharmastore !== false &&
                  this.props.user.access_e4pharmahome !== true &&
                  this.props.user.access_e4pharmadesk !== false && (
                    <li className="nav-item list-inline-item align-middle text-nowrap">
                      <NavLink
                        className="w-100 p-3"
                        to="/home/orders"
                        exact
                        activeClassName="selected"
                      >
                        <FormattedMessage id="Orders" />
                        {newOrdersNbr > 0 && (
                          <span className="badge badge-danger">
                            {newOrdersNbr}
                          </span>
                        )}
                      </NavLink>
                    </li>
                  )}

                {/* Suivi Patients */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home/patients"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Patients.Monitoring" />
                  </NavLink>
                </li>

                {/* Devis */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home/estimates"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Estimates" />
                  </NavLink>
                </li>

                {/* Statistiques */}
                <li className="nav-item list-inline-item align-middle text-nowrap">
                  <NavLink
                    className="w-100 p-3"
                    to="/home/budget"
                    exact
                    activeClassName="selected"
                  >
                    <FormattedMessage id="Budget" />
                  </NavLink>
                </li>

                <MenuLogo generalSettings={this.props.generalSettings} />

                <li className="menuProfile nav-item list-inline-item align-middle">
                  <MenuProfile />
                </li>
              </ul>
            </div>
            <button
              className="navbar-toggler m-auto"
              type="button"
              data-toggle="collapse"
              data-target="#menu"
              aria-controls="menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </nav>
        );
      }
    }
    return (
      <React.Fragment>
        <div></div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    lang: state.i18n.lang,
    cart: state.cart,
    orders: state.orders,
    requests: state.requests,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => dispatch(getUser()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuSalesRepGen)
);
