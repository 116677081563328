import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { logout } from "../../../actions/authentication/authentication";
import { updateLang } from "../../../actions/user/user";
import { Link } from "react-router-dom";

class MenuProfile extends React.Component {
  logout(e) {
    e.preventDefault();
    e.stopPropagation();

    let that = this;
    function onLogoutSuccess() {
      that.props.history.push("/");
    }

    // Call logout and redirect on success
    this.props.onLogout(onLogoutSuccess);
  }

  updateLang(lang) {
    this.props.onUpdateLang(lang);
  }

  render() {
    var lang = this.props.lang;

    if (!lang) return;

    // Available langs to chose from
    // var availableLangs = ["en", "fr"];

    // var otherLangsNode = availableLangs.map((l) => {
    //     // Lang is the one currently selected. Return
    //     var color = null;
    //     if(l === lang) color = "langselected";
    //
    //     // Add option to pick this lang
    //     return (
    //         <span key={l} className={color + " ml-2 lang"} onClick={(e) => this.updateLang(l)}><FormattedMessage id={"Lang." + l} /></span>
    //     );
    // });

    return (
      /*
            <div className="dropdown dropcenter">
                <span className="username" data-toggle="dropdown">
                    <span className="a-like">
                        {this.props.user.first_name} {this.props.user.name}
                    </span>
                    <span className="angle-down">
                      <i className="fa fa-angle-down"></i>
                    </span>
                </span>
                <ul className="dropdown-menu multi-level">
                    {/* <li className="dropdown-item langitem">
                        <span><FormattedMessage id="Lang" /> {otherLangsNode} </span>
                     </li>*/ /* }
                    <Link to="/home/account" className="text-dark nostyle-a">
                        <li className="dropdown-item">
                          <FormattedMessage id="My.Account" />
                        </li>
                    </Link>
                    <li className="dropdown-divider"></li>
                    <li className="dropdown-item" onClick={(e) => this.logout(e)}><FormattedMessage id="Disconnect" /></li>
                </ul>
            </div>
            */

      <div className="dropdown">
        <button
          className="btn btn-info dropdown-toggle text-nowrap"
          type="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-user"></i>
        </button>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuLink"
        >
          <h6 className="dropdown-header">
            {this.props.user.first_name} {this.props.user.name}
          </h6>
          <div className="dropdown-divider"></div>
          <Link to="/home/account" className="dropdown-item">
            <i className="fa fa-edit"></i>
            <FormattedMessage id="My.Account" />
          </Link>
          <div className="dropdown-divider"></div>
          <Link
            to="/#"
            className="dropdown-item"
            onClick={(e) => this.logout(e)}
          >
            <i className="fa fa-power-off"></i>
            <FormattedMessage id="Disconnect" />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (successCallback) => dispatch(logout(successCallback)),
    onUpdateLang: (lang) => dispatch(updateLang(lang)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuProfile)
);
