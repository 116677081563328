import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { Modal } from "react-bootstrap";

class AuditEmailModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      documentsSelected: [],
      prescribersSelected: [],
      helpersSelected: [],
      documentsNotSelected: this.props.documents,
      prescribersNotSelected: this.props.patient.prescribers_ids,
      helpersNotSelected: this.props.patient.helpers_ids,
      send_mail_patient: false,
      spinner: "fa fa-spinner fa-spin d-none",
      btnMessage: <FormattedMessage id="Send" />,
    };
  }

  scanForm() {
    if (
      this.state.prescribersSelected.length === 0 &&
      this.state.helpersSelected.length === 0 &&
      !this.state.send_mail_patient
    )
      return true;
  }

  sendEmails() {
    this.setState({ spinner: "fa fa-spinner fa-spin" });
    this.setState({ btnMessage: <FormattedMessage id="Plz.Wait" /> });

    var emailsToSend = [];
    for (var prescriberSelected of this.state.prescribersSelected) {
      if (prescriberSelected && prescriberSelected.email) {
        emailsToSend.push(prescriberSelected.email);
      }
    }
    for (var helperSelected of this.state.helpersSelected) {
      if (helperSelected && helperSelected.email) {
        emailsToSend.push(helperSelected.email);
      }
    }
    if (this.state.send_mail_patient)
      emailsToSend.push(this.props.patient.email);

    this.props.sendEmails(
      emailsToSend,
      this.state.documentsSelected,
      this.props.audit
    );
  }

  removeEmail(selected) {
    if (Object.keys(selected)[0] === "prescriber") {
      var prescriber = this.props.prescribers.find(
        (prescriber) => prescriber._id === Object.values(selected)[0]
      );
      if (prescriber && prescriber.email) {
        this.setState((state) => ({
          prescribersSelected: [...this.state.prescribersSelected, prescriber],
        }));
      }
      var prescribersSelected = this.state.prescribersSelected;
      var indexPres = prescribersSelected.findIndex(
        (prescriberSelected) =>
          prescriberSelected._id === Object.values(selected)[0]
      );
      delete prescribersSelected[indexPres];
      var prescribersSelectedFiltered = prescribersSelected.filter(function (
        prescriberSelected
      ) {
        return prescriberSelected != null;
      });

      this.setState((state) => ({
        prescribersSelected: prescribersSelectedFiltered,
      }));
    }
    if (Object.keys(selected)[0] === "helper") {
      var helpersSelected = this.state.helpersSelected;
      var indexHelp = helpersSelected.findIndex(
        (helperSelected) => helperSelected._id === Object.values(selected)[0]
      );
      delete helpersSelected[indexHelp];
      var helpersSelectedFiltered = helpersSelected.filter(function (
        helperSelected
      ) {
        return helperSelected != null;
      });

      this.setState((state) => ({ helpersSelected: helpersSelectedFiltered }));
    }
  }

  pushDocument(selected) {
    let documentIsSelected = this.state.documentsSelected.find((d) => {
      return d === selected;
    });
    if (documentIsSelected) return null;

    this.setState((state) => ({
      documentsSelected: [...this.state.documentsSelected, selected],
    }));
  }

  removeDocument(selected) {
    let documentsSelectedFiltered = this.state.documentsSelected.filter((d) => {
      return d !== selected;
    });

    this.setState((state) => ({
      documentsSelected: documentsSelectedFiltered,
    }));
  }

  pushEmail(selected) {
    if (!Object.values(selected)[0]) return;

    if (Object.keys(selected)[0] === "prescriber") {
      var prescriber = this.props.prescribers.find(
        (prescriber) => prescriber._id === Object.values(selected)[0]
      );
      if (prescriber && prescriber.email) {
        this.setState((state) => ({
          prescribersSelected: [...this.state.prescribersSelected, prescriber],
        }));
      }
    }
    if (Object.keys(selected)[0] === "helper") {
      var helper = this.props.helpers.find(
        (helper) => helper._id === Object.values(selected)[0]
      );
      if (helper && helper.email) {
        this.setState((state) => ({
          helpersSelected: [...this.state.helpersSelected, helper],
        }));
      }
    }
    if (Object.keys(selected)[0] === "patient") {
      if (this.state.send_mail_patient) {
        this.setState({ send_mail_patient: false });
      } else {
        this.setState({ send_mail_patient: true });
      }
    }
  }

  render() {
    const { patient } = this.props;

    var prescribersNotSelectedNode = this.state.prescribersNotSelected.map(
      (prescriberNotSelected) => {
        if (
          this.state.prescribersSelected &&
          this.state.prescribersSelected.find(
            (prescriberSelected) =>
              prescriberSelected._id === prescriberNotSelected._id
          )
        )
          return null;

        var prescriberinfo = this.props.prescribers.find(
          (h) => h._id === prescriberNotSelected
        );

        if (!prescriberinfo.email) {
          return (
            <option
              key={prescriberinfo._id + "-notselected"}
              value={prescriberinfo._id}
              disabled
            >
              {prescriberinfo.first_name +
                " " +
                prescriberinfo.name +
                " (Il n'a pas email)"}
            </option>
          );
        }
        return (
          <option
            key={prescriberinfo._id + "-notselected"}
            value={prescriberinfo._id}
          >
            {prescriberinfo.first_name + " " + prescriberinfo.name}
          </option>
        );
      }
    );

    var prescribersSelectedNode = this.state.prescribersSelected.map(
      (prescriberSelected) => {
        if (!prescriberSelected || prescriberSelected.length === 0) return null;
        return (
          <div
            key={prescriberSelected._id + "-selected"}
            className="d-flex justify-content-between divSelected"
          >
            <span>
              {prescriberSelected.first_name + " " + prescriberSelected.name}
            </span>
            <i
              className="fa fa-times-circle"
              onClick={() => {
                this.removeEmail({ prescriber: prescriberSelected._id });
              }}
            ></i>
          </div>
        );
      }
    );

    var helpersNotSelectedNode = this.state.helpersNotSelected.map(
      (helperNotSelected) => {
        if (
          this.state.helpersSelected &&
          this.state.helpersSelected.length !== 0 &&
          this.state.helpersSelected.find(
            (helperSelected) => helperSelected._id === helperNotSelected._id
          )
        )
          return null;

        var helperinfo = this.props.helpers.find(
          (h) => h._id === helperNotSelected
        );
        if (!helperinfo.email) {
          return (
            <option
              key={helperinfo._id + "-notselected"}
              value={helperinfo._id}
              disabled
            >
              {helperinfo.first_name +
                " " +
                helperinfo.name +
                " (Il n'a pas email)"}
            </option>
          );
        }
        return (
          <option key={helperinfo._id + "-notselected"} value={helperinfo._id}>
            {helperinfo.first_name + " " + helperinfo.name}
          </option>
        );
      }
    );

    var helpersSelectedNode = this.state.helpersSelected.map(
      (helperSelected) => {
        if (!helperSelected || helperSelected.length === 0) return null;

        return (
          <div
            key={helperSelected._id + "-selected"}
            className="d-flex justify-content-between divSelected"
          >
            <span>{helperSelected.first_name + " " + helperSelected.name}</span>
            <i
              className="fa fa-times-circle"
              onClick={() => {
                this.removeEmail({ helper: helperSelected._id });
              }}
            ></i>
          </div>
        );
      }
    );

    var documentsNotSelectedNode = this.state.documentsNotSelected.map(
      (documentNotSelected) => {
        if (
          this.state.documentsSelected &&
          this.state.documentsSelected.find(
            (documentSelected) =>
              documentSelected._id === documentNotSelected._id
          )
        )
          return null;

        return (
          <option
            key={documentNotSelected._id + "-notselected"}
            value={documentNotSelected._id}
          >
            {documentNotSelected.name}
          </option>
        );
      }
    );

    var documentsSelectedNode = this.state.documentsSelected.map(
      (documentSelected) => {
        if (!documentSelected || documentSelected.length === 0) return null;

        var documentinfo = this.props.documents.find(
          (h) => h._id === documentSelected
        );

        return (
          <div
            key={documentinfo._id + "-selected"}
            className="d-flex justify-content-between divSelected"
          >
            <span>{documentinfo.name}</span>
            <i
              className="fa fa-times-circle"
              onClick={() => {
                this.removeDocument(documentinfo._id);
              }}
            ></i>
          </div>
        );
      }
    );

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
          size={"xl"}
        >
          <Modal.Body>
            <button
              type="button"
              className="close left p-3"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.close()}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div className="mx-auto audit-emails text-center">
              <div className="title">
                <FormattedMessage id="Send.Report.To" />
              </div>

              <div
                className={"items text-left rowcontaint"}
                onClick={() => {
                  this.pushEmail({ patient: patient });
                }}
              >
                {this.state.send_mail_patient ? (
                  <i className="fa fa-check audit-checkbox mt-fix"></i>
                ) : (
                  <div className="audit-checkbox mt-fix"></div>
                )}
                <div className="audit-dm-name mt-1">
                  <FormattedMessage id="Patient" />
                </div>
              </div>

              <div className="overflow-auto">{prescribersSelectedNode}</div>

              <div className="text-center" role="alert">
                <select
                  className="form-control col-12 mb-2 mt-2 mx-auto"
                  onChange={(e) => {
                    this.pushEmail({ prescriber: e.target.value });
                  }}
                >
                  <option value="">
                    {this.props.intl.formatMessage({
                      id: "Select.Prescribers",
                    })}
                    ...
                  </option>
                  {prescribersNotSelectedNode}
                </select>
              </div>

              <div className="overflow-auto">{helpersSelectedNode}</div>

              <div className="text-center" role="alert">
                <select
                  className="form-control col-12 mb-2 mt-2 mx-auto"
                  onChange={(e) => {
                    this.pushEmail({ helper: e.target.value });
                  }}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "Select.Helpers" })}...
                  </option>
                  {helpersNotSelectedNode}
                </select>
              </div>

              {this.props.documents && this.props.documents.length ? (
                <>
                  <div className="overflow-auto">{documentsSelectedNode}</div>

                  <div className="text-center" role="alert">
                    <select
                      className="form-control col-12 mb-2 mt-2 mx-auto"
                      onChange={(e) => {
                        this.pushDocument(e.target.value);
                      }}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({
                          id: "Select.Documents",
                        })}
                        ...
                      </option>
                      {documentsNotSelectedNode}
                    </select>
                  </div>
                </>
              ) : null}

              <button
                type="button"
                className="btn audit-conf-btn mx-auto mt-5"
                onClick={() => {
                  this.sendEmails();
                }}
                disabled={this.scanForm()}
              >
                <i id="spinner" className={this.state.spinner}></i>{" "}
                {this.state.btnMessage}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    helpers: Util.filterClients(state.clients, "helper"),
    prescribers: Util.filterClients(state.clients, "prescriber"),
    documents: state.documents,
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AuditEmailModal));
