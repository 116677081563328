import React from "react";
import { connect } from "react-redux";
import {
  updPatientAdmin,
  deletePatientTechnicalSheet,
  addPatientTechnicalFile,
} from "../../actions/patients/patients";
import APIUrl from "../../APIUrl";
import Document from "../sub/documentCrud/Document";

class PatientTechnicalSheets extends React.Component {
  render() {
    const {
      patient,
      user,
      onUpdPatientAdmin,
      onAddPatientTechnicalFile,
      onDeletePatientTechnicalSheet,
    } = this.props;

    return (
      <React.Fragment>
        <Document
          data={patient}
          documentType={"technical_sheets"}
          addDataDocument={(patientId, file, successCallback) =>
            onAddPatientTechnicalFile(patientId, file, successCallback)
          }
          updateData={(patient, successCallback) =>
            onUpdPatientAdmin(patient, successCallback)
          }
          deleteDocument={(patientId, sheetId, successCallback) =>
            onDeletePatientTechnicalSheet(patientId, sheetId, successCallback)
          }
          messageId={"Patient.Technical.Sheet.Add"}
          adminEmptyMessageId={"Empty.Patient.Technical.Sheets"}
          clientEmptyMessageId={"Client.Empty.Patient.Technical.Sheets"}
          user={user}
          url={APIUrl.getPatientTechnicalSheetFile}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onAddPatientTechnicalFile: (patientId, file, successCallback) =>
      dispatch(addPatientTechnicalFile(patientId, file, successCallback)),
    onDeletePatientTechnicalSheet: (patientId, sheetId, successCallback) =>
      dispatch(
        deletePatientTechnicalSheet(patientId, sheetId, successCallback)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientTechnicalSheets);
