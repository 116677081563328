const MercurialsMapping = {
  fam: "FAM",
  famille: "FAMILLE",
  sous_famille: "SOUS FAMILLE",
  caracteristiques: "CARACTERISTIQUES",
  designation: "DESIGNATION",
  unite_de_vente: "UNITE DE VENTE",
  min_cde: "MIN CDE",
  ref: "REF",
  prix_u_ht_emera: "PRIX U. HT",
  tva: "TVA",
  fournisseur: "FOURNISSEUR",
  ref_frn: "REF FRN",
};

export default MercurialsMapping;
