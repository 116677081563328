import React from "react";
import { connect } from "react-redux";
import {
  updPatientAdmin,
  deletePatientBillFile,
  addPatientBillFile,
} from "../../actions/patients/patients";
import APIUrl from "../../APIUrl";
import Document from "../sub/documentCrud/Document";

class PatientBills extends React.Component {
  render() {
    const {
      patient,
      user,
      onUpdPatientAdmin,
      onAddPatientBillFile,
      onDeletePatientBillFile,
    } = this.props;

    return (
      <React.Fragment>
        <Document
          data={patient}
          documentType={"bills"}
          addDataDocument={(patientId, file, successCallback) =>
            onAddPatientBillFile(patientId, file, successCallback)
          }
          updateData={(patient, successCallback) =>
            onUpdPatientAdmin(patient, successCallback)
          }
          deleteDocument={(patientId, sheetId, successCallback) =>
            onDeletePatientBillFile(patientId, sheetId, successCallback)
          }
          messageId={"Add.Bill"}
          adminEmptyMessageId={"Empty.Bills"}
          clientEmptyMessageId={"Empty.Bills"}
          user={user}
          url={APIUrl.getPatientBillFile}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddPatientBillFile: (patientId, bill, successCallback) =>
      dispatch(addPatientBillFile(patientId, bill, successCallback)),
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onDeletePatientBillFile: (patientId, billId, successCallback) =>
      dispatch(deletePatientBillFile(patientId, billId, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientBills);
