import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { createRgpd } from "../../actions/rgpd/rgpd";
import { logout } from "../../actions/authentication/authentication";
import { Button, Modal } from "react-bootstrap";

class RgpdModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
    };
  }

  close(e) {
    // e.preventDefault();
    // e.stopPropagation();

    let that = this;
    function onLogoutSuccess() {
      that.props.history.push("/");
    }

    // Call logout and redirect on success
    this.props.onLogout(onLogoutSuccess);
  }

  accept() {
    this.props.onCreateRgpd(() => this.props.closeRgpdModal());
    this.setState({ disabled: true });
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Accept.Cookies" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div id="rgpd-scroll" className="text-justify">
              <p>
                E4IA est très sensible à la protection des données personnelles
                des utilisateurs de ses applications. L'ensemble des règles
                applicables à la protection de vos données personnelles, comme
                les mesures techniques de protection de vos données numériques,
                figurent dans le présent document qu'il est nécessaire que vous
                acceptiez pour pouvoir continuer à utiliser les applications
                E4BIZZ.
              </p>
              <p>
                E4IA vous informe qu’en décidant de poursuivre à utiliser ses
                services (en cliquant sur le bouton "accepter et continuer")
                vous l'autorisez à collecter des données personnelles vous
                concernant dans les limites définies dans les présentes
                conditions.
              </p>

              <h5>1. E4IA collecte certaines de vos données personnelles</h5>
              <p>
                Lorsque vous utilisez les applications E4BIZZ sur ordinateur
                personnel, tablette ou téléphone mobile, E4IA collecte et traite
                des données personnelles vous concernant qui sont nécessaires
                afin de permettre d'afficher les contenus que vous souhaitez
                consulter / visualiser / lire en ligne. Conformément au
                Règlement européen n°2016/679 du 27 avril 2016 sur la protection
                des données à caractère personnel (le "RGPD"), E4IA vous informe
                qu'il s’agit d’un traitement de données sans consentement
                "nécessaire à l’exécution" du contrat entre vous, "Utilisateur",
                et nous, "E4IA" (art.6.1(f) RGPD). Parmi les données vous
                concernant que E4IA traite, E4IA est susceptible d'utiliser les
                capacités de traitement ou de stockage de votre terminal (par
                exemple par l'utilisation de "cookies") ainsi que certaines
                données techniques émises ou transmises par votre terminal.
              </p>

              <h5>
                2. Identification du responsable du traitement de vos données
                personnelles
              </h5>
              <p>
                En collectant et en traitant les données personnelles des
                utilisateurs de ses solutions, E4IA agit en qualité de
                responsable du traitement de vos données. Vous trouverez le
                détail de l'identification légale de E4IA en consultant nos{" "}
                <a href="https://e4-bizz.com/tou-fr.html">
                  Conditions Générales
                </a>
                .
              </p>
              <p>
                Afin de garantir à ses utilisateurs une parfaite transparence
                sur le traitement de leurs données personnelles, E4IA a choisi
                de nommer un Délégué à la Protection des Données personnelles
                (le "DPD"). Pour l'exercice de vos "droits RGPD" ou pour
                répondre à toute question sur le traitement des données
                personnelles par E4IA, vous pouvez contacter le DPD de E4IA par
                email à l'adresse :{" "}
                <a href="mailto:rgpd@e4-ia.fr?subject=RGPD E4IA">
                  rgpd@e4-ia.fr
                </a>
              </p>
              <p>
                Le détail des droits au regard des disposition du RGPD qui sont
                offerts par E4IA aux utilisateurs de ses services figure à
                l'article 8 de la politique de protection des données
                personnelles de E4IA.
              </p>

              <h5>3. Finalité du traitement de vos données personnelles</h5>
              <p>
                Toutes les données personnelles collectées et traitées
                directement par E4IA ont pour finalité de permettre à E4IA
                d'assurer techniquement un service performant pour ses clients
                et utilisateurs ainsi que la sécurité informatique nécessaire au
                bon fonctionnement de ses applications.
              </p>
              <p>
                Ces données sont collectées soit automatiquement lors de la
                connexion de votre terminal aux applications de la suite E4BIZZ,
                soit lorsque vous transmettez des informations vous concernant à
                E4IA (par exemple via des formulaires de saisie ou la connexion
                à votre espace personnel) et ont pour seule finalité de
                permettre à E4IA de fournir les services auxquels vous avez
                souscrit ou pour lesquels un accès vous a été accordé.
              </p>
              <p>
                Certaines de vos données personnelles sont enfin nécessaires à
                E4IA pour nous permettre de vous rendre les services qui sont
                décrits dans nos{" "}
                <a href="https://e4-bizz.com/tou-fr.html">
                  Conditions Générales
                </a>
                .
              </p>
              <p>
                E4IA s’engage en outre à ne pas commercialiser vos données
                personnelles à des tiers.
              </p>

              <h5>4. Durée de conservation de vos données personnelles</h5>
              <p>
                E4IA s’engage à conserver vos données personnelles pendant une
                durée de deux (2) ans à compter de votre dernière connexion à
                l'une de ses applications de la suite E4BIZZ. Si vous ne vous
                reconnectez pas pendant cette durée, E4IA effacera l'ensemble de
                vos données personnelles, ne conservant que des données
                minimales permettant à E4IA de justifier des actions entreprises
                sur vos données dans le cadre de l'exercice de vos droits RGPD.
              </p>

              <h5>5. Détail des données recueillies par E4IA</h5>
              <p>
                Les catégories de données personnelles vous concernant
                collectées et traitées par E4IA sont les suivantes :
              </p>
              <ul>
                <li>
                  Les adresses IP des terminaux (notamment ordinateur, tablette,
                  smartphone) que vous utilisez pour accéder aux services
                  fournis par E4IA
                </li>
                <li>
                  Des données techniques relatives à votre terminal et au
                  logiciel de navigation web (navigateur ou browser) de votre
                  terminal que vous utilisez pour accéder aux services fournis
                  par E4IA (système d’exploitation du terminal, version du
                  navigateur, etc.)
                </li>
              </ul>

              <h5>6. Destinataires de vos données personnelles</h5>
              <p>
                E4IA est le seul destinataire des données personnelles
                collectées à partir des applications de la suite E4BIZZ et
                traitées par E4IA. E4IA a mis en place une politique
                d'habilitation de ses salariés et collaborateurs de sorte que ne
                puissent accéder à vos données personnelles que les salariés ou
                collaborateurs ayant un intérêt professionnel à y accéder dans
                le cadre de leurs fonctions.
              </p>
              <p>
                Pour des raisons purement techniques, E4IA peut être amenée à
                sous-traiter certaines prestations à sa charge (comme par
                exemple l'hébergement des applications). Dans tous les cas de
                sous-traitance du traitement de certaines de vos données
                personnelles, E4IA a mis en place une politique contractuelle
                avec chacun de ses sous-traitants de sorte que la sécurité de
                vos données soit assurée par chacun des sous-traitants de E4IA
                dans des conditions au moins aussi élevées que celles mises en
                place par E4IA. E4IA a pris un soin particulier à veiller à ce
                qu'aucun de ses sous-traitants ayant un accès direct à vos
                données personnelles ait le droit de reproduire ni à son profit
                ni au profit de qui que ce soit vos données, ni bien sûr de les
                commercialiser.
              </p>
              <p>
                Dans l’hypothèse où E4IA viendrait à sous-traiter tout ou partie
                des données personnelles vous concernant, E4IA demeurera seul
                responsable du traitement à votre égard. De manière générale,
                E4IA a mis en place une politique interne lui permettant de
                veiller à ce que chacun de ses sous-traitants concernés respecte
                l’ensemble des dispositions juridiques et techniques de
                protection de vos données personnelles conformément au présent
                engagement de E4IA à votre égard.
              </p>
              <p>
                Enfin, E4IA vous rappelle que vos données personnelles sont
                stockées sur des serveurs agréés HDS (Hébergement Données de
                Santé) situés en France et vous garantit qu’aucun transfert de
                vos données vers un pays hors de l’Union Européenne n’aura lieu,
                sauf (i) à vous en informer au préalable et, éventuellement, à
                vous demander votre accord ou (ii) si le pays concerné bénéficie
                d'une décision officielle d'adéquation de l'Union Européenne qui
                garantit que vos données seront protégées de manière équivalente
                dans le pays concerné.
              </p>

              <h5>
                7. Mesures techniques de protection de vos données personnelles
              </h5>
              <p>
                Afin de vous permettre d'accéder à ses applications, E4IA a mis
                en place des mesures d'authentification à ses services par un
                certificat de sécurité. Ainsi, votre connexion au site web
                e4-bizz.com est sécurisée si vous voyez apparaitre l'adresse
                https:// ("s" comme sécurisé) dans l'URL de votre navigateur.
                Cette connexion sécurisée entre votre terminal et nos serveurs
                vous offre des garanties maximales de sécurisation de transfert
                de vos données.
              </p>

              <h5>
                8. Vos droits sur vos données personnelles traitées par E4IA
              </h5>
              <p>
                Pour exercer vos droits au regard des dispositions du RGPD, vous
                devez vous adresser directement à E4IA dans les conditions
                fixées ci-dessous. En cas de "doute raisonnable" quant à votre
                identité, E4IA peut vous demander de fournir des informations
                supplémentaires nécessaires à la confirmation de votre identité
                et attendre de recevoir ces informations supplémentaires avant
                toute action relative à vos données.
              </p>

              <h6>8.1. Justificatif de votre identité</h6>
              <p>
                Pour que E4IA puisse vous identifier comme la personne
                effectivement concernée par le droit d'accès et/ou de
                rectification, vous devez envoyer à E4IA une copie recto verso
                de votre carte d’identité par courrier postal, en précisant
                également votre adresse électronique. Aucune demande ne sera
                prise en compte par E4IA à défaut de réception du justificatif
                de votre identité.
              </p>

              <h6>8.2. Droit d’accès (article 15 RGPD)</h6>
              <p>
                Vous disposez du droit de demander directement, sans frais, à
                E4IA de vous confirmer (ou non) l'existence du traitement de
                données personnelles vous concernant. Si E4IA traite
                effectivement des données personnelles vous concernant, E4IA
                s'engage à vous adresser dans les trente (30) jours de votre
                demande, par voie électronique, à l'adresse mail que vous
                indiquerez (i) une copie intégrale desdites données et
                informations vous concernant et (ii) l'ensemble des informations
                supplémentaires (notamment les finalités et les destinataires du
                traitement, les catégories et la durée de conservation des
                données) fixées par l'article 13 RGPD.
              </p>
              <p>
                E4IA vous rappelle toutefois que l’exercice du droit d’accès et
                de réception d'une copie de vos données est gratuit si vous
                l’exercez dans des conditions raisonnables. Si vos demandes de
                droit d'accès sont « manifestement infondées ou excessives,
                notamment en raison de leur caractère répétitif » (art. 12.5
                RGPD), E4IA peut refuser de vous répondre ou vous demander une
                participation financière pour la fourniture de ces informations.
              </p>
              <p>
                Vous pouvez exercer votre droit d'accès en adressant un courrier
                accompagné d'une copie de votre pièce d'identité à l'adresse
                suivante :
              </p>
              <p className="text-center">
                E FOR IA
                <br /> A l'attention du Délégué à la Protection des Données
                <br /> Zone Gabriélat – 1 route de Trémège 09100 Pamiers
              </p>
              <p>
                E4IA s'engage alors à vous donner accès en ligne à l'ensemble de
                vos données et aux informations que E4IA est tenue de vous
                communiquer. Il ne sera procédé par E4IA à aucun envoi "papier"
                de ces informations.
              </p>

              <h6>8.3. Droit de rectification (article 16 RGPD)</h6>
              <p>
                En qualité d'utilisateur référencé de nos applications, vous
                pouvez obtenir de E4IA, dans les meilleurs délais, la
                rectification des données personnelles vous concernant si ces
                données venaient à être inexactes.
              </p>
              <p>
                Pour exercer votre droit de rectification sur les données que
                vous avez vous-même saisi lors de la création ou de la
                modification de vos données personnelles, il vous appartient
                d'effectuer vous-même cette rectification en ligne. Dans tous
                les autres cas, et pour autant que vous puissiez justifier du
                caractère erroné des données vous concernant et traitées par
                E4IA, veuillez{" "}
                <a href="mailto:rgpd@e4-ia.fr?subject=RGPD E4IA">
                  nous contacter
                </a>
                .
              </p>

              <h6>
                8.4. Notification des violations de données personnelles
                (article 34 RGPD)
              </h6>
              <p>
                Toute "violation" de vos données personnelles qui serait
                constatée par E4IA (et qui entraînerait, de manière accidentelle
                ou illicite, l'accès ou la divulgation non autorisée,
                l'altération, la perte ou la destruction de tout ou partie de
                vos données personnelles) fera l'objet d'une information à la
                Commission Nationale Informatique et Libertés au plus tard dans
                les 72 heures de la découverte par E4IA de cette violation.
              </p>
              <p>
                Pour le cas où cette violation de vos données personnelles
                entrainerait un risque élevé pour vos droits et libertés, E4IA
                s'engage à vous en informer dans les meilleurs délais, sauf :
              </p>
              <ul>
                <li>
                  Si E4IA a mis en œuvre des mesures de protection techniques et
                  organisationnelles et si ces mesures ont été appliquées à vos
                  données personnelles affectées par ladite violation, comme par
                  exemple des mesures qui rendent vos données [personnelles]
                  incompréhensibles pour toute personne qui ne serait pas
                  autorisée à y avoir accès
                </li>
                <li>
                  Si E4IA a pris des mesures ultérieures qui garantissent que le
                  risque élevé pour vos droits et libertés n'est plus
                  susceptible de se matérialiser
                </li>
                <li>
                  Si cette information exigerait des efforts disproportionnés
                  pour E4IA. Dans ce cas, E4IA s’engage à une communication
                  publique ou à une mesure similaire vous permettant d'être
                  informé(e) de manière tout aussi efficace.
                </li>
              </ul>

              <h5>
                9. Utilisation de vos données personnelles par E4IA à des fins
                de prospection commerciale
              </h5>
              <p>
                E4IA s'engage à n'utiliser vos données personnelles que dans le
                cadre des nécessités induites par les services auxquels vous
                avez souscrit ou pour lesquels un accès vous a été accordé et en
                aucun cas à des fins de prospection commerciale relative à
                d'autres produits ou services.
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => this.close()}
              disabled={this.state.disabled}
            >
              <FormattedMessage id="Refuse" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.accept()}
              disabled={this.state.disabled}
            >
              <FormattedMessage id="Accept" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRgpd: (successCallback) => dispatch(createRgpd(successCallback)),
    onLogout: (successCallback) => dispatch(logout(successCallback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RgpdModal)
);
