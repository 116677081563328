import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";

class AuditCommentModal extends React.Component {
  render() {
    const { audit, auditDMs } = this.props;

    let comments = [];
    let rawcomments = [];

    if (audit.private_comments && audit.private_comments.length) {
      audit.private_comments.map((cmt) => {
        if (cmt && cmt.audit_dm_id && cmt.comment) {
          //if (cmt && cmt.audit_dm_id) {

          let DM = auditDMs.find((ele) => {
            return ele._id === cmt.audit_dm_id;
          });

          if (DM.room && DM.room.ref) {
            if (!rawcomments[DM.room.ref]) {
              rawcomments[DM.room.ref] = { name: DM.room.name, dms: [] };
            }

            rawcomments[DM.room.ref].dms.push({
              name: DM.name,
              comment: cmt.comment,
            });
          }
        }
        return null;
      });

      for (let roomref in rawcomments) {
        for (let dm in rawcomments[roomref].dms) {
          comments.push({
            room: rawcomments[roomref].name,
            dm: rawcomments[roomref].dms[dm].name,
            comment: rawcomments[roomref].dms[dm].comment,
          });
        }
      }
    }

    var commentsAuditsNode = null;

    if (comments.length > 0) {
      commentsAuditsNode = comments.map((cmt, idx) => {
        return (
          <tr key={"comment-" + idx} className={"order-tr order-tr"}>
            <td className="d-md-table-cell mw-200 align-middle">{cmt.room}</td>
            <td className="d-md-table-cell mw-200 align-middle">{cmt.dm}</td>
            <td className="d-md-table-cell mw-200 align-middle">
              {cmt.comment}
            </td>
          </tr>
        );
      });
    }

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
          size={"xl"}
        >
          <Modal.Body>
            <button
              type="button"
              className="close left"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.close()}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <table className="table tablee4mad mt-3">
              <thead>
                <tr className="tablerowhead">
                  <th className="d-md-table-cell">
                    <FormattedMessage id="Audit.Rooms" />
                    <i className="fa fa-chevron-down p-1"></i>
                  </th>
                  <th className="d-md-table-cell">
                    <FormattedMessage id="Medical.Dm" />{" "}
                    <i className="fa fa-chevron-down p-1"></i>
                  </th>
                  <th className="d-md-table-cell">
                    <FormattedMessage id="Internal.Comment" />
                    <i className="fa fa-chevron-down p-1"></i>
                  </th>
                </tr>
              </thead>
              <tbody>{commentsAuditsNode}</tbody>
            </table>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditDMs: state.auditDMs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditCommentModal);
