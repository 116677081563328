import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import FileUtil from "../../util/FileUtil";
import FileDropZone from "./FileDropZone";
import { Alert, Button } from "react-bootstrap";
import Roles from "../../enums/Roles";

// PROPS //
// files: Array<any>
// data: Object
// addFiles: function
// deleteFile: function
// url: string APIURL
// disabled: boolean

class CustomFileDropZone extends React.Component {
  constructor(props) {
    super(props);

    let maxFiles = 3;

    this.state = {
      files: this.props.files || [],
      errorFilesDropped: null,
      disabledDropZone:
        this.props.userRole === Roles.CLIENT ||
        (this.props.files ? this.props.files.length >= maxFiles : false),
      maxFiles: maxFiles,
      maxFileSize: process.env.REACT_APP_UPLOAD_MAXFILESIZE || 2097152, // If not defined in .env, we set it to 2mb (in bytes)
    };
  }

  checkDataLengthFile(files) {
    let response = false;
    for (let file of files) {
      if (file.size > this.state.maxFileSize) {
        response = true;
      }
    }
    return response;
  }

  onDropFiles(files, clearCallback) {
    let formData = new FormData();

    // Required to lookup for possible duplicates
    let fileCount = 0;
    formData.set("fileCount", fileCount);

    if (this.state.files.length + files.length <= this.state.maxFiles) {
      if (!this.checkDataLengthFile(files)) {
        let value = [];
        value = this.state.files;

        for (let file of files) {
          // Avoid duplicates
          if (value.indexOf(file.name) === -1) {
            value.push(file.name);
            this.setState({ files: value });
            formData.append(file.name, file);
            fileCount++;
          }
        }

        let successCallback = () => {
          clearCallback();
          if (this.state.files.length >= this.state.maxFiles) {
            this.setState({ disabledDropZone: true });
          } else {
            this.setState({ disabledDropZone: false });
          }
        };

        this.setState({ errorFilesDropped: null });

        // Upload only valid files and total files below or equal max allowed files to upload
        if (fileCount > 0) {
          this.props.addFiles(this.props.data._id, formData, successCallback);
        }
      } else {
        this.setState({
          errorFilesDropped: (
            <FormattedMessage
              id="File.Oversized"
              values={{ value: FileUtil.bytesToSize(this.state.maxFileSize) }}
            />
          ),
        });
      }
    } else {
      this.setState({
        errorFilesDropped: (
          <FormattedMessage
            id="File.Maxlength"
            values={{
              maxFiles: this.state.maxFiles,
              submittedFiles: files.length,
            }}
          />
        ),
      });
    }
  }

  removeFile = (file) => {
    // Prepare data to be sent to the BE
    let data = {
      itemId: this.props.data._id,
      fileName: file,
    };

    // Define a successcalback on file deletion
    let successCallback = () => {
      // Update state value for files
      const newStateFiles = this.state.files.filter(
        (stateFile) => stateFile !== file
      );
      this.setState({ files: newStateFiles });

      if (this.state.files.length <= this.state.maxFileSize) {
        this.setState({ disabledDropZone: false });
      }
    };

    this.props.deleteFile(data, successCallback);
  };

  render() {
    let filesNode = null;
    if (this.state.files.length > 0) {
      filesNode = this.state.files.map((file, key) => {
        return (
          <div key={key} className="mb-1">
            <small>
              {this.props.userRole !== Roles.CLIENT && (
                <Button
                  variant="danger"
                  className="mr-2 btn-sm"
                  onClick={() => this.removeFile(file)}
                  disabled={this.props.disabled}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              )}
              <a
                role="button"
                href={
                  this.props.url +
                  this.props.data._id +
                  "/" +
                  file +
                  "?token=" +
                  APIUrl.jwtToken
                }
                rel="noopener noreferrer"
                download={file}
                target="_blank"
              >
                {file}
              </a>
            </small>
          </div>
        );
      });
    }

    return (
      <div className="form-group row mt-3">
        <div className="col-12 col-sm-5">
          <span>
            <FormattedMessage id="Files" /> &nbsp; :
          </span>
        </div>
        {(this.props.userRole !== Roles.CLIENT ||
          (this.props.userRole === Roles.CLIENT &&
            this.state.files.length > 0)) && (
          <div className="col-12 col-sm-7">
            <div>
              <div
                className={this.state.disabledDropZone ? "mb-2 hide" : "mb-2"}
              >
                <FileDropZone
                  disabled={this.state.disabledDropZone || this.props.disabled}
                  onDropFiles={(files, clearCallback) =>
                    this.onDropFiles(files, clearCallback)
                  }
                  acceptedExtensions={[
                    "pdf",
                    "doc",
                    "docx",
                    "jpg",
                    "png",
                    "jpeg",
                  ]}
                  multiple={true}
                />
              </div>
              {this.props.userRole !== Roles.CLIENT && (
                <>
                  <FormattedMessage id="Attachments" />:{" "}
                  {this.state.files.length} / {this.state.maxFiles}
                </>
              )}
              <p className="text-danger">{this.state.errorFilesDropped}</p>
            </div>
            {filesNode}
          </div>
        )}

        {this.props.userRole === Roles.CLIENT && this.state.files.length <= 0 && (
          <div className="col-12 col-sm-7">
            <Alert variant="info">Aucun document pour le moment</Alert>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(CustomFileDropZone);
