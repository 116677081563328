import { GET_AUDIT_EQUIPMENTS } from "../../actions/auditEquipments/auditEquipments";

export default function auditEquipments(state = [], action) {
  switch (action.type) {
    case GET_AUDIT_EQUIPMENTS:
      return action.auditEquipments;
    default:
      return state;
  }
}
