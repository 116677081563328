import React from "react";
//import OrdersAdmin from "../orders/admin/OrdersAdmin";
import Estimates from "../estimates/Estimates";
// import RequestsAdmin from '../requests/admin/RequestsAdmin';
// import Establishments from '../clients/Establishments';
//import Roles from '../../enums/Roles';
import { connect } from "react-redux";
import Patients from "../../components/patients/Patients";
import Clients from "../clients/Clients";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Card, CardTitle } from "reactstrap";
import OrdersAdmin from "../orders/admin/OrdersAdmin";

class SplitHomeDeskAdmin extends React.Component {
  render() {
    if (this.props.user.access_e4pharmadesk === true) {
      return (
        <React.Fragment>
          <div className="col-12">
            {/* <div className="col-12 col-lg-6 max-width">
                        <div className="m-2 zoom-7">
                            <Card className="p-3">
                                <CardTitle><h4 className="text-info"><FormattedMessage id="Summary.Orders.Admin" /></h4></CardTitle>
                                <OrdersAdmin limit={5} />
                                <Link to="/home/orders" className="overlay text-center"><i className="fa fa-search"></i></Link>
                            </Card>
                        </div>
                    </div> */}

            {/* <div className="col-12 col-lg-6 max-width"> */}

            <div className="col-12">
              <div className="m-2 zoom-7">
                <Card className="p-3">
                  <CardTitle>
                    <h4 className="text-info">
                      <FormattedMessage id="Summary.Patients.Monitoring" />
                    </h4>
                  </CardTitle>
                  <Patients limit={5} />
                  <Link to="/home/patients" className="overlay text-center">
                    <i className="fa fa-search"></i>
                  </Link>
                </Card>
              </div>
            </div>

            <div className="col-12">
              <div className="m-2 zoom-7">
                <Card className="p-3 mt-5">
                  <CardTitle>
                    <h4 className="text-info">
                      <FormattedMessage id="Summary.Estimates.Admin" />
                    </h4>
                  </CardTitle>
                  <Estimates limit={5} />
                  <Link to="/home/estimates" className="overlay text-center">
                    <i className="fa fa-search"></i>
                  </Link>
                </Card>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="m-2 zoom-7">
                <Card className="p-3 mt-5">
                  <CardTitle>
                    <h4 className="text-info">
                      <FormattedMessage id="Summary.Clients.Admin" />
                    </h4>
                  </CardTitle>
                  <Clients limit={5} />
                  <Link to="/home/clients" className="overlay text-center">
                    <i className="fa fa-search"></i>
                  </Link>
                </Card>
              </div>
            </div>

            {/* <div className="col-12 col-lg-6 ">
                        <div className="m-2 zoom-7">
                            <Card className="p-3">
                                <CardTitle><h4 className="text-info"><FormattedMessage id="Summary.Clients.Admin" /></h4></CardTitle>
                                <Establishments limit={5}/>
                                <Link to="/home/clients" className="overlay text-center"><i className="fa fa-search"></i></Link>
                            </Card>
                        </div>
                    </div> */}

            {/* <div className="col-12 col-lg-6 ">
                        <div className="m-2 zoom-7">
                            <Card className="p-3">
                                <CardTitle><h4 className="text-info"><FormattedMessage id="Summary.Requests.Admin" /></h4></CardTitle>
                                <RequestsAdmin limit={5} />
                                <Link to="/home/requests" className="overlay text-center"><i className="fa fa-search"></i></Link>
                            </Card>
                        </div>
                    </div> */}
          </div>
        </React.Fragment>
      );
    }
    if (
      this.props.user.access_e4pharmahome !== false &&
      this.props.user.access_e4pharmadesk === true
    ) {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-12 col-lg-6 max-width">
              <div className="m-2 zoom-7">
                <Card className="p-3">
                  <CardTitle>
                    <h4 className="text-info">
                      <FormattedMessage id="Summary.Orders.Admin" />
                    </h4>
                  </CardTitle>
                  <OrdersAdmin limit={5} />
                  <Link to="/home/orders" className="overlay text-center">
                    <i className="fa fa-search"></i>
                  </Link>
                </Card>
              </div>
            </div>

            <div className="col-12 col-lg-6 max-width">
              <div className="m-2 zoom-7">
                <Card className="p-3">
                  <CardTitle>
                    <h4 className="text-info">
                      <FormattedMessage id="Summary.Estimates.Admin" />
                    </h4>
                  </CardTitle>
                  <Estimates limit={5} />
                  <Link to="/home/estimates" className="overlay text-center">
                    <i className="fa fa-search"></i>
                  </Link>
                </Card>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6 max-width">
              <div className="m-2 zoom-7">
                <Card className="p-3">
                  <CardTitle>
                    <h4 className="text-info">
                      <FormattedMessage id="Summary.Patients.Monitoring" />
                    </h4>
                  </CardTitle>
                  <Patients limit={5} />
                  <Link to="/home/patients" className="overlay text-center">
                    <i className="fa fa-search"></i>
                  </Link>
                </Card>
              </div>
            </div>

            <div className="col-12 col-lg-6 max-width">
              <div className="m-2 zoom-7">
                <Card className="p-3">
                  <CardTitle>
                    <h4 className="text-info">
                      <FormattedMessage id="Summary.Clients.Admin" />
                    </h4>
                  </CardTitle>
                  <Clients limit={5} />
                  <Link to="/home/clients" className="overlay text-center">
                    <i className="fa fa-search"></i>
                  </Link>
                </Card>
              </div>
            </div>

            {/* <div className="col-12 col-lg-6 ">
                                    <div className="m-2 zoom-7">
                                        <Card className="p-3">
                                            <CardTitle><h4 className="text-info"><FormattedMessage id="Summary.Clients.Admin" /></h4></CardTitle>
                                            <Establishments limit={5}/>
                                            <Link to="/home/clients" className="overlay text-center"><i className="fa fa-search"></i></Link>
                                        </Card>
                                    </div>
                                </div> */}

            {/* <div className="col-12 col-lg-6 ">
                                    <div className="m-2 zoom-7">
                                        <Card className="p-3">
                                            <CardTitle><h4 className="text-info"><FormattedMessage id="Summary.Requests.Admin" /></h4></CardTitle>
                                            <RequestsAdmin limit={5} />
                                            <Link to="/home/requests" className="overlay text-center"><i className="fa fa-search"></i></Link>
                                        </Card>
                                    </div>
                                </div> */}
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitHomeDeskAdmin);
