import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import AuditDMRowAdmin from "./AuditDMRowAdmin";

class AuditRoomsTableAdmin extends React.Component {
  render() {
    // ADD FILTER BY ROOM_ID
    // console.log(this.props.auditRoomId);

    let auditDMsRowNode = this.props.auditDMs.map((auditDM) => {
      if (auditDM.room_id === this.props.auditRoomId) {
        // console.log("this.props.auditDM >> ",auditDM.room.name,auditDM.room_id == this.props.auditRoomId);

        return (
          <AuditDMRowAdmin
            key={auditDM._id}
            auditDM={auditDM}
            openEditModal={(auditDM) => this.props.openEditModal(auditDM)}
            openConfModal={(title, content, successCallback) =>
              this.props.openConfModal(title, content, successCallback)
            }
          />
        );
      }

      return null;
    });

    return (
      <React.Fragment>
        <table className="table table-striped table-bordered tablee4mad mt-5">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Name" />
              </th>
              <th>
                <FormattedMessage id="Material.DM" />
              </th>
              <th>
                <FormattedMessage id="Refundable" />
              </th>
              <th>
                <FormattedMessage id="Type" />
              </th>
              <th className="text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{auditDMsRowNode}</tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditRoomsTableAdmin);
