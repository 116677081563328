import APIUrl from "../../../APIUrl";
import axios from "axios";

export const GET_DOCUMENTS = "GET_DOCUMENTS";

function getDocumentsAction(documents) {
  return { type: GET_DOCUMENTS, documents: documents };
}

export const getDocuments = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getDocuments)
      .then(function (response) {
        dispatch(getDocumentsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getDocument = function (name, successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getDocument + name)
      .then(function (response) {
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDocument = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDocument, data)
      .then(function (response) {
        dispatch(getDocuments());
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDocumentFile = function (documentId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDocumentFile + documentId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function () {
        dispatch(getDocuments());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateDocument = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateDocument, data)
      .then(function () {
        dispatch(getDocuments());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteDocument = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteDocument, data)
      .then(function () {
        dispatch(getDocuments());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
