import React from "react";
import { connect } from "react-redux";
import DateUtil from "../../util/DateUtil";
import { FormattedMessage, injectIntl } from "react-intl";
import ExcelUtil from "../../util/ExcelUtil";
import FileUtil from "../../util/FileUtil";
import MercurialStatus from "../../enums/MercurialStatus";
import { UncontrolledTooltip } from "reactstrap";

import MercurialsMapping from "../../util/MercurialsMapping";

import "../../css/orders/orders.css";

class MercurialRow extends React.Component {
  getMercurialProducts(mercurial) {
    let products = [];

    for (let product of this.props.products) {
      if (product.mercurial_id === mercurial._id) {
        products.push(product);
      }
    }

    return products;
  }

  download(mercurial) {
    // Convert data to Excel format
    let products = this.getMercurialProducts(mercurial);
    let excelData = ExcelUtil.toExcel(products, MercurialsMapping, [
      "_id",
      "__v",
      "mercurial_id",
      "product_mercurial",
      "company_id",
      "customImg",
      "createdAt",
      "updatedAt",
    ]);

    // Sanitize the file name
    let fileName = FileUtil.toFileName(mercurial.name);

    // Save the file
    ExcelUtil.save(excelData, fileName);
  }

  render() {
    if (!this.props.mercurial) return null;

    var mercurial = this.props.mercurial;

    // 'Delete order' modal setup
    // var modalTitle = <FormattedMessage id="Confirm" />;
    // var modalContent = (
    //     <React.Fragment>
    //         <FormattedMessage id="Mercurial.Remove.Confirmation" />
    //         <br /><br />
    //         <div className="card text-white bg-danger">
    //             <div className="card-header">
    //                 <i className="fa fa-warning mr-2 text-white"></i>
    //                 <FormattedMessage id="Warning" />
    //             </div>
    //             <div className="card-body">
    //                 <p className="card-text"><FormattedMessage id="Mercurials.Delete.Warning.Content" /></p>
    //             </div>
    //         </div>
    //     </React.Fragment>
    // );
    // var successCallback = (e) => this.props.onDeleteMercurial({ mercurialId: mercurial._id });

    var hasGreaterVersion = false;
    for (let m of this.props.mercurials) {
      if (m.name === mercurial.name && m.version > mercurial.version) {
        hasGreaterVersion = true;
        break;
      }
    }

    let versionXS = null;
    if (hasGreaterVersion || mercurial.version > 1)
      versionXS = (
        <span className="d-table-cell d-md-none">
          (<FormattedMessage id="Version" /> {mercurial.version})
        </span>
      );

    let isOutDated = new Date(mercurial.end_date) < new Date();

    const status = isOutDated ? MercurialStatus.INACTIVE : mercurial.status;
    const isInactive = status === MercurialStatus.INACTIVE;

    return (
      <React.Fragment>
        <tr
          className={isOutDated || isInactive ? "font-italic text-muted" : ""}
        >
          <td>
            {mercurial.name}
            {versionXS}
          </td>
          <td className="d-none d-md-table-cell">
            {DateUtil.toDate(mercurial.start_date)}
          </td>
          <td className="d-none d-md-table-cell">
            {DateUtil.toDate(mercurial.end_date)}
          </td>
          <td className="d-none d-sm-table-cell">{mercurial.file_name}</td>
          <td className="d-none d-md-table-cell">{mercurial.version}</td>
          <td>
            {this.props.intl.formatMessage({
              id: "Mercurial.Status." + status,
            })}
          </td>
          <td className="text-center align-middle tdaction">
            <i
              id={"import" + mercurial._id}
              className="fa fa-upload icon-big"
              onClick={(e) => {
                if (!isOutDated && !isInactive)
                  this.props.openMercurialUpdModal(mercurial);
              }}
              disabled={isOutDated || isInactive}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"import" + mercurial._id}
            >
              <FormattedMessage id="Import.Mercurial" />
            </UncontrolledTooltip>
            <i
              id={"export" + mercurial._id}
              className="fa fa-download icon-big"
              onClick={(e) => {
                this.download(mercurial);
              }}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"export" + mercurial._id}
            >
              <FormattedMessage id="Export.Mercurial" />
            </UncontrolledTooltip>
            <i
              id={"import-image" + mercurial._id}
              className="fa fa-file-image-o icon-big"
              onClick={(e) => {
                if (!isOutDated && !isInactive)
                  this.props.openImgsModal(mercurial);
              }}
              disabled={isOutDated || isInactive}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"import-image" + mercurial._id}
            >
              <FormattedMessage id="Import.Image.Mercurial" />
            </UncontrolledTooltip>
            <i
              id={"delete" + mercurial._id}
              className="fa fa-trash icon-big"
              onClick={(e) => {
                this.props.openLayersModal(mercurial);
              }}
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"delete" + mercurial._id}
            >
              <FormattedMessage id="Delete" />
            </UncontrolledTooltip>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MercurialRow));
