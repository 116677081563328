import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import AuditDMModalAdmin from "./AuditDMModalAdmin";
import AuditDMTableAdmin from "./AuditDMTableAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";

class AuditDM extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openAuditDMModal() {
    this.setState({
      modal: (
        <AuditDMModalAdmin isOpen={true} closeModal={() => this.closeModal()} />
      ),
    });
  }

  openEditModal(auditDM) {
    this.setState({
      modal: (
        <AuditDMModalAdmin
          isOpen={true}
          closeModal={() => this.closeModal()}
          auditDMId={auditDM._id}
          roomId={auditDM.room_id}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    const { auditDMs, auditRoomId } = this.props;

    return (
      <React.Fragment>
        <button
          className="btn btn-info m-auto hoverable"
          onClick={(e) => this.openAuditDMModal()}
        >
          <FormattedMessage id="Add.DM" />
        </button>

        {(!auditDMs || auditDMs.length <= 0) && (
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Empty.Audit.DM" />
          </div>
        )}

        {this.props.auditDMs && this.props.auditDMs.length !== 0 && (
          <AuditDMTableAdmin
            auditRoomId={auditRoomId}
            auditDMs={this.props.auditDMs}
            openEditModal={(auditDM) => this.openEditModal(auditDM)}
            openConfModal={(title, content, successCallback) =>
              this.openConfModal(title, content, successCallback)
            }
          />
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    auditDMs: state.auditDMs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditDM);
