import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addDocument,
  updateDocument,
  getDocument,
  addDocumentFile,
} from "../../../../actions/settings/documentSettings/document";
import FileDropZone from "../../../sub/FileDropZone";
import Util from "../../../../util/Util";
import { Button, Modal } from "react-bootstrap";

class DocumentModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.document ? this.props.document.name : "",
      scope: this.props.document ? this.props.document.scope : "0",
      nameError: null,
      disabled: this.props.document ? true : false,
      modal: null,
    };

    this.acceptedFileExtensions = [
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "jpeg",
      "png",
    ];
    this.maxFileSize = 10000000;
  }

  close() {
    this.props.closeModal();
  }

  onChange(key, value) {
    this.setState({
      [key]: value,
    });

    if (key !== "name") return;

    if (Util.emptyString(value)) return;
  }

  onDropFile(file, clearCallback) {
    if (!file || file.length === 0) return;

    // Update mode. Immediately send file to BE
    if (this.props.document && this.props.document._id) {
      this.setState({ disabledFileUpload: true });

      let formData = new FormData();
      formData.append(file.name, file);

      var successCallback = () => {
        this.setState({ disabledFileUpload: false, file: file });
        clearCallback();
      };

      // Send to BE
      this.props.onAddDocumentFile(
        this.props.document._id,
        formData,
        successCallback
      );
    }
    // Create mode. Store the file and wait for the onComplete event
    else {
      this.setState({ file: file });
    }
  }

  onSubmit() {
    var sendfile = (document) => {
      if (!this.state.file || this.state.file.length === 0)
        return this.props.closeModal();

      let formData = new FormData();
      formData.append(this.state.file.name, this.state.file);

      this.props.onAddDocumentFile(document._id, formData, () =>
        this.props.closeModal()
      );
    };

    var fileExist = !!this.state.file;

    var data = {
      name: this.state.name,
      normalized: this.state.name.trim().toLowerCase(),
      scope: this.state.scope,
      file: fileExist,
    };

    this.props.onAddDocument(data, sendfile);
  }

  onUpdate(updatedField, updatedValue) {
    if (!this.props.document || !this.props.document._id) return;

    if (updatedField === "name" && Util.emptyString(updatedValue)) return;

    var data = {
      documentId: this.props.document._id,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    this.setState({ [updatedField]: updatedValue });

    this.props.onUpdateDocument(data);
  }

  checkErrors() {
    return (
      this.state.nameError ||
      this.state.disabled ||
      !this.state.file ||
      Util.emptyString(this.state.name)
    );
  }

  getDocument(documentId) {
    for (let document of this.props.documents) {
      if (document._id === documentId) return document;
    }
  }

  render() {
    var img;

    let extensionsAcceptedTXT = this.acceptedFileExtensions.map((ele, idx) => {
      return idx === this.acceptedFileExtensions.length - 1 ? ele : ele + ", ";
    });

    let filesize = this.state.file
      ? (this.state.file.size / 1000000).toFixed(2)
      : this.props.document
      ? (this.props.document.file_size / 1000000).toFixed(2)
      : null;

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.closeModal()}
          backdrop={"static"}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Patient.Document.Add" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-8">
                {document && document.file && (
                  <div className="d-none d-lg-block w-150 rounded light-card text-center">
                    <div className="form-group row m-0 p-0"></div>
                    <div className="w-150 m-0 p-0">{img}</div>
                  </div>
                )}

                <div className="form-group row">
                  <label
                    htmlFor="name"
                    className="col-12 col-md-4 col-form-label"
                  >
                    <FormattedMessage id="Name" />
                  </label>
                  <div id="name" className="col-12 col-md-8 mb-4">
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      autoComplete="off"
                      value={this.state.name}
                      onChange={(e) => this.onChange("name", e.target.value)}
                      onBlur={(e) => this.onUpdate("name", this.state.name)}
                    />
                    <small className="text-danger">
                      {this.state.nameError}
                    </small>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="name"
                    className="col-12 col-md-4 col-form-label"
                  >
                    <FormattedMessage id="Document.Scope" />
                  </label>
                  <div id="name" className="col-12 col-md-8 mb-4">
                    <select
                      className="form-control w-100 selectlist"
                      value={this.state.scope}
                      onChange={(e) => this.onChange("scope", e.target.value)}
                      onBlur={(e) => this.onUpdate("scope", e.target.value)}
                    >
                      <option value="0">
                        {this.props.intl.formatMessage({
                          id: "Document.Scope.0",
                        })}
                      </option>
                      <option value="1">
                        {this.props.intl.formatMessage({
                          id: "Document.Scope.1",
                        })}
                      </option>
                      <option value="2">
                        {this.props.intl.formatMessage({
                          id: "Document.Scope.2",
                        })}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-12 col-md-5 col-form-label mb-2">
                    <FormattedMessage id="Document" />
                    <br />({extensionsAcceptedTXT})
                    <br />
                    <br />
                    {this.state.file ? (
                      <>
                        <label>{this.state.file.name}</label>
                        <FormattedMessage id="Document.Size" />:<br />
                        {filesize}
                        <FormattedMessage id="Document.Mega" />
                      </>
                    ) : this.props.document ? (
                      <>
                        <label>{this.props.document.file_name}</label>
                        <FormattedMessage id="Document.Size" />:<br />
                        {filesize}
                        <FormattedMessage id="Document.Mega" />
                      </>
                    ) : null}
                  </label>

                  <div className="col-12 col-md-7">
                    <FileDropZone
                      disabled={this.state.disabledDropZone}
                      onDropFile={(file, clearCallback) =>
                        this.onDropFile(file, clearCallback)
                      }
                      acceptedExtensions={this.acceptedFileExtensions}
                      maxFileSize={this.maxFileSize}
                      multiple={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          {(!this.props.document || !this.props.document._id) && (
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.props.closeModal()}
              >
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="info"
                onClick={() => this.onSubmit()}
                disabled={this.checkErrors()}
              >
                <FormattedMessage id="Add" />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documents: state.documents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDocument: (data, successCallback) =>
      dispatch(addDocument(data, successCallback)),
    onGetDocument: (data, successCallback) =>
      dispatch(getDocument(data, successCallback)),
    onUpdateDocument: (data) => dispatch(updateDocument(data)),
    onAddDocumentFile: (documentId, data, successCallback) =>
      dispatch(addDocumentFile(documentId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DocumentModalAdmin));
