import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class CartConfModalAdmin extends React.Component {
  close() {
    this.props.closeModal();
  }

  confirm() {
    this.props.successCallback();
    this.close();
  }

  render() {
    var clientsNode = this.props.clients.map((client) => {
      return (
        <option key={client._id} value={client._id}>
          {client.username}
        </option>
      );
    });

    return (
      <div className="modal-bg show">
        <Modal show={true} onHide={() => this.close()} backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Order.Confirm" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <label htmlFor="comment">
              <FormattedMessage id="Choose.Client" /> :
            </label>
            <select id="order-clientId" className="form-control mb-3 w-100">
              {clientsNode}
            </select>

            <label htmlFor="comment">
              <FormattedMessage id="Comment" /> :
            </label>
            <div>
              <textarea
                className="form-control"
                type="text"
                id="comment"
                name="comment"
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.confirm()}
              disabled={this.props.clients.length === 0}
            >
              <FormattedMessage id="Confirm" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartConfModalAdmin);
