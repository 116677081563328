import React from "react";
import { connect } from "react-redux";
import CartRow from "./CartRow";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import PromptModal from "../sub/modals/PromptModal";
import { deleteAll } from "../../actions/cart/cart";
import { FormattedMessage, injectIntl } from "react-intl";
import { passOrder, passOrderAdmin } from "../../actions/orders/orders";
import ProductsTotalUtil from "../../util/ProductsTotalUtil";
import PriceUtil from "../../util/PriceUtil";
import DateUtil from "../../util/DateUtil";
import Roles from "../../enums/Roles";
import CartPromptModalAdmin from "./CartPromptModalAdmin";
import Paginator from "../sub/Paginator";
import APIUrl from "../../APIUrl";
import { Link } from "react-router-dom";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disableButton: true,
    };

    this.paginator = new Paginator(this);
  }

  getProduct(productId) {
    for (let product of this.props.products) {
      if (product._id === productId) return product;
    }

    return null;
  }

  passOrder(e) {
    var comment = document.getElementById("comment").value;
    var data = { comment: comment };

    // Admin
    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    ) {
      // Add the clientId to the call
      var clientId = document.getElementById("order-clientId").value;
      data.clientId = clientId;

      this.props.onPassOrderAdmin(data);
    }
    // User
    else {
      this.props.onPassOrder(data);
    }
  }

  deleteAll(e) {
    this.props.onDeleteAll();
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openOrderPromptModal() {
    // Admin user
    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    ) {
      this.setState({
        modal: (
          <CartPromptModalAdmin
            isOpen={true}
            successCallback={(e) => this.passOrder()}
            closeModal={() => this.closeModal()}
          />
        ),
      });
    }
    // Normal user
    else {
      var promptModalTitle = <FormattedMessage id="Order.Confirm" />;
      var promptModalContent = <FormattedMessage id="Comment" />;

      this.setState({
        modal: (
          <PromptModal
            isOpen={true}
            title={promptModalTitle}
            content={promptModalContent}
            successCallback={(e) => this.passOrder()}
            closeModal={() => this.closeModal()}
          />
        ),
      });
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    var ordersSettings;
    if (
      this.props.ordersSettings.length === 0 &&
      this.props.ordersSettingsAdmin.length === 0
    )
      return null;
    if (this.props.ordersSettings.length !== 0)
      ordersSettings = this.props.ordersSettings;
    if (this.props.ordersSettingsAdmin.length !== 0)
      ordersSettings = this.props.ordersSettingsAdmin;

    var offreOfMoment;
    if (this.props.promoOperations.length > 0) {
      var promoOperation =
        this.props.promoOperations[
          Math.floor(
            Math.random() * Math.floor(this.props.promoOperations.length)
          )
        ];
      var product = this.getProduct(promoOperation.product_id);
      if (!product) {
        offreOfMoment = null;
      } else {
        if (promoOperation.fileName) {
          offreOfMoment = (
            <div className="col-6 m-auto border border-danger p-0">
              <div className="text-center bg-danger">
                <strong className="text-white">
                  {this.props.intl.formatMessage({
                    id: "Offer.Of.Moment.Until",
                  }) +
                    DateUtil.toDate(promoOperation.endDate) +
                    ")"}
                </strong>
              </div>
              <div className="text-center">
                <Link
                  to={
                    "/home/products/mercurial/" +
                    product.mercurial_id +
                    "/" +
                    encodeURI(product.famille) +
                    "/" +
                    product._id
                  }
                  className="nostyle-a"
                >
                  <p>
                    {product.designation + " " + product.unite_de_vente + " "}
                    <span className="text-danger">
                      {PriceUtil.priceTtc(product, 1, 2) + "€"}
                    </span>
                  </p>
                </Link>
                <a
                  className="text-warning"
                  role="button"
                  href={
                    APIUrl.getPromoOperationFile +
                    promoOperation._id +
                    "/" +
                    promoOperation.fileName +
                    "?token=" +
                    APIUrl.jwtToken
                  }
                  rel="noopener noreferrer"
                  download={promoOperation.fileName}
                  target="_blank"
                >
                  <FormattedMessage id="Click.Here" />
                </a>
              </div>
            </div>
          );
        } else {
          offreOfMoment = (
            <div className="col-6 m-auto border border-danger p-0">
              <div className="text-center bg-danger">
                <strong className="text-white">
                  {this.props.intl.formatMessage({
                    id: "Offer.Of.Moment.Until",
                  }) +
                    DateUtil.toDate(promoOperation.endDate) +
                    ")"}
                </strong>
              </div>
              <div className="text-center">
                <Link
                  to={
                    "/home/products/mercurial/" +
                    product.mercurial_id +
                    "/" +
                    encodeURI(product.famille) +
                    "/" +
                    product._id
                  }
                  className="nostyle-a"
                >
                  <p>
                    {product.designation + " " + product.unite_de_vente + " "}
                    <span className="text-danger">
                      {PriceUtil.priceTtc(product, 1, 2) + "€"}
                    </span>
                  </p>
                </Link>
              </div>
            </div>
          );
        }
      }
    }

    if (this.props.cart.length === 0 || this.props.products.length === 0) {
      return (
        <React.Fragment>
          {offreOfMoment}
          <Link to="/home/families" className="iconed-btn nostyle-a hoverable">
            <button className="btn btn-info m-auto">
              <FormattedMessage id="Categories" />
            </button>
          </Link>
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Empty.Cart" />
          </div>
        </React.Fragment>
      );
    }

    // Helper that will do all calculations for us
    var productsTotalUtil = new ProductsTotalUtil(
      this.props.cart,
      this.props.products,
      null,
      null,
      ordersSettings
    );
    // 'Delete cart' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = <FormattedMessage id="Empty.Cart.Confirm" />;
    var successCallbackConfModal = (e) => this.deleteAll(e);

    this.paginator.init();
    let cartNode = this.props.cart.map((cart) => {
      if (!this.paginator.keep()) return null;

      var product = this.getProduct(cart.id_product);

      // if (!product) return null;
      return (
        <CartRow
          key={cart._id}
          cart={cart}
          product={product}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
        />
      );
    });

    var shoppingAlert = null;
    var shippingAlert = null;

    if (!productsTotalUtil.aboveMinToOrder()) {
      shoppingAlert = (
        <div className="alert alert-danger" role="alert">
          <FormattedMessage
            id="Min.Order.Cost"
            values={{ cost: productsTotalUtil.min_cost_to_order }}
          />
        </div>
      );
    }

    if (productsTotalUtil.freeShipping()) {
      var max =
        ordersSettings.shipping_costs[ordersSettings.shipping_costs.length - 1]
          .max;
      var min =
        ordersSettings.shipping_costs[ordersSettings.shipping_costs.length - 1]
          .min;
      shippingAlert = (
        <div className="alert alert-info" role="alert">
          <FormattedMessage
            id="Min.Shipping.Cost"
            values={{ cost: max || min }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        {offreOfMoment}
        <a href="#/home/families" className="iconed-btn nostyle-a hoverable">
          <button className="btn btn-info m-auto">
            <FormattedMessage id="Categories" />
          </button>
        </a>

        <div className="content mt-3">
          {this.state.modal}
          {shoppingAlert}
          {shippingAlert}

          <table className="table table-striped tablee4mad">
            <thead>
              <tr>
                <th className="d-none d-lg-table-cell">
                  <FormattedMessage id="Category" />
                </th>
                <th>
                  <FormattedMessage id="Designation" />
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Vending.unit" />
                </th>
                <th className="d-none d-lg-table-cell">
                  <FormattedMessage id="Reference" />
                </th>
                <th>
                  <FormattedMessage id="Qty" />
                </th>
                <th className="d-none d-lg-table-cell">
                  <FormattedMessage id="Unit.Price.Excl.Tax" />
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Unit.Tax" />
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Unit.Price.Incl.Tax" />
                </th>
                <th className="d-none d-lg-table-cell">
                  <FormattedMessage id="Total" />{" "}
                  <FormattedMessage id="Excl.Tax" />
                </th>
                <th>
                  <FormattedMessage id="Total" />{" "}
                  <FormattedMessage id="Incl.Tax" />
                </th>
                <th className="d-none d-md-table-cell">
                  <FormattedMessage id="Urgent" />
                </th>
                <th>
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{cartNode}</tbody>
          </table>

          {this.paginator.render()}

          <div className="card col-12 col-lg-6 offset-lg-6">
            <div className="card-body">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id="Total.Cart.Excl.Tax" />
                    </td>
                    {productsTotalUtil.aboveMinToOrder() ? (
                      <td>
                        <b>{productsTotalUtil.totalHtCart}€</b>
                      </td>
                    ) : (
                      <td>
                        <b className="text-danger">
                          {productsTotalUtil.totalHtCart}€
                        </b>

                        <i
                          id="aboveMinToOrder"
                          className={
                            "fa fa-exclamation-triangle text-danger ml-4"
                          }
                        ></i>
                        <UncontrolledTooltip
                          delay={{ show: 0, hide: 0 }}
                          placement="top"
                          target={"aboveMinToOrder"}
                        >
                          <FormattedMessage
                            id="Cart.Min.Order"
                            values={{
                              amount: productsTotalUtil.min_cost_to_order,
                              missing: Number(
                                productsTotalUtil.min_cost_to_order -
                                  productsTotalUtil.totalHtCart
                              ).toFixed(1),
                            }}
                          />
                        </UncontrolledTooltip>
                      </td>
                    )}
                  </tr>
                  {productsTotalUtil.urgent && (
                    <tr>
                      <td>
                        <FormattedMessage id="Emergency.Cost" />
                      </td>
                      <td>
                        <b>{productsTotalUtil.urgent_shipping_cost}€</b>
                      </td>
                    </tr>
                  )}
                  {productsTotalUtil.aboveMinToOrder() && (
                    <tr>
                      <td>
                        <FormattedMessage id="Shipping.Cost" />
                        &nbsp;:
                      </td>
                      <td>
                        <b>{productsTotalUtil.shippingCost}€</b>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <FormattedMessage id="Total.Excl.Tax" />
                    </td>
                    <td>
                      <b>{productsTotalUtil.totalHt}€</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="VAT" />
                    </td>
                    <td>
                      <b>{productsTotalUtil.totalTva}€</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Total.Incl.Tax" />
                    </td>
                    <td>
                      <b>{productsTotalUtil.totalTtc}€</b>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="container">
                <div className="row text-center">
                  <div className="col-12 col-sm-6 mb-1">
                    <button
                      className="btn btn-m btn-info btn-block"
                      onClick={(e) =>
                        this.openConfModal(
                          modalTitle,
                          modalContent,
                          successCallbackConfModal
                        )
                      }
                    >
                      <FormattedMessage id="Cart.Empty" />
                    </button>
                  </div>
                  <div className="col-12 col-sm-6">
                    <button
                      className="btn btn-m btn-info btn-block"
                      onClick={(e) => this.openOrderPromptModal()}
                      disabled={!productsTotalUtil.aboveMinToOrder()}
                    >
                      <FormattedMessage id="Order.Pass" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    cart: state.cart,
    products: state.products,
    user: state.user,
    ordersSettings: state.ordersSettings,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    promoOperations: state.promoOperations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAll: () => dispatch(deleteAll()),
    onPassOrder: (data) => dispatch(passOrder(data)),
    onPassOrderAdmin: (data) => dispatch(passOrderAdmin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart));
