const rootUrl = process.env.REACT_APP_BE_URL;

var APIUrl = {
  // Authentication
  logout: rootUrl + "/logout", // GET

  createAdmin: rootUrl + "/superadmin/init", // POST (admin)

  // User
  getUser: rootUrl + "/user", // GET
  updateLang: rootUrl + "/user/lang/", // GET {lang = ["en", "fr"]}
  checkIfUsernameExists: rootUrl + "/user/check/username/", // GET {username}
  checkIfEmailExists: rootUrl + "/user/check/email/", // GET {email}
  checkIfUpdatedEmailExists: rootUrl + "/user/check/updatedemail/", // GET {updatedemail}
  updateUser: rootUrl + "/user/update", // POST {updatedField, updatedValue}
  getAdmin: rootUrl + "/user/getadmin", // GET {}
  addFavorite: rootUrl + "/user/favorite/add", //POST
  deleteFavorite: rootUrl + "/user/favorite/delete", //POST

  // Rgpd
  createRgpd: rootUrl + "/rgpd/create", // POST
  getRgpd: rootUrl + "/rgpd/", // GET {}

  // Products
  getProducts: rootUrl + "/product", // GET
  getProductImg: rootUrl + "/product/img/", // GET {mercurialId, ref}
  getFamilyImg: rootUrl + "/product/fam/img/", // GET {mercurialId, fam}
  getManyProducts: rootUrl + "/product/many", // POST {ids}

  // Cart
  getCart: rootUrl + "/cart", // GET
  addProductToCart: rootUrl + "/cart/add", // POST {id_product, quantity}
  updateUrgent: rootUrl + "/cart/urgent", // POST {cartId, urgent}
  increaseQuantity: rootUrl + "/cart/inc", // POST {cartId}
  decreaseQuantity: rootUrl + "/cart/dec", // POST {cartId}
  deleteCartById: rootUrl + "/cart/delete", // POST {cartId}
  deleteAll: rootUrl + "/cart/delete/all", // POST

  // Order
  addOrder: rootUrl + "/order/add", // POST {comment}
  getOrders: rootUrl + "/order", // GET
  deleteOrder: rootUrl + "/order/delete", // POST {orderId}
  duplicate: rootUrl + "/order/duplicate", // POST {orderId}
  // validate: rootUrl + "/order/validate",                                    // POST {orderId}

  // PDFs
  toPDF: rootUrl + "/pdf", // POST {html, css=[], header, footer}

  // Notifications
  getNotifs: rootUrl + "/notifs", // GET
  deleteNotif: rootUrl + "/notifs/delete", // POST {notifId}

  // Orders settings
  getOrdersSettings: rootUrl + "/settings/orders", // GET

  // Audit DM
  getAuditDMImg: rootUrl + "/settings/dm/img/", // GET URL {auditDMId, roomId}

  // Audit Room
  getAuditRoomImg: rootUrl + "/settings/room/img/", // GET URL {auditRoomId, roomId}

  // Audits
  getAudits: rootUrl + "/audits", // GET
  getAuditsPDF: rootUrl + "/audits/file/", // GET URL

  // Audits Desk
  getAuditsDesk: rootUrl + "/auditsdesk", // GET
  getAuditsDeskPDF: rootUrl + "/auditsdesk/file/", // GET URL

  // Desk Category
  getDeskCategoryImg: rootUrl + "/settings/deskcategory/img/", // GET URL {catId, roomId}
  getDeskProductImg: rootUrl + "/settings/deskproduct/img/",

  // Requests
  getRequests: rootUrl + "/requests", // GET
  addNewRequest: rootUrl + "/requests/add", // POST {material_type_id, method, precisions}
  getRequestFile: rootUrl + "/requests/file/", // URL {requestId} - POST {files}

  // Equipments
  getEquipments: rootUrl + "/equipment", // GET
  updateEquipment: rootUrl + "/equipment/update", // POST {equipmentId, updatedField, updatedValue}
  getEquipmentFile: rootUrl + "/equipment/file/", // GET {equipmentId}/{fileName}

  // Configurables
  getCollaboratorTypes: rootUrl + "/collaboratortype", // GET

  // Password forgotten
  addToken: rootUrl + "/passwordrecoverytokens/add", // POST {email}
  resetPassword: rootUrl + "/passwordrecoverytokens/resetpassword/", // POST {password}

  // Establishment settings
  getEstablishmentSettings: rootUrl + "/establishmentsettings", // GET

  // Establishment
  getEstablishment: rootUrl + "/establishment", // GET {}

  // Contact
  contact: rootUrl + "/contacts", // POST {object, message}

  // General settings
  getGeneralSettings: rootUrl + "/generalsettings", // GET
  getGeneralSettingsLogo: rootUrl + "/generalsettings/logo/", // URL {generalSettingsId} - GET

  // Companies
  checkIfCompanyUrlExists: rootUrl + "/company/check/url/", // GET {url}
  getCompany: rootUrl + "/company", // GET

  // Patients
  getPatients: rootUrl + "/patient", // GET

  // Audit equipments
  getAuditEquipments: rootUrl + "/auditequipment", // GET

  updPatient: rootUrl + "/patient/upd", // POST

  //Promo offer
  getPromoOperations: rootUrl + "/promo", // GET
  getPromoOperationFile: rootUrl + "/promo/file/", // POST{file}

  // ======================================================================
  // ============================= ADMIN ==================================
  // ======================================================================

  // Products
  getProductsAdmin: rootUrl + "/admin/product", // GET
  getProductsByMercurialIdAdmin: rootUrl + "/admin/product/mercurial/",
  updateProductAdmin: rootUrl + "/admin/product/update", // POST {productId, updatedField, updatedValue}

  // Mercurials
  getMercurials: rootUrl + "/admin/mercurial", // GET
  addMercurial: rootUrl + "/admin/mercurial/add", // POST {name, fileName, data, startDate, endDate}
  deleteMercurial: rootUrl + "/admin/mercurial/delete", // POST {mercurialId}
  updateMercurial: rootUrl + "/admin/mercurial/update", // POST {mercurialId, data}
  updateMercurialStatus: rootUrl + "/admin/mercurial/updatestatus", // POST {mercurialId, data}
  addProductsImgs: rootUrl + "/admin/mercurial/imgs/products/", // URL {mercuriaId} - POST {imgs}
  removeProductsImgs: rootUrl + "/admin/mercurial/imgs/products/", // URL {mercuriaId} - POST {imgs}
  addFamilyImgs: rootUrl + "/admin/mercurial/imgs/families/", // URL {mercuriaId} - POST {imgs}
  getMercImgOverview: rootUrl + "/admin/mercurial/img/overview/", // GET {mercurialId}

  // Orders
  addOrderAdmin: rootUrl + "/admin/order/add", // POST {comment, clientId}
  getOrdersAdmin: rootUrl + "/admin/order", // GET
  updateAdmin: rootUrl + "/admin/order/update", // POST {status}
  deleteOrderAdmin: rootUrl + "/admin/order/delete", // POST {orderId}
  splitOrderAdmin: rootUrl + "/admin/order/split", // POST {orderId, productsAvailable, productsUnavailable}
  duplicateAdmin: rootUrl + "/admin/order/duplicate", // POST {orderId}

  // Clients (prescribers)

  getClientsforClient: rootUrl + "/clients",

  getClients: rootUrl + "/admin/client", // GET
  addClient: rootUrl + "/admin/client/add", // POST
  updClient: rootUrl + "/admin/client/update", // POST
  // deletePrescriber: rootUrl + "/admin/prescriber/remove",                                  // POST

  // Cart
  addProductToCartAdmin: rootUrl + "/admin/cart/add", // POST {id_product, quantity}

  // Establishments
  addEstablishment: rootUrl + "/admin/establishment/add", // POST {name, email, phone, address, address_compl, postal_code, city, website}
  getEstablishments: rootUrl + "/admin/establishment", // GET
  updateEstablishment: rootUrl + "/admin/establishment/update", // POST {establishmentId, updatedField, updatedValue}
  getEstablishmentsSettings: rootUrl + "/admin/establishmentsettings/all", // GET
  updateEstablishmentSettings: rootUrl + "/admin/establishmentsettings/update", // POST {establishmentId, updatedField, updatedValue}

  // Settings
  getOrdersSettingsAdmin: rootUrl + "/admin/settings/orders", // GET
  updateOrderSettingAdmin: rootUrl + "/admin/settings/orders/update", // POST {orderSettingId, updatedField, updatedValue}
  updateShippingCostsSetting:
    rootUrl + "/admin/settings/orders/shipping/update", // POST {shippingCosts}

  // Rooms audit
  addAuditRoom: rootUrl + "/admin/settings/room/add", // POST {name, default_text, specific_text}
  getAuditRooms: rootUrl + "/admin/settings/room", // GET
  getAuditRoom: rootUrl + "/admin/settings/room/check/", // GET {name}
  updateAuditRoom: rootUrl + "/admin/settings/room/update", // POST {auditRoomId, updatedField, updatedValue}
  deleteAuditRoom: rootUrl + "/admin/settings/room/delete", // POST {auditRoomId}
  addAuditRoomFile: rootUrl + "/admin/settings/room/file/", // URL {auditDMId, roomId} - POST {file}

  // Audit DM
  addAuditDM: rootUrl + "/admin/settings/dm/add", // POST {name, room, text, type, refundable}
  getAuditDMs: rootUrl + "/admin/settings/dm", // GET
  updateAuditDM: rootUrl + "/admin/settings/dm/update", // POST {auditDMId, updatedField, updatedValue}
  deleteAuditDM: rootUrl + "/admin/settings/dm/delete", // POST {auditDMId}
  getAuditDMByRoomIdAndName: rootUrl + "/admin/settings/dm/check/", // GET {name, room_id}
  addAuditDMFile: rootUrl + "/admin/settings/dm/file/", // URL {auditDMId, roomId} - POST {file}

  // General settings
  updateGeneralSettings: rootUrl + "/admin/generalsettings/update", // POST {updatedField, updatedValue}
  addLogo: rootUrl + "/admin/generalsettings/logo/", // URL {generalSettingsId} - POST {data}

  // DeskCateogry
  addDeskCategory: rootUrl + "/admin/settings/desk/category/add", // POST {name, default_text, specific_text}
  getDeskCategories: rootUrl + "/admin/settings/desk/category", // GET
  getDeskCategory: rootUrl + "/admin/settings/desk/category/check/", // GET {name}
  updateDeskCategory: rootUrl + "/admin/settings/desk/category/update", // POST {auditRoomId, updatedField, updatedValue}
  deleteDeskCategory: rootUrl + "/admin/settings/desk/category/delete", // POST {auditRoomId}
  addDeskCategoryFile: rootUrl + "/admin/settings/desk/category/file/", // URL {auditDMId, roomId} - POST {file}

  // DeskProduct
  addDeskProduct: rootUrl + "/admin/settings/desk/product/add", // POST {name, default_text, specific_text}
  getDeskProducts: rootUrl + "/admin/settings/desk/product", // GET
  getDeskProduct: rootUrl + "/admin/settings/desk/product/check/", // GET {name}
  updateDeskProduct: rootUrl + "/admin/settings/desk/product/update", // POST {auditRoomId, updatedField, updatedValue}
  deleteDeskProduct: rootUrl + "/admin/settings/desk/product/delete", // POST {auditRoomId}
  addDeskProductFile: rootUrl + "/admin/settings/desk/product/file/", // URL {auditDMId, roomId} - POST {file}
  addDeskTypeofControl:
    rootUrl + "/admin/settings/desk/product/typeofcontrol/add", // URL {auditDMId, roomId} - POST {file}
  deleteDeskTypeofControl:
    rootUrl + "/admin/settings/desk/product/typeofcontrol/delete", // URL {auditDMId, roomId} - POST {file}

  updateDeskTypeofControl:
    rootUrl + "/admin/settings/desk/product/typeofcontrol/update", // URL {auditDMId, roomId} - POST {file}

  addDeskProductRelated:
    rootUrl + "/admin/settings/desk/product/productrelated/add",
  addDeskCategoryRelated:
    rootUrl + "/admin/settings/desk/product/categoryrelated/add",
  deleteDeskCategoryRelated:
    rootUrl + "/admin/settings/desk/product/categoryrelated/delete",
  deleteDeskProductRelated:
    rootUrl + "/admin/settings/desk/product/productrelated/delete",

  // Configurables
  getEstablishmentTypes: rootUrl + "/admin/establishmenttype", // GET
  addEstablishmentType: rootUrl + "/admin/establishmenttype/add", // POST {name}
  getPaymentMethods: rootUrl + "/admin/paymentmethods", // GET
  addPaymentMethod: rootUrl + "/admin/paymentmethods/add", // POST {name}
  getPaymentDeadlines: rootUrl + "/admin/paymentdeadlines", // GET
  addPaymentDeadline: rootUrl + "/admin/paymentdeadlines/add", // POST {name}
  addCollaboratorType: rootUrl + "/admin/collaboratortype/add", // POST {name}
  removeCollaboratorType: rootUrl + "/admin/collaboratortype/remove", // POST {name}

  // Equipment
  getEstablishementEquipments: rootUrl + "/admin/equipment/", // GET {establishmentId}
  addEquipment: rootUrl + "/admin/equipment/add", // POST {establishment_id, internal_ref, material_type_id, brand, model, serial_nbr, purchase_date, warranty, affectation, resident_id, floor, room, owner, state, materiovigilance, misc}
  checkIfEquipmentExists: rootUrl + "/admin/equipment/check", // POST {establishmentId, internRef}
  updateEquipmentAdmin: rootUrl + "/admin/equipment/update", // POST {equipmentId, updatedField, updatedValue}
  deleteEquipment: rootUrl + "/admin/equipment/delete", // POST {equipmentId}
  addEquipmentFiles: rootUrl + "/admin/equipment/files/", // URL {equipmentId} - POST {files}
  getAllEquipments: rootUrl + "/admin/equipment/all", // GET

  // Requests
  getAllRequests: rootUrl + "/admin/requests", // GET
  updateRequest: rootUrl + "/admin/requests/update", // POST {requestId, updatedField, updatedValue}
  addRequestFiles: rootUrl + "/admin/requests/files/", // URL {requestId} - POST {files}
  addNewRequestAdmin: rootUrl + "/admin/requests/add", // POST
  deleteRequest: rootUrl + "/admin/requests/delete", // DELETE
  deleteRequestFile: rootUrl + "/admin/requests/file/delete",

  // Collaborators
  getCollaborators: rootUrl + "/admin/collaborator", // GET
  addCollaborator: rootUrl + "/admin/collaborator/add", // POST {data}
  removeCollaborator: rootUrl + "/admin/collaborator/remove/", // POST {collaboratorId}
  updateCollaborator: rootUrl + "/admin/collaborator/update", // POST {collaboratorId, updatedField, updatedValue}
  addCollaboratorEstablishment:
    rootUrl + "/admin/collaborator/establishment/add", // POST {collaboratorId, establishmentId}
  removeCollaboratorEstablishment:
    rootUrl + "/admin/collaborator/establishment/remove", // POST {collaboratorId, establishmentId}
  //addCollaboratorPrescriber: rootUrl + "/admin/collaborator/prescriber/add",   // POST {collaboratorId, prescriberId}
  //removeCollaboratorPrescriber: rootUrl + "/admin/collaborator/prescriber/remove", // POST {collaboratorId, prescriberId}

  addCollaboratorClient: rootUrl + "/admin/collaborator/client/add", // POST {collaboratorId, prescriberId}
  removeCollaboratorClient: rootUrl + "/admin/collaborator/client/remove", // POST {collaboratorId, prescriberId}

  // Patients
  getPatientsAdmin: rootUrl + "/admin/patient", // GET
  addPatient: rootUrl + "/admin/patient/add", // POST
  deletePatient: rootUrl + "/admin/patient/remove", // POST
  addPatientTechnicalSheet: rootUrl + "/admin/patient/file/add/", // POST {patientId}
  deletePatientTechnicalSheet: rootUrl + "/admin/patient/file/delete/", // GET {patientId, sheetId}
  getPatientTechnicalSheetFile: rootUrl + "/admin/patient/file/", // GET {patientId, sheetId}
  addPatientEstimateFile: rootUrl + "/admin/patient/estimate/file/add/", // POST {patientId, fileId}
  deletePatientEstimateFile: rootUrl + "/admin/patient/estimate/file/delete/", // GET {patientId, fileId}
  getPatientEstimateFile: rootUrl + "/admin/patient/estimate/file/", // GET {patientId, sheefileIdtId}
  addPatientDocument: rootUrl + "/admin/patient/document/file/add/", // POST {patientId}
  deletePatientDocument: rootUrl + "/admin/patient/document/file/delete/", // GET {patientId, documentId}
  getPatientDocumentFile: rootUrl + "/admin/patient/document/file/", // GET {patientId, documentId}
  getPatientBillFile: rootUrl + "/admin/patient/bill/file/", // GET  	{patientId, billId}
  deletePatientBill: rootUrl + "/admin/patient/bill/file/delete/", // DELETE   {patientId, billId}
  addPatientBill: rootUrl + "/admin/patient/bill/file/add/", // POST 	{patientId}

  // Type Prescribers
  //getPrescriberTypes: rootUrl + "/prescribertype",                                    // GET
  //addPrescriberType: rootUrl + "/prescribertype/add",                                // POST
  //updatePrescriberType: rootUrl + "/prescribertype/update",                          // POST
  //deletePrescriberType: rootUrl + "/prescribertype/delete",                           // POST

  getClientTypes: rootUrl + "/clienttype", // GET
  addClientType: rootUrl + "/clienttype/add", // POST
  updateClientType: rootUrl + "/clienttype/update", // POST
  deleteClientType: rootUrl + "/clienttype/delete", // POST

  // Audit equipments
  getAuditEquipmentsAdmin: rootUrl + "/admin/auditequipment", // GET
  addAuditEquipment: rootUrl + "/admin/auditequipment/add", // POST
  updAuditEquipment: rootUrl + "/admin/auditequipment/upd", // POST
  deleteAuditEquipment: rootUrl + "/admin/auditequipment/remove", // POST

  // Audit settings
  getAuditSettings: rootUrl + "/auditsettings", // GET
  getAuditSettingsLogo: rootUrl + "/auditsettings/logo/", // URL {generalSettingsId} - GET
  addLogoAuditSettings: rootUrl + "/admin/auditsettings/logo/", // ADD
  // addAuditSettings : rootUrl + "/admin/auditsettings/add",                                       // POST
  updateAuditSettings: rootUrl + "/admin/auditsettings/update", // POST
  // deleteAuditSettings: rootUrl + "/admin/auditsettings/remove",                                  // POST

  // Audit Desk settings
  getAuditDeskSettings: rootUrl + "/auditdesksettings", // GET
  getAuditDeskSettingsLogo: rootUrl + "/auditdesksettings/logo/", // URL {generalSettingsId} - GET
  addLogoAuditDeskSettings: rootUrl + "/admin/auditdesksettings/logo/", // ADD
  // addAuditDeskSettings : rootUrl + "/admin/auditdesksettings/add",                                       // POST
  updateAuditDeskSettings: rootUrl + "/admin/auditdesksettings/update", // POST
  // deleteAuditSettings: rootUrl + "/admin/auditsettings/remove",

  // Audits

  getAuditAdmin: rootUrl + "/admin/audit", // GET

  // Audits Desk

  getAuditDeskAdmin: rootUrl + "/admin/auditdesk", // GET

  // Delete audit

  deleteAudit: rootUrl + "/admin/audit/delete",

  sendAudit: rootUrl + "/admin/audit/send", // POST{emailsSendTo , patientId, auditId}
  sendDeskAudit: rootUrl + "/desk/audit/send", // POST{emailsSendTo , patientId, auditId}

  // DOCUMENTS

  getDocuments: rootUrl + "/admin/document",
  getDocument: rootUrl + "/admin/document/check/",
  addDocument: rootUrl + "/admin/document/add",
  addDocumentFile: rootUrl + "/admin/document/file/",
  updateDocument: rootUrl + "/admin/document/update",
  deleteDocument: rootUrl + "/admin/document/delete",
  getDocumentFile: rootUrl + "/admin/document/file/get/", // GET URL

  //Promo offer
  getPromoOperationsAdmin: rootUrl + "/admin/promo", // GET
  createPromoOperation: rootUrl + "/admin/promo/add", // POST{product_id, mercurial_id, startDate, endDate}
  addPromoOperationFile: rootUrl + "/admin/promo/file/", // POST{file}
  deletePromoOperation: rootUrl + "/admin/promo/delete/", // POST{operationPromotionalId}
  updatePromoOperation: rootUrl + "/admin/promo/update/", // POST{updatedField, updatedValue, promoOperationId}
};

var sessionJWT = sessionStorage.getItem("jwt");
if (sessionJWT) APIUrl.jwtToken = sessionJWT;

export default APIUrl;
