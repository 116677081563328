import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addDeskCategory,
  updateDeskCategory,
  getDeskCategory,
  addDeskCategoryFile,
} from "../../../../actions/settings/deskCategories/admin/deskCategories";
import FileDropZone from "../../../sub/FileDropZone";
import Util from "../../../../util/Util";
import APIUrl from "../../../../APIUrl";

class DeskCategoryModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description_text: "",
      nameError: null,
      disabled: false,
      modal: null,
    };

    //console.log(this.props)

    if (this.props.deskCategoryId) {
      //console.log('EDIT MODE',this.props.deskCategoryId);
      //console.log(this.props.deskCategories);
      const category = this.props.deskCategories.find(
        (r) => r._id === this.props.deskCategoryId
      );

      if (category) {
        this.state = {
          name: category.name,
          description_text: category.description_text,

          nameError: null,
          disabled: false,
          modal: null,
        };
      }
    }
  }

  close() {
    this.props.closeModal();
  }

  onChange(key, value) {
    this.setState({
      [key]: value,
    });

    if (key !== "name") return;

    if (Util.emptyString(value)) return;

    var successCallback = (category) => {
      if (category)
        this.setState({ nameError: <FormattedMessage id="Room.Exist" /> });
      else this.setState({ nameError: null });
    };

    if (this.props.deskCategoryId) {
      const category = this.props.deskCategories.find(
        (r) => r._id === this.props.deskCategoryId
      );
      if (category && value === category.name) return;

      this.props.onGetDeskCategory(value, successCallback);
    } else {
      this.props.onGetDeskCategory(value, successCallback);
    }
  }

  onDropFile(file, clearCallback) {
    if (!file || file.length === 0) return;

    // Update mode. Immediately send file to BE
    if (this.props.deskCategoryId) {
      this.setState({ disabledFileUpload: true });

      let formData = new FormData();
      formData.append(file.name, file);

      var successCallback = () => {
        this.setState({ disabledFileUpload: false });
        clearCallback();
      };

      // Send to BE
      this.props.onAddDeskCategoryFile(
        this.props.deskCategoryId,
        formData,
        successCallback
      );
    }
    // Create mode. Store the file and wait for the onComplete event
    else {
      this.setState({ file: file });
    }
  }

  onSubmit() {
    var sendfile = (category) => {
      console.log("this.state.file", this.state.file);

      if (!this.state.file || this.state.file.length === 0)
        return this.props.closeModal();

      let formData = new FormData();
      formData.append(this.state.file.name, this.state.file);

      this.props.onAddDeskCategoryFile(category._id, formData, () =>
        this.props.closeModal()
      );
    };

    var imageExist = !!this.state.file;

    var data = {
      name: this.state.name,
      description_text: this.state.description_text,
      image: imageExist,
      sortIndex: this.props.deskCategories
        ? this.props.deskCategories.length + 1
        : 1,
    };

    //this.setState({ disabled: true, disabledFileUpload: true });

    //this.props.closeModal();

    this.props.onAddDeskCategory(data, sendfile);

    //	this.props.onAddDeskCategory(data, (deskCategory) => { return this.props.editModal(deskCategory)}  );
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onUpdate(updatedField, updatedValue) {
    if (!this.props.deskCategoryId || this.checkErrors()) return;

    if (updatedField === "name" && Util.emptyString(updatedValue)) return;

    var data = {
      deskCategoryId: this.props.deskCategoryId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    this.props.onUpdateDeskCategory(data);
  }

  checkErrors() {
    return (
      this.state.nameError ||
      this.state.disabled ||
      Util.emptyString(this.state.name)
    );
  }

  getDeskCategory(categoryId) {
    for (let category of this.props.deskCategories) {
      if (category._id === categoryId) return category;
    }
  }

  render() {
    var img;

    if (this.props.deskCategoryId) {
      var category = this.props.deskCategories.find(
        (r) => r._id === this.props.deskCategoryId
      );
      if (!category) return null;

      if (category.image) {
        var imgSrc =
          APIUrl.getDeskCategoryImg +
          category._id +
          "/" +
          Math.random() +
          "?token=" +
          APIUrl.jwtToken;
        img = (
          <img
            src={imgSrc}
            className="col-form-label m-3"
            alt={"desk_category_img" + Math.random()}
            height="150"
            width="150"
          />
        );
      }
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-8">
            {category && category.image && (
              <div className="d-none d-lg-block w-150 rounded light-card text-center">
                <div className="form-group row m-0 p-0"></div>
                <div className="w-150 m-0 p-0">{img}</div>
              </div>
            )}
            <div className="form-group row">
              <label htmlFor="logo" className="col-12 col-md-5 col-form-label">
                <FormattedMessage id="Visuel" />
              </label>
              <div className="col-12 col-md-7">
                <FileDropZone
                  disabled={this.state.disabledDropZone}
                  onDropFile={(file, clearCallback) =>
                    this.onDropFile(file, clearCallback)
                  }
                  acceptedExtensions={["jpg", "png"]}
                  multiple={false}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="name" className="col-12 col-md-4 col-form-label">
                <FormattedMessage id="Name" />
              </label>
              <div id="name" className="col-12 col-md-8">
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  autoComplete="off"
                  value={this.state.name}
                  onChange={(e) => this.onChange("name", e.target.value)}
                  onBlur={(e) => this.onUpdate("name", this.state.name)}
                />
                <small className="text-danger">{this.state.nameError}</small>
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="defaultText"
                className="col-12 col-md-4 col-form-label"
              >
                <FormattedMessage id="Description" />
              </label>
              <div id="defaultText" className="col-12 col-md-8">
                <textarea
                  className="form-control"
                  type="text"
                  value={this.state.description_text}
                  id="description"
                  rows="7"
                  onChange={(e) =>
                    this.onChange("description_text", e.target.value)
                  }
                  onBlur={(e) =>
                    this.onUpdate(
                      "description_text",
                      this.state.description_text
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {!this.props.deskCategoryId && (
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => this.close()}
            >
              <FormattedMessage id="Cancel" />
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={() => this.onSubmit()}
              disabled={this.checkErrors()}
            >
              <FormattedMessage id="Add" />
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDeskCategory: (data, successCallback) =>
      dispatch(addDeskCategory(data, successCallback)),
    onGetDeskCategory: (data, successCallback) =>
      dispatch(getDeskCategory(data, successCallback)),
    onUpdateDeskCategory: (data) => dispatch(updateDeskCategory(data)),
    onAddDeskCategoryFile: (deskCategoryId, data, successCallback) =>
      dispatch(addDeskCategoryFile(deskCategoryId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeskCategoryModalAdmin));
