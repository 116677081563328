import React from "react";
import Maths from "../../util/Maths";

class OrderProductRow extends React.Component {
  render() {
    const { product } = this.props;
    return (
      <tr
        id={"product-" + product.id_product}
        className={product.urgent ? "text-danger" : ""}
      >
        <td>{product.ref}</td>
        <td>
          {product.designation + " "}
          {product.urgent && <i className={"fa fa-exclamation-triangle"}></i>}
        </td>
        <td>{product.quantity}</td>
        <td>{Maths.round(product.prix_u_ht_emera)}€</td>
        <td>{Maths.round(product.total_ht)}€</td>
      </tr>
    );
  }
}

export default OrderProductRow;
