import APIUrl from "../../../../APIUrl";
import axios from "axios";

export const GET_CLIENT_TYPES = "GET_CLIENT_TYPES";
export const DELETE_CLIENT_TYPE = "DELETE_CLIENT_TYPE";

function getClientTypesAction(clientTypes) {
  return { type: GET_CLIENT_TYPES, clientTypes: clientTypes };
}
function deleteClientTypeAction(clientTypeId) {
  return { type: DELETE_CLIENT_TYPE, clientTypeId: clientTypeId };
}

export const getClientTypes = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getClientTypes)
      .then(function (response) {
        if (response) dispatch(getClientTypesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addClientType = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addClientType, data)
      .then(function (response) {
        dispatch(getClientTypes());
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateClientType = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateClientType, data)
      .then(function () {
        dispatch(getClientTypes());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteClientType = function (clientTypeId, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteClientType, { clientTypeId: clientTypeId })
      .then(function () {
        dispatch(deleteClientTypeAction(clientTypeId));
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
