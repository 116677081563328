import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addAuditRoom,
  updateAuditRoom,
  getAuditRoom,
  addAuditRoomFile,
} from "../../../../actions/settings/auditRooms/admin/auditRooms";
import FileDropZone from "../../../sub/FileDropZone";
import Util from "../../../../util/Util";
import APIUrl from "../../../../APIUrl";

class RoomsAuditModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      default_text: "",
      specific_text: "",
      nameError: null,
      disabled: false,
      modal: null,
    };

    if (this.props.auditRoomId) {
      const room = this.props.auditRooms.find(
        (r) => r._id === this.props.auditRoomId
      );

      if (room) {
        this.state = {
          name: room.name,
          default_text: room.default_text,
          specific_text: room.specific_text,

          nameError: null,
          disabled: false,
          modal: null,
        };
      }
    }
  }

  close() {
    this.props.closeModal();
  }

  onChange(key, value) {
    this.setState({
      [key]: value,
    });

    if (key !== "name") return;

    if (Util.emptyString(value)) return;

    var successCallback = (auditRoom) => {
      if (auditRoom)
        this.setState({ nameError: <FormattedMessage id="Room.Exist" /> });
      else this.setState({ nameError: null });
    };

    if (this.props.auditRoomId) {
      const room = this.props.auditRooms.find(
        (r) => r._id === this.props.auditRoomId
      );
      if (room && value === room.name) return;

      this.props.onGetAuditRoom(value, successCallback);
    } else {
      this.props.onGetAuditRoom(value, successCallback);
    }
  }

  onDropFile(file, clearCallback) {
    if (!file || file.length === 0) return;

    // Update mode. Immediately send file to BE
    if (this.props.auditRoomId) {
      this.setState({ disabledFileUpload: true });

      let formData = new FormData();
      formData.append(file.name, file);

      var successCallback = () => {
        this.setState({ disabledFileUpload: false });
        clearCallback();
      };

      // Send to BE
      this.props.onAddAuditRoomFile(
        this.props.auditRoomId,
        formData,
        successCallback
      );
    }
    // Create mode. Store the file and wait for the onComplete event
    else {
      this.setState({ file: file });
    }
  }

  onSubmit() {
    var sendfile = (auditRoom) => {
      if (!this.state.file || this.state.file.length === 0)
        return this.props.closeModal();

      let formData = new FormData();
      formData.append(this.state.file.name, this.state.file);

      this.props.onAddAuditRoomFile(auditRoom._id, formData, () =>
        this.props.closeModal()
      );
    };

    var imageExist = !!this.state.file;

    var data = {
      name: this.state.name,
      default_text: this.state.default_text,
      specific_text: this.state.specific_text,
      image: imageExist,
      sortIndex: this.props.auditRooms.length + 1,
    };

    this.setState({ disabled: true, disabledFileUpload: true });

    this.props.onAddAuditRoom(data, sendfile);
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onUpdate(updatedField, updatedValue) {
    if (!this.props.auditRoomId || this.checkErrors()) return;

    if (updatedField === "name" && Util.emptyString(updatedValue)) return;

    var data = {
      auditRoomId: this.props.auditRoomId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    this.props.onUpdateAuditRoom(data);
  }

  checkErrors() {
    return (
      this.state.nameError ||
      this.state.disabled ||
      Util.emptyString(this.state.name)
    );
  }

  getAuditRoom(auditRoomId) {
    for (let auditRoom of this.props.auditRooms) {
      if (auditRoom._id === auditRoomId) return auditRoom;
    }
  }

  render() {
    var img;

    if (this.props.auditRoomId) {
      var auditRoom = this.props.auditRooms.find(
        (r) => r._id === this.props.auditRoomId
      );
      if (!auditRoom) return null;

      if (auditRoom.image) {
        var imgSrc =
          APIUrl.getAuditRoomImg +
          auditRoom._id +
          "/" +
          Math.random() +
          "?token=" +
          APIUrl.jwtToken;

        console.log("imgSrc", imgSrc, APIUrl.getAuditRoomImg);

        img = (
          <img
            src={imgSrc}
            className="col-form-label m-3"
            alt={"audit_Room_img" + Math.random()}
            height="150"
            width="150"
          />
        );
      }
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-8">
            {auditRoom && auditRoom.image && (
              <div className="d-none d-lg-block w-150 rounded light-card text-center">
                <div className="form-group row m-0 p-0"></div>
                <div className="w-150 m-0 p-0">{img}</div>
              </div>
            )}
            <div className="form-group row">
              <label htmlFor="logo" className="col-12 col-md-5 col-form-label">
                <FormattedMessage id="Logo" />
              </label>
              <div className="col-12 col-md-7">
                <FileDropZone
                  disabled={this.state.disabledDropZone}
                  onDropFile={(file, clearCallback) =>
                    this.onDropFile(file, clearCallback)
                  }
                  acceptedExtensions={["jpg", "png"]}
                  multiple={false}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="name" className="col-12 col-md-4 col-form-label">
                <FormattedMessage id="Name" />
              </label>
              <div id="name" className="col-12 col-md-8">
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  autoComplete="off"
                  value={this.state.name}
                  onChange={(e) => this.onChange("name", e.target.value)}
                  onBlur={(e) => this.onUpdate("name", this.state.name)}
                />
                <small className="text-danger">{this.state.nameError}</small>
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="defaultText"
                className="col-12 col-md-4 col-form-label"
              >
                <FormattedMessage id="Default.Text" />
              </label>
              <div id="defaultText" className="col-12 col-md-8">
                <textarea
                  className="form-control"
                  type="text"
                  value={this.state.default_text}
                  id="defaultText"
                  rows="7"
                  onChange={(e) =>
                    this.onChange("default_text", e.target.value)
                  }
                  onBlur={(e) =>
                    this.onUpdate("default_text", this.state.default_text)
                  }
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="specificText"
                className="col-12 col-md-4 col-form-label"
              >
                <FormattedMessage id="Specific.Text" />
              </label>
              <div id="specificText" className="col-12 col-md-8">
                <textarea
                  className="form-control"
                  type="text"
                  value={this.state.specific_text}
                  id="specificText"
                  rows="7"
                  onChange={(e) =>
                    this.onChange("specific_text", e.target.value)
                  }
                  onBlur={(e) =>
                    this.onUpdate("specific_text", this.state.specific_text)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {!this.props.auditRoomId && (
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => this.close()}
            >
              <FormattedMessage id="Cancel" />
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={() => this.onSubmit()}
              disabled={this.checkErrors()}
            >
              <FormattedMessage id="Add" />
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAuditRoom: (data, successCallback) =>
      dispatch(addAuditRoom(data, successCallback)),
    onGetAuditRoom: (data, successCallback) =>
      dispatch(getAuditRoom(data, successCallback)),
    onUpdateAuditRoom: (data) => dispatch(updateAuditRoom(data)),
    onAddAuditRoomFile: (auditRoomId, data, successCallback) =>
      dispatch(addAuditRoomFile(auditRoomId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RoomsAuditModalAdmin));
