import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import OrdersSettingsAdmin from "./ordersSettings/OrdersSettingsAdmin";
import GeneralSettingsAdmin from "./GeneralSettingsAdmin";
import Mercurials from "../../mercurials/Mercurials";
import AuditRooms from "./auditRooms/AuditRooms";
import ClientTypes from "./clientType/ClientTypes";
import Document from "./document/document";
import AuditSettings from "./auditSettings/AuditSettings";
import AuditDeskSettings from "./auditSettings/AuditDeskSettings";
import DeskCategories from "./auditDesk/DeskCategories";
import PromotionalOperations from "./promotionalOperations/PromotionalOperations";
import Roles from "../../../enums/Roles";
import Collaborators from "./collaborators/Collaborators";

// Get User informtions
import { getUser } from "../../../actions/user/user";
import { Tab, Tabs } from "react-bootstrap";

class MenuSettingsMad extends React.Component {
  componentDidMount() {
    // First, fetch the user for access
    this.props.onGetUser();
  }

  render() {
    if (Object.keys(this.props.ordersSettingsAdmin).length === 0) return null;
    if (Object.keys(this.props.generalSettings).length === 0) return null;
    if (Object.keys(this.props.auditSettings).length === 0) return null;
    if (Object.keys(this.props.auditDeskSettings).length === 0) return null;

    let moduleDesk = (
      <Tabs
        defaultActiveKey="desk-param"
        id="tab-collaborators"
        className="mb-3"
      >
        <Tab
          eventKey="desk-param"
          title={<FormattedMessage id="Parametrage" />}
        >
          <AuditDeskSettings auditDeskSettings={this.props.auditDeskSettings} />
        </Tab>
        <Tab
          eventKey="desk-categ"
          title={<FormattedMessage id="Categories.Desk" />}
        >
          <DeskCategories />
        </Tab>
      </Tabs>
    );

    let moduleHome = (
      <Tabs defaultActiveKey="home-param" id="tab-home" className="mb-3">
        <Tab
          eventKey="home-param"
          title={<FormattedMessage id="Parametrage" />}
        >
          <AuditSettings auditSettings={this.props.auditSettings} />
        </Tab>

        <Tab
          eventKey="home-rooms"
          title={<FormattedMessage id="Audit.Rooms" />}
        >
          <AuditRooms auditRooms={this.props.auditRooms} />
        </Tab>
      </Tabs>
    );

    let moduleStore = (
      <Tabs defaultActiveKey="store-profile" id="tab-store" className="mb-3">
        <Tab
          eventKey="store-profile"
          title={<FormattedMessage id="Cost.Budget" />}
        >
          <OrdersSettingsAdmin
            ordersSettings={this.props.ordersSettingsAdmin}
          />
        </Tab>

        <Tab
          eventKey="store-mercurials"
          title={<FormattedMessage id="Mercurials" />}
        >
          <Mercurials />
        </Tab>

        <Tab
          eventKey="store-promotional-operation"
          title={<FormattedMessage id="Promotional.Operations" />}
        >
          <PromotionalOperations promoOperations={this.props.promoOperations} />
        </Tab>
      </Tabs>
    );

    let hasAccessToAllModule =
      this.props.user.access_e4pharmamad &&
      this.props.user.role === Roles.ADMIN;
    let hasAccessToDeskModule =
      this.props.user.access_e4pharmadesk &&
      this.props.user.role === Roles.ADMIN;
    let hasAccessToHomeModule =
      this.props.user.access_e4pharmahome &&
      this.props.user.role === Roles.ADMIN;
    let hasAccessToStoreModule =
      this.props.user.access_e4pharmastore &&
      this.props.user.role === Roles.ADMIN;

    return (
      <Tabs defaultActiveKey="home" id="tab-mad" className="mb-3">
        <Tab eventKey="home" title={<FormattedMessage id="General" />}>
          <GeneralSettingsAdmin generalSettings={this.props.generalSettings} />
        </Tab>
        <Tab
          eventKey="collaborators"
          title={<FormattedMessage id="Collaborators" />}
        >
          <Collaborators />
        </Tab>
        {(hasAccessToAllModule || hasAccessToDeskModule) && (
          <Tab
            eventKey="mad-desk"
            title={<FormattedMessage id="Module.Desk" />}
          >
            {moduleDesk}
          </Tab>
        )}

        {(hasAccessToAllModule || hasAccessToHomeModule) && (
          <Tab
            eventKey="mad-home"
            title={<FormattedMessage id="Module.Home" />}
          >
            {moduleHome}
          </Tab>
        )}

        {(hasAccessToAllModule || hasAccessToStoreModule) && (
          <Tab
            eventKey="mad-store"
            title={<FormattedMessage id="Module.Store" />}
          >
            {moduleStore}
          </Tab>
        )}

        <Tab
          eventKey="mad-document"
          title={<FormattedMessage id="Module.Document" />}
        >
          <Document />
        </Tab>

        <Tab
          eventKey="mad-clients"
          title={<FormattedMessage id="Add.Client.Type" />}
        >
          <ClientTypes />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    user: state.user,
    generalSettings: state.generalSettings,
    auditSettings: state.auditSettings,
    auditDeskSettings: state.auditDeskSettings,
    auditRooms: state.auditRooms,
    promoOperations: state.promoOperations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => dispatch(getUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSettingsMad);
