import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class MercurialArchiveModal extends React.Component {
  close() {
    this.props.closeModal();
  }

  updateStatus() {
    this.props.updateStatus();
    this.close();
  }

  openModalDelete() {
    this.props.openModalDelete();
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Warning" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormattedMessage id="Mercurial.Remove.Confirmation" />
            <br />
            <br />
            <div className="card text-white bg-danger">
              <div className="card-header">
                <i className="fa fa-warning mr-2 text-white"></i>
                <FormattedMessage id="Archive" />
              </div>
              <div className="card-body">
                <p className="card-text">
                  <FormattedMessage id="Mercurials.Warning.Content" />
                </p>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.updateStatus()}>
              <FormattedMessage id="Make.Inactive" />
            </Button>
            <Button variant="danger" onClick={() => this.openModalDelete()}>
              <FormattedMessage id="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default MercurialArchiveModal;
