import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import StringUtil from "../../util/StringUtil";
import { addClient, updClient } from "../../actions/clients/clients";
import {
  checkIfUsernameExists,
  checkIfEmailExists,
} from "../../actions/user/user";
import MercurialStatus from "../../enums/MercurialStatus";
import { Button, Modal } from "react-bootstrap";

class AddClientModal extends React.Component {
  constructor(props) {
    super(props);

    const { clientId } = this.props;
    const client = this.props.clients.find((p) => p._id === clientId);

    this.isUpdate = !!client;

    const getValue = (field) => (client ? client[field] : "");

    const getClientType = () => {
      if (client) return client.type;
      if (this.props.clientTypes && this.props.clientTypes.length) {
        return this.props.clientTypes._id;
      }

      // console.log("this.props.clientTypes",this.props.clientTypes)

      return "";
    };

    const getMercurialId = () => {
      if (client) return client.mercurial_Id;
      if (this.props.mercurials && this.props.mercurials.length) {
        return this.props.mercurials._id;
      }

      return "";
    };

    this.state = {
      first_name: getValue("first_name"),
      name: getValue("name"),
      address: getValue("address"),
      mercurial_Id: getMercurialId(),
      postal_code: getValue("postal_code") || "",
      city: getValue("city"),
      phone: getValue("phone"),
      mobile: getValue("mobile"),
      email: getValue("email"),
      username: getValue("username"),
      numero_agrement: getValue("numero_agrement"),
      type: getClientType(),
      client_type: getValue("client_type") || "helper",
      isSendEmail: true,
      phoneError: null,
      mobileError: null,
      emailError: null,
      loading: false,
    };

    this.handleCheck = this.handleCheck.bind(this);
  }

  onSubmit() {
    this.setState({ loading: true });
    var mercurialId = this.state.mercurial_Id;
    if (mercurialId === "") mercurialId = null;

    const client = {
      first_name: this.state.first_name,
      name: this.state.name,
      address: this.state.address,
      postal_code: this.state.postal_code,
      city: this.state.city,
      mercurial_Id: mercurialId,
      phone: this.state.phone,
      mobile: this.state.mobile,
      email: this.state.email,
      username: this.state.username,
      numero_agrement: this.state.numero_agrement,
      type: this.state.type,
      client_type:
        this.state.client_type === "" ? "helper" : this.state.client_type,
      isSendEmail: this.state.isSendEmail,
    };

    if (!this.state.type || this.state.type === "") {
      delete client.type;
    }

    // Send to BE
    this.props.onAddClient(client, (client) =>
      this.setState({ loading: false }, () => this.props.close())
    );
  }

  disabled() {
    return (
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.first_name) ||
      Util.emptyString(this.state.username) ||
      Util.emptyString(this.state.email) ||
      this.state.phoneError ||
      this.state.mobileError ||
      this.state.emailError ||
      this.state.usernameError ||
      this.state.doctorIdError ||
      this.state.postal_codeError ||
      this.state.loading
    );
  }

  onChange(field, value) {
    this.setState({ [field]: value });

    const client = this.props.clients.find(
      (p) => p._id === this.props.clientId
    );

    if (field === "phone") {
      if (!Util.emptyString(value) && !Util.isPhone(value))
        this.setState({
          phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      else this.setState({ phoneError: null });
    } else if (field === "postal_code") {
      if (!Util.emptyString(value) && !Util.isPostal(value))
        this.setState({
          postal_codeError: (
            <FormattedMessage id="Invalid.Postal.Number.Error" />
          ),
        });
      else this.setState({ postal_codeError: null });
    } else if (field === "mobile") {
      if (!Util.emptyString(value) && !Util.isPhone(value))
        this.setState({
          mobileError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      else this.setState({ mobileError: null });
    } else if (field === "email") {
      if (!Util.emptyString(value) && !Util.isEmail(value))
        this.setState({
          emailError: <FormattedMessage id="Invalid.Email.Error" />,
        });
      else {
        this.setState({ emailError: null });
        if (Util.emptyString(value)) return;
        // else if (!this.isUpdate || (this.isUpdate && client.email !== value)) {
        //     this.props.onCheckIfEmailExists(
        //         value,
        //         () => { this.setState({ emailError: <FormattedMessage id="Email.Already.Exists" /> }) },
        //         () => { }
        //     )
        // }
      }
    } else if (field === "username") {
      this.setState({ usernameError: null });

      // Force this value to be in lowercase
      // value = value.toLowerCase();

      // We call setState again due to case change above
      this.setState({ [field]: value });

      if (Util.emptyString(value)) {
        this.setState({
          usernameError: <FormattedMessage id="Empty.Username" />,
        });
        return;
      }

      // Test username syntax
      var checkUsername = Util.isValidUserName(value, 3, 20);

      // There is an error with password syntax
      if (checkUsername instanceof Object === true) {
        this.setState({
          usernameError: (
            <FormattedMessage
              id="Invalid.Username"
              values={{
                minLength: checkUsername.minLength,
                maxLength: checkUsername.maxLength,
              }}
            />
          ),
        });
        return;
      } else {
        this.setState({ usernameError: null });
      }

      if (!this.isUpdate || (this.isUpdate && client.username !== value)) {
        this.props.onCheckIfUsernameExists(
          value,
          () => {
            this.setState({
              usernameError: (
                <FormattedMessage
                  id="Username.Already.Exists"
                  values={{ username: value }}
                />
              ),
            });
          },
          () => {
            this.setState({ usernameError: null });
          }
        );
      }
    }
  }

  onBlur(field, value) {
    if (!this.isUpdate) return;

    if (field === "phone" && this.state.phoneError) return;
    else if (field === "mobile" && this.state.mobileError) return;
    else if (field === "email" && this.state.emailError) return;
    else if (field === "username" && this.state.usernameError) return;
    else if (field === "postal_code" && this.state.postal_codeError) return;
    //else if (field === "isSendEmail" && this.state.postal_codeError) return;

    const client = {
      _id: this.props.clientId,
      updatedField: field,
      updatedValue: value,
    };

    // Send to BE
    this.props.onUpdClient(client);
  }

  handleclienType(e) {
    this.clienType.setSelectionRange(0, this.clienType.value.length);
    return (e) =>
      this.setState({
        editing: !this.state.editing,
      });
  }

  handleCheck = () => {
    this.setState({
      isSendEmail: !this.state.isSendEmail,
    });
  };

  render() {
    var now = new Date();
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.isUpdate ? (
                <FormattedMessage id="Update.Client" />
              ) : (
                <FormattedMessage id="Add.Client" />
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <label htmlFor="type" className="col-12 col-sm-5 col-form-label">
                <FormattedMessage id="Pharma.Client.client_type" />
              </label>
              <div className="col-12 col-sm-7">
                <select
                  className="form-control w-100 selectlist"
                  value={this.state.client_type}
                  onChange={(e) => this.onChange("client_type", e.target.value)}
                  onBlur={(e) => this.onBlur("client_type", e.target.value)}
                >
                  <option value="helper">
                    {this.props.intl.formatMessage({ id: "Client.Helper" })}
                  </option>
                  <option value="prescriber">
                    {this.props.intl.formatMessage({ id: "Client.Prescriber" })}
                  </option>
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="type" className="col-12 col-sm-5 col-form-label">
                <FormattedMessage id="Client.Type.Label" />
              </label>
              <div className="col-12 col-sm-7">
                <select
                  className="form-control w-100 selectlist"
                  value={this.state.type}
                  onChange={(e) => this.onChange("type", e.target.value)}
                  onBlur={(e) => this.onBlur("type", e.target.value)}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "None.Masculine" })}
                  </option>
                  {this.props.clientTypes.map((t) => (
                    <option key={t._id} value={t._id}>
                      {t.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {(this.props.user.access_e4pharmamad ||
              this.props.user.access_e4pharmastore) && (
              <div className="form-group row">
                <label
                  htmlFor="mercurial_Id"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Mercurial" />
                </label>
                <div className="col-12 col-sm-7">
                  <select
                    className="form-control w-100 selectlist"
                    value={this.state.mercurial_Id}
                    onChange={(e) =>
                      this.onChange("mercurial_Id", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("mercurial_Id", e.target.value)}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "None.Feminine" })}
                    </option>
                    {this.props.mercurials.map((t) => {
                      var hasExpired =
                        new Date(t.start_date) > now ||
                        new Date(t.end_date) < now;
                      if (hasExpired) return null;
                      if (t.status === MercurialStatus.INACTIVE) return null;

                      return (
                        <option key={t._id} value={t._id} disabled={hasExpired}>
                          {t.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}

            <div className="form-group row">
              <label
                htmlFor="first_name"
                className="col-12 col-sm-5 col-form-label"
              >
                <FormattedMessage id="First.Name" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="first_name"
                  autoComplete="off"
                  value={this.state.first_name}
                  onChange={(e) =>
                    this.onChange(
                      "first_name",
                      StringUtil.ucFirst(e.target.value)
                    )
                  }
                  onBlur={(e) => this.onBlur("first_name", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="name" className="col-12 col-sm-5 col-form-label">
                <FormattedMessage id="Name" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  autoComplete="off"
                  value={this.state.name}
                  onChange={(e) =>
                    this.onChange("name", e.target.value.toUpperCase())
                  }
                  onBlur={(e) => this.onBlur("name", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="address"
                className="col-12 col-sm-5 col-form-label"
              >
                <FormattedMessage id="Address" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="address"
                  autoComplete="off"
                  value={this.state.address}
                  onChange={(e) => this.onChange("address", e.target.value)}
                  onBlur={(e) => this.onBlur("address", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="postal_code"
                className="col-12 col-sm-5 col-form-label"
              >
                <FormattedMessage id="Postal.Code" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="postal_code"
                  autoComplete="off"
                  value={this.state.postal_code}
                  onChange={(e) => this.onChange("postal_code", e.target.value)}
                  onBlur={(e) => this.onBlur("postal_code", e.target.value)}
                />
                <div className="text-danger">
                  <small>{this.state.postal_codeError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="city" className="col-12 col-sm-5 col-form-label">
                <FormattedMessage id="City" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="city"
                  autoComplete="off"
                  value={this.state.city}
                  onChange={(e) =>
                    this.onChange("city", e.target.value.toUpperCase())
                  }
                  onBlur={(e) => this.onBlur("city", e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="phone" className="col-12 col-sm-5 col-form-label">
                <FormattedMessage id="Phone" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="phone"
                  autoComplete="off"
                  value={this.state.phone}
                  onChange={(e) => this.onChange("phone", e.target.value)}
                  onBlur={(e) => this.onBlur("phone", e.target.value)}
                />
                <div className="text-danger">
                  <small>{this.state.phoneError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="mobile"
                className="col-12 col-sm-5 col-form-label"
              >
                <FormattedMessage id="Mobile" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="mobile"
                  autoComplete="off"
                  value={this.state.mobile}
                  onChange={(e) => this.onChange("mobile", e.target.value)}
                  onBlur={(e) => this.onBlur("mobile", e.target.value)}
                />
                <div className="text-danger">
                  <small>{this.state.mobileError}</small>
                </div>
              </div>
            </div>

            {this.state.client_type !== "helper" && (
              <div className="form-group row">
                <label
                  htmlFor="numero_agrement"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Numero.Agrement" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="numero_agrement"
                    autoComplete="off"
                    value={this.state.numero_agrement}
                    onChange={(e) =>
                      this.onChange("numero_agrement", e.target.value)
                    }
                    onBlur={(e) =>
                      this.onBlur("numero_agrement", e.target.value)
                    }
                  />
                  <div className="text-danger">
                    <small>{this.state.doctorIdError}</small>
                  </div>
                </div>
              </div>
            )}

            <div className="form-group row">
              <label
                htmlFor="username"
                className="col-12 col-sm-5 col-form-label"
              >
                <FormattedMessage id="Username" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control col-12 col-md-7 d-inline"
                  type="text"
                  id="username"
                  autoComplete="off"
                  value={this.state.username}
                  onChange={(e) =>
                    this.onChange("username", e.target.value.toLowerCase())
                  }
                  onBlur={(e) => this.onBlur("username", e.target.value)}
                />
                <span className="col-12 col-md-3">
                  -{this.props.company.url}
                </span>
                <div className="text-danger">
                  <small>{this.state.usernameError}</small>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="email" className="col-12 col-sm-5 col-form-label">
                <FormattedMessage id="Email" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  autoComplete="off"
                  value={this.state.email}
                  onChange={(e) =>
                    this.onChange("email", e.target.value.toLowerCase())
                  }
                  onBlur={(e) => this.onBlur("email", e.target.value)}
                />
                <div className="text-danger">
                  <small>{this.state.emailError}</small>
                </div>
              </div>
            </div>

            {!this.isUpdate && (
              <div className="form-group row">
                <label
                  htmlFor="email"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Notification" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    id="isemailsend"
                    type="checkbox"
                    onChange={(e) => this.onChange("checkbox", e.target.value)}
                    checked={this.state.isSendEmail}
                    onClick={this.handleCheck}
                  />
                </div>
              </div>
            )}
          </Modal.Body>

          {!this.isUpdate && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.props.close()}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="info"
                onClick={() => this.onSubmit()}
                disabled={this.disabled()}
              >
                <FormattedMessage id="Add" />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    clientTypes: state.clientTypes,
    mercurials: state.mercurials,
    company: state.company,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddClient: (client, successCallback) =>
      dispatch(addClient(client, successCallback)),
    onUpdClient: (client) => dispatch(updClient(client)),
    onCheckIfUsernameExists: (username, existsCallback, noExistsCallback) =>
      dispatch(
        checkIfUsernameExists(username, existsCallback, noExistsCallback)
      ),
    onCheckIfEmailExists: (email, existsCallback, noExistsCallback) =>
      dispatch(checkIfEmailExists(email, existsCallback, noExistsCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddClientModal));
