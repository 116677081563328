import React from "react";
import { connect } from "react-redux";
import { removeCollaboratorClient } from "../../../../actions/collaborators/collaborators";
import { UncontrolledTooltip } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { getOldProducts } from "../../../../actions/products/oldProducts";

class CollaboratorAssignmentsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
    };
  }

  removeClient(clientId) {
    if (this.state.disabled) return;

    this.setState({ disabled: true });

    var data = {
      collaboratorId: this.props.collaborator._id,
      clientId: clientId,
    };

    var successCallback = () => {
      this.setState({ disabled: false });
    };

    this.props.onRemoveCollaboratorClient(data, successCallback);
  }

  getTypeName(clientTypeId) {
    let clientType = this.props.clientTypes.find((p) => p._id === clientTypeId);
    if (clientType && clientType.name) return clientType.name;
    return null;
  }

  render() {
    return (
      <tr key={this.props.client._id} className="tre4mad">
        <td className="d-none d-md-table-cell">{this.props.client.name}</td>
        <td className="d-none d-md-table-cell">
          {this.props.client.first_name}
        </td>
        <td className="d-none d-md-table-cell">
          {this.getTypeName(this.props.client.type)}
        </td>
        <td className="text-center">
          <i
            id={"delete" + this.props.client._id}
            className="fa fa-minus-circle icon-big"
            onClick={() => this.removeClient(this.props.client._id)}
            disabled={this.state.disabled}
          ></i>
          <UncontrolledTooltip
            delay={{ show: 0, hide: 0 }}
            placement="top"
            target={"delete" + this.props.client._id}
          >
            <FormattedMessage id="Delete" />
          </UncontrolledTooltip>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveCollaboratorClient: (data, successCallback) =>
      dispatch(removeCollaboratorClient(data, successCallback)),
    onGetOldProducts: (data) => dispatch(getOldProducts(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaboratorAssignmentsRow);
