import React from "react";
import APIUrl from "../../../APIUrl";

class MenuLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <li className="nav-item mx-auto">
        {this.props.generalSettings.logo && (
          <img
            src={
              APIUrl.getGeneralSettingsLogo +
              this.props.generalSettings._id +
              "/" +
              Math.random() +
              "?token=" +
              APIUrl.jwtToken
            }
            alt=""
            className="clientLogo"
          />
        )}
      </li>
    );
  }
}

export default MenuLogo;
