import APIUrl from "../../APIUrl";
import axios from "axios";
import { getProductsAdmin } from "../products/products";
import { getCart } from "../cart/cart";
import { getNotifs } from "../notifications/notifications";
import { getOrdersAdmin } from "../orders/orders";
import { getProductsByMercurialIdAdmin } from "../products/products";
// import { getEstablishmentsSettings } from "../establishments/establishmentSettings";
import { getClients } from "../clients/clients";

export const GET_MERCURIAL = "GET_MERCURIAL";

function getMercurialsAction(mercurials) {
  return { type: GET_MERCURIAL, mercurials: mercurials };
}

export const getMercurials = function (successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getMercurials)
      .then(function (response) {
        dispatch(getMercurialsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addMercurial = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addMercurial, data)
      .then(function (response) {
        dispatch(getMercurials());
        dispatch(getProductsAdmin());

        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateMercurial = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateMercurial, data)
      .then(function (response) {
        dispatch(getMercurials());
        dispatch(getProductsAdmin());
        dispatch(getCart());
        dispatch(getNotifs());
        dispatch(getOrdersAdmin());
        // dispatch(getEstablishmentsSettings());
        dispatch(getClients());

        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateMercurialStatus = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateMercurialStatus, data)
      .then(function (response) {
        dispatch(getMercurials());
        // dispatch(getProductsAdmin());
        // dispatch(getCart());
        // dispatch(getNotifs());
        // dispatch(getOrdersAdmin());
        // dispatch(getEstablishmentsSettings());

        // return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteMercurial = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteMercurial, data)
      .then(function (response) {
        dispatch(getMercurials());
        dispatch(getProductsAdmin());
        dispatch(getCart());
        dispatch(getNotifs());
        dispatch(getOrdersAdmin());
        // dispatch(getEstablishmentsSettings());
        dispatch(getClients());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addProductsImgs = function (mercurialId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addProductsImgs + mercurialId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getProductsByMercurialIdAdmin(mercurialId, true));
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeProductsImgs = function (
  mercurialId,
  data,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.removeProductsImgs + mercurialId, data)
      .then(function (response) {
        dispatch(getProductsByMercurialIdAdmin(mercurialId, true));

        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addFamilyImgs = function (mercurialId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addFamilyImgs + mercurialId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
