import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import Roles from "../../enums/Roles";
import { UncontrolledTooltip } from "reactstrap";
import MercurialStatus from "../../enums/MercurialStatus";
import "../../css/orders/orders.css";

class ClientRow extends React.Component {
  countPatients(client, patients) {
    let scope =
      client.client_type === "helper" ? "helpers_ids" : "prescribers_ids";

    if (!client || !patients) return 0;

    var count = 0;
    outer: for (let p of patients) {
      for (let pId of p[scope]) {
        if (pId === client._id) {
          count++;
          continue outer;
        }
      }
    }
    return count;
  }

  getMercurialName(client) {
    for (let m of this.props.mercurials) {
      if (
        m._id === client.mercurial_Id &&
        m.status === MercurialStatus.ACTIVE
      ) {
        let version = "";
        if (m.version !== 1) version = "(v" + m.version + ")";
        return m.name + " " + version;
      }
    }

    return null;
  }

  /*
    getClientType = (client) => {
        const typeClient = this.props.prescriberTypes.find(t => t._id === client.type)
        if (typeClient) {
            return typeClient.name
        }
    }
    */

  getType = (client) => {
    if (client.client_type && client.client_type === "helper") {
      return this.props.intl.formatMessage({ id: "Client.Helper" });
    }

    if (client.client_type && client.client_type === "prescriber") {
      return this.props.intl.formatMessage({ id: "Client.Prescriber" });
    }
  };

  getClientLabel = (client) => {
    const labelClient = this.props.clientTypes.find(
      (t) => t._id === client.type
    );
    if (labelClient) {
      return labelClient.name;
    }
  };

  render() {
    const { client, patients, user } = this.props;

    if (!client) return null;

    const patientsNbr = this.countPatients(client, patients);

    return (
      <React.Fragment>
        <tr key={client._id}>
          <td className="align-middle">
            <span className="text-uppercase">
              {client.name && client.name.toUpperCase()}
            </span>{" "}
            <span className="text-capitalize">
              {client.first_name && client.first_name.toLowerCase()}
            </span>
          </td>
          <td className="align-middle">{this.getType(client)}</td>
          <td className="align-middle">{this.getClientLabel(client)}</td>
          <td className="align-middle">
            {client.address} {client.postal_code}{" "}
            {client.city && client.city.toUpperCase()}
          </td>
          <td className="align-middle">{patientsNbr}</td>
          {(this.props.user.access_e4pharmamad ||
            this.props.user.access_e4pharmastore) && (
            <td className="align-middle">{this.getMercurialName(client)}</td>
          )}
          {!this.props.limit && user.role === Roles.ADMIN && (
            <td className="text-center tdaction">
              <i
                id={"modify" + client._id}
                className="fa fa-edit icon-big"
                onClick={(e) => this.props.edit(client)}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"modify" + client._id}
              >
                <FormattedMessage id="Modify" />
              </UncontrolledTooltip>
              {/* <i className="fa fa-trash icon-big" onClick={(e) => this.props.delete(prescriber)}></i> */}
            </td>
          )}
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    clientTypes: state.clientTypes,
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ClientRow));
