import React from "react";
import { connect } from "react-redux";
import {
  updPatientAdmin,
  deletePatientDocument,
  addPatientDocumentFile,
} from "../../actions/patients/patients";
import APIUrl from "../../APIUrl";
import Document from "../sub/documentCrud/Document";

class PatientDocuments extends React.Component {
  render() {
    const {
      patient,
      user,
      onUpdPatientAdmin,
      onAddPatientDocumentFile,
      onDeletePatientDocument,
    } = this.props;

    return (
      <React.Fragment>
        <Document
          data={patient}
          documentType={"documents"}
          addDataDocument={(patientId, file, successCallback) =>
            onAddPatientDocumentFile(patientId, file, successCallback)
          }
          updateData={(patient, successCallback) =>
            onUpdPatientAdmin(patient, successCallback)
          }
          deleteDocument={(patientId, sheetId, successCallback) =>
            onDeletePatientDocument(patientId, sheetId, successCallback)
          }
          messageId={"Patient.Document.Add"}
          adminEmptyMessageId={"Empty.Patient.Document"}
          clientEmptyMessageId={"Client.Empty.Patient.Document"}
          user={user}
          url={APIUrl.getPatientDocumentFile}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddPatientDocumentFile: (patientId, file, successCallback) =>
      dispatch(addPatientDocumentFile(patientId, file, successCallback)),
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onDeletePatientDocument: (patientId, documentId, successCallback) =>
      dispatch(deletePatientDocument(patientId, documentId, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDocuments);
