import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import PatientModal from "./PatientModal";
import Paginator from "../sub/Paginator";
import PatientRow from "./PatientRow";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import { deletePatient } from "../../actions/patients/patients";
import Roles from "../../enums/Roles";
import Util from "../../util/Util";

class Patients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,

      // Search filters
      nameFilter: "",
      addressFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  openModal(patient, target) {
    // Quick patch to fix body while waiting to completely redo the interface
    // (Try to fix bug of difficulty to add a signature when multiple modals are opened)
    document.body.classList.add("modal-fixed");

    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <PatientModal
            isOpen={true}
            patientId={patient ? patient._id : null}
            openModal={(patient, target) => this.openModal(patient, target)}
            close={() => this.closeModal()}
            target={target}
          />
        ),
      })
    );
  }

  delete(patient) {
    if (!patient) return;

    const onConfirm = () => {
      // Send to BE
      this.props.onDeletePatient(patient._id);
    };

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id="Confirm" />}
          content={<FormattedMessage id="Confirm.Patient.Removal" />}
          successCallback={onConfirm}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });

    // Quick patch to fix body while waiting to completely redo the interface
    // (Try to fix bug of difficulty to add a signature when multiple modals are opened)
    document.body.classList.remove("modal-fixed");
  }

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.nameFilter) ||
      !Util.emptyString(this.state.addressFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      nameFilter: "",
      addressFilter: "",
    });
  }

  render() {
    const { patients } = this.props;

    // No patients
    if (
      (!patients || patients.length === 0) &&
      this.props.user.role === Roles.ADMIN
    ) {
      return (
        <React.Fragment>
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">Recherche</h5> */}
                  <div className="form-inline">
                    {this.props.user.role === Roles.ADMIN && (
                      <div className="alert alert-info w-100" role="alert">
                        <FormattedMessage id="Empty.Patient" />
                      </div>
                    )}
                    {this.props.user.role === Roles.SALES_REP && (
                      <div className="alert alert-info w-100" role="alert">
                        <FormattedMessage id="Empty.Patient.Collaborator" />
                      </div>
                    )}

                    {this.props.user.role === Roles.ADMIN && (
                      <button
                        className="btn btn-info ml-auto"
                        onClick={(e) => this.openModal()}
                      >
                        <FormattedMessage id="Patient.Add" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.modal}
        </React.Fragment>
      );
    }

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;
    let patientsNode = patients.map((patient) => {
      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (
          patient.name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1 &&
          patient.first_name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (this.state.addressFilter && this.state.addressFilter !== "") {
        if (
          patient.address
            .toUpperCase()
            .indexOf(this.state.addressFilter.toUpperCase()) === -1 &&
          patient.postal_code
            .toString()
            .toUpperCase()
            .indexOf(this.state.addressFilter.toUpperCase()) === -1 &&
          patient.city
            .toUpperCase()
            .indexOf(this.state.addressFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <PatientRow
          key={patient._id}
          patient={patient}
          edit={(patient) => this.openModal(patient, "general")}
          delete={(patient) => this.delete(patient)}
          // displaysEquipmentModal={(patient) => this.openModal(patient, "equipments")}
          displaysPrescribersModal={(patient) =>
            this.openModal(patient, "prescribers")
          }
          displaysHelpersModal={(patient) => this.openModal(patient, "helpers")}
          audits={this.props.audits}
        />
      );
    });

    // Define a button to reset search filters
    let resetSearchButton = () => {
      let button = (
        <button className="btn btn-outline-secondary" disabled>
          <i className="fa fa-filter"></i>
        </button>
      );

      if (this.areResultsFiltered()) {
        return (
          <button
            id="searchFilterBtn"
            className="btn btn-warning"
            onClick={() => this.resetSearchFields()}
          >
            <i className="fa fa-filter"></i>
          </button>
        );
      } else {
        return button;
      }
    };

    return (
      <React.Fragment>
        {/** BEGIN SEARCH FILTERS */}
        {!this.props.limit && (
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-inline">
                    <label className="my-1 mr-2" htmlFor="search_name">
                      {this.props.intl.formatMessage({ id: "Patient" })}
                    </label>
                    <input
                      id="search_name"
                      type="text"
                      className="form-control mr-sm-3"
                      style={{ width: "15%" }}
                      placeholder={
                        this.props.intl.formatMessage({ id: "Name" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "Or" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "First.Name" })
                      }
                      onChange={(e) => {
                        this.setState({ nameFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.nameFilter}
                    />

                    <label className="my-1 mr-2" htmlFor="search_address">
                      {this.props.intl.formatMessage({ id: "Address" })}
                    </label>
                    <input
                      id="search_address"
                      type="text"
                      className="form-control w-50 mr-sm-3"
                      placeholder={this.props.intl.formatMessage({
                        id: "Address",
                      })}
                      onChange={(e) => {
                        this.setState({ addressFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.addressFilter}
                    />

                    {resetSearchButton()}

                    {this.props.user.role === Roles.ADMIN && (
                      <button
                        className="btn btn-info ml-auto"
                        onClick={(e) => this.openModal()}
                      >
                        <FormattedMessage id="Patient.Add" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/** END SEARCH FILTERS */}

        <table className="table tablee4mad">
          <thead>
            <tr className="d-flex">
              {this.props.user.role === Roles.ADMIN && (
                <React.Fragment>
                  <th className="col-2">
                    <FormattedMessage id="Name" /> <FormattedMessage id="And" />{" "}
                    <FormattedMessage id="First.Name" />
                  </th>
                  <th className="col-4">
                    <FormattedMessage id="Address" />
                  </th>
                  <th className="col-1">
                    <FormattedMessage
                      id="Last.Audit.Date"
                      values={{ er: <sup>er</sup> }}
                    />
                  </th>
                  <th className="col-1">
                    <FormattedMessage id="Prescribers" />
                  </th>
                  <th className="col-1">
                    <FormattedMessage id="Helpers" />
                  </th>
                  <th className="col-1">
                    <FormattedMessage id="Active" />
                  </th>
                  <th className="col-1">
                    <FormattedMessage id="Request.New.Audit" />
                  </th>
                  <th className="col-1">
                    <FormattedMessage id="Actions" />
                  </th>
                </React.Fragment>
              )}
              {this.props.user.role === Roles.SALES_REP && (
                <React.Fragment>
                  <th className="col-2">
                    <FormattedMessage id="Name" /> <FormattedMessage id="And" />{" "}
                    <FormattedMessage id="First.Name" />
                  </th>
                  <th className="col-5">
                    <FormattedMessage id="Address" />
                  </th>
                  <th className="col-2">
                    <FormattedMessage
                      id="Last.Audit.Date"
                      values={{ er: <sup>er</sup> }}
                    />
                  </th>
                  <th className="col-1">
                    <FormattedMessage id="Prescribers" />
                  </th>
                  <th className="col-1">
                    <FormattedMessage id="Helpers" />
                  </th>
                  {/* <th className="col-1"><FormattedMessage id="Active" /></th> */}
                  <th className="col-1">
                    <FormattedMessage id="Actions" />
                  </th>
                </React.Fragment>
              )}
              {this.props.user.role === Roles.CLIENT && (
                <React.Fragment>
                  <th scope="col" className="col col-1 text-center">
                    <FormattedMessage id="Active" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Name" /> <FormattedMessage id="And" />{" "}
                    <FormattedMessage id="First.Name" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Address" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage
                      id="Last.Audit.Date"
                      values={{ er: <sup>er</sup> }}
                    />
                  </th>
                  <th scope="col" className="col col-1 text-center">
                    <FormattedMessage id="Report" />
                  </th>
                  {/* <th scope="col" className="col col-1 text-center"><FormattedMessage id="Equipments" /></th> */}
                  <th scope="col" className="col col-1 text-center">
                    <FormattedMessage id="Alert.Renewal" />
                  </th>
                  <th scope="col" className="col col-1 text-center">
                    <FormattedMessage id="Estimates" />
                  </th>
                  <th scope="col" className="col col-1 text-center">
                    <FormattedMessage id="Tech.Sheet" />
                  </th>
                  <th scope="col" className="col col-1 text-center">
                    <FormattedMessage id="Request.New.Audit" />
                  </th>
                  {!this.props.limit && (
                    <th scope="col" className="col col-1 text-center">
                      <FormattedMessage id="Actions" />
                    </th>
                  )}
                </React.Fragment>
              )}
            </tr>
          </thead>
          <tbody>{patientsNode}</tbody>
        </table>

        {this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditEquipments: state.auditEquipments,
    patients: state.patients,
    user: state.user,
    audits: state.audits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeletePatient: (patientId) => dispatch(deletePatient(patientId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Patients));
