import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import CollaboratorAssignmentsRow from "./CollaboratorAssignmentsRow";
import Paginator from "../../../sub/Paginator";

class CollaboratorAssignments extends React.Component {
  constructor(props) {
    super(props);

    this.paginator = new Paginator(this);
  }

  getEstablishment(establishmentId) {
    for (let establishment of this.props.establishments) {
      if (establishment._id === establishmentId) return establishment;
    }
  }

  render() {
    if (!this.props.collaborator) return null;

    this.paginator.init();
    var hasValues = false;

    var clientsNode = this.props.clients.map((client) => {
      if (!this.paginator.keep()) return null;
      if (
        this.props.collaborator.clients.find((p) => p.client_id === client._id)
      ) {
        if (!client) return null;
        else hasValues = true;
        return (
          <CollaboratorAssignmentsRow
            key={client._id}
            collaborator={this.props.collaborator}
            clientTypes={this.props.clientTypes}
            client={client}
          />
        );
      }
      return null;
    });

    if (!hasValues) return null;

    return (
      <React.Fragment>
        <table className="table table-striped col-12 tablee4mad">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Name" />
              </th>
              <th className="d-none d-md-table-cell">
                <FormattedMessage id="First.Name" />
              </th>
              <th className="d-none d-md-table-cell">
                <FormattedMessage id="Type" />
              </th>
              <th className="text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{clientsNode}</tbody>
        </table>

        {this.paginator.render()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishments: state.establishments,
    clients: state.clients,
    clientTypes: state.clientTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaboratorAssignments);
