import React from "react";
import { connect } from "react-redux";
import FileDropZone from "../sub/FileDropZone";
import CustomLabel from "../sub/CustomLabel";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import FileUtil from "../../util/FileUtil";
import ExcelUtil from "../../util/ExcelUtil";
import XLSX from "xlsx";
import APIUrl from "../../APIUrl";
import Axios from "axios";
import {
  Modal,
  Button,
  Alert,
  Container,
  Row,
  ToggleButton,
  ToggleButtonGroup,
  Badge,
} from "react-bootstrap";

class MercurialImgsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disabled: false,
      // Uploaded files
      summary: null,
      productFiles: null,
      familyFiles: null,
      declinedFiles: null,
      // Info
      familiesWithNoImg: null,
      productsWithNoImg: null,
      formVisibility: "hide",
      progressBarVisibility: "show",
      progressbarMessage: (
        <FormattedMessage id="Import.Mercurial.Image.Check" />
      ),
      useSupplierReference: false,
      useCustomImage: false,
      files: [],
    };
  }

  componentWillMount() {
    if (!this.state.summary) {
      Axios.get(APIUrl.getMercImgOverview + this.props.mercurial._id).then(
        (response) => {
          this.setState({
            familiesWithNoImg: response.data.familiesWithNoImg,
            productsWithNoImg: response.data.productsWithNoImg,
            formVisibility: "show",
            progressBarVisibility: "hide",
          });
        }
      );
    }
  }

  close() {
    this.props.closeModal();
  }

  onComplete() {
    this.setState({
      disabled: true,
      formVisibility: "hide",
      progressBarVisibility: "show",
      progressbarMessage: (
        <FormattedMessage id="Import.Mercurial.Image.Upload" />
      ),
    });

    this.props.onComplete(
      this.props.mercurial._id,
      this.state.productFiles,
      this.state.familyFiles,
      this.state.useCustomImage
    );
  }

  onDropFiles(files) {
    this.setState({ files: files });

    let productFiles = [];
    let familyFiles = [];
    let declinedFiles = [];

    let products = this.getMercurialProducts(this.props.mercurial);

    const usedReference = this.state.useSupplierReference ? "ref_frn" : "ref";

    outer: for (let file of files) {
      for (let product of products) {
        let fileNameWithoutExtension = FileUtil.withoutExtension(file.name);

        // Product img
        if (fileNameWithoutExtension === product[usedReference]) {
          let image = {
            file: file,
            reference: product.ref_frn,
          };

          productFiles.push(image);
          continue outer;
        }
        // Family img
        else if (
          fileNameWithoutExtension ===
          "FAM_" + product.fam.split(".")[0]
        ) {
          familyFiles.push(file);
          continue outer;
        }
      }

      declinedFiles.push(file);
    }

    this.setState({
      productFiles: productFiles,
      familyFiles: familyFiles,
      declinedFiles: declinedFiles,
    });
  }

  disabled() {
    return (
      this.state.disabled ||
      (Util.emptyArray(this.state.productFiles) &&
        Util.emptyArray(this.state.familyFiles))
    );
  }

  // When an error occurs, allow download of generated import file
  downloadMissingImageList(families, products) {
    var wb = XLSX.utils.book_new();

    if (Util.typeOf(products) === "Array" && products.length > 0) {
      let colHeadersProducts = {
        REFERENCE: "REFERENCE",
        "REFERENCE FOURNISSEUR": "REFERENCE FOURNISSEUR",
        DESIGNATION: "DESIGNATION",
        FAMILLE: "FAMILLE",
      };

      let newProducts = [];

      for (let p of products) {
        var newProduct = {
          REFERENCE: p.ref,
          "REFERENCE FOURNISSEUR": p.ref_frn,
          DESIGNATION: p.designation,
          FAMILLE: p.famille,
        };

        newProducts.push(newProduct);
      }

      // Convert data to Excel format
      let excelData = ExcelUtil.toExcel(newProducts, colHeadersProducts);

      var wsProducts = XLSX.utils.aoa_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, wsProducts, "produits");
    }

    if (Util.typeOf(families) === "Array" && families.length > 0) {
      let colHeadersFamilies = {
        FAMILLE: "FAMILLE",
      };

      let missingFamilies = [];

      for (let f of families) {
        var missingFamily = {
          REFERENCE: f.fam,
          FAMILLE: f.famille,
        };

        missingFamilies.push(missingFamily);
      }

      // Convert data to Excel format
      let excelDataFamilies = ExcelUtil.toExcel(
        missingFamilies,
        colHeadersFamilies
      );

      var wsFamilies = XLSX.utils.aoa_to_sheet(excelDataFamilies);

      XLSX.utils.book_append_sheet(wb, wsFamilies, "familles");
    }

    XLSX.writeFile(wb, "missingImages.xlsx");
  }

  closeModal() {
    this.setState({ modal: null });
  }

  displayMissingImgs() {
    // Users has dropped stuff, don't show
    // if (this.state.productFiles || this.state.familyFiles || this.state.declinedFiles) return false;

    return (
      this.state.familiesWithNoImg &&
      this.state.productsWithNoImg &&
      (this.state.familiesWithNoImg.length !== 0 ||
        this.state.productsWithNoImg.length !== 0)
    );
  }

  getMercurialProducts(mercurial) {
    let products = [];

    for (let product of this.props.products) {
      if (product.mercurial_id === mercurial._id) {
        products.push(product);
      }
    }

    return products;
  }

  toggle(scope) {
    switch (scope) {
      case "reference":
        // https://stackoverflow.com/questions/41278385/setstate-doesnt-update-the-state-immediately
        this.setState(
          { useSupplierReference: !this.state.useSupplierReference },
          function () {
            // re-check valid images if useSupplierReference mode has changed
            if (this.state.files.length) {
              this.onDropFiles(this.state.files);
            }
          }
        );
        break;
      case "image":
        this.setState({ useCustomImage: !this.state.useCustomImage });
        break;

      default:
        break;
    }
  }

  render() {
    // Alert box with info about how much missing imgs this mercurial has, with the list of the items
    var missingImgs = null;
    if (this.displayMissingImgs()) {
      var missingFamilyImgs = null;
      if (this.state.familiesWithNoImg.length !== 0) {
        missingFamilyImgs = (
          <li>
            {this.state.familiesWithNoImg.length}{" "}
            <FormattedMessage
              id="Mercurials.Missing.Family.Images"
              values={{
                plural: this.state.familiesWithNoImg.length > 1 ? "s" : "",
              }}
            />
          </li>
        );
      }

      var missingProductImgs = null;
      if (this.state.productsWithNoImg.length !== 0) {
        missingProductImgs = (
          <li>
            {this.state.productsWithNoImg.length}{" "}
            <FormattedMessage
              id="Mercurials.Missing.Product.Images"
              values={{
                plural: this.state.productsWithNoImg.length > 1 ? "s" : "",
              }}
            />
          </li>
        );
      }

      missingImgs = (
        <Alert variant="danger">
          <FormattedMessage id="Mercurials.Missing" /> :
          <ul>
            {missingFamilyImgs}
            {missingProductImgs}
          </ul>
          <div className="text-center">
            <button
              className="btn btn-danger"
              onClick={() => {
                this.downloadMissingImageList(
                  this.state.familiesWithNoImg,
                  this.state.productsWithNoImg
                );
              }}
            >
              <i className="fa fa-download fa-fw"></i>{" "}
              <FormattedMessage id="Download.Missing.Image.List" />
            </button>
          </div>
        </Alert>
      );
    }

    return (
      <React.Fragment>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.close()}
            size={"lg"}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="Mercurials.Import.Imgs" />
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div
                className={
                  "text-center mb-5 " + this.state.progressBarVisibility
                }
              >
                <i className="fa fa-cog fa-spin fa-3x fa-fw text-success mb-3"></i>
                <div className="progress" style={{ height: "30px" }}>
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "100%" }}
                  >
                    <strong>{this.state.progressbarMessage}</strong>
                  </div>
                </div>
              </div>

              <div className={this.state.formVisibility}>
                {missingImgs}
                <Container fluid className="mt-3">
                  <Row xs="auto">
                    <div className="col">
                      <CustomLabel
                        label={this.props.intl.formatMessage({
                          id: "Used.Reference.System",
                        })}
                        htmlFor="name"
                        help={
                          <FormattedMessage id="Popover.Image.Reference.System" />
                        }
                        labelClassName="col-12 col-form-label"
                        required
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <ToggleButtonGroup
                        id="buttongroup"
                        name="buttongroup"
                        size="sm"
                        className="btn-block"
                        defaultValue={
                          this.state.useSupplierReference
                            ? "supplier"
                            : "internal"
                        }
                        onChange={() => this.toggle("reference")}
                      >
                        <ToggleButton
                          id="internal"
                          value={"internal"}
                          variant={
                            this.state.useSupplierReference
                              ? "light"
                              : "dark disabled"
                          }
                        >
                          <FormattedMessage id="Intern.Ref" />
                        </ToggleButton>

                        <ToggleButton
                          id="supplier"
                          value={"supplier"}
                          variant={
                            this.state.useSupplierReference
                              ? "dark disabled"
                              : "light"
                          }
                        >
                          <FormattedMessage id="Supplier.Ref" />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Row>
                  <Row xs="auto">
                    <div className="col">
                      <CustomLabel
                        label={this.props.intl.formatMessage({
                          id: "Image.To.Display",
                        })}
                        htmlFor="name"
                        help={
                          <FormattedMessage id="Popover.Image.To.Display" />
                        }
                        labelClassName="col-12 col-form-label"
                        required
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <ToggleButtonGroup
                        id="buttongroup2"
                        name="buttongroup2"
                        size="sm"
                        className="btn-block"
                        defaultValue={
                          !this.state.useCustomImage ? "bank" : "custom"
                        }
                        onChange={() => this.toggle("image")}
                      >
                        <ToggleButton
                          id="bank"
                          value={"bank"}
                          variant={
                            this.state.useCustomImage
                              ? "light"
                              : "dark disabled"
                          }
                        >
                          <FormattedMessage id="Use.Database.Image" />
                        </ToggleButton>

                        <ToggleButton
                          id="custom"
                          value={"custom"}
                          variant={
                            this.state.useCustomImage
                              ? "dark disabled"
                              : "light"
                          }
                        >
                          <FormattedMessage id="Use.Custom.Image" />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Row>
                  <Row xs="auto">
                    <div className="col">
                      <CustomLabel
                        label={this.props.intl.formatMessage({
                          id: "Drop.Visual",
                        })}
                        htmlFor="name"
                        help={
                          <FormattedMessage id="Popover.Mercurials.Upload.Imgs.Recommended.Dimensions" />
                        }
                        labelClassName="col-12 col-form-label"
                        required
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      {this.state.productFiles && (
                        <Badge variant="success" className="mr-1">
                          <i className="fa fa-check-circle mr-1"></i>
                          {this.state.productFiles.length}{" "}
                          <FormattedMessage
                            id="Mercurials.Missing.Product.Images"
                            values={{
                              plural:
                                this.state.productFiles.length > 1 ? "s" : "",
                            }}
                          />
                        </Badge>
                      )}
                      {this.state.declinedFiles && (
                        <Badge variant="danger" className="ml-1">
                          <i className="fa fa-times-circle mr-1"></i>
                          {this.state.declinedFiles.length}{" "}
                          <FormattedMessage
                            id="Mercurials.Invalid.Images"
                            values={{
                              plural:
                                this.state.declinedFiles.length > 1 ? "s" : "",
                            }}
                          />
                        </Badge>
                      )}
                    </div>
                  </Row>
                  <Row xs="auto">
                    <div className="col">
                      <FileDropZone
                        onDropFiles={(files) => this.onDropFiles(files)}
                        acceptedExtensions={["jpg", "jpeg", "png"]}
                        multiple={true}
                      />
                    </div>
                  </Row>
                </Container>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.close()}>
                <FormattedMessage id="Skip.This.Step" />
              </Button>
              <Button
                variant="info"
                disabled={this.disabled()}
                onClick={() => this.onComplete()}
              >
                <FormattedMessage id="Import" />
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MercurialImgsModal));
