import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { updateShippingCostsSetting } from "../../../../actions/settings/ordersSettings/admin/ordersSettings";
import { UncontrolledTooltip } from "reactstrap";

class ShippingCostSettingAdminRow extends React.Component {
  updateShippingCostsSetting(shippingCosts) {
    // Send to BE
    this.props.onUpdateShippingCostsSetting({ shippingCosts: shippingCosts });
  }

  isLast() {
    return this.props.index === this.props.shippingCosts.length - 1;
  }

  openShippingModal(index, successCallbackUpdate) {
    if (!this.isLast()) return;

    this.props.openShippingModal(index, successCallbackUpdate);
  }

  openConfModal(modalTitle, modalContent, successCallbackDelete) {
    if (!this.isLast()) return;

    this.props.openConfModal(modalTitle, modalContent, successCallbackDelete);
  }

  splice(array, index) {
    array.splice(index, 1);
    return array;
  }

  render() {
    // 'Delete shipping setting' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = <FormattedMessage id="Remove.Row.Confirmation" />;
    var successCallbackDelete = () =>
      this.updateShippingCostsSetting(
        this.splice(this.props.shippingCosts, this.props.index)
      );

    // Shipping modal set up
    var successCallbackUpdate = (shippingCosts) =>
      this.updateShippingCostsSetting(shippingCosts);

    var max = !this.props.shippingCost.max ? (
      <FormattedMessage id="Infinity" />
    ) : (
      <span>{this.props.shippingCost.max}</span>
    );

    return (
      <tr key={this.props.key} className="tre4mad">
        <td>
          <span className="d-sm-none">
            {this.props.shippingCost.min} / {max}
          </span>
          <span className="d-none d-sm-table-cell">
            {this.props.shippingCost.min}
          </span>
        </td>
        <td className="d-none d-sm-table-cell">{max}</td>
        <td>{this.props.shippingCost.cost}</td>
        <td className="text-center tdaction">
          <i
            id={"modify" + this.props.index}
            className="fa fa-edit icon-big"
            onClick={(index, successCallback) =>
              this.openShippingModal(this.props.index, successCallbackUpdate)
            }
            disabled={!this.isLast()}
          ></i>
          {this.isLast() && (
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"modify" + this.props.index}
            >
              <FormattedMessage id="Modify" />
            </UncontrolledTooltip>
          )}
          <i
            id={"delete" + this.props.index}
            className="fa fa-trash icon-big"
            onClick={(e) =>
              this.openConfModal(
                modalTitle,
                modalContent,
                successCallbackDelete
              )
            }
            disabled={!this.isLast()}
          ></i>
          {this.isLast() && (
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"delete" + this.props.index}
            >
              <FormattedMessage id="Delete" />
            </UncontrolledTooltip>
          )}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateShippingCostsSetting: (data) =>
      dispatch(updateShippingCostsSetting(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingCostSettingAdminRow);
