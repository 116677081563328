import React from "react";
import { connect } from "react-redux";
import { addProductToCart } from "../../actions/cart/cart";
import { addProductToCartAdmin } from "../../actions/cart/cart";
import ProductRow from "./ProductRow";
import { FormattedMessage, injectIntl } from "react-intl";
import Roles from "../../enums/Roles";
import Paginator from "../sub/Paginator";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import { Link } from "react-router-dom";
import ArrayUtil from "../../util/ArrayUtil";
import "../../css/products/products.css";
import UploadProductImgModal from "../mercurials/UploadProductImgModal";
import { NotificationManager } from "react-notifications";
import {
  addProductsImgs,
  removeProductsImgs,
} from "../../actions/mercurials/mercurials";
import { getProductsByMercurialIdAdmin } from "../../actions/products/products";
import ProductModal from "./ProductModal";
import { updateProductAdmin } from "../../actions/products/products";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import { Alert } from "react-bootstrap";
import Maths from "../../util/Maths";

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      searchMercurialId: this.props.match.params.mercurialId,
      searchProductId: this.props.match.params.product,
      mercurialId: this.getMercurialId(),
      modal: null,
    };

    this.paginator = new Paginator(this);
  }

  getMercurialId() {
    let id = this.props.match.params.mercurialId;
    return id;
  }

  componentDidMount() {
    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SUPER_ADMIN
    ) {
      switch (true) {
        case this.state.mercurialId !== "" &&
          this.props.products &&
          this.props.products.length !==
            this.getMercurial(this.state.mercurialId).productsLength:
        case this.props.products &&
          this.state.mercurialId &&
          this.props.products.length !==
            this.getMercurial(this.state.mercurialId).productsLength:
          this.props.onGetProductsByMercurialIdAdmin(this.state.mercurialId);
          break;

        default:
          break;
      }
    } else {
      if (
        this.props.mercurials.length !== 0 &&
        this.props.products.length !== this.props.mercurials.productsLength
      ) {
        this.props.onGetProducts();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SUPER_ADMIN
    ) {
      switch (true) {
        case this.state.mercurialId !== prevState.mercurialId &&
          this.state.mercurialId !== "" &&
          this.props.products &&
          this.getProductsLengthByMercurialId(this.state.mercurialId) !==
            this.getMercurial(this.state.mercurialId).productsLength:
        case this.props.products &&
          this.props.products.length === 0 &&
          this.state.mercurialId &&
          this.getProductsLengthByMercurialId(this.state.mercurialId) !==
            this.getMercurial(this.state.mercurialId).productsLength:
          this.props.onGetProductsByMercurialIdAdmin(this.state.mercurialId);
          break;
        default:
          break;
      }
    } else {
      if (
        this.props.mercurials.length !== 0 &&
        this.props.products.length !== this.props.mercurials.productsLength
      ) {
        this.props.onGetProducts();
      }
    }
  }

  getProductsLengthByMercurialId(mercurialId) {
    let response = 0;
    for (let p of this.props.products) {
      if (p.mercurial_id === mercurialId) response = response + 1;
    }
    return response;
  }

  getMercurial(mercurialId) {
    let response = {};
    for (let mercurial of this.props.mercurials) {
      if (mercurial._id === mercurialId) response = mercurial;
    }
    return response;
  }

  add(e, productId, quantity, successCallback) {
    var data = {
      id_product: productId,
      quantity: quantity,
    };

    // Add product. Careful with role
    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    )
      this.props.onAddProductToCartAdmin(data, successCallback);
    else this.props.onAddProductToCart(data, successCallback);
  }

  search(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ search: e.target.value, searchProductId: null });
  }

  formatsContain(formats, productId) {
    for (let format of formats) {
      if (format._id === productId) return true;
    }
    return false;
  }

  isFavoriteFamily = (familyName) => {
    return (
      familyName ===
      this.props.intl.formatMessage({ id: "Top.Products" }).toUpperCase()
    );
  };

  isClient() {
    return (
      this.props.user.role !== Roles.SALES_REP &&
      this.props.user.role !== Roles.ADMIN
    );
  }
  // Simple close modal function that can handle a possible callback if provided
  closeModal(callback) {
    this.setState({ modal: null }, callback);
  }

  // Management of images uploading
  uploadImage(productRef, productImage) {
    var sendProductImage = (successCallback) => {
      if (!productImage || productImage.length === 0) return successCallback();

      let formData = new FormData();

      // Tell the backend that we want to use a custom images even if a database image exists
      formData.append("useCustomImage", true);

      for (let img of productImage) {
        formData.append(productRef, img);
      }

      this.props.onAddProductsImgs(
        this.state.searchMercurialId,
        formData,
        successCallback
      );
    };

    // Notification after image upldate
    let errorMessage = this.props.intl.formatMessage({
      id: "Product.Image.Updated",
    });

    let notification = () => {
      NotificationManager.success(errorMessage);
    };

    sendProductImage(() => this.closeModal(notification));
  }

  // Management of images deletion
  removeImage(productRef) {
    var removeProductImage = (successCallback) => {
      if (!productRef) return successCallback();
      const data = { removeRef: productRef };
      this.props.onRemoveProductsImgs(
        this.state.searchMercurialId,
        data,
        successCallback
      );
    };

    // Notification after image upldate
    let errorMessage = this.props.intl.formatMessage({
      id: "Product.Image.Updated",
    });

    let notification = () => {
      NotificationManager.success(errorMessage);
    };

    removeProductImage(() => this.closeModal(notification));
  }

  // In mercurial "create" mode, Step 3 is when we let the ability to the user to upload associated categories/products images
  openUploadProductImgModal(productFormat, currentProductImage) {
    this.setState({
      modal: (
        <UploadProductImgModal
          closeModal={() => this.closeModal()}
          productFormat={productFormat}
          currentProductImage={currentProductImage}
          mode="upload"
          onComplete={(newProductImage) =>
            this.uploadImage(productFormat.ref_frn, newProductImage)
          }
        />
      ),
    });
  }

  // In mercurial "create" mode, Step 3 is when we let the ability to the user to upload associated categories/products images
  openDeleteProductImgModal(
    productFormat,
    currentProductImage,
    defaultProductImage
  ) {
    this.setState({
      modal: (
        <UploadProductImgModal
          closeModal={() => this.closeModal()}
          productFormat={productFormat}
          currentProductImage={currentProductImage}
          defaultProductImage={defaultProductImage}
          mode="delete"
          onComplete={() => this.removeImage(productFormat.ref_frn)}
        />
      ),
    });
  }

  openProductModal(product) {
    this.setState({
      modal: (
        <ProductModal
          closeModal={() => this.closeModal()}
          product={product}
          onComplete={(data) => this.openConfModal(data)}
        />
      ),
    });
  }

  openConfModal(data) {
    const content = (
      <React.Fragment>
        <Alert variant="danger">
          <strong>
            <FormattedMessage id="Warning" /> :{" "}
            <FormattedMessage
              id="Unit.Price.Excl.Tax.Update.Confirm"
              values={{ from: Maths.round(data.oldPrice, 4), to: data.price }}
            />
          </strong>
        </Alert>
        <p>
          <FormattedMessage id="Confirm.Choice" />
        </p>
      </React.Fragment>
    );

    const successCallback = () => {
      let updatedProduct = {
        productId: data.productId,
        updatedField: "prix_u_ht_emera",
        updatedValue: data.price,
      };

      const notifSuccessCallback = () => {
        let message = this.props.intl.formatMessage({
          id: "Unit.Price.Excl.Tax.Update.Notif.Success",
        });

        NotificationManager.success(message);
      };
      this.props.onUpdateProductAdmin(updatedProduct, notifSuccessCallback);
    };

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
          context="danger"
        />
      ),
    });
  }

  render() {
    var famille = this.props.match.params.famille;
    let isRendered = true;
    let favorites_found;

    var addButton = <th className="d-none d-md-table-cell col-1"></th>;
    var quantity = (
      <th className="d-none d-md-table-cell col-1 required">
        <FormattedMessage id="Quantity" />
        <i className="fa fa-asterisk"></i>
      </th>
    );

    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    ) {
      addButton = null;
      quantity = null;
    }

    switch (true) {
      // No 'Products'? No render
      case this.props.products.length === 0:
        isRendered = false;
        break;
      // Current family is favorite
      case this.isFavoriteFamily(famille):
        let favoriteRefs = this.props.user.favorite_products.map(
          (value) => value.ref_frn
        );

        // Lets check if we find favorite products for current user
        if (favoriteRefs.length !== 0) {
          let products_ref_frn = this.props.products.map(
            (value) => value.ref_frn
          );
          favorites_found = ArrayUtil.intersection(
            products_ref_frn,
            favoriteRefs
          );
        }

        isRendered =
          favorites_found && favorites_found.length !== 0 ? true : false;
        break;
      default:
        break;
    }

    // Make a special treatment to raw products. Regroup by categories, for instance
    let filtedProductsOnMercurialId = this.props.products.filter(
      (p) => p.mercurial_id === this.state.searchMercurialId
    );
    let products = ProductsParserUtil.parseProducts(
      filtedProductsOnMercurialId,
      !this.isFavoriteFamily(famille)
        ? famille
        : isRendered
        ? favorites_found
        : ""
    );

    this.paginator.init();

    let productsNode = products.map((product) => {
      // NOTE : THESE CHECKS MIGHT BE UNNECESSARY IF PRODUCT PARSING WAS WELL DONE BEFORE
      // First of all, check if we are in favorite category
      if (!this.isFavoriteFamily(famille)) {
        // If we are not, check if product family is the right one
        if (product.famille !== famille) {
          return null;
        }
      }

      // Search filter
      if (this.state.search && this.state.search !== "") {
        if (
          product.sous_famille
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) === -1 &&
          product.designation
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) === -1 &&
          product.caracteristiques
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) === -1 &&
          product._id !== "" + this.state.searchProductId
        )
          return null;
      }

      if (
        this.state.searchProductId &&
        product._id !== this.state.searchProductId &&
        !this.formatsContain(product.formats, this.state.searchProductId)
      ) {
        return null;
      }

      if (!this.paginator.keep()) return null;

      return (
        <ProductRow
          product={product}
          searchProductId={this.state.searchProductId}
          key={product._id}
          openUploadProductImgModal={(
            productFormat,
            currentProductImgUrl,
            databaseDefaultProductImgUrl
          ) =>
            this.openUploadProductImgModal(
              productFormat,
              currentProductImgUrl,
              databaseDefaultProductImgUrl
            )
          }
          openDeleteProductImgModal={(
            productFormat,
            currentProductImgUrl,
            databaseDefaultProductImgUrl
          ) =>
            this.openDeleteProductImgModal(
              productFormat,
              currentProductImgUrl,
              databaseDefaultProductImgUrl
            )
          }
          onAdd={(e, productId, quantity, successCallback) =>
            this.add(e, productId, quantity, successCallback)
          }
          openProductModal={(productFormat) =>
            this.openProductModal(productFormat)
          }
        />
      );
    });

    let placeholder = this.props.intl.formatMessage({ id: "Search" });

    return (
      <React.Fragment>
        <div className="row search-filters">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {/* <h5 className="card-title">Recherche</h5> */}
                <div className="form-inline">
                  <Link
                    to={
                      this.isClient()
                        ? "/home/families"
                        : "/home/families/" + this.state.searchMercurialId
                    }
                    className="btn btn-info"
                  >
                    <i className="fa fa-arrow-circle-left mr-1"></i>
                    <FormattedMessage
                      id={this.isClient() ? "Categories" : "Return"}
                    />
                  </Link>
                  <span className="btn-default ml-4">
                    {famille}
                    {this.isFavoriteFamily(famille) && (
                      <span className="pl-1">
                        <strong>({products.length})</strong>
                      </span>
                    )}
                  </span>
                  {isRendered && (
                    <input
                      className="form-control ml-auto w-50"
                      type="search"
                      value={this.state.search}
                      onChange={(e) => this.search(e)}
                      placeholder={placeholder}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isRendered && (
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">Recherche</h5> */}
                  <div className="form-inline">
                    <div className="alert alert-info w-100">
                      <FormattedMessage id="Products.None" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isRendered && (
          <table className="table tablee4mad">
            <thead className="d-none d-md-block d-lg-block d-xl-block">
              <tr className="d-flex">
                <th className="d-none d-lg-table-cell col-2">
                  <FormattedMessage id="Product" />
                </th>
                <th className={this.isClient() ? "col-4" : "col-6"}>
                  <FormattedMessage id="Description" />
                </th>
                <th className="col-2">
                  <FormattedMessage id="Features" />
                </th>
                <th className="d-none d-md-table-cell text-right col-1">
                  <FormattedMessage id="Unit.Price" />
                </th>
                <th className="d-none d-lg-table-cell text-right col-1">
                  <FormattedMessage id="VAT" />
                </th>
                {quantity}
                {addButton}
              </tr>
            </thead>
            <tbody>{productsNode}</tbody>
          </table>
        )}

        {this.paginator.render()}
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    products: state.products,
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddProductToCart: (data, successCallback) =>
      dispatch(addProductToCart(data, successCallback)),
    onAddProductToCartAdmin: (data, successCallback) =>
      dispatch(addProductToCartAdmin(data, successCallback)),
    onAddProductsImgs: (mercurialId, data, successCallback) =>
      dispatch(addProductsImgs(mercurialId, data, successCallback)),
    onRemoveProductsImgs: (mercurialId, data, successCallback) =>
      dispatch(removeProductsImgs(mercurialId, data, successCallback)),
    onGetProductsByMercurialIdAdmin: (mercurialId) =>
      dispatch(getProductsByMercurialIdAdmin(mercurialId)),
    onUpdateProductAdmin: (data, successCallback) =>
      dispatch(updateProductAdmin(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Products));
