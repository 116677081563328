import { GET_MERCURIAL } from "../../actions/mercurials/mercurials";

export default function mercurials(state = [], action) {
  switch (action.type) {
    case GET_MERCURIAL:
      return action.mercurials;
    default:
      return state;
  }
}
