import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import CollaboratorsModal from "./CollaboratorsModal";

import Paginator from "../../../sub/Paginator";
import { UncontrolledTooltip } from "reactstrap";

import { getOldProducts } from "../../../../actions/products/oldProducts";

import CollaboratorTypes from "./collaboratorTypes/CollaboratorTypes";
import { Tab, Tabs } from "react-bootstrap";
import Roles from "../../../../enums/Roles";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import { removeCollaborator } from "../../../../actions/collaborators/collaborators";
class Collaborators extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };

    this.paginator = new Paginator(this);
  }

  openCollabsModal(collaborator) {
    var collaboratorId = collaborator ? collaborator._id : null;

    this.setState({
      modal: (
        <CollaboratorsModal
          close={() => this.closeModal()}
          collaboratorId={collaboratorId}
          onCollaboratorCreated={(collaborator) =>
            this.onCollaboratorCreated(collaborator)
          }
        />
      ),
    });
  }

  openConfModal(user) {
    let content = (
      <div>
        <p>
          <FormattedMessage id="User.Remove.Confirmation1" />
        </p>
        <p>
          <FormattedMessage
            id="User.Remove.Confirmation2"
            values={{ name: `${user.first_name} ${user.name}` }}
          />
        </p>
      </div>
    );

    this.setState({
      modal: (
        <ConfirmationModal
          context={"danger"}
          title={<FormattedMessage id="Warning" />}
          mandatoryConfirmation
          content={content}
          successCallback={() =>
            this.props.onRemoveCollaborators({ collaboratorId: user._id })
          }
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  onCollaboratorCreated(collaborator) {
    this.setState({ modal: null }, () => this.openCollabsModal(collaborator));
  }

  roleBadge(roleId) {
    let badgeClass = "success";

    switch (roleId) {
      case Roles.ADMIN:
      case Roles.SUPER_ADMIN:
        badgeClass = "danger";
        break;
      default:
        break;
    }

    return (
      <div className={"badge badge-" + badgeClass}>
        <FormattedMessage id={"Role." + roleId} />
      </div>
    );
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    if (this.props.collaborators.length === 0) {
      return (
        <React.Fragment>
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">Recherche</h5> */}
                  <div className="form-inline">
                    <div className="alert alert-info w-100">
                      <FormattedMessage id="Empty.Collaborators" />
                    </div>
                    <button
                      className="btn btn-info ml-auto"
                      onClick={(e) => this.openCollabsModal()}
                    >
                      <FormattedMessage id="Add.Collaborator" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.modal}
        </React.Fragment>
      );
    }

    this.paginator.init();
    var collaboratorsNode = this.props.collaborators
      .filter((collaborator) => collaborator._id !== this.props.user._id)
      .map((collaborator) => {
        if (!this.paginator.keep()) return null;

        var prescribersNbr = 0;
        if (collaborator.prescribers)
          prescribersNbr = collaborator.prescribers.length;

        if (collaborator.prescribers) {
          var collaboratorCLientsId = [];

          for (var client of this.props.clients) {
            for (var prescriber of collaborator.prescribers) {
              if (client._id === prescriber.prescriber_id)
                collaboratorCLientsId.push(client);
            }
          }
        }

        return (
          <tr key={collaborator._id}>
            <td className="text-uppercase">{collaborator.name}</td>
            <td className="text-capitalize">{collaborator.first_name}</td>
            <td>{collaborator.username}</td>
            <td>{this.roleBadge(collaborator.role)}</td>
            <td className="d-md-table-cell">{prescribersNbr}</td>
            <td className="text-center tdaction">
              {collaborator.role !== Roles.ADMIN &&
                collaborator.role !== Roles.SUPER_ADMIN && (
                  <div>
                    <i
                      id={"modify" + collaborator._id}
                      className="fa fa-edit icon-big"
                      onClick={() => this.openCollabsModal(collaborator)}
                    />
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"modify" + collaborator._id}
                    >
                      <FormattedMessage id="Modify" />
                    </UncontrolledTooltip>

                    <i
                      id={"delete" + collaborator._id}
                      className="fa fa-trash icon-big"
                      onClick={() => this.openConfModal(collaborator)}
                    />
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"delete" + collaborator._id}
                    >
                      <FormattedMessage id="Delete" />
                    </UncontrolledTooltip>
                  </div>
                )}
            </td>
          </tr>
        );
      });

    return (
      <React.Fragment>
        <Tabs
          defaultActiveKey="collaborators"
          id="tab-collaborators"
          className="mb-3"
        >
          <Tab eventKey="collaborators" title={<FormattedMessage id="List" />}>
            {!this.props.limit && (
              <div className="row search-filters">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      {/* <h5 className="card-title">Recherche</h5> */}
                      <div className="form-inline">
                        <button
                          className="btn btn-info ml-auto"
                          onClick={(e) => this.openCollabsModal()}
                        >
                          <FormattedMessage id="Add.Collaborator" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <table className="table tablee4mad mt-3">
              <thead>
                <tr>
                  <th scope="col">
                    <FormattedMessage id="Name" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="First.Name" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="Username" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="Role" />
                  </th>
                  <th scope="col" className="d-md-table-cell">
                    <FormattedMessage id="Clients.Nbr" />
                  </th>
                  <th scope="col" className="text-center">
                    <FormattedMessage id="Actions" />
                  </th>
                </tr>
              </thead>
              <tbody>{collaboratorsNode}</tbody>
            </table>

            {this.paginator.render()}

            {this.state.modal}
          </Tab>
          <Tab
            eventKey="collaborators-type"
            title={<FormattedMessage id="Collaborators.Types" />}
          >
            <CollaboratorTypes collaborators={this.props.collaborators} />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborators: state.collaborators,
    products: state.products,
    oldProducts: state.oldProducts,
    orders: state.orders,
    clients: state.clients,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOldProducts: (data) => dispatch(getOldProducts(data)),
    onRemoveCollaborators: (data) => dispatch(removeCollaborator(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Collaborators);
