import React from "react";
import Orders from "../orders/Orders";
import Estimates from "../estimates/Estimates";
// import FleetSummary from "../fleet/FleetSummary";
// import Requests from '../requests/Requests';
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Card, CardTitle } from "reactstrap";
import Patients from "../../components/patients/Patients";

class SplitHome extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="m-2 zoom-7">
              <Card className="mw-100 p-3 mt-5">
                <CardTitle>
                  <h4 className="text-info">
                    <FormattedMessage id="Summary.Patients.Monitoring" />
                  </h4>
                </CardTitle>
                <Patients limit={5} />
                <Link to="/home/patients" className="overlay text-center">
                  <i className="fa fa-search"></i>
                </Link>
              </Card>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7">
              <Card className="mw-100 p-3 mt-5">
                <CardTitle>
                  <h4 className="text-info">
                    <FormattedMessage id="Summary.Orders" />
                  </h4>
                </CardTitle>
                <Orders limit={5} />
                <Link to="/home/orders" className="overlay text-center">
                  <i className="fa fa-search"></i>
                </Link>
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7">
              <Card className="mw-100 p-3 mt-5">
                <CardTitle>
                  <h4 className="text-info">
                    <FormattedMessage id="Summary.Estimates" />
                  </h4>
                </CardTitle>
                <Estimates limit={5} />
                <Link to="/home/estimates" className="overlay text-center">
                  <i className="fa fa-search"></i>
                </Link>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    equipments: state.equipments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitHome);
