import React from "react";
import { connect } from "react-redux";
import PDFUtil from "../../../util/PDFUtil";
import DateUtil from "../../../util/DateUtil";
import Effects from "../../../util/Effects";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderProducts from "../OrderProducts";
import { UncontrolledTooltip } from "reactstrap";
import OrderPDF from "../OrderPDF";
import PDFViewer from "../../pdf/PDFViewer";
import OrderStatus from "../../../enums/OrderStatus";
import {
  deleteOrderAdmin,
  updateAdmin,
  duplicateAdmin,
} from "../../../actions/orders/orders";
import "../../../css/orders/orders.css";
import Maths from "../../../util/Maths";

class OrderRowAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      displayPDF: false,
      disableExport: false,
      disabledDuplicat: false,
    };
    // List of products we have already attempted to fetch from BE
    this.alreadySent = {};

    // Enabled to know if the order contains outdated products or not
    this.outdated = false;
  }

  collapse(elemId) {
    this.setState({
      collapsed: Effects.collapse(elemId),
    });
  }

  deleteOrderAdmin(e, orderId) {
    var data = { orderId: orderId };
    this.props.onDeleteOrderAdmin(data);
  }

  togglePDF(pdfElemId, pdfFileName) {
    if (this.state.disableExport) return;
    if (process.env.REACT_APP_PDF_OVERVIEW === "true") {
      // Possibility n°1 (dev env) : display preview of PDF (without header, footer, pages etc.)
      this.setState({ displayPDF: !this.state.displayPDF });
    } else {
      // Possibility n°2 (prod env) : directly save to PDF
      this.setState({ disableExport: true });
      var successCallback = () => this.setState({ disableExport: false });
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        pdfElemId,
        pdfFileName,
        "/css/orderPdf.css",
        successCallback
      );
    }
  }

  duplicatOrderAdmin(orderId) {
    if (this.state.disabledDuplicat) return;

    this.setState({ disabledDuplicat: true });

    var successCallback = () => this.setState({ disabledDuplicat: false });

    this.props.onDuplicateAdmin(orderId, successCallback);
  }

  updateStatus(e, order) {
    var data = {
      updatedValue: e.target.value,
      updatedField: "status",
      orderId: order._id,
    };

    this.props.onUpdateAdmin(data);
  }

  render() {
    if (!this.props.client.username) return null;

    let isUrgent = this.props.order.urgent_costs > 0;
    let orderHasProducts = this.props.order.products.length > 0;

    // 'Delete order' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = <FormattedMessage id="Order.Remove.Confirmation" />;
    var successCallback = (e) => this.deleteOrderAdmin(e, this.props.order._id);

    let pdfElemId = "order-pdf-" + this.props.order._id;
    let pdfFileName = "order-" + this.props.order._id;
    let pdfOrder = null;

    if (orderHasProducts) {
      pdfOrder = (
        <OrderPDF
          id={pdfElemId}
          order={this.props.order}
          admin={this.props.admin}
          establishment={this.props.establishment}
          client={this.props.client}
        />
      );
    }

    var statusNode = Object.values(OrderStatus).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "Order.Status." + option })}
        </option>
      );
    });

    let statusClassName = "light";
    if (orderHasProducts) {
      // if (this.props.request.request_type === RequestTypes.NEW_MATERIAL) {
      if (parseInt(this.props.order.status) === 0) statusClassName = "danger";
      if (parseInt(this.props.order.status) === 1) statusClassName = "info";
      else if (parseInt(this.props.order.status) === 2)
        statusClassName = "success";
      else if (parseInt(this.props.order.status) === 4)
        statusClassName = "warning";
      //}
    }

    return (
      <React.Fragment>
        <tr
          key={this.props.order._id}
          className={!orderHasProducts ? "font-italic text-muted" : ""}
        >
          <td className="align-middle">
            {this.props.client.name.toUpperCase()}{" "}
            {this.props.client.first_name}
            {/* <div className="d-md-none text-muted mt-2">
                            <FormattedMessage id="Total" /> <FormattedMessage id="Incl.Tax" />&nbsp;: {productsTotalUtil.totalTtc}€
                        </div> */}
          </td>
          <td className="align-middle">
            {this.props.order.ref}
            {this.props.order.comment && this.props.comment !== "" && (
              <span>
                <i
                  id={"comment" + this.props.order._id}
                  className="fa fa-commenting icon-big float-right text-info"
                  disabled
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"comment" + this.props.order._id}
                >
                  <FormattedMessage id="Available.Comment" /> :{" "}
                  {this.props.order.comment}
                </UncontrolledTooltip>
              </span>
            )}
          </td>
          <td className="align-middle">
            {DateUtil.toDateWithHour(this.props.order.creation_date)}
          </td>
          <td className="align-middle">
            {DateUtil.toDate(
              new Date(this.props.order.creation_date).getTime() +
                this.props.order.shipping_delay * 24 * 3600 * 1000
            )}
          </td>
          <td className="align-middle">
            {Maths.round(this.props.order.total_ht)}€
          </td>
          <td className="align-middle">
            <select
              className={"form-control alert-" + statusClassName}
              onChange={(e) => this.updateStatus(e, this.props.order)}
              defaultValue={this.props.order.status}
              disabled={!orderHasProducts}
            >
              {statusNode}
            </select>
          </td>
          {!this.props.limit && (
            <td className="text-center align-middle tdaction">
              <i
                id={"collapse" + this.props.order._id}
                className={
                  !this.state.collapsed
                    ? "fa fa-eye-slash icon-big "
                    : "fa fa-eye icon-big "
                }
                onClick={(e) => {
                  if (orderHasProducts)
                    this.collapse("prods-" + this.props.order._id);
                }}
                disabled={!orderHasProducts}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"collapse" + this.props.order._id}
              >
                {!this.state.collapsed ? (
                  <FormattedMessage id="Display.Product.Order" />
                ) : (
                  <FormattedMessage id="Hide.Product.Order" />
                )}
              </UncontrolledTooltip>
              <i
                id={"split" + this.props.order._id}
                className="fa fa-cut icon-big"
                onClick={(e) => {
                  if (orderHasProducts) this.props.openSplitModal(this.props.order);
                }}
                disabled={!orderHasProducts}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"split" + this.props.order._id}
              >
                <FormattedMessage id="Split.Order" />
              </UncontrolledTooltip>
              <i
                id={"downloadPdf" + this.props.order._id}
                className="fa fa-file-pdf-o icon-big"
                onClick={(e) => {
                  if (orderHasProducts) this.togglePDF(pdfElemId, pdfFileName);
                }}
                disabled={this.state.disableExport || !orderHasProducts}
              ></i>
              {this.state.disableExport || !orderHasProducts ? (
                ""
              ) : (
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"downloadPdf" + this.props.order._id}
                >
                  <FormattedMessage id="Download.PDF" />
                </UncontrolledTooltip>
              )}
              {
                <PDFViewer
                  open={this.state.displayPDF}
                  toggle={(pdfElemId, pdfFileName) => {
                    if (orderHasProducts) this.togglePDF(pdfElemId, pdfFileName);
                  }}
                  template={pdfOrder}
                  fileName={pdfFileName}
                />
              }
              {/* <i id={"duplicate-btn" + this.props.order._id} className="fa fa-files-o icon-big" onClick={(e) => {if(!hasMissingData && !hasOldData) this.duplicatOrderAdmin({orderId: order._id})}} disabled={hasMissingData || hasOldData || this.state.disabledDuplicat}></i>
                        {(hasOldData && !hasMissingData) && <UncontrolledTooltip placement="top" target={"duplicate-btn" + this.props.order._id}><FormattedMessage id="Order.ToolTip.NoDuplicate" /></UncontrolledTooltip>} */}
              <i
                id={"delete" + this.props.order._id}
                className="fa fa-trash icon-big"
                onClick={(e) => {
                  if (orderHasProducts)
                    this.props.openConfModal(
                      modalTitle,
                      modalContent,
                      successCallback
                    );
                }}
                disabled={!orderHasProducts}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"delete" + this.props.order._id}
              >
                <FormattedMessage id="Delete" />
              </UncontrolledTooltip>
            </td>
          )}
          <td className="text-center align-middle">
            {orderHasProducts && isUrgent && (
              <i
                className={
                  "fa fa-exclamation-triangle fa-lg static text-danger"
                }
              ></i>
            )}
          </td>
        </tr>

        <tr>
          <td colSpan="8" className="p-1">
            <div
              id={"prods-" + this.props.order._id}
              className="table-visible-blue"
            >
              {orderHasProducts && (
                <OrderProducts order={this.props.order} paginate={true} />
              )}
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    generalSettings: state.generalSettings,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteOrderAdmin: (data) => dispatch(deleteOrderAdmin(data)),
    onUpdateAdmin: (data) => dispatch(updateAdmin(data)),
    onDuplicateAdmin: (data, successCallback) =>
      dispatch(duplicateAdmin(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OrderRowAdmin));
