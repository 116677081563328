import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import AddClientModal from "./AddClientModal";
import Paginator from "../sub/Paginator";
import ClientRow from "./ClientRow";
import Roles from "../../enums/Roles";
import Util from "../../util/Util";
import { UncontrolledTooltip } from "reactstrap";

class Clients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,

      // filters
      nameFilter: "",
      typeFilter: "",
      addressFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  openModal(client) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <AddClientModal
            isOpen={true}
            clientId={client ? client._id : null}
            openModal={(client) => this.openModal(client)}
            close={() => this.closeModal()}
          />
        ),
      })
    );
  }

  closeModal() {
    this.setState({ modal: null });
  }

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.nameFilter) ||
      !Util.emptyString(this.state.typeFilter) ||
      !Util.emptyString(this.state.addressFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      nameFilter: "",
      typeFilter: "",
      addressFilter: "",
    });
  }

  render() {
    const { clients, user } = this.props;

    /*
        var clientsTypes = this.props.client_type.map(client => {
            return <option key={client._id} value={client._id}>{client.client_type}</option>
        });
        */

    var clientsLabels = this.props.clientTypes.map((client) => {
      return (
        <option key={client._id} value={client._id}>
          {client.name}
        </option>
      );
    });
    // No clients
    if ((!clients || clients.length === 0) && user.role === Roles.ADMIN) {
      return (
        <React.Fragment>
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">Recherche</h5> */}
                  <div className="form-inline">
                    <div className="alert alert-info w-100">
                      <FormattedMessage id="Empty.Client" />
                    </div>
                    <button
                      className="btn btn-info ml-auto"
                      onClick={(e) => this.openModal()}
                    >
                      <FormattedMessage id="Client.Add" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.modal}
        </React.Fragment>
      );
    } else if (
      (!clients || clients.length === 0) &&
      user.role === Roles.SALES_REP
    ) {
      return (
        <div className="alert alert-secondary mt-3" role="alert">
          <FormattedMessage id="Empty.Client.Collaborator" />
        </div>
      );
    }

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;
    let clientsNode = clients.map((client) => {
      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (
          client.name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1 &&
          client.first_name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (this.state.client_typeFilter && this.state.client_typeFilter !== "") {
        /**
         * Important: Check if client.type is defined before trying to filter to avoid
         * "TypeError: Cannot read property 'indexOf' of undefined"
         */
        if (
          typeof client.client_type === "undefined" ||
          client.client_type.indexOf(this.state.client_typeFilter) === -1
        )
          return null;
      }

      if (this.state.typeFilter && this.state.typeFilter !== "") {
        /**
         * Important: Check if client.type is defined before trying to filter to avoid
         * "TypeError: Cannot read property 'indexOf' of undefined"
         */
        if (
          typeof client.type === "undefined" ||
          client.type.indexOf(this.state.typeFilter) === -1
        )
          return null;
      }

      if (this.state.addressFilter && this.state.addressFilter !== "") {
        if (
          (!client.address ||
            client.address
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!client.postal_code ||
            client.postal_code
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!client.city ||
            client.city
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1)
        )
          return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <ClientRow
          key={client._id}
          client={client}
          edit={(client) => this.openModal(client)}
          user={user}
          limit={this.props.limit && true}
          // delete={(client) => this.delete(client)}
        />
      );
    });

    // Define a button to reset search filters
    let resetSearchButton = () => {
      let button = (
        <button className="btn btn-outline-secondary" disabled>
          <i className="fa fa-filter"></i>
        </button>
      );

      if (this.areResultsFiltered()) {
        return (
          <>
            <button
              id="searchFilterBtn"
              className="btn btn-warning"
              onClick={() => this.resetSearchFields()}
            >
              <i className="fa fa-filter"></i>
            </button>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="bottom"
              target="searchFilterBtn"
            >
              <FormattedMessage id="Remove.Filter" />
            </UncontrolledTooltip>
          </>
        );
      } else {
        return button;
      }
    };

    return (
      <React.Fragment>
        {!this.props.limit && (
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-inline">
                    <label className="my-1 mr-2" htmlFor="search_client">
                      {this.props.intl.formatMessage({ id: "Client" })}
                    </label>
                    <input
                      id="search_client"
                      type="text"
                      className="form-control mr-sm-3"
                      placeholder={
                        this.props.intl.formatMessage({ id: "Name" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "Or" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "First.Name" })
                      }
                      onChange={(e) => {
                        this.setState({ nameFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.nameFilter}
                    />

                    <label className="my-1 mr-2" htmlFor="search_type">
                      {this.props.intl.formatMessage({ id: "Type" })}
                    </label>
                    <select
                      id="search_type"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ client_typeFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.client_typeFilter}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      <option value="helper">
                        {this.props.intl.formatMessage({ id: "Client.Helper" })}
                      </option>
                      <option value="prescriber">
                        {this.props.intl.formatMessage({
                          id: "Client.Prescriber",
                        })}
                      </option>
                    </select>

                    <label className="my-1 mr-2" htmlFor="search_type">
                      {this.props.intl.formatMessage({ id: "Label" })}
                    </label>
                    <select
                      id="search_type"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ typeFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.typeFilter}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      {clientsLabels}
                    </select>

                    <label className="my-1 mr-2" htmlFor="search_address">
                      {this.props.intl.formatMessage({ id: "Address" })}
                    </label>
                    <input
                      id="adress"
                      type="text"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ addressFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.addressFilter}
                    />
                    {resetSearchButton()}
                    {user.role === Roles.ADMIN && (
                      <button
                        className="btn btn-info ml-auto"
                        onClick={(e) => this.openModal()}
                      >
                        <FormattedMessage id="Client.Add" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <table className="table tablee4mad">
          {clients.length > 0 && (
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="Name" /> <FormattedMessage id="And" />{" "}
                  <FormattedMessage id="First.Name" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Type" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Label" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Address" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Nbr.Patients" />
                </th>
                {(this.props.user.access_e4pharmamad ||
                  this.props.user.access_e4pharmastore) && (
                  <th scope="col">
                    <FormattedMessage id="Mercurial" />
                  </th>
                )}
                {!this.props.limit && user.role === Roles.ADMIN && (
                  <th scope="col" className="text-center">
                    <FormattedMessage id="Actions" />
                  </th>
                )}
              </tr>
            </thead>
          )}
          <tbody>{clientsNode}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    user: state.user,
    clientTypes: state.clientTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Clients));
