import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addClientType,
  updateClientType,
} from "../../../../actions/settings/clientType/admin/clientType";
import Util from "../../../../util/Util";
import { Button, Modal } from "react-bootstrap";

class ClientTypeModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      disabled: false,
      modal: null,
      nameError: null,
    };

    if (this.props.clientType) {
      this.state = {
        name: this.props.clientType.name,
        disabled: false,
        modal: null,
        nameError: null,
      };
    }
  }

  close() {
    this.props.closeModal();
  }

  onChange(field, value) {
    if (field === "name") {
      if (this.props.clientType && value === this.props.clientType.name) {
        this.setState({ nameError: null });
      } else {
        const alreadyExisting = this.props.clientTypes.find(
          (t) => value === t.name
        );

        if (alreadyExisting) {
          this.setState({
            nameError: <FormattedMessage id="ClientType.Already.Existing" />,
          });
        } else {
          this.setState({ nameError: null });
        }
      }
    }

    this.setState({ [field]: value });
  }

  onSubmit() {
    if (this.checkErrors()) return;

    this.setState({ disabled: true });

    var data = {
      name: this.state.name,
    };

    var successCallback = () => {
      this.props.closeModal();
    };

    this.props.onAddClientType(data, successCallback);
  }

  onUpdate(updatedField, updatedValue) {
    if (!this.props.clientType || this.checkErrors()) return;

    var data = {
      _id: this.props.clientType._id,
      name: updatedValue,
    };

    this.props.onUpdateClientType(data);
  }

  checkErrors() {
    return (
      this.state.nameError ||
      this.state.disabled ||
      Util.emptyString(this.state.name)
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.close()}
            backdrop={"static"}
            size={"xl"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="Add.Client.Type" />
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="row">
                <div className="col-12 col-lg-8">
                  <div className="form-group row">
                    <label
                      htmlFor="name"
                      className="col-12 col-md-4 col-form-label"
                    >
                      <FormattedMessage id="Pharma.Client.client_type" />
                    </label>
                    <div id="name" className="col-12 col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        autoComplete="off"
                        value={this.state.name}
                        onChange={(e) => this.onChange("name", e.target.value)}
                        onBlur={(e) => this.onUpdate("name", this.state.name)}
                      />
                      <small className="text-danger">
                        {this.state.nameError}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>

            {!this.props.clientType && (
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.close()}>
                  <FormattedMessage id="Cancel" />
                </Button>
                <Button
                  variant="info"
                  onClick={() => this.onSubmit()}
                  disabled={this.checkErrors()}
                >
                  <FormattedMessage id="Add" />
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientTypes: state.clientTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddClientType: (data, successCallback) =>
      dispatch(addClientType(data, successCallback)),
    onUpdateClientType: (data) => dispatch(updateClientType(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ClientTypeModalAdmin));
