import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { FormattedMessage } from "react-intl";
import APIUrl from "../../../../APIUrl";
import DocumentModalAdmin from "./DocumentModalAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import { deleteDocument } from "../../../../actions/settings/documentSettings/document";

import { UncontrolledTooltip } from "reactstrap";

class Document extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };

    this.maxDocuments = 10;
  }

  openDocumentModal() {
    this.setState({
      modal: (
        <DocumentModalAdmin
          isOpen={true}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  editDocumentModal(document) {
    this.setState({
      modal: (
        <DocumentModalAdmin
          isOpen={true}
          document={document}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  delete(_id, name) {
    var title = <FormattedMessage id="Confirm" />;
    var content = (
      <FormattedMessage
        id="Document.Type.Remove.Confirmation"
        values={{ name: name }}
      />
    );

    var _confirm = (_id) => {
      let data = {
        documentId: _id,
      };

      // Send to BE
      this.props.onDeleteDocument(data);
    };

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={() => _confirm(_id)}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.documents ||
        this.props.documents.length < this.maxDocuments ? (
          <button
            className="btn btn-info m-auto hoverable"
            onClick={(e) => this.openDocumentModal()}
          >
            <FormattedMessage id="Add.Document" />
          </button>
        ) : null}

        <div>
          <br className="hidden-xs" />
        </div>
        {this.props.documents && this.props.documents.length ? (
          <div>
            <label className="col-form-label float-right">
              {this.props.documents.length} / {this.maxDocuments}{" "}
              <FormattedMessage id="Documents" />
            </label>
          </div>
        ) : null}

        {(!this.props.documents || this.props.documents.length === 0) && (
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Empty.Document.List" />
          </div>
        )}

        {this.props.documents && this.props.documents.length !== 0 && (
          <table className="table tablee4mad">
            <thead>
              <tr className="tablerowhead">
                <th className="d-md-table-cell align-middle">
                  <FormattedMessage id="Document.Date.Create" />
                </th>
                <th className="d-md-table-cell p-3 align-middle">
                  <FormattedMessage id="Title" />
                </th>

                <th className="d-md-table-cell align-middle">
                  <FormattedMessage id="Document.Date.Update" />
                </th>
                <th className="d-md-table-cell align-middle">
                  <FormattedMessage id="Document.Scope" />
                </th>
                <th className="d-md-table-cell align-middle">
                  <FormattedMessage id="Document.Size" />
                </th>
                <th className="d-md-table-cell align-middle float-right mr-2">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.documents.map((t, i) => (
                <tr key={i} className="order-tr order-tr tablelist row-striped">
                  <td className="align-middle text-capitalize">
                    {Moment(t.createdAt).format("DD/MM/YYYY HH:mm")}
                  </td>
                  <td className="align-middle text-capitalize">{t.name}</td>
                  <td className="align-middle text-capitalize">
                    {t.updatedAt
                      ? Moment(t.updatedAt).format("DD/MM/YYYY HH:mm")
                      : "-"}
                  </td>
                  <td className="align-middle text-capitalize">
                    <FormattedMessage id={"Document.Scope." + t.scope} />
                  </td>
                  <td className="align-middle text-capitalize">
                    {t.file_size ? (t.file_size / 1000000).toFixed(2) : "0"}{" "}
                    <FormattedMessage id="Document.Mega" />
                  </td>
                  <td className="float-right tdaction">
                    <a
                      role="button"
                      href={
                        APIUrl.getDocumentFile +
                        t._id +
                        "?token=" +
                        APIUrl.jwtToken
                      }
                      download={t.name + "." + t.file_ext}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        id={"displayFile" + t._id}
                        className="fa fa-download icon-big mr-3"
                        disabled=""
                      ></i>
                      <UncontrolledTooltip
                        delay={{ show: 0, hide: 0 }}
                        placement="top"
                        target={"displayFile" + t._id}
                      >
                        <FormattedMessage id="Document.Download" />{" "}
                        {t.name + "." + t.file_ext}
                      </UncontrolledTooltip>
                    </a>

                    <i
                      id={"modify" + t._id}
                      className="fa fa-edit icon-big  mr-3"
                      onClick={(e) => this.editDocumentModal(t)}
                    ></i>
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"modify" + t._id}
                    >
                      <FormattedMessage id="Modify" />
                    </UncontrolledTooltip>

                    <i
                      id={"delete" + t._id}
                      className="fa fa-trash icon-big"
                      onClick={(e) => this.delete(t._id, t.name)}
                    ></i>
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"delete" + t._id}
                    >
                      <FormattedMessage id="Delete" />
                    </UncontrolledTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documents: state.documents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteDocument: (_id) => dispatch(deleteDocument(_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
