import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  addTypeofControl,
  updateTypeofControl,
} from "../../../actions/settings/deskCategories/admin/deskProducts";
import { Button, Modal } from "react-bootstrap";

class ControlPointModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      modal: null,
      description:
        this.props.index !== undefined
          ? this.props.deskProduct[
              this.props.typeofcontrol === "question"
                ? "relatedQuestions"
                : "relatedRecommendations"
            ][this.props.index]
          : "",
      typeofcontrol: this.props.typeofcontrol,
    };
  }

  close() {
    this.props.closeModal();
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  confirm() {
    // IF this.props.index; > UPDATE

    if (this.props.index !== undefined) {
      let data = {
        deskProductId: this.props.deskProductId,
        type: this.state.typeofcontrol,
        description: this.state.description,
        index: this.props.index,
      };

      this.props.onupdatetypeofControl(data);
    } else {
      let data = {
        deskProductId: this.props.deskProductId,
        type: this.state.typeofcontrol,
        description: this.state.description,
      };

      this.props.onaddtypeofControl(data);
    }

    this.close();
  }

  render() {
    //let sizeClassName = "";

    if (this.props.typeofcontrol === "question") {
      var disabledquest = false;
      var disabledreco = this.props.index !== undefined ? true : false;
    } else {
      disabledreco = false;
      disabledquest = this.props.index !== undefined ? true : false;
    }

    var buttonCancel = <FormattedMessage id="Cancel" />;
    if (this.props.modalButtonCancel)
      buttonCancel = this.props.modalButtonCancel;

    var buttonConfirm = <FormattedMessage id="Confirm" />;
    if (this.props.modalButtonConfirm)
      buttonConfirm = this.props.modalButtonConfirm;

    return (
      <React.Fragment>
        <div className="modal-bg show">
          <Modal show={true} onHide={() => this.close()} backdrop={"static"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="Add.Control.Point" />
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {/* Description */}
              <div className="form-group row">
                <label
                  htmlFor="description"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Description" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="description"
                    autoComplete="off"
                    value={this.state.description}
                    onChange={(e) =>
                      this.onChange("description", e.target.value)
                    }
                    //onBlur={(e) => this.onBlur("description", e.target.value)}
                  />
                </div>
              </div>

              {/* Type */}
              <div className="form-group row">
                <label
                  htmlFor="type"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Type" />
                </label>
                <div className="col-12 col-sm-7">
                  <select
                    className="form-control w-100 selectlist"
                    onChange={(e) =>
                      this.onChange("typeofcontrol", e.target.value)
                    }
                    defaultValue={this.props.typeofcontrol}
                  >
                    <option
                      value="question"
                      defaultValue={
                        this.props.typeofcontrol === "question" ? true : false
                      }
                      disabled={disabledquest}
                    >
                      Question
                    </option>
                    <option
                      value="recommandation"
                      defaultValue={
                        this.props.typeofcontrol === "recommandation"
                          ? true
                          : false
                      }
                      disabled={disabledreco}
                    >
                      Recommandation
                    </option>
                  </select>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.close()}>
                {buttonCancel}
              </Button>
              <Button
                variant="info"
                onClick={() => this.confirm()}
                disabled={this.state.disabled}
              >
                {buttonConfirm}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onaddtypeofControl: (data) => dispatch(addTypeofControl(data)),
    onupdatetypeofControl: (data) => dispatch(updateTypeofControl(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlPointModal);
