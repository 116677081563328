import { combineReducers } from "redux";
import user from "./user/userReducer";
import products from "./products/productsReducer";
import oldProducts from "./products/oldProductsReducer";
import cart from "./cart/cartReducer";
import i18n from "./i18n/i18nReducer";
import orders from "./orders/ordersReducer";
import clients from "./clients/clientsReducer";
import establishments from "./establishments/establishmentsReducer";
import ordersSettingsAdmin from "./settings/ordersSettings/admin/ordersSettingsAdminReducer";
import ordersSettings from "./settings/ordersSettings/ordersSettingsReducer";
import notifications from "./notifications/notificationsReducer";
import mercurials from "./mercurials/mercurialsReducer";
import collaboratorTypes from "./configurables/collaboratorTypesReducer";
import paymentMethods from "./configurables/paymentMethodsReducer";
import paymentDeadlines from "./configurables/paymentDeadlinesReducer";
import establishmentsSettings from "./establishments/establishmentsSettingsReducer";
import establishmentSettings from "./establishments/establishmentSettingsReducer";
import requests from "./requests/requestsReducer";
import patients from "./patients/patientsReducer";
import collaborators from "./collaborators/collabotarorsReducer";
import establishment from "./establishment/establishmentReducer";
import admin from "./admin/adminReducer";
import generalSettings from "./settings/generalSettings/generalSettingsReducer";

import auditRooms from "./settings/auditRooms/auditRoomsReducer";

import auditDMs from "./settings/auditDMs/auditDMsReducer";
import url from "./authentication/authentication";
import rgpd from "./rgpd/rgpdReducer";
import clientTypes from "./settings/clientType/clientTypeReducer";
import auditEquipments from "./auditEquipments/auditEquipmentsReducer";
import auditSettings from "./settings/auditSettings/auditSettingsReducer";
import auditDeskSettings from "./settings/auditSettings/auditDeskSettingsReducer";
import audits from "./audits/auditsReducer";
import { LOGOUT } from "../actions/authentication/authentication";
import company from "./company/companyReducer";
import promoOperations from "./promoOperations/promoOperationsReducer";
import { resetOldProducts } from "../actions/products/oldProducts";
import { resetEquipments } from "../actions/equipments/equipments";

import deskCategories from "./settings/deskCategory/deskCategoryReducer";
import deskProducts from "./settings/deskProduct/deskProductReducer";

import documents from "./settings/documentSettings/documentReducer";

const appReducer = combineReducers({
  user: user,
  products: products,
  oldProducts: oldProducts,
  cart: cart,
  i18n: i18n,
  orders: orders,
  clients: clients,
  establishments: establishments,
  establishmentsSettings: establishmentsSettings,
  establishmentSettings: establishmentSettings,
  ordersSettings: ordersSettings,
  ordersSettingsAdmin: ordersSettingsAdmin,
  mercurials: mercurials,
  notifications: notifications,
  paymentMethods: paymentMethods,
  paymentDeadlines: paymentDeadlines,
  requests: requests,
  collaboratorTypes: collaboratorTypes,
  collaborators: collaborators,
  establishment: establishment,
  admin: admin,
  generalSettings: generalSettings,
  patients: patients,
  auditDMs: auditDMs,
  auditRooms: auditRooms,
  url: url,
  clientTypes: clientTypes,
  auditEquipments: auditEquipments,
  auditSettings: auditSettings,
  auditDeskSettings: auditDeskSettings,
  audits: audits,
  promoOperations: promoOperations,
  company: company,
  rgpd: rgpd,
  deskCategories: deskCategories,
  deskProducts: deskProducts,
  documents: documents,
});

const urlReducer = (state, action) => {
  if (action.type === LOGOUT) {
    // Wipe all redux state
    state = undefined;

    // Call other reset functions that might need a clean state
    resetOldProducts();
    resetEquipments();
  }

  return appReducer(state, action);
};

export default urlReducer;
