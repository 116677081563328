import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ClientTypeModalAdmin from "./ClientTypeModalAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import {
  deleteClientType,
  updateClientType,
} from "../../../../actions/settings/clientType/admin/clientType";
import { UncontrolledTooltip } from "reactstrap";

class ClientTypes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openClientTypeModal() {
    this.setState({
      modal: (
        <ClientTypeModalAdmin
          isOpen={true}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openEditModal(clientType) {
    this.setState({
      modal: (
        <ClientTypeModalAdmin
          isOpen={true}
          closeModal={() => this.closeModal()}
          clientType={clientType}
        />
      ),
    });
  }

  delete(_id, name) {
    var title = <FormattedMessage id="Confirm" />;
    var content = (
      <FormattedMessage
        id="Client.Type.Remove.Confirmation"
        values={{ name: name }}
      />
    );

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={() => this.props.onDeleteClientType(_id)}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-info m-auto hoverable"
          onClick={(e) => this.openClientTypeModal()}
        >
          <FormattedMessage id="Add.Client.Type" />
        </button>
        <div>
          {/* Br */}
          <br className="hidden-xs" />
        </div>
        {(!this.props.clientTypes || this.props.clientTypes.length === 0) && (
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Empty.Client.Types" />
          </div>
        )}

        {this.props.clientTypes && this.props.clientTypes.length !== 0 && (
          <table className="table tablee4mad">
            <thead>
              <tr className="tablerowhead">
                <th className="d-md-table-cell p-3 align-middle">
                  <FormattedMessage id="Client.Type.Label" />{" "}
                </th>
                <th className="d-md-table-cell align-middle float-right mr-2">
                  <FormattedMessage id="Actions" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.clientTypes.map((t, i) => (
                <tr key={i} className="order-tr order-tr tablelist row-striped">
                  <td className="align-middle text-capitalize">{t.name}</td>
                  <td className="float-right tdaction">
                    <i
                      id={"modify" + t._id}
                      className="fa fa-edit icon-big"
                      onClick={(e) => this.openEditModal(t)}
                    ></i>
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"modify" + t._id}
                    >
                      <FormattedMessage id="Modify" />
                    </UncontrolledTooltip>
                    <i
                      id={"delete" + t._id}
                      className="fa fa-trash icon-big"
                      onClick={(e) => this.delete(t._id, t.name)}
                    ></i>
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"delete" + t._id}
                    >
                      <FormattedMessage id="Delete" />
                    </UncontrolledTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientTypes: state.clientTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteClientType: (_id) => dispatch(deleteClientType(_id)),
    onUpdateClientType: (clientTypes) =>
      dispatch(updateClientType(clientTypes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientTypes);
