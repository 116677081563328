import React from "react";
import { connect } from "react-redux";
import PDFUtil from "../../util/PDFUtil";
import DateUtil from "../../util/DateUtil";
import Effects from "../../util/Effects";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderProducts from "./OrderProducts";
import { UncontrolledTooltip } from "reactstrap";
import { NotificationManager } from "react-notifications";
import OrderPDF from "./OrderPDF";
import PDFViewer from "../pdf/PDFViewer";
import OrderStatus from "../../enums/OrderStatus";
import { deleteOrder, duplicate } from "../../actions/orders/orders";
import "../../css/orders/orders.css";
import "react-notifications/lib/notifications.css";
import Maths from "../../util/Maths";

class OrderRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      displayPDF: false,
      disableExport: false,
      disabledDuplicat: false,
    };

    // List of products we have already attempted to fetch from BE
    this.alreadySent = {};

    // Enabled to know if the order contains outdated products or not
    this.outdated = false;
  }

  collapse(elemId) {
    this.setState({
      collapsed: Effects.collapse(elemId),
    });
  }

  deleteOrder(e, orderId, role) {
    var data = { orderId: orderId };
    this.props.onDeleteOrder(data);
  }

  hasUrgent(products) {
    for (var product of products) {
      if (product.urgent) return true;
    }

    return false;
  }

  canPDF(order) {
    return (
      order.status === OrderStatus.PENDING ||
      order.status === OrderStatus.SENT ||
      order.status === OrderStatus.NEW ||
      order.status === OrderStatus.BLOCKED
    ); //|| order.status === Role.RECEIVED
  }

  togglePDF(pdfElemId, pdfFileName) {
    if (this.state.disableExport || !this.canPDF(this.props.order)) return;
    if (process.env.REACT_APP_PDF_OVERVIEW === "true") {
      // Possibility n°1 (dev env) : display preview of PDF (without header, footer, pages etc.)
      this.setState({ displayPDF: !this.state.displayPDF });
    } else {
      // Possibility n°2 (prod env) : directly save to PDF
      this.setState({ disableExport: true });
      var successCallback = () => this.setState({ disableExport: false });
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        pdfElemId,
        pdfFileName,
        "/css/orderPdf.css",
        successCallback
      );
    }
  }

  duplicate(orderId) {
    if (this.state.disabledDuplicat) return;

    this.setState({ disabledDuplicat: true });

    var successCallback = () => {
      NotificationManager.info(
        this.props.intl.formatMessage({ id: "Cart.duplicate" })
      );
      this.setState({ disabledDuplicat: false });
    };

    this.props.onDuplicate(orderId, successCallback);
  }

  // validate(e, order){
  //     e.target.disabled = true;

  //     this.props.onValidate(order._id);
  // }

  getClient(order) {
    for (let client of this.props.clients) {
      if (client._id === order.id_client)
        return client.first_name + " " + client.name;
    }
  }

  render() {
    // 'Delete order' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = <FormattedMessage id="Order.Remove.Confirmation" />;
    var successCallback = (e) => this.deleteOrder(e, this.props.order._id);

    let pdfElemId = "order-pdf-" + this.props.order._id;
    let pdfFileName = "order-" + this.props.order._id;
    let pdfOrder = null;
    pdfOrder = (
      <OrderPDF
        id={pdfElemId}
        order={this.props.order}
        admin={this.props.admin}
        client={this.props.user}
        establishment={this.props.establishment}
      />
    );

    var orderStatusMessage = (
      <FormattedMessage id={"Order.Status." + this.props.order.status} />
    );

    let orderStatusClassName;

    switch (this.props.order.status) {
      case 1:
        orderStatusClassName = "info";
        break;
      case 2:
        orderStatusClassName = "success";
        break;
      case 4:
        orderStatusClassName = "warning";
        break;
      case 10:
      default:
        orderStatusClassName = "danger";
        break;
    }

    if (
      this.props.order.status ===
      OrderStatus.TBV /*&& this.props.user.validator*/
    ) {
      //orderStatusMessage = (
      // <button className="btn btn-outline-primary e4madColors" type="submit"
      //     onClick={(e) => this.validate(e, this.props.order)}
      //     onMouseEnter={(e) => e.target.innerHTML = this.props.intl.formatMessage({ id: "Validate" })}
      //     onMouseLeave={(e) => e.target.innerHTML = this.props.intl.formatMessage({ id: "Order.Status." + this.props.order.status })}
      // >
      //     <FormattedMessage id={"Order.Status." + this.props.order.status} />
      // </button>
      //<FormattedMessage id={"Order.Status." + this.props.order.status} />
      //);
    }

    return (
      <React.Fragment>
        <tr key={this.props.order._id} className={"d-flex"}>
          {/* <td className="d-none d-md-table-cell mw-200 align-middle">{this.getClient(this.props.order)}</td> */}
          <td className="col align-middle">
            {this.props.order.ref}
            {this.props.order.comment && this.props.comment !== "" && (
              <span>
                <i
                  id={"comment" + this.props.order._id}
                  className="fa fa-commenting icon-big float-right text-info"
                  disabled
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"comment" + this.props.order._id}
                >
                  <FormattedMessage id="Available.Comment" /> :<br />
                  {this.props.order.comment}
                </UncontrolledTooltip>
              </span>
            )}
          </td>
          <td className="col align-middle">
            {DateUtil.toDateWithHour(this.props.order.creation_date)}
          </td>
          <td className="col align-middle">
            {DateUtil.toDate(
              new Date(this.props.order.creation_date).getTime() +
                this.props.order.shipping_delay * 24 * 3600 * 1000
            )}
          </td>
          <td className="col col-1 align-middle">
            {/* <div><FormattedMessage id="Excl.Tax" />: {productsTotalUtil.totalHt}€</div> */}
            {Maths.round(this.props.order.total_ttc)}€
          </td>
          <td
            className={
              !this.props.limit
                ? "col col-2 align-middle"
                : "col col-3 align-middle"
            }
          >
            <div className={"alert alert-" + orderStatusClassName}>
              {orderStatusMessage}
            </div>
          </td>
          {!this.props.limit && (
            <td className="col text-center tdaction">
              <i
                id={"collapse" + this.props.order._id}
                className={
                  !this.state.collapsed
                    ? "fa fa-eye-slash icon-big "
                    : "fa fa-eye icon-big "
                }
                onClick={(e) => {
                  this.collapse("prods-" + this.props.order._id);
                }}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"collapse" + this.props.order._id}
              >
                {this.state.collapsed ? (
                  <FormattedMessage id="Hide.Product.Order" />
                ) : (
                  <FormattedMessage id="Display.Product.Order" />
                )}
              </UncontrolledTooltip>
              <i
                id={"download" + this.props.order._id}
                className="fa fa-file-pdf-o icon-big"
                onClick={(e) => {
                  this.togglePDF(pdfElemId, pdfFileName);
                }}
                disabled={
                  this.state.disableExport || !this.canPDF(this.props.order)
                }
              ></i>
              {this.state.disableExport || !this.canPDF(this.props.order) ? (
                ""
              ) : (
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"download" + this.props.order._id}
                >
                  <FormattedMessage id="Download.Order" />
                </UncontrolledTooltip>
              )}
              {
                <PDFViewer
                  open={this.state.displayPDF}
                  toggle={(pdfElemId, pdfFileName) => {
                    this.togglePDF(pdfElemId, pdfFileName);
                  }}
                  template={pdfOrder}
                  fileName={pdfFileName}
                />
              }
              <i
                id={"duplicate-btn" + this.props.order._id}
                className="fa fa-files-o icon-big"
                onClick={(e) => {
                  this.duplicate({ orderId: this.props.order._id });
                }}
                disabled={
                  this.state.disabledDuplicat ||
                  this.props.order.status === OrderStatus.TBV
                }
              ></i>
              {this.state.disabledDuplicat ||
              this.props.order.status === OrderStatus.TBV ? (
                ""
              ) : (
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"duplicate-btn" + this.props.order._id}
                >
                  <FormattedMessage id="Duplicate.Order.To.New.Order" />
                </UncontrolledTooltip>
              )}
              {/* {(hasOldData && !hasMissingData) && <UncontrolledTooltip placement="top" target={"duplicate-btn-" + this.props.order._id + this.random}><FormattedMessage id="Order.ToolTip.NoDuplicate" /></UncontrolledTooltip>} */}
              <i
                id={"delete" + this.props.order._id}
                className="fa fa-trash icon-big"
                onClick={(e) => {
                  this.props.openConfModal(
                    modalTitle,
                    modalContent,
                    successCallback
                  );
                }}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"delete" + this.props.order._id}
              >
                <FormattedMessage id="Delete" />
              </UncontrolledTooltip>
            </td>
          )}
          <td className="col col-1 text-center">
            {this.hasUrgent(this.props.order.products) && (
              <i
                className={
                  "fa fa-exclamation-triangle text-danger icon-big static"
                }
              ></i>
            )}
          </td>
        </tr>

        <tr>
          <td colSpan="7" className="p-1">
            <div
              id={"prods-" + this.props.order._id}
              className="table-visible-blue"
            >
              <OrderProducts order={this.props.order} paginate={true} />
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clients: state.clients,
    generalSettings: state.generalSettings,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteOrder: (data) => dispatch(deleteOrder(data)),
    onDuplicate: (data, successCallback) =>
      dispatch(duplicate(data, successCallback)),
    // onValidate: (orderId) => dispatch(validate(orderId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OrderRow));
