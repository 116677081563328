import {
  GET_CLIENT_TYPES,
  DELETE_CLIENT_TYPE,
} from "../../../actions/settings/clientType/admin/clientType";

export default function clientTypes(state = [], action) {
  switch (action.type) {
    case GET_CLIENT_TYPES:
      return action.clientTypes;
    case DELETE_CLIENT_TYPE:
      return state.filter((t) => t._id !== action.clientTypeId);
    default:
      return state;
  }
}
