import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class PromptModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
    };
  }

  close() {
    if (this.state.disabled) return;

    this.props.closeModal();
  }

  confirm() {
    var callback = async () => {
      await this.props.successCallback();
    };

    this.setState({ disabled: true }, callback);
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal show={true} onHide={() => this.close()} backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <label htmlFor="comment">{this.props.content} :</label>
            <div>
              <textarea
                className="form-control"
                type="text"
                id="comment"
                name="comment"
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.close()}
              disabled={this.state.disabled}
            >
              <FormattedMessage id="No" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.confirm()}
              disabled={this.state.disabled}
            >
              <FormattedMessage id="Yes" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default PromptModal;
