import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import APIUrl from "../../APIUrl";
import { UncontrolledTooltip } from "reactstrap";
import Util from "../../util/Util";
import {
  deleteAudit,
  getAuditsAdmin,
  sendAudit,
} from "../../actions/audits/audits";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import NotificationModal from "../sub/modals/NotificationModal";
import ErrorModal from "../sub/modals/ErrorModal";

import AuditCommentModal from "./AuditCommentModal";
import AuditEmailModal from "./AuditEmailModal";

class PatientAudit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  closeModal() {
    this.setState({ modal: null });
  }

  commentModal(audit) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <AuditCommentModal
            isOpen={true}
            audit={audit ? audit : null}
            close={() => this.closeModal()}
          />
        ),
      })
    );
  }

  emailModal(audit) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <AuditEmailModal
            isOpen={true}
            sendEmails={(emails, documents, audit) =>
              this.sendEmails(emails, documents, audit)
            }
            patient={this.props.patient}
            audit={audit ? audit : null}
            close={() => this.closeModal()}
          />
        ),
      })
    );
  }

  sendEmails(emails, documents, audit) {
    if (emails.length > 0) {
      var data = {
        patientId: this.props.patient._id,
        auditId: audit._id,
      };

      data.emailsToSend = emails;
      if (documents) data.documents = documents;

      if (audit._id && !audit.products) {
        data.auditType = "home";
      } else {
        data.auditType = "desk";
      }

      this.setState({
        disabled: true,
      });

      // Close the modal

      var successCallback = (response) => {
        let title = <FormattedMessage id="Mail.Audit.Sent.Title" />;
        let content = <FormattedMessage id="Mail.Audit.Sent.Content" />;
        if (response.data !== "OK") {
          content = <FormattedMessage id="Mail.Audit.Sent.Content.Error" />;
          this.setState({
            modal: (
              <ErrorModal
                key={this.state.audit_id}
                title={title}
                content={content}
                successCallback={() => {
                  return null;
                }}
                closeModal={() => {
                  this.closeModal();
                }}
              />
            ),
          });
        } else {
          this.setState({
            modal: (
              <NotificationModal
                key={this.state.audit_id}
                title={title}
                content={content}
                successCallback={() => {
                  return null;
                }}
                closeModal={() => {
                  this.closeModal();
                }}
              />
            ),
          });
        }
      };

      //console.log(data);

      this.props.onSendAudit(data, successCallback);
    } else {
      this.props.close();
    }
  }

  getAuditsPatient(audits, patient) {
    return audits.filter((p) => p.patient_id === patient._id);
  }

  // DELETE AN AUDIT

  _deleteAudit(auditType, auditId) {
    const onConfirm = () => {
      let data = {
        auditType: auditType,
        auditId: auditId,
        patienId: this.props.patient._id,
      };

      this.props.onDeleteAudit(data, () => {
        this.props.onGetAuditsAdmin();
      });
    };

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id="Warning" />}
          content={<FormattedMessage id="Confirm.Audit.Removal" />}
          successCallback={onConfirm}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  render() {
    const { audits, patient } = this.props;

    var patientAuditsNode = null;

    if (!patient || !audits) return null;

    const { user } = this.props;
    const { role } = user;

    if (role === undefined) return null;

    let auditsPatient = this.getAuditsPatient(audits, patient);
    let fileNameAudit =
      "audit_" +
      this.props.patient.first_name +
      "_" +
      this.props.patient.name +
      "_";

    if (auditsPatient.length > 0) {
      patientAuditsNode = auditsPatient.map((audit) => {
        // Display which audit type is (Desk, Home)
        var typeAudit = "";
        var domicile = <FormattedMessage id="Creation.Type.Domicile" />;
        var comptoir = <FormattedMessage id="Creation.Type.Comptoir" />;
        if (audit._id && !audit.products) {
          // Before desk db
          typeAudit = domicile;
        } else {
          typeAudit = comptoir;
        }

        var helper = this.props.helpers.find((ele) => {
          return ele._id === audit.helper_id;
        });
        var prescriber = this.props.prescribers.find((ele) => {
          return ele._id === audit.prescriber_id;
        });

        var hasCmt = 0;
        if (audit.private_comments && audit.private_comments.length) {
          audit.private_comments.forEach((cmt) => {
            if (cmt.comment) {
              hasCmt = 1;
            }
          });
        }
        return (
          <tr key={"audit-" + audit._id} className={"order-tr order-tr"}>
            <td className="d-md-table-cell mw-200 align-middle">
              {audit && DateUtil.toDate(audit.createdAt)}
            </td>
            <td className="d-md-table-cell mw-200 align-middle">{typeAudit}</td>
            <td className="d-md-table-cell mw-200 align-middle">
              {helper && helper._id
                ? helper.first_name + " " + helper.name
                : "-"}
            </td>
            <td className="d-md-table-cell mw-200 align-middle">
              {prescriber && prescriber._id
                ? prescriber.first_name + " " + prescriber.name
                : "-"}
            </td>

            {audit._id && !audit.products && hasCmt /* HOME Only  */ ? (
              <td className="d-md-table-cell mw-200 text-center">
                <i
                  id={"comment" + audit._id}
                  className="fa fa-comment icon-big"
                  onClick={(e) => this.commentModal(audit)}
                ></i>
              </td>
            ) : (
              <td className="d-md-table-cell mw-200 text-center">-</td>
            )}

            <td className="d-md-table-cell mw-200 text-center">
              <i
                id={"email" + audit._id}
                className="fa fa-envelope icon-big"
                onClick={(e) => this.emailModal(audit)}
              ></i>
            </td>

            <td className="d-md-table-cell mw-200 text-center">
              <div key={audit._id}>
                <a
                  role="button"
                  href={
                    APIUrl.getAuditsPDF +
                    audit.patient_id +
                    "/" +
                    audit._id +
                    "?token=" +
                    APIUrl.jwtToken
                  }
                  download={
                    fileNameAudit +
                    DateUtil.toyyyyMMdd(audit.createdAt) +
                    ".pdf"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    id={"displayPdf" + audit._id}
                    className="fa fa-download icon-big text-dark"
                    disabled=""
                  ></i>
                  <UncontrolledTooltip
                    delay={{ show: 0, hide: 0 }}
                    placement="top"
                    target={"displayPdf" + audit._id}
                  >
                    <FormattedMessage id="Display.PDF" />
                  </UncontrolledTooltip>
                </a>
              </div>
            </td>
            <td className="d-md-table-cell mw-200 text-center">
              <div key={audit._id}>
                {audit && audit.products ? (
                  <a
                    role="button"
                    href={
                      APIUrl.getAuditsPDF +
                      audit.patient_id +
                      "/cerfa_" +
                      audit._id +
                      "?token=" +
                      APIUrl.jwtToken
                    }
                    download={
                      fileNameAudit +
                      DateUtil.toyyyyMMdd(audit.createdAt) +
                      ".pdf"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      id={"displayPdf" + audit._id}
                      className="fa fa-download icon-big text-dark"
                      disabled=""
                    ></i>
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"displayPdf" + audit._id}
                    >
                      <FormattedMessage id="Display.PDF" />
                    </UncontrolledTooltip>
                  </a>
                ) : (
                  <a
                    role="button"
                    href={
                      APIUrl.getAuditsPDF +
                      audit.patient_id +
                      "/home-cerfa_" +
                      audit._id +
                      "?token=" +
                      APIUrl.jwtToken
                    }
                    download={
                      fileNameAudit +
                      DateUtil.toyyyyMMdd(audit.createdAt) +
                      ".pdf"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      id={"displayCerfa" + audit._id}
                      className="fa fa-download icon-big text-dark"
                      disabled=""
                    ></i>
                    <UncontrolledTooltip
                      delay={{ show: 0, hide: 0 }}
                      placement="top"
                      target={"displayCerfa" + audit._id}
                    >
                      <FormattedMessage id="Display.CERFA" />
                    </UncontrolledTooltip>
                  </a>
                )}
              </div>
            </td>

            <td className="d-md-table-cell mw-200 text-center">
              <i
                id={"delete" + audit._id}
                className="fa fa-trash icon-big"
                onClick={(e) =>
                  this._deleteAudit(
                    audit._id && !audit.products ? "home" : "desk",
                    audit._id
                  )
                }
              ></i>
            </td>
          </tr>
        );
      });
    }

    return (
      <React.Fragment>
        {patient && auditsPatient && auditsPatient.length <= 0 && (
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Client.Empty.Audits" />
          </div>
        )}

        {patient && auditsPatient && auditsPatient.length > 0 && (
          <table className="table tablee4mad mt-3">
            <thead>
              <tr className="tablerowhead">
                <th className="d-md-table-cell">
                  <FormattedMessage id="Creation.Date" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Creation.Type" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Helper.Lead" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Prescriber" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-md-table-cell">
                  <FormattedMessage id="Comment" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell text-center">
                  <FormattedMessage id="Email" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell text-center">
                  <FormattedMessage id="Elements.PDF.Title" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell text-center">
                  <FormattedMessage id="Elements.PDF.Cerfa" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
                <th className="d-none d-md-table-cell text-center">
                  <FormattedMessage id="Delete" />{" "}
                  <i className="fa fa-chevron-down float-right mr-5"></i>
                </th>
              </tr>
            </thead>
            <tbody>{patientAuditsNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    helpers: Util.filterClients(state.clients, "helper"),
    prescribers: Util.filterClients(state.clients, "prescriber"),
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAudit: (data, successCallback) =>
      dispatch(deleteAudit(data, successCallback)),
    onGetAuditsAdmin: (data, successCallback) =>
      dispatch(getAuditsAdmin(data, successCallback)),
    onSendAudit: (data, successCallback) =>
      dispatch(sendAudit(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientAudit));
