import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Util from "../../util/Util";
import { contact } from "../../actions/contacts/contacts";
import SuccessModal from "./SuccessModal";

class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      object: "",
      message: "",
      disabled: false,
      modal: null,
    };
  }

  onChange(key, value) {
    this.setState({
      [key]: value,
      error: null,
    });
  }

  hasEmptyField() {
    return (
      Util.emptyString(this.state.object) ||
      Util.emptyString(this.state.message)
    );
  }

  closeModal() {
    this.setState({
      modal: null,
      object: "",
      message: "",
    });
  }

  send() {
    if (
      Util.emptyString(this.state.object) ||
      Util.emptyString(this.state.message) ||
      this.state.disabled
    )
      return;

    this.setState({ disabled: true });
    var onUpdatePwdSuccess = () => {
      var title = <FormattedMessage id="Success" />;
      var content = <FormattedMessage id="Mail.Sent" />;
      this.setState({
        disabled: false,
        modal: (
          <SuccessModal
            isOpen={true}
            title={title}
            content={content}
            closeModal={() => this.closeModal()}
          />
        ),
      });
    };

    var data = {
      object: this.state.object,
      message: this.state.message,
    };

    this.props.onContact(data, onUpdatePwdSuccess);
  }

  render() {
    if (!this.props.admin) {
      return (
        <div className="alert alert-secondary" role="alert">
          <FormattedMessage id="Have.Not.Admin" />
        </div>
      );
    }

    var commercialSign = false;

    if (
      this.props.generalSettings.commercial_sign ||
      this.props.generalSettings.contact_information ||
      this.props.generalSettings.phone ||
      this.props.generalSettings.email ||
      this.props.generalSettings.website
    ) {
      commercialSign = true;
    }

    return (
      <React.Fragment>
        <div className="row">
          {commercialSign && (
            <div className="col-5 ml-5">
              <div className="form-group row">
                <strong className="col-12">
                  {this.props.generalSettings.commercial_sign}
                </strong>
                <span className="col-12">
                  {this.props.generalSettings.contact_information}
                </span>
                <span className="col-12">
                  {this.props.generalSettings.phone}
                </span>
                <span className="col-12">
                  {this.props.generalSettings.email}
                </span>
                <span className="col-12">
                  {this.props.generalSettings.website}
                </span>
              </div>
            </div>
          )}
          <div className="col-5">
            <div className="form-group row">
              <strong className="col-12">
                <FormattedMessage id="Commercial" />
              </strong>
              <span className="col-12">{this.props.admin.email}</span>
              <span className="col-12">{this.props.admin.phone}</span>
            </div>
            <div className="form-group row">
              <label
                htmlFor="object"
                className="col-12 col-sm-5 col-form-label"
              >
                <FormattedMessage id="Object" />
              </label>
              <div className="col-12 col-sm-7">
                <input
                  className="form-control"
                  type="text"
                  id="object"
                  rows="7"
                  autoComplete="off"
                  value={this.state.object}
                  onChange={(e) => this.onChange("object", e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="message"
                className="col-12 col-sm-5 col-form-label"
              >
                <FormattedMessage id="Message" />
              </label>
              <div className="col-12 col-sm-7">
                <textarea
                  className="form-control"
                  type="text"
                  value={this.state.message}
                  id="message"
                  rows="7"
                  onChange={(e) => this.onChange("message", e.target.value)}
                />
              </div>
            </div>

            <br />
            <br />

            <button
              type="submit"
              className="btn btn-info btn-block col-7 m-auto"
              onClick={(e) => this.send()}
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <FormattedMessage id="Send" />
            </button>
          </div>
        </div>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onContact: (data, successCallback) =>
      dispatch(contact(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
