import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Alert,
} from "react-bootstrap";
import Maths from "../../util/Maths";

const PRICE_MIN_VALUE = 0;

class ProductModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      price: Maths.round(this.props.product.prix_u_ht_emera, 4),
      priceError: null,
      confirmationModal: null,
    };
  }

  onComplete() {
    let updatedProduct = {
      productId: this.props.product._id,
      oldPrice: Maths.round(this.props.product.prix_u_ht_emera, 4),
      price: this.state.price,
    };
    this.props.onComplete(updatedProduct);
  }

  isPriceValid(price) {
    let errorMessage = null;
    if (price.toString().length < 1) {
      errorMessage = <FormattedMessage id="Unit.Price.Excl.Tax.Error.Length" />;
    } else if (isNaN(parseInt(price))) {
      errorMessage = <FormattedMessage id="Unit.Price.Excl.Tax.Error.NaN" />;
    } else if (price < PRICE_MIN_VALUE) {
      errorMessage = (
        <FormattedMessage id="Unit.Price.Excl.Tax.Error.Negative" />
      );
    }
    this.setState({ priceError: errorMessage });
    return errorMessage === null ? true : false;
  }

  updatePrice(price) {
    if (!this.isPriceValid(price)) return;
    this.setState({ price: Maths.round(price, 4) });
  }

  disabled() {
    return (
      this.state.price.length === 0 ||
      this.state.priceError ||
      Maths.round(this.state.price, 4) ===
        Maths.round(this.props.product.prix_u_ht_emera, 4)
    );
  }

  render() {
    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.closeModal()}
          backdrop="static"
          size="lg"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Product.Update" /> (
              <FormattedMessage id="Ref.Short" /> {this.props.product.ref})
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="text-justify">
              <Row>
                <Col md={6}>
                  <p>
                    <FormattedMessage id="Unit.Price.Excl.Tax.Update.Warning.1" />
                  </p>
                  <p>
                    <strong>
                      <FormattedMessage id="Unit.Price.Excl.Tax.Update.Warning.2" />
                    </strong>
                  </p>
                </Col>
                <Col md={6}>
                  <Alert variant="success">
                    <Row>
                      <Col md={5}>
                        <FormattedMessage id="Current.Unit.Price.Excl.Tax.Long" />{" "}
                        :
                      </Col>
                      <Col md={7} className="pl-4 d-flex align-items-center">
                        <strong>
                          {Maths.round(this.props.product.prix_u_ht_emera, 4)}€
                        </strong>
                      </Col>
                    </Row>
                  </Alert>
                  <Alert variant="danger">
                    <Form.Group controlId="validationPrice" className="mb-0">
                      <Row>
                        <Col md={5}>
                          <Form.Label className="mb-0">
                            <FormattedMessage id="New.Unit.Price.Excl.Tax.Long" />{" "}
                            :
                          </Form.Label>
                        </Col>
                        <Col md={7} className="d-flex align-items-center">
                          {/* conflicts with table.css -> input-group */}
                          <InputGroup hasValidation style={{ borderBottom: 0 }}>
                            <Form.Control
                              type="number"
                              min={PRICE_MIN_VALUE}
                              step="any"
                              aria-describedby="inputGroupPrice"
                              defaultValue={this.state.price || 0}
                              onChange={(e) =>
                                this.updatePrice(Maths.round(e.target.value, 4))
                              }
                              onBlur={(e) =>
                                this.isPriceValid(
                                  Maths.round(e.target.value, 4)
                                )
                              }
                              required
                              isInvalid={this.state.priceError}
                            />
                            <InputGroup.Text id="inputGroupPrice">
                              €
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              {this.state.priceError}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Alert>
                </Col>
              </Row>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.props.closeModal()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.onComplete()}
              disabled={this.disabled()}
            >
              <FormattedMessage id="Confirm" />
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.confirmationModal}
      </div>
    );
  }
}

export default injectIntl(ProductModal);
