import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { addPatient, updPatientAdmin } from "../../actions/patients/patients";
import DateUtil from "../../util/DateUtil";
import Roles from "../../enums/Roles";

class PatientGeneralInfo extends React.Component {
  constructor(props) {
    super(props);

    const { patient } = this.props;

    this.isUpdate = !!patient;

    const getValue = (field) => (patient ? patient[field] : "");

    this.state = {
      first_name: getValue("first_name"),
      name: getValue("name"),
      birth_date: getValue("birth_date") || "",
      address: getValue("address"),
      postal_code: getValue("postal_code") || "",
      city: getValue("city"),
      phone: getValue("phone"),
      mobile: getValue("mobile"),
      email: getValue("email"),
      codeCRM: getValue("codeCRM"),

      phoneError: null,
      mobileError: null,
      emailError: null,

      loading: false,
    };
  }

  onSubmit() {
    this.setState({ loading: true });

    const patient = {
      first_name: this.state.first_name,
      name: this.state.name,
      birth_date: this.state.birth_date,
      address: this.state.address,
      postal_code: this.state.postal_code,
      city: this.state.city,
      phone: this.state.phone,
      mobile: this.state.mobile,
      email: this.state.email,
      codeCRM: this.state.codeCRM,
      active: 1,
    };

    // Send to BE
    this.props.onAddPatient(patient, (patient) =>
      this.setState({ loading: false }, () => this.props.openModal(patient))
    );
  }

  disabled() {
    return (
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.first_name) ||
      Util.emptyString(this.state.address) ||
      Util.emptyString(this.state.postal_code) ||
      Util.emptyString(this.state.city) ||
      this.state.phoneError ||
      this.state.mobileError ||
      this.state.emailError ||
      this.state.postal_codeError ||
      this.state.loading
    );
  }

  onChange(field, value) {
    this.setState({ [field]: value });

    if (field === "phone") {
      if (!Util.emptyString(value) && !Util.isPhone(value))
        this.setState({
          phoneError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      else this.setState({ phoneError: null });
    } else if (field === "postal_code") {
      if (!Util.emptyString(value) && !Util.isPostal(value))
        this.setState({
          postal_codeError: (
            <FormattedMessage id="Invalid.Postal.Number.Error" />
          ),
        });
      else this.setState({ postal_codeError: null });
    } else if (field === "mobile") {
      if (!Util.emptyString(value) && !Util.isPhone(value))
        this.setState({
          mobileError: <FormattedMessage id="Invalid.Phone.Number.Error" />,
        });
      else this.setState({ mobileError: null });
    } else if (field === "email") {
      if (!Util.emptyString(value) && !Util.isEmail(value))
        this.setState({
          emailError: <FormattedMessage id="Invalid.Email.Error" />,
        });
      else this.setState({ emailError: null });
    }
  }

  onBlur(field, value) {
    if (!this.isUpdate) return;

    if (field === "phone" && this.state.phoneError) return;
    else if (field === "mobile" && this.state.mobileError) return;
    else if (field === "email" && this.state.emailError) return;
    else if (field === "postal_code" && this.state.postal_codeError) return;
    // Required fields
    else if (field === "name" && Util.emptyString(this.state.name)) return;
    else if (field === "first_name" && Util.emptyString(this.state.first_name))
      return;
    else if (field === "city" && Util.emptyString(this.state.city)) return;
    else if (field === "address" && Util.emptyString(this.state.address))
      return;
    else if (
      field === "postal_code" &&
      Util.emptyString(this.state.postal_code)
    )
      return;

    const patient = {
      _id: this.props.patient._id,
      [field]: value,
    };

    // Send to BE
    this.props.onupdPatientAdmin(patient);
  }

  render() {
    const { user } = this.props;
    const { role } = user;

    if (role === undefined) return null;

    // DateUtil.toyyyyMMdd(this.state.birth_date)
    return (
      <React.Fragment>
        <div className="col-12 col-sm-8">
          {role === Roles.ADMIN && (
            <>
              <div className="form-group row">
                <label
                  htmlFor="first_name"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="First.Name" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="first_name"
                    autoComplete="off"
                    value={this.state.first_name}
                    onChange={(e) =>
                      this.onChange("first_name", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("first_name", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="name"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Name" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={this.state.name}
                    onChange={(e) => this.onChange("name", e.target.value)}
                    onBlur={(e) => this.onBlur("name", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="birth_date"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Birth.Date" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="date"
                    name="birth_date"
                    value={DateUtil.toyyyyMMddPatient(this.state.birth_date)}
                    onChange={(e) =>
                      this.onChange("birth_date", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("birth_date", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="address"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Address" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="address"
                    autoComplete="off"
                    value={this.state.address}
                    onChange={(e) => this.onChange("address", e.target.value)}
                    onBlur={(e) => this.onBlur("address", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="postal_code"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Postal.Code" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="postal_code"
                    autoComplete="off"
                    value={this.state.postal_code}
                    onChange={(e) =>
                      this.onChange("postal_code", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("postal_code", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.postal_codeError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="city"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="City" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="city"
                    autoComplete="off"
                    value={this.state.city}
                    onChange={(e) => this.onChange("city", e.target.value)}
                    onBlur={(e) => this.onBlur("city", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="phone"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Phone" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="phone"
                    autoComplete="off"
                    value={this.state.phone}
                    onChange={(e) => this.onChange("phone", e.target.value)}
                    onBlur={(e) => this.onBlur("phone", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.phoneError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="mobile"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Mobile" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="mobile"
                    autoComplete="off"
                    value={this.state.mobile}
                    onChange={(e) => this.onChange("mobile", e.target.value)}
                    onBlur={(e) => this.onBlur("mobile", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.mobileError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="email"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Email" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="email"
                    autoComplete="off"
                    value={this.state.email}
                    onChange={(e) => this.onChange("email", e.target.value)}
                    onBlur={(e) => this.onBlur("email", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.emailError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="codeCRM"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Code.CRM" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="codeCRM"
                    autoComplete="off"
                    value={this.state.codeCRM}
                    onChange={(e) => this.onChange("codeCRM", e.target.value)}
                    onBlur={(e) => this.onBlur("codeCRM", e.target.value)}
                  />
                </div>
              </div>

              <div className="row mt-5">
                {!this.isUpdate && (
                  <div className="col-12 col-lg-6 offset-lg-3">
                    <button
                      className="btn btn-info btn-block"
                      disabled={this.disabled()}
                      onClick={() => this.onSubmit()}
                    >
                      <FormattedMessage id="Add" />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}

          {role === Roles.CLIENT && (
            <>
              <div className="form-group row">
                <label
                  htmlFor="first_name"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="First.Name" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    id="first_name"
                    autoComplete="off"
                    value={this.state.first_name}
                    onChange={(e) =>
                      this.onChange("first_name", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("first_name", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="name"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Name" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={this.state.name}
                    onChange={(e) => this.onChange("name", e.target.value)}
                    onBlur={(e) => this.onBlur("name", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="birth_date"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Birth.Date" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="date"
                    name="birth_date"
                    value={DateUtil.toyyyyMMdd(this.state.birth_date)}
                    onChange={(e) =>
                      this.onChange("birth_date", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("birth_date", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="address"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Address" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    id="address"
                    autoComplete="off"
                    value={this.state.address}
                    onChange={(e) => this.onChange("address", e.target.value)}
                    onBlur={(e) => this.onBlur("address", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="postal_code"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Postal.Code" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    id="postal_code"
                    autoComplete="off"
                    value={this.state.postal_code}
                    onChange={(e) =>
                      this.onChange("postal_code", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("postal_code", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.postal_codeError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="city"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="City" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    id="city"
                    autoComplete="off"
                    value={this.state.city}
                    onChange={(e) => this.onChange("city", e.target.value)}
                    onBlur={(e) => this.onBlur("city", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="phone"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Phone" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    id="phone"
                    autoComplete="off"
                    value={this.state.phone}
                    onChange={(e) => this.onChange("phone", e.target.value)}
                    onBlur={(e) => this.onBlur("phone", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.phoneError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="mobile"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Mobile" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    id="mobile"
                    autoComplete="off"
                    value={this.state.mobile}
                    onChange={(e) => this.onChange("mobile", e.target.value)}
                    onBlur={(e) => this.onBlur("mobile", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.mobileError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="email"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Email" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    id="email"
                    autoComplete="off"
                    value={this.state.email}
                    onChange={(e) => this.onChange("email", e.target.value)}
                    onBlur={(e) => this.onBlur("email", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.emailError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="codeCRM"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Code.CRM" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    id="codeCRM"
                    autoComplete="off"
                    value={this.state.codeCRM}
                    onChange={(e) => this.onChange("codeCRM", e.target.value)}
                    onBlur={(e) => this.onBlur("codeCRM", e.target.value)}
                  />
                </div>
              </div>

              <div className="row mt-5">
                {!this.isUpdate && (
                  <div className="col-12 col-lg-6 offset-lg-3">
                    <button
                      className="btn btn-info btn-block"
                      disabled={this.disabled()}
                      onClick={() => this.onSubmit()}
                    >
                      <FormattedMessage id="Add" />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}

          {role === Roles.SALES_REP && (
            <>
              <div className="form-group row">
                <label
                  htmlFor="first_name"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="First.Name" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="first_name"
                    autoComplete="off"
                    value={this.state.first_name}
                    onChange={(e) =>
                      this.onChange("first_name", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("first_name", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="name"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Name" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={this.state.name}
                    onChange={(e) => this.onChange("name", e.target.value)}
                    onBlur={(e) => this.onBlur("name", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="birth_date"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Birth.Date" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="date"
                    name="birth_date"
                    value={DateUtil.toyyyyMMdd(this.state.birth_date)}
                    onChange={(e) =>
                      this.onChange("birth_date", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("birth_date", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="address"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Address" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="address"
                    autoComplete="off"
                    value={this.state.address}
                    onChange={(e) => this.onChange("address", e.target.value)}
                    onBlur={(e) => this.onBlur("address", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="postal_code"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Postal.Code" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="postal_code"
                    autoComplete="off"
                    value={this.state.postal_code}
                    onChange={(e) =>
                      this.onChange("postal_code", e.target.value)
                    }
                    onBlur={(e) => this.onBlur("postal_code", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.postal_codeError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="city"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="City" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="city"
                    autoComplete="off"
                    value={this.state.city}
                    onChange={(e) => this.onChange("city", e.target.value)}
                    onBlur={(e) => this.onBlur("city", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="phone"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Phone" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="phone"
                    autoComplete="off"
                    value={this.state.phone}
                    onChange={(e) => this.onChange("phone", e.target.value)}
                    onBlur={(e) => this.onBlur("phone", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.phoneError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="mobile"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Mobile" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="mobile"
                    autoComplete="off"
                    value={this.state.mobile}
                    onChange={(e) => this.onChange("mobile", e.target.value)}
                    onBlur={(e) => this.onBlur("mobile", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.mobileError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="email"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Email" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="email"
                    autoComplete="off"
                    value={this.state.email}
                    onChange={(e) => this.onChange("email", e.target.value)}
                    onBlur={(e) => this.onBlur("email", e.target.value)}
                  />
                  <div className="text-danger">
                    <small>{this.state.emailError}</small>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="codeCRM"
                  className="col-12 col-sm-5 col-form-label"
                >
                  <FormattedMessage id="Code.CRM" />
                </label>
                <div className="col-12 col-sm-7">
                  <input
                    className="form-control"
                    type="text"
                    id="codeCRM"
                    autoComplete="off"
                    value={this.state.codeCRM}
                    onChange={(e) => this.onChange("codeCRM", e.target.value)}
                    onBlur={(e) => this.onBlur("codeCRM", e.target.value)}
                  />
                </div>
              </div>

              <div className="row mt-5">
                {!this.isUpdate && (
                  <div className="col-12 col-lg-6 offset-lg-3">
                    <button
                      className="btn btn-info btn-block"
                      disabled={this.disabled()}
                      onClick={() => this.onSubmit()}
                    >
                      <FormattedMessage id="Add" />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddPatient: (patient, successCallback) =>
      dispatch(addPatient(patient, successCallback)),
    onupdPatientAdmin: (patient) => dispatch(updPatientAdmin(patient)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientGeneralInfo));
