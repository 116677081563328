import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import Roles from "../../enums/Roles";
import APIUrl from "../../APIUrl";
import "../../css/orders/orders.css";
import DateUtil from "../../util/DateUtil";
import EqSellModes from "../../enums/EqSellModes";
import { updPatientAdmin, updPatient } from "../../actions/patients/patients";
import { UncontrolledTooltip } from "reactstrap";

class PatientRow extends React.Component {
  constructor(props) {
    super(props);

    const { patient } = this.props;

    if (patient) {
      this.state = {
        active: patient.active ? patient.active : false,
        new_audit_request: patient.new_audit_request
          ? patient.new_audit_request
          : false,
      };
    }
  }

  onChange(field, value) {
    let { patient } = this.props;
    if (!patient) return null;
    patient = {
      _id: this.props.patient._id,
      [field]: value,
    };
    var successCallback = () => {
      this.setState({ [field]: value });
    };
    if (this.props.user.role === Roles.ADMIN)
      this.props.onupdPatientAdmin(patient, successCallback);
    if (this.props.user.role === Roles.CLIENT)
      this.props.onupdPatient(patient, successCallback);
  }

  stopEvent(e) {
    // e.preventDefault();
    e.stopPropagation();
  }

  fileIconDisabled = () => {
    return (
      <div>
        <i className="fa fa-file-pdf-o icon-big" disabled></i>
      </div>
    );
  };

  getAuditEquipment(equipmentId) {
    const { auditEquipments } = this.props;

    const equipment = auditEquipments.find((e) => e._id === equipmentId);

    return equipment;
  }

  renewalDate() {
    const { patient } = this.props;

    let renewalAlert = null;

    if (!patient) return null;
    const { equipments } = patient;
    if (!equipments) return null;

    equipments.map((equipment) => {
      let date = new Date(equipment.last_prescription_date);

      const auditEquipment = this.getAuditEquipment(
        equipment.audit_equipment_id
      );

      if (!auditEquipment) return null;

      if (equipment.mode === EqSellModes.SALE) {
        date = new Date(
          date.setFullYear(
            date.getFullYear() + parseInt(auditEquipment.purchase_renewal)
          )
        );
      } else if (equipment.mode === EqSellModes.LEASING) {
        date = new Date(
          date.setMonth(date.getMonth() + parseInt(equipment.rental_renewal))
        );
      }

      date = DateUtil.toDate(date);

      if (date && new Date(date).getTime() < Date.now()) {
        renewalAlert = "warning";
      } else if (renewalAlert !== "warning") {
        renewalAlert = "success";
      }
      return null;
    });

    if (renewalAlert === "success") {
      return <i className="fa fa-recycle icon-big text-success static"></i>;
    } else if (renewalAlert === "warning") {
      return <i className="fa fa-recycle icon-big text-warning static"></i>;
    } else {
      return <i className="fa fa-recycle icon-big static" disabled></i>;
    }
  }

  getAuditPatient = () => {
    const { audits, patient } = this.props;

    let audit = null;

    if (!patient || !audits) return this.fileIconDisabled();

    let auditsPatient = audits.filter((p) => p.patient_id === patient._id);

    if (!auditsPatient) return this.fileIconDisabled();

    let fileNameAudit =
      "audit_" +
      this.props.patient.first_name +
      "_" +
      this.props.patient.name +
      "_";

    if (auditsPatient.length > 0) audit = auditsPatient[0];

    if (audit) {
      return (
        <div key={audit._id}>
          <a
            role="button"
            href={
              APIUrl.getAuditsPDF +
              audit.patient_id +
              "/" +
              audit._id +
              "?token=" +
              APIUrl.jwtToken
            }
            download={
              fileNameAudit + DateUtil.toyyyyMMdd(audit.createdAt) + ".pdf"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              id={"display-report" + patient._id}
              className="fa fa-file-pdf-o icon-big"
              disabled=""
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"display-report" + patient._id}
            >
              <FormattedMessage id="Display" />
            </UncontrolledTooltip>
          </a>
        </div>
      );
    } else return this.fileIconDisabled();
  };

  getEstimatePatient = () => {
    const { patient } = this.props;
    let estimate = null;

    if (!patient) return this.fileIconDisabled();

    const { estimates } = patient;

    if (!estimates) return this.fileIconDisabled();

    let fileNameEstimate =
      "estimate_" +
      this.props.patient.first_name +
      "_" +
      this.props.patient.name +
      "_";

    if (estimates.length > 0) estimate = estimates[estimates.length - 1];

    if (estimate) {
      return (
        <div key={estimate._id}>
          <a
            role="button"
            href={
              APIUrl.getPatientEstimateFile +
              patient._id +
              "/" +
              estimate._id +
              "?token=" +
              APIUrl.jwtToken
            }
            download={
              fileNameEstimate + DateUtil.toyyyyMMdd(estimate.date) + ".pdf"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              id={"display-estimate" + patient._id}
              className="fa fa-file-pdf-o icon-big"
              disabled=""
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"display-estimate" + patient._id}
            >
              <FormattedMessage id="Display" />
            </UncontrolledTooltip>
          </a>
        </div>
      );
    } else return this.fileIconDisabled();
  };

  getTechnicalFiles = () => {
    const { patient } = this.props;
    let technicalSheet = null;

    if (!patient) return this.fileIconDisabled();

    const { technical_sheets } = patient;

    if (!technical_sheets) return this.fileIconDisabled();

    let fileNameTechnicalSheet =
      "technical_sheet_" +
      this.props.patient.first_name +
      "_" +
      this.props.patient.name +
      "_";

    if (technical_sheets.length > 0)
      technicalSheet = technical_sheets[technical_sheets.length - 1];

    if (technicalSheet) {
      return (
        <div key={technicalSheet._id}>
          <a
            role="button"
            href={
              APIUrl.getPatientTechnicalSheetFile +
              patient._id +
              "/" +
              technicalSheet._id +
              "?token=" +
              APIUrl.jwtToken
            }
            download={
              fileNameTechnicalSheet +
              DateUtil.toyyyyMMdd(technicalSheet.date) +
              ".pdf"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              id={"display-technicalSheet" + patient._id}
              className="fa fa-file-pdf-o icon-big"
              disabled=""
            ></i>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="top"
              target={"display-technicalSheet" + patient._id}
            >
              <FormattedMessage id="Display" />
            </UncontrolledTooltip>
          </a>
        </div>
      );
    } else return this.fileIconDisabled();
  };

  getDateLastAudit = () => {
    const { patient, audits } = this.props;
    if (!audits) return null;
    let auditsPatient = audits.find((p) => p.patient_id === patient._id);
    if (auditsPatient) return DateUtil.toDate(auditsPatient.createdAt);
    return "-";
  };

  render() {
    // const { patient, clients } = this.props;
    const { patient } = this.props;

    const { user } = this.props;
    const { role } = user;

    if (role === undefined) return null;
    if (!patient) return null;

    /*
        let clientLead = clients ? clients.find(p => patient.prescriber_lead === p._id) : '';
        let helperLead = clients ? clients.find(p => patient.helper_lead === p._id) : '';
        */

    return (
      <React.Fragment>
        <tr key={patient._id} className={"d-flex"}>
          {this.props.user.role === Roles.ADMIN && (
            <React.Fragment>
              <td className="col-2 align-middle text-uppercase">
                {patient.name && patient.name.toLowerCase()}{" "}
                <span className="text-capitalize">
                  {patient.first_name && patient.first_name.toLowerCase()}
                </span>
              </td>
              <td className="col-4 align-middle">
                <span className="text-capitalize">
                  {patient.address && patient.address.toLowerCase()}
                </span>{" "}
                {patient.postal_code}{" "}
                <span className="text-uppercase">
                  {patient.city && patient.city.toUpperCase()}
                </span>
              </td>
              <td className="col-1 align-middle">{this.getDateLastAudit()}</td>
              <td className="col-1 align-middle text-center">
                <i
                  id={"display-prescriber" + patient._id}
                  className="fa fa-eye icon-big"
                  onClick={(e) => this.props.displaysPrescribersModal(patient)}
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"display-prescriber" + patient._id}
                >
                  <FormattedMessage id="Display.Prescriber" />
                </UncontrolledTooltip>
              </td>
              <td className="col-1 align-middle text-center">
                <i
                  id={"display-helper" + patient._id}
                  className="fa fa-eye icon-big"
                  onClick={(e) => this.props.displaysHelpersModal(patient)}
                ></i>
                <UncontrolledTooltip
                  delay={{ show: 0, hide: 0 }}
                  placement="top"
                  target={"display-helper" + patient._id}
                >
                  <FormattedMessage id="Display.Helper" />
                </UncontrolledTooltip>
              </td>
              <td className="col-1 align-middle text-center">
                <div className="custom-control custom-switch mx-auto d-inline-block">
                  <input
                    onChange={(e) => {
                      this.onChange("active", e.target.checked);
                    }}
                    type="checkbox"
                    className={"custom-control-input switch-bg-blue"}
                    id={"switch-av" + patient._id}
                    checked={this.state.active}
                  />
                  <label
                    className="custom-control-label cursor-pointer"
                    htmlFor={"switch-av" + patient._id}
                  ></label>
                </div>
              </td>
              <td className="col-1 align-middle text-center">
                <div className="custom-control custom-switch mx-auto d-inline-block">
                  <input
                    onChange={(e) => {
                      this.onChange("new_audit_request", e.target.checked);
                    }}
                    type="checkbox"
                    className={"custom-control-input switch-bg-blue"}
                    id={"new_audit_request" + patient._id}
                    checked={this.state.new_audit_request}
                  />
                  <label
                    className="custom-control-label cursor-pointer"
                    htmlFor={"new_audit_request" + patient._id}
                  ></label>
                </div>
              </td>

              {(role === Roles.ADMIN || role === Roles.SALES_REP) && (
                <td className="col-1 align-middle text-center tdaction">
                  <i
                    id={"modify" + patient._id}
                    className="fa fa-edit icon-big"
                    onClick={(e) => this.props.edit(patient)}
                  ></i>
                  <UncontrolledTooltip
                    delay={{ show: 0, hide: 0 }}
                    placement="top"
                    target={"modify" + patient._id}
                  >
                    <FormattedMessage id="Modify" />
                  </UncontrolledTooltip>
                  <i
                    id={"delete" + patient._id}
                    className="fa fa-trash icon-big"
                    onClick={(e) => this.props.delete(patient)}
                  ></i>
                  <UncontrolledTooltip
                    delay={{ show: 0, hide: 0 }}
                    placement="top"
                    target={"delete" + patient._id}
                  >
                    <FormattedMessage id="Delete" />
                  </UncontrolledTooltip>
                </td>
              )}
            </React.Fragment>
          )}
          {this.props.user.role === Roles.SALES_REP && (
            <React.Fragment>
              <td className="col-2 align-middle text-uppercase">
                {patient.name.toLowerCase()}{" "}
                <span className="text-capitalize">
                  {patient.first_name.toLowerCase()}
                </span>
              </td>
              <td className="col-5 align-middle">
                <span className="text-capitalize">
                  {patient.address.toLowerCase()}
                </span>{" "}
                {patient.postal_code}{" "}
                <span className="text-uppercase">
                  {patient.city && patient.city.toUpperCase()}
                </span>
              </td>
              <td className="col-2 align-middle">{this.getDateLastAudit()}</td>
              <td className="col-1 align-middle text-center">
                <i
                  className="fa fa-eye icon-big"
                  onClick={(e) => this.props.displaysPrescribersModal(patient)}
                ></i>
              </td>
              <td className="col-1 align-middle text-center">
                <i
                  className="fa fa-eye icon-big"
                  onClick={(e) => this.props.displaysHelpersModal(patient)}
                ></i>
              </td>
              <td className="col-1 align-middle text-center tdaction">
                <i
                  className="fa fa-edit icon-big"
                  onClick={(e) => this.props.edit(patient)}
                ></i>
              </td>
            </React.Fragment>
          )}
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    user: state.user,
    audits: state.audits,
    auditEquipments: state.auditEquipments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
    onupdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientRow));
