import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import PatientGeneralInfo from "./PatientGeneralInfo";
import PatientHelpers from "./PatientHelpers";
import PatientPrescribers from "./PatientPrescribers";
import PatientTechnicalSheets from "./PatientTechnicalSheets";
import PatientDocuments from "./PatientDocuments";
import PatientEstimates from "./PatientEstimates";
import PatientAudit from "./PatientAudit";
import Roles from "../../enums/Roles";
import { Modal, Tab, Tabs } from "react-bootstrap";
import PatientBills from "./PatientBills";

class PatientModal extends React.Component {
  getPatient(patientId) {
    for (let p of this.props.patients) {
      if (p._id === patientId) return p;
    }
  }

  render() {
    const { patientId } = this.props;

    var target = this.props.target ? this.props.target : "general";

    const patient = this.getPatient(patientId);

    if (patientId && !patient) return null;

    var aClassName = patient ? "" : " disabled";

    return (
      <div className="modal-bg show">
        <Modal
          show={true}
          onHide={() => this.props.close()}
          backdrop={"static"}
          size={"xl"}
        >
          <Modal.Body>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.close()}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <Tabs
              defaultActiveKey={target}
              id="tab-collaborators"
              className="mb-3"
            >
              <Tab
                eventKey="general"
                title={<FormattedMessage id="General.Informations" />}
              >
                <PatientGeneralInfo
                  patient={patient}
                  openModal={(patient) =>
                    this.props.openModal(patient, "general")
                  }
                />
              </Tab>

              {this.props.user.role !== Roles.CLIENT && (
                <Tab
                  eventKey="helpers"
                  title={<FormattedMessage id="Helpers" />}
                  disabled={aClassName}
                >
                  <PatientHelpers patient={patient} />
                </Tab>
              )}

              {this.props.user.role !== Roles.CLIENT && (
                <Tab
                  eventKey="prescribers"
                  title={<FormattedMessage id="Prescribers" />}
                  disabled={aClassName}
                >
                  <PatientPrescribers patient={patient} />
                </Tab>
              )}
              {(this.props.user.access_e4pharmamad ||
                this.props.user.access_e4pharmahome ||
                this.props.user.access_e4pharmadesk) === true && (
                <Tab
                  eventKey="audit"
                  title={<FormattedMessage id="Audit" />}
                  disabled={aClassName}
                >
                  <PatientAudit patient={patient} audits={this.props.audits} />
                </Tab>
              )}
              <Tab
                eventKey="documents"
                title={<FormattedMessage id="Documents" />}
                disabled={aClassName}
              >
                <PatientDocuments patient={patient} />
              </Tab>
              <Tab
                eventKey="estimates"
                title={<FormattedMessage id="Estimates" />}
                disabled={aClassName}
              >
                <PatientEstimates patient={patient} />
              </Tab>
              <Tab
                eventKey="tech-sheets"
                title={<FormattedMessage id="Tech.Sheets" />}
                disabled={aClassName}
              >
                <PatientTechnicalSheets patient={patient} />
              </Tab>
              <Tab
                eventKey="bills"
                title={<FormattedMessage id="Bills" />}
                tabClassName={aClassName}
              >
                <PatientBills patient={patient} />
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    audits: state.audits,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientModal);
