import React from "react";
import { connect } from "react-redux";
import FileDropZone from "../sub/FileDropZone";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Modal, Alert, Badge } from "react-bootstrap";
import ReactImageFallback from "react-image-fallback";

class UploadProductImgModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      // Uploaded files
      customImg: [],
      disabledDropzone: false,
      previewImage: false,
    };
  }

  close() {
    this.props.closeModal();
  }

  stopEvent(e) {
    e.stopPropagation();
  }

  onComplete() {
    this.setState({
      disabled: true,
      formVisibility: "hide",
      progressBarVisibility: "show",
      progressbarMessage: (
        <FormattedMessage id="Import.Mercurial.Image.Upload" />
      ),
    });

    this.props.onComplete(this.state.customImg);
  }

  onDropFile(files) {
    const that = this;

    var reader = new FileReader();
    reader.onloadend = function () {
      var imageSrc = reader.result;
      that.setState({
        customImg: [files],
        disabledDropzone: true,
        previewImage: imageSrc,
        disabled: false,
      });
    };

    reader.readAsDataURL(files);
  }

  abortCustomImage() {
    this.setState({
      customImg: [],
      disabledDropzone: false,
      previewImage: false,
    });
  }

  disabled() {
    return this.state.disabled || !this.state.customImg.length;
  }

  render() {
    const productFormat = this.props.productFormat;
    const currentProductImage = this.props.currentProductImage;
    const defaultProductImage = this.props.defaultProductImage;

    let modalTitle = <FormattedMessage id="Product.Image.Update" />;
    let modalWarning = (
      <Alert variant="success" className="text-justify">
        <FormattedMessage id="Imagery.Message.1" />
      </Alert>
    );
    let confirmButton = !this.disabled() && (
      <Button variant="info" onClick={() => this.onComplete()}>
        <FormattedMessage id="Import" />
      </Button>
    );

    if (this.props.mode === "delete") {
      modalTitle = <FormattedMessage id="Product.Image.Delete" />;
      modalWarning = (
        <Alert variant="danger" className="text-justify">
          <FormattedMessage id="Imagery.Message.2" />
        </Alert>
      );
      confirmButton = (
        <Button variant="danger" onClick={() => this.onComplete()}>
          <FormattedMessage id="Confirm" />
        </Button>
      );
    }

    return (
      <React.Fragment>
        <div className="modal-bg show">
          <Modal
            show={true}
            onHide={() => this.close()}
            backdrop="static"
            size="lg"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="container">
                {modalWarning}
                <div className="row">
                  <div className="col col-5 text-center">
                    <Badge variant="success" className="mb-2">
                      <FormattedMessage id="Current.Imagery" />
                    </Badge>
                    <div className="card-img-top d-block mx-auto img-thumbnail">
                      <ReactImageFallback
                        src={currentProductImage}
                        fallbackImage="/images/no_image_512.png"
                        initialImage="/images/loader.gif"
                        style={{ width: "200px" }}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="col col-2 text-center p-0 pt-5">
                    <i
                      className={
                        this.state.disabledDropzone
                          ? "fa fa-arrow-right fa-3x text-success pt-5"
                          : this.props.mode === "delete"
                          ? "fa fa-arrow-right fa-3x text-danger pt-5"
                          : "fa fa-arrow-right fa-3x text-dark disabled pt-5"
                      }
                    ></i>
                  </div>

                  {this.props.mode === "upload" && (
                    <div className="col col-5 text-center">
                      <Badge variant="info" className="mb-2">
                        <FormattedMessage id="Custom.Imagery" />
                      </Badge>
                      {!this.state.disabledDropzone && (
                        <FileDropZone
                          onDropFile={(file) => this.onDropFile(file)}
                          acceptedExtensions={["jpg", "jpeg", "png"]}
                          appearance="thumbnail"
                          multiple={false}
                        />
                      )}
                      {this.state.disabledDropzone && (
                        <div>
                          <div
                            className="card-img-top d-block mx-auto img-thumbnail"
                            style={
                              this.state.disabledDropzone && {
                                border: "1px solid #28a745",
                              }
                            }
                          >
                            <div className="position-absolute">
                              <Button
                                onClick={(e) => this.abortCustomImage()}
                                variant="danger"
                                className="btn-sm"
                              >
                                <i className="fa fa-trash" />
                              </Button>
                            </div>
                            <ReactImageFallback
                              src={this.state.previewImage}
                              fallbackImage="/images/no_image_512.png"
                              initialImage="/images/loader.gif"
                              style={{ maxWidth: "200px", maxHeight: "200px" }}
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {this.props.mode === "delete" && (
                    <div className="col col-5 text-center">
                      <Badge variant="info" className="mb-2">
                        <FormattedMessage id="Default.Imagery" />
                      </Badge>
                      <div
                        className="card-img-top d-block mx-auto img-thumbnail"
                        style={{ border: "1px solid #dc3545" }}
                      >
                        <ReactImageFallback
                          src={defaultProductImage}
                          fallbackImage="/images/no_image_512.png"
                          initialImage="/images/loader.gif"
                          style={{ width: "200px" }}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.close()}>
                <FormattedMessage id="Cancel" />
              </Button>
              {confirmButton}
            </Modal.Footer>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UploadProductImgModal));
