import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import StringUtil from "../../../util/StringUtil";

import ProductsBudget from "../ProductsBudget";
import OverTimeBudget from "../OverTimeBudget";
import TopProductsBudget from "../TopProductsBudget";

import Util from "../../../util/Util";
import { Tab, Tabs } from "react-bootstrap";

class BudgetAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: null,
    };
  }

  selectClient(clientId) {
    this.setState({ clientId: clientId });
  }

  getClient(clientId) {
    for (let client of this.props.clients) {
      if (client._id === clientId) return client;
    }
  }

  getOrders(clientId) {
    var orders = [];

    for (let order of this.props.orders) {
      var client = this.getClient(order.id_client);

      if (!client) continue;

      if (client._id === clientId) {
        orders.push(order);
      }
    }

    return orders;
  }

  render() {
    var clientsNode = this.props.clients.map((client) => {
      return (
        <option key={client._id} value={client._id}>
          {client.name + " " + StringUtil.ucFirst(client.first_name)}
        </option>
      );
    });

    var clientSelectNode = (
      <div className="text-center" role="alert">
        <div className="row search-filters">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="form-inline">
                  <i className="fa fa-user-md ml-auto mr-4"></i>
                  <select
                    id="fleet-filter-clientId"
                    className="form-control mr-auto"
                    onChange={(e) => this.selectClient(e.target.value)}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select.Client" })}
                      ...
                    </option>
                    {clientsNode}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    // The admin hasn't picked a client yet
    if (!this.state.clientId || Util.emptyString(this.state.clientId))
      return clientSelectNode;

    var orders = this.getOrders(this.state.clientId);

    return (
      <React.Fragment>
        {clientSelectNode}

        <Tabs
          defaultActiveKey="budget-purchases"
          id="tab-budget"
          className="mb-3"
        >
          <Tab
            eventKey="budget-purchases"
            title={<FormattedMessage id="Purchases" />}
          >
            <ProductsBudget orders={orders} />
          </Tab>
          <Tab
            eventKey="budget-overview"
            title={<FormattedMessage id="Overview" />}
          >
            <OverTimeBudget orders={orders} />
          </Tab>
          <Tab
            eventKey="budget-topproducts"
            title={<FormattedMessage id="Top.Products" />}
          >
            <TopProductsBudget orders={orders} />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BudgetAdmin));
