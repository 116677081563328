import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Util from "../../../../util/Util";
import { addCollaboratorEstablishment } from "../../../../actions/collaborators/collaborators";
import { addCollaboratorClient } from "../../../../actions/collaborators/collaborators";
import CollaboratorAssignments from "./CollaboratorAssignments";
import { UncontrolledTooltip } from "reactstrap";

class Assignments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishment_id: "",

      disabled: false,
    };
  }

  isUpdate() {
    return (
      this.props.collaborator !== undefined && this.props.collaborator !== null
    );
  }

  close() {
    this.props.close();
  }

  stopEvent(e) {
    e.stopPropagation();
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  addEstablishment() {
    if (Util.emptyString(this.state.establishment_id) || this.state.disabled)
      return;

    var data = {
      collaboratorId: this.props.collaborator._id,
      establishmentId: this.state.establishment_id,
    };

    this.setState({ disabled: true });

    var successCallback = () => {
      this.setState({ disabled: false });
    };

    this.props.onAddCollaboratorEstablishment(data, successCallback);
  }

  addClient() {
    if (Util.emptyString(this.state.client_id) || this.state.disabled) return;

    var data = {
      collaboratorId: this.props.collaborator._id,
      clientId: this.state.client_id,
    };

    this.setState({ disabled: true });

    var successCallback = () => {
      this.setState({ disabled: false });
    };

    this.props.onAddCollaboratorClient(data, successCallback);
  }

  render() {
    const { collaborator, clients } = this.props;

    if (!collaborator) return null;
    if (!clients) return null;

    if (!collaborator.clients) collaborator.clients = [];

    var clientsNode;
    if (collaborator && collaborator.clients) {
      clientsNode = clients.map((client) => {
        let disabled = false;
        collaborator.clients.map((p) => {
          if (p.client_id === client._id) {
            disabled = true;
          }
          return null;
        });
        return (
          <option value={client._id} key={client._id} disabled={disabled}>
            {client.name} {client.first_name}
          </option>
        );
      });
    }

    return (
      <React.Fragment>
        <div className="col-12 col-lg-9 col-xl-8">
          <div className="form-group row">
            <label
              htmlFor="client_id"
              className="col-12 col-md-5 col-form-label"
            >
              <FormattedMessage id="Assign.Client" />
            </label>
            <div className="col-11 col-md-6">
              <select
                className="form-control d-inline"
                id="client_id"
                defaultValue={this.state.client_id}
                onChange={(e) => this.onChange("client_id", e.target.value)}
              >
                <option value=""></option>
                {clientsNode}
              </select>
            </div>

            <div className="col-1 text-left align-left float-left p-0">
              <i
                id={"addClient"}
                className="fa fa-plus-square icon-huge hoverable clickable d-inline"
                onClick={() => this.addClient()}
                disabled={
                  Util.emptyString(this.state.client_id) || this.state.disabled
                }
              />
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"addClient"}
              >
                <FormattedMessage id="Add.Client" />
              </UncontrolledTooltip>
            </div>
          </div>
        </div>

        <CollaboratorAssignments collaborator={this.props.collaborator} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborators: state.collaborators,
    establishments: state.establishments,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddCollaboratorEstablishment: (data, successCallback) =>
      dispatch(addCollaboratorEstablishment(data, successCallback)),
    onAddCollaboratorClient: (data, successCallback) =>
      dispatch(addCollaboratorClient(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assignments);
