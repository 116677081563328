import APIUrl from "../../../../APIUrl";
import axios from "axios";

export const GET_AUDIT_DMS = "GET_AUDIT_DMS";

function getAuditDMsAction(auditDMs) {
  return { type: GET_AUDIT_DMS, auditDMs: auditDMs };
}

export const getAuditDMs = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAuditDMs)
      .then(function (response) {
        if (response.data) dispatch(getAuditDMsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getAuditDMByRoomIdAndName = function (
  name,
  room_id,
  successCallback
) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAuditDMByRoomIdAndName + name + "/" + room_id)
      .then(function (response) {
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addAuditDM = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addAuditDM, data)
      .then(function (response) {
        dispatch(getAuditDMs());
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addAuditDMFile = function (
  auditDMId,
  roomId,
  data,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addAuditDMFile + auditDMId + "/" + roomId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function () {
        dispatch(getAuditDMs());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateAuditDM = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateAuditDM, data)
      .then(function () {
        dispatch(getAuditDMs());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteAuditDM = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteAuditDM, data)
      .then(function () {
        dispatch(getAuditDMs());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
