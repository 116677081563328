import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import LoginForm from "./components/authentication/LoginForm";
import Homepage from "./components/homepage/Homepage";
import ErrorModal from "./components/sub/modals/ErrorModal.js";
import NotFound from "./components/NotFound";
import axios from "axios";
import APIUrl from "./APIUrl";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { logout } from "./actions/authentication/authentication";
// import Init from './components/init/Init';

// Configure Axios for CORS requests
axios.defaults.baseURL = APIUrl.rootUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

// Used to keep connexion alive (using page refresh)
var sessionJWT = sessionStorage.getItem("jwt");
if (sessionJWT) {
  axios.defaults.headers.common["jwtToken"] = sessionJWT;
  APIUrl.jwtToken = sessionJWT;
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { modal: null };

    // Using interceptors for cross-request handling of errors
    axios.interceptors.response.use(undefined, (err) => {
      // If we get a 500 from server stop the chain
      if (err.response && err.response.status === 500) {
        this.openErrorModal();
        return new Promise(() => {});
      } else if (err.response && err.response.status === 403) {
        const hash = window.location.hash;

        window.location.href = "/#/";

        if (hash.indexOf("home") !== -1) return new Promise(() => {});
      } else throw err;
    });
  }

  logout() {
    function onLogoutSuccess() {
      window.location.href = "/#/";
    }

    // Remove the modal, logout and redirect on success
    this.setState({ modal: null }, () => this.props.onLogout(onLogoutSuccess));
  }

  openErrorModal() {
    var errorModalTitle = <FormattedMessage id="Unexpected.Error" />;
    var errorModalContent = <FormattedMessage id="Unexpected.Error.Expl" />;

    this.setState({
      modal: (
        <ErrorModal
          isOpen={true}
          title={errorModalTitle}
          content={errorModalContent}
          closeModal={(e) => this.logout()}
        />
      ),
    });
  }

  render() {
    return (
      <React.Fragment>
        <Router basename={"/"} refresh={true}>
          <Switch>
            <Route path="/home" component={Homepage} />
            <Route exact path="/:jwtToken?" component={LoginForm} />
            <Route component={NotFound} />
          </Switch>
        </Router>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (successCallback) => dispatch(logout(successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
