import React from "react";
import { connect } from "react-redux";
import {
  updPatientAdmin,
  deletePatientEstimateFile,
  addPatientEstimateFile,
} from "../../actions/patients/patients";
import APIUrl from "../../APIUrl";
import Document from "../sub/documentCrud/Document";

class PatientEstimates extends React.Component {
  render() {
    const {
      patient,
      user,
      onUpdPatientAdmin,
      onAddPatientEstimateFile,
      onDeletePatientEstimateFile,
    } = this.props;

    return (
      <React.Fragment>
        <Document
          data={patient}
          documentType={"estimates"}
          addDataDocument={(patientId, file, successCallback) =>
            onAddPatientEstimateFile(patientId, file, successCallback)
          }
          updateData={(patient, successCallback) =>
            onUpdPatientAdmin(patient, successCallback)
          }
          deleteDocument={(patientId, sheetId, successCallback) =>
            onDeletePatientEstimateFile(patientId, sheetId, successCallback)
          }
          messageId={"Patient.Estimates.Add"}
          adminEmptyMessageId={"Empty.Patient.Estimates"}
          clientEmptyMessageId={"Client.Empty.Patient.Estimates"}
          user={user}
          url={APIUrl.getPatientEstimateFile}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onAddPatientEstimateFile: (patientId, file, successCallback) =>
      dispatch(addPatientEstimateFile(patientId, file, successCallback)),
    onDeletePatientEstimateFile: (patientId, estimateId, successCallback) =>
      dispatch(
        deletePatientEstimateFile(patientId, estimateId, successCallback)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientEstimates);
