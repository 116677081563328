import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import MenuProfile from "./MenuProfile";
import RequestStatus from "../../../enums/RequestStatus";
import RequestTypes from "../../../enums/RequestTypes";
import ProductsTotalUtil from "../../../util/ProductsTotalUtil";
import { passOrder } from "../../../actions/orders/orders";
import OrderStatus from "../../../enums/OrderStatus";
import APIUrl from "../../../APIUrl";
import { NavLink } from "react-router-dom";
import MenuLogo from "./MenuLogo";
import Roles from "../../../enums/Roles";
import { getUser } from "../../../actions/user/user";
import Util from "../../../util/Util";

class MenuClientsGen extends React.Component {
  componentDidMount() {
    // First, fetch the user

    this.props.onGetUser();
  }

  constructor(props) {
    super(props);

    this.state = {
      ordersSettings: this.props.ordersSettings,
    };
  }

  countNewRequests(requests) {
    var nbr = 0;
    for (var request of requests) {
      if (request.request_type === RequestTypes.NEW_MATERIAL) continue;

      if (request.status === RequestStatus.NEW) nbr++;
    }

    return nbr;
  }

  countNewOrders() {
    var nbr = 0;

    for (let order of this.props.orders) {
      if (order.status === OrderStatus.NEW) nbr++;
    }

    return nbr;
  }

  countCartProduct() {
    var nbr = 0;

    for (let c of this.props.cart) {
      nbr += c.quantity;
    }

    return nbr;
  }

  countTotalCart() {
    // Helper that will do all calculations for us
    var productsTotalUtil = 0;
    if (
      this.props.cart.length !== 0 &&
      this.props.products.length !== 0 &&
      this.state.ordersSettings
    ) {
      productsTotalUtil = new ProductsTotalUtil(
        this.props.cart,
        this.props.products,
        null,
        null,
        this.state.ordersSettings
      );
      return productsTotalUtil.totalTtc;
    }
    return productsTotalUtil;
  }

  render() {
    var totalCart = this.countTotalCart();
    var newOrdersNbr = this.countNewOrders();

    var CartProductsNbr = this.countCartProduct();
    var disabled = true;
    if (this.props.cart.length !== 0 && this.props.products.length !== 0) {
      var productsTotalUtil = new ProductsTotalUtil(
        this.props.cart,
        this.props.products,
        null,
        null,
        this.state.ordersSettings
      );
      disabled = !productsTotalUtil.aboveMinToOrder();
    }

    // First Check Role
    if (this.props.user.role === Roles.CLIENT) {
      // Check User Access
      if (this.props.user.access_e4pharmamad !== false) {
        // PharmaMad is true
        return (
          <React.Fragment>
            <nav className="navbar navbar-expand-lg menu">
              <div
                id="menu"
                className="collapse navbar-collapse mb-3 mb-md-0 mt-2 pb-2"
              >
                <ul className="navbar-nav list-inline text-center align-items-center">
                  <li className="nav-item list-inline-item align-middle">
                    <img
                      className="logo-header"
                      alt="logo e4Pharma"
                      src=" ./images/svg/Logo_PHARMA_MAD.svg"
                    />
                  </li>

                  {/* Displaying All Menu with role CLIENTS for Mad / Home / Store / Desk  */}
                  {/* Accueil */}
                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <NavLink
                      className="w-100 p-3"
                      to="/home"
                      exact
                      activeClassName="selected"
                    >
                      <FormattedMessage id="Home" />
                    </NavLink>
                  </li>

                  {/* Mercurial */}
                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <NavLink
                      className="w-100 p-3"
                      to="/home/families"
                      exact
                      activeClassName="selected"
                    >
                      <FormattedMessage id="Mercurials" />
                    </NavLink>
                  </li>

                  {/* Commandes */}
                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <NavLink
                      className="w-100 p-3"
                      to="/home/orders"
                      exact
                      activeClassName="selected"
                    >
                      <FormattedMessage id="Orders" />
                      {newOrdersNbr > 0 && (
                        <span className="badge badge-danger">
                          {newOrdersNbr}
                        </span>
                      )}
                    </NavLink>
                  </li>

                  {/* Suivi Patients */}
                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <NavLink
                      className="w-100 p-3"
                      to="/home/patients"
                      exact
                      activeClassName="selected"
                    >
                      <FormattedMessage id="Patients.Monitoring" />
                      {this.countNewRequests(this.props.requests) > 0 && (
                        <span className="badge badge-danger">
                          {this.countNewRequests(this.props.requests)}
                        </span>
                      )}
                    </NavLink>
                  </li>

                  {/* Devis */}
                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <NavLink
                      className="w-100 p-3"
                      to="/home/estimates"
                      exact
                      activeClassName="selected"
                    >
                      <FormattedMessage id="Estimates" />
                    </NavLink>
                  </li>

                  {/* Statistiques HOME=FALSE / STORE=TRUE / MAD=TRUE / DESK=TRUE */}
                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <NavLink
                      className="w-100 p-3"
                      to="/home/budget"
                      exact
                      activeClassName="selected"
                    >
                      <span className="relative">
                        <FormattedMessage id="Budget" />
                      </span>
                    </NavLink>
                  </li>

                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <span className="w-100 p-3"></span>
                  </li>

                  {/* Panier HOME=FALSE / STORE=TRUE / MAD=TRUE / DESK=FALSE */}
                  {(this.props.user.access_e4pharmastore === true || this.props.user.access_e4pharmamad === true) &&
                    this.props.user.role === Roles.CLIENT && (
                      <li className="nav-item list-inline-item align-middle text-nowrap">
                        <div className="cart">
                          <div className="row d-flex justify-content-center mb-2">
                            <div className="col-6 text-left nav-item">
                              <span className="relative">
                                <FormattedMessage id="Cart" />
                                <i className=" ml-1 fa fa-shopping-cart"></i>
                                {CartProductsNbr > 0 && (
                                  <span className="badge badge-danger">
                                    {CartProductsNbr}
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="col-6 text-right">
                              <FormattedMessage id="Total" />
                              <b> {totalCart}€</b>
                            </div>
                          </div>
                          <div>
                            <NavLink
                              to="/home/cart"
                              className="cart-item"
                              disabled={disabled}
                            >
                              <button className="btn btn-outline-info">
                                <FormattedMessage id="Go.To.Cart" />
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      </li>
                    )}
                  <MenuLogo generalSettings={this.props.generalSettings} />
                  {/*
                                <div className="ml-md-auto">
                                    <li className="nav-item list-inline-item align-middle text-nowrap mx-auto p-2 col-12">
                                    {(this.props.generalSettings.logo) && <img src={APIUrl.getLogo + this.props.generalSettings._id + "/" + Math.random() + "?token=" + APIUrl.jwtToken} className="logo-img" width="70" height="70" alt="logo"/>}
                                    </li>
                                </div>
                                */}

                  <li className="menuProfile nav-item list-inline-item align-middle text-nowrap">
                    <MenuProfile />
                  </li>
                </ul>
              </div>
            </nav>
            {this.state.modal}
          </React.Fragment>
        );
      }
      if (this.props.user.access_e4pharmamad !== true) {
        // MAD is false
        return (
          <React.Fragment>
            <nav className="navbar navbar-expand-lg menu">
              <div
                id="menu"
                className="collapse navbar-collapse mb-3 mb-md-0 mt-2 pb-2"
              >
                <ul className="navbar-nav list-inline text-center align-items-center">
                  <li className="nav-item list-inline-item align-middle">
                    <a
                      href={process.env.REACT_APP_LOGIN_URL + APIUrl.jwtToken}
                      className="logo-item"
                    >
                      <img
                        className="logo-header"
                        alt="logo e4Pharma"
                        src=" ./images/svg/Logo_PHARMA_MAD.svg"
                      />
                    </a>
                  </li>

                  {/* Displaying Menu with role CLIENTS for Home / Store  / Desk */}
                  {/* Accueil */}
                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <NavLink
                      className="w-100 p-3"
                      to="/home"
                      exact
                      activeClassName="selected"
                    >
                      <FormattedMessage id="Home" />
                    </NavLink>
                  </li>

                  {/* Mercurials displaying */}
                  {/* Mercurial STORE=TRUE / DESK=FALSE */}
                  {this.props.user.access_e4pharmastore !== false &&
                    this.props.user.access_e4pharmahome !== true &&
                    this.props.user.access_e4pharmadesk !== true && (
                      <li className="nav-item list-inline-item align-middle text-nowrap">
                        <NavLink
                          className="w-100 p-3"
                          to="/home/families"
                          exact
                          activeClassName="selected"
                        >
                          <FormattedMessage id="Mercurials" />
                        </NavLink>
                      </li>
                    )}

                  {/* Mercurial STORE=TRUE / DESK=TRUE */}
                  {this.props.user.access_e4pharmastore !== false &&
                    this.props.user.access_e4pharmahome !== true &&
                    this.props.user.access_e4pharmadesk !== false && (
                      <li className="nav-item list-inline-item align-middle text-nowrap">
                        <NavLink
                          className="w-100 p-3"
                          to="/home/families"
                          exact
                          activeClassName="selected"
                        >
                          <FormattedMessage id="Mercurials" />
                        </NavLink>
                      </li>
                    )}

                  {/* Commandes displaying */}
                  {/* Commandes HOME=FALSE / STORE=TRUE / MAD=TRUE / DESK=FALSE */}
                  {this.props.user.access_e4pharmastore !== false &&
                    this.props.user.access_e4pharmahome !== true &&
                    this.props.user.access_e4pharmadesk !== true && (
                      <li className="nav-item list-inline-item align-middle text-nowrap">
                        <NavLink
                          className="w-100 p-3"
                          to="/home/orders"
                          exact
                          activeClassName="selected"
                        >
                          <FormattedMessage id="Orders" />
                          {newOrdersNbr > 0 && (
                            <span className="badge badge-danger">
                              {newOrdersNbr}
                            </span>
                          )}
                        </NavLink>
                      </li>
                    )}

                  {/* Commandes HOME=FALSE / STORE=TRUE / MAD=TRUE / DESK=TRUE */}
                  {this.props.user.access_e4pharmastore !== false &&
                    this.props.user.access_e4pharmahome !== true &&
                    this.props.user.access_e4pharmadesk !== false && (
                      <li className="nav-item list-inline-item align-middle text-nowrap">
                        <NavLink
                          className="w-100 p-3"
                          to="/home/orders"
                          exact
                          activeClassName="selected"
                        >
                          <FormattedMessage id="Orders" />
                          {newOrdersNbr > 0 && (
                            <span className="badge badge-danger">
                              {newOrdersNbr}
                            </span>
                          )}
                        </NavLink>
                      </li>
                    )}

                  {/* Suivi Patients */}
                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <NavLink
                      className="w-100 p-3"
                      to="/home/patients"
                      exact
                      activeClassName="selected"
                    >
                      <FormattedMessage id="Patients.Monitoring" />
                      {this.countNewRequests(this.props.requests) > 0 && (
                        <span className="badge badge-danger">
                          {this.countNewRequests(this.props.requests)}
                        </span>
                      )}
                    </NavLink>
                  </li>

                  {/* Devis */}
                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <NavLink
                      className="w-100 p-3"
                      to="/home/estimates"
                      exact
                      activeClassName="selected"
                    >
                      <FormattedMessage id="Estimates" />
                    </NavLink>
                  </li>

                  {/* Statistiques HOME=FALSE / STORE=TRUE / MAD=TRUE / DESK=TRUE */}
                  {this.props.user.access_e4pharmahome !== true &&
                    this.props.user.access_e4pharmadesk !== true && (
                      <li className="nav-item list-inline-item align-middle text-nowrap">
                        <NavLink
                          className="w-100 p-3"
                          to="/home/budget"
                          exact
                          activeClassName="selected"
                        >
                          <span className="relative">
                            <FormattedMessage id="Budget" />
                          </span>
                        </NavLink>
                      </li>
                    )}

                  <li className="nav-item list-inline-item align-middle text-nowrap">
                    <span className="w-100 p-3"></span>
                  </li>

                  {/* Panier displaying */}
                  {/* Panier STORE=TRUE / DESK=FALSE */}
                  {this.props.user.access_e4pharmastore !== false &&
                    this.props.user.access_e4pharmahome !== true &&
                    this.props.user.access_e4pharmadesk !== true && (
                      <li className="nav-item list-inline-item align-middle text-nowrap">
                        <div className="cart">
                          <div className="row d-flex justify-content-center mb-2">
                            <div className="col-6 text-left nav-item">
                              <span className="relative">
                                <FormattedMessage id="Cart" />
                                <i className=" ml-1 fa fa-shopping-cart"></i>
                                {CartProductsNbr > 0 && (
                                  <span className="badge badge-danger">
                                    {CartProductsNbr}
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="col-6 text-right">
                              <FormattedMessage id="Total" />
                              <b> {totalCart}€</b>
                            </div>
                          </div>
                          <div>
                            <NavLink
                              to="/home/cart"
                              className="cart-item"
                              disabled={disabled}
                            >
                              <button className="btn btn-outline-info">
                                <FormattedMessage id="Go.To.Cart" />
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      </li>
                    )}

                  {/* Panier STORE=TRUE / DESK=TRUE */}
                  {this.props.user.access_e4pharmastore !== false &&
                    this.props.user.access_e4pharmahome !== true &&
                    this.props.user.access_e4pharmadesk !== false && (
                      <li className="nav-item list-inline-item align-middle text-nowrap">
                        <div className="cart">
                          <div className="row d-flex justify-content-center mb-2">
                            <div className="col-6 text-left nav-item">
                              <span className="relative">
                                <FormattedMessage id="Cart" />
                                <i className=" ml-1 fa fa-shopping-cart"></i>
                                {CartProductsNbr > 0 && (
                                  <span className="badge badge-danger">
                                    {CartProductsNbr}
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="col-6 text-right">
                              <FormattedMessage id="Total" />
                              <b> {totalCart}€</b>
                            </div>
                          </div>
                          <div>
                            <NavLink
                              to="/home/cart"
                              className="cart-item"
                              disabled={disabled}
                            >
                              <button className="btn btn-outline-info">
                                <FormattedMessage id="Go.To.Cart" />
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      </li>
                    )}
                  <MenuLogo generalSettings={this.props.generalSettings} />
                  {/*
                                <div className="ml-md-auto">
                                    <li className="nav-item list-inline-item align-middle text-nowrap mx-auto p-2 col-12">
                                    {(this.props.generalSettings.logo) && <img src={APIUrl.getLogo + this.props.generalSettings._id + "/" + Math.random() + "?token=" + APIUrl.jwtToken} className="logo-img" width="70" height="70" alt="logo"/>}
                                    </li>
                                </div>
                                */}
                  <li className="menuProfile nav-item list-inline-item align-middle text-nowrap">
                    <MenuProfile />
                  </li>
                </ul>
              </div>
            </nav>
            {this.state.modal}
          </React.Fragment>
        );
      }
    }
    return (
      <React.Fragment>
        <div></div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    lang: state.i18n.lang,
    cart: state.cart,
    orders: state.orders,
    requests: state.requests,
    products: state.products,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    ordersSettings: state.ordersSettings,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPassOrder: (data, successCallback) =>
      dispatch(passOrder(data, successCallback)),
    onGetUser: () => dispatch(getUser()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuClientsGen)
);
