import React from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";

class SuccessModal extends React.Component {
  render() {
    return (
      <div className="modal-bg show">
        <Modal show={true} onHide={() => this.props.closeModal()} backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{this.props.content}</Modal.Body>

          <Modal.Footer>
            <Button variant="info" onClick={() => this.props.closeModal()}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default SuccessModal;
