import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { updPatientAdmin } from "../../actions/patients/patients";
import Roles from "../../enums/Roles";
import { nanoid } from "nanoid";
import { updClient } from "../../actions/clients/clients";
import { UncontrolledTooltip } from "reactstrap";

class PatientHelpers extends React.Component {
  constructor(props) {
    super(props);

    this.helperLeadExist = false;

    this.state = {
      helper_id: "",
      helper_lead: this.props.patient ? this.props.patient.helper_lead : null,
      disabled: false,
    };
  }

  onChange(field, value, helperId) {
    //				console.log('onChange',field,value);

    if (field === "lead") {
      var clonedPatient = Util.shallowClone(this.props.patient);
      clonedPatient.helper_lead = helperId;

      if (!value) clonedPatient.helper_lead = null;

      const successCallback = (patient) => {
        this.setState({ helper_lead: patient.helper_lead });
      };

      this.props.onupdPatientAdmin(clonedPatient, successCallback);
    } else {
      this.setState({ [field]: value });

      /*
						
						if (field == 'helper_id' && !this.props.patient.helpers_ids.length) {
								var clonedPatient = Util.shallowClone(this.props.patient);
								clonedPatient.helper_lead = value;
								const successCallback = (patient) => {
										this.setState({ helper_lead: patient.helper_lead });
								}
								
								this.props.onupdPatientAdmin(clonedPatient, successCallback);
						}		

						*/

      if (field === "helper_id" && !this.props.patient.helpers_ids.length) {
        this.setState({ helper_lead: value });
      }
    }
  }

  addHelper() {
    if (Util.emptyString(this.state.helper_id) || this.state.disabled) return;

    this.setState({ disabled: true, helper_id: "" });

    var successCallback = () => this.setState({ disabled: false });

    var clonedPatient = Util.shallowClone(this.props.patient);
    if (!clonedPatient.helpers_ids) clonedPatient.helpers_ids = [];

    clonedPatient.helpers_ids.push(this.state.helper_id);

    if (clonedPatient.helpers_ids.length === 1) {
      clonedPatient.helper_lead = this.state.helper_lead;
    }

    this.props.onupdPatientAdmin(clonedPatient, successCallback);
  }

  removeHelper(patient, helperId) {
    if (this.state.disabled) return;

    var clonedPatient = Util.shallowClone(patient);

    clonedPatient.helpers_ids = clonedPatient.helpers_ids.filter(
      (id) => id !== helperId
    );
    if (clonedPatient.helper_lead === helperId)
      clonedPatient.helper_lead = null;

    var successCallback = (patient) => {
      this.setState({ disabled: false, helper_lead: patient.helper_lead });
    };

    this.props.onupdPatientAdmin(clonedPatient, successCallback);
  }

  render() {
    const { user, patient, helpers } = this.props;

    if (!patient) return null;

    var helpersNode = helpers.map((p) => {
      var disabled = false;
      if (patient.helpers_ids)
        disabled = patient.helpers_ids.find((pId) => p._id === pId);
      return (
        <option value={p._id} key={p._id} disabled={disabled}>
          {p.first_name} {p.name}
        </option>
      );
    });

    var helpersAssignmentsNodes = patient.helpers_ids.map((id) => {
      var helper = helpers.find((p) => p._id === id);
      if (!helper) return null;
      let leadId = nanoid();
      return (
        <tr key={"helper-" + helper._id}>
          <td>{helper.first_name}</td>
          <td>{helper.name}</td>
          <td>
            <div className="custom-control custom-switch mx-auto switch-success text-left">
              <input
                type="checkbox"
                id={"lead-switch" + leadId}
                disabled={user.role === Roles.ADMIN ? false : true}
                className="custom-control-input switch-bg-blue"
                onChange={(e) => {
                  this.onChange("lead", e.target.checked, helper._id);
                }}
                checked={this.state.helper_lead === helper._id}
              />
              <label
                className="custom-control-label"
                htmlFor={"lead-switch" + leadId}
              ></label>
            </div>
          </td>

          {user.role === Roles.ADMIN && (
            <td>
              <i
                id={"delete" + helper._id}
                className="fa fa-trash icon-big"
                onClick={(e) => this.removeHelper(patient, helper._id)}
              ></i>
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="top"
                target={"delete" + helper._id}
              >
                <FormattedMessage id="Delete" />
              </UncontrolledTooltip>
            </td>
          )}
        </tr>
      );
    });

    return (
      <React.Fragment>
        {user.role !== Roles.CLIENT && (
          <div className="col-12 col-lg-9 col-xl-8">
            <div className="form-group row">
              <label
                htmlFor="helper_id"
                className="col-12 col-md-5 col-form-label"
              >
                <FormattedMessage id="Assign.Helper" />
              </label>
              <div className="col-11 col-md-6">
                <select
                  className="form-control d-inline"
                  id="helper_id"
                  value={this.state.helper_id}
                  onChange={(e) => this.onChange("helper_id", e.target.value)}
                >
                  <option value=""></option>
                  {helpersNode}
                </select>
              </div>

              <div className="col-1 text-left align-left float-left p-0">
                <i
                  className="fa fa-plus-square icon-huge hoverable clickable d-inline"
                  onClick={() => this.addHelper()}
                  disabled={
                    Util.emptyString(this.state.helper_id) ||
                    this.state.disabled
                  }
                />
              </div>
            </div>
          </div>
        )}

        {patient && patient.helpers_ids && patient.helpers_ids.length > 0 && (
          <table className="table col-12 tablee4mad mt-5">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="First.Name" />
                </th>
                <th>
                  <FormattedMessage id="Name" />
                </th>
                <th>
                  <FormattedMessage id="Lead" />
                </th>
                <th>
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{helpersAssignmentsNodes}</tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    helpers: Util.filterClients(state.clients, "helper"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onupdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onupdClient: (helper, successCallback) =>
      dispatch(updClient(helper, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PatientHelpers));
