import APIUrl from "../../APIUrl";
import axios from "axios";
import { getPatientsAdmin } from "../patients/patients";

export const GET_AUDIT_EQUIPMENTS = "GET_AUDIT_EQUIPMENTS";

function getAuditEquipmentsAction(auditEquipments) {
  return { type: GET_AUDIT_EQUIPMENTS, auditEquipments: auditEquipments };
}

export const getAuditEquipments = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAuditEquipments)
      .then(function (response) {
        dispatch(getAuditEquipmentsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getAuditEquipmentsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAuditEquipmentsAdmin)
      .then(function (response) {
        dispatch(getAuditEquipmentsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addAuditEquipment = function (auditEquipment, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addAuditEquipment, auditEquipment)
      .then(function (response) {
        dispatch(getAuditEquipments());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updAuditEquipment = function (auditEquipment, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updAuditEquipment, auditEquipment)
      .then(function (response) {
        dispatch(getAuditEquipments());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteAuditEquipment = function (
  auditEquipmentId,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteAuditEquipment, { auditEquipmentId })
      .then(function (response) {
        dispatch(getPatientsAdmin());
        dispatch(getAuditEquipments());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
